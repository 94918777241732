<template>
  <div>
    <div class="digitalInfo">
      <div class="card-left">
        <div class="top">
          <i class="iconfont icon-iconcopy"></i>
          <span>办公系列</span>
        </div>
        <ul class="infos">
          <li
            class="info-item"
            :class="checked == item.id ? 'checked' : ''"
            v-for="item in menuInfo"
            :key="item.id"
            @click="replace(item)"
          >
            {{ item.text }}
          </li>
        </ul>
      </div>
      <div class="card-right">
        <div class="info" v-if="checked == 1013">
          <carbon></carbon>
        </div>
        <!-- 不干胶 -->
        <div class="info" v-if="checked == 1014">
          <self-adhesive></self-adhesive>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Carbon from "@/components/Goods/Carbon";
import SelfAdhesive from "@/components/Goods/SelfAdhesive";

export default {
  name: "officeMenu",
  components: {

    Carbon,
    SelfAdhesive
  },
  data() {

    return {

      checked: 1013, // 切换模块
      menuInfo: [
        {
          id: 1013,
          img: "carbon/Carbon1.jpg",
          text: "无碳联单",
        },
        {
          id: 1014,
          img: "selfAdhesive/SelfAdhesive2.jpg",
          text: "不干胶",
        },
      ],

    };
  },
    created() {
      let id = window.sessionStorage.getItem("leftMenu")
      if (id) {
        this.checked = id;
      }
  },
  methods: {

    // 切换列表
    replace(item) {

      this.checked = item.id
    }

  }
};
</script>

<style lang="scss" scoped>
.digitalInfo {
  padding:20px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  .card-left {
    width: 200px;
    min-height: 1000px;
    .top {
      width: 100%;
      height: 86px;
      line-height: 86px;
      text-align: center;
      background-color: #047bd7;
      color: #fff;
      font-size: 24px;
      i {
        font-size: 28px;
        margin-right: 10px;
      }
    }
    .infos {
      width: 100%;
      box-sizing: border-box;
      // border: 1px solid #e6e6e6;
      // border-top: none;
      li {
        box-sizing: border-box;
        width: 100%;
        height: 55px;
        text-align: center;
        line-height: 55px;
        border-bottom: 1px dashed #e6e6e6;
        cursor: pointer;
      }
      li:last-child {
        border-bottom: none;
      }
      .checked {
        background-color: #058af2;
        color: #fff;
        font-weight: 600;
      }
    }
  }
  .card-right {
    width: 980px;
    background-color: #fff;
  }
  .info {
    width: 100%;
    box-sizing: border-box;
    // padding: 15px;
  }
  .card {
    width: 100%;
    .card-top {
      display: flex;
      align-items: center;
      height: 50px;
      border-bottom: 1px solid #e6e6e6;
      padding: 0 30px;
      line-height: 50px;
      span {
        display: inline-block;
        width: 5px;
        height: 17px;
        background: #058af2;
        margin-right: 20px;
      }
      h3 {
        color: #058af2;
      }
    }
    .list {
      width: 100%;
      box-sizing: border-box;
      padding: 5px 30px;
      .list-top {
        display: flex;
        justify-content: space-between;
        span {
          color: #058af2;
        }
        span:last-child:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
      .list-item {
        width: 100%;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .items {
          margin-top: 10px;
          width: 255px;
          height: 155px;
          background: transparent url("../../static/imgs/beijing1.png")
            no-repeat scroll 0% 0%;
          position: relative;
          .border {
            box-sizing: border-box;
            padding: 15px;
            font-size: 14px;
            font-weight: 400;
            p {
              padding: 3px 0 5px 0;

              border-bottom: 1px solid #e6e6e6;
            }
            i {
              display: inline-block;
              margin-right: 10px;
            }
            p:nth-child(2) {
              height: 65px;
              display: flex;
              align-items: center;
              span {
                display: block;
                overflow: hidden;
                white-space: wrap;
                text-overflow: ellipsis;
              }
            }
            p:last-child {
              border-bottom: none;
            }
          }
          .top {
            position: absolute;
            top: 0;
            right: 15px;
            font-size: 14px;
            height: 20px;
            line-height: 20px;
            color: #fff;
            .top-df {
              display: none;
              background: #fbb244 none repeat scroll 0% 0%;
              padding: 0px 10px;
              border-radius: 0px 0px 5px 5px;
            }
          }
          .active {
            display: block;
            padding: 0 10px;
            background: #058af2 none repeat scroll 0% 0%;
          }
          .top:hover {
            cursor: pointer;
            text-decoration: underline;
          }
          .bottom {
            display: none;
            position: absolute;
            right: 20px;
            bottom: 8px;
            cursor: pointer;
            font-size: 14px;
            p {
              float: left;
              border: 1px solid #d4d4d4;
              margin-left: 5px;
              height: 25px;
              line-height: 25px;
              width: 40px;
              text-align: center;
              border-radius: 3px;
              background-color: #fff;
            }
            p:hover {
              color: #058af2;
            }
          }
        }
        .items-bgd {
          background: transparent url("../../static/imgs/beijing2.png")
            no-repeat scroll 0% 0%;
        }
        .items:hover .top-df {
          display: block;
        }
        .items:hover .bottom {
          display: block;
        }
        .items:nth-last-child(-n + 1) {
          height: 0;
          background: none;
        }
      }
    }
    .card-form {
      width: 50%;
      margin: 30px auto;
    }
  }
}
</style>
