import { render, staticRenderFns } from "./danye.vue?vue&type=template&id=dbdd29c6&scoped=true&"
import script from "./danye.vue?vue&type=script&lang=js&"
export * from "./danye.vue?vue&type=script&lang=js&"
import style0 from "./danye.vue?vue&type=style&index=0&id=dbdd29c6&lang=scss&scoped=true&"
import style1 from "./danye.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dbdd29c6",
  null
  
)

export default component.exports