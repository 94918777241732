import request from "@/api/request";
// const baseURL = 'http://cpsapi.yy12365.com';

//报价
function getQuoteData(productId, data) {
    return request({
        method: "get",
        url: `/cpshttp/getQuoteData?productId=${productId}`,
        data,
    });
}

//获取默认地址
function receiveadderss(operatorId, data) {
    return request({
        method: "get",
        url: `/receiveadderss/listAll?operatorId=${operatorId}`,
        data,
    });
}

//立即报价_手提袋
function getHandBag(data) {
    return request({
        method: "post",
        url: `/cpshttp/getHandBag`,
        data,
    });
}

//立即报价_单页
function getSinglePageQuotation(data) {
    return request({
        method: "post",
        url: `/cpshttp/getSinglePageQuotation`,
        data,
    });
}

//立即报价_封套
function getEnvelope(data) {
    return request({
        method: "post",
        url: `/cpshttp/getEnvelope`,
        data,
    });
}

//立即报价_普通画册
function getOrdinaryAlbum(data) {
    return request({
        method: "post",
        url: `/cpshttp/getOrdinaryAlbum`,
        data,
    });
}

//立即报价_精装画册
function getHardcoverBook(data) {
    return request({
        method: "post",
        url: `/cpshttp/getHardcoverBook`,
        data,
    });
}

//立即报价_挂历
function getWallCalendar(data) {
    return request({
        method: "post",
        url: `/cpshttp/getWallCalendar`,
        data,
    });
}

//立即报价_台历
function getDeskCalendar(data) {
    return request({
        method: "post",
        url: `/cpshttp/getDeskCalendar`,
        data,
    });
}

//立即报价_1023-纸卡盒-普通双插盒-01
function cardBox(data) {
    return request({
        method: "post",
        url: `/cpshttp/getCardBox`,
        data,
    });
}

function getDigit(data) {
    return request({
        method: "post",
        url: `/cpshttp/getDigit`,
        data,
    });
}

//立即下单
function createMallOrder(data) {
    return request({
        method: "post",
        url: `/cpshttp/createMallOrder`,
        data,
    });
}

function digit4Hardcover(data) {
    return request({
        method: "post",
        url: `/cpshttp/mallDigit4Hardcover`,
        data,
    });
}
export default {
    getQuoteData,
    receiveadderss,
    getHandBag,
    getSinglePageQuotation,
    getEnvelope,
    getOrdinaryAlbum,
    getHardcoverBook,
    getWallCalendar,
    getDeskCalendar,
    createMallOrder,
    getDigit,
    cardBox,
    digit4Hardcover
};
