<template>
    <div class="card">
        <!--<div class="card-top">-->
        <!--    <span></span>-->
        <!--    <h3>会员管理列表</h3>-->
        <!--</div>-->
        <div class="query">
            <el-select v-model="pages.userLevel" placeholder="请选择会员级别">
                <el-option v-for="item in options" :key="item.value" :label="item.label"
                           :value="item.value"></el-option>
            </el-select>
            <el-input class="input" v-model="memberPhone" placeholder="请输入手机号"></el-input>
            <el-input class="input" v-model="custName" placeholder="请输入企业名称"></el-input>
            <el-button type="primary" class="btn" @click="init">查询</el-button>
            <el-button type="primary" class="btn" @click="refresh">刷新</el-button>
        </div>
        <div class="tab">
            <el-table :data="tableData" border style="width: 100%">
                <!-- <el-table-column-->
                <!--         type="selection"-->
                <!--         width="55"-->
                <!--         :selectable="selectEnable"-->
                <!--         align="center"-->
                <!--         header-align="center"-->
                <!-- >-->
                <el-table-column width="120">
                    <template scope="scope">
                        <el-radio :label="scope.$index" v-model="radio"
                                  @change.native="getCurrentRow(scope.row)"></el-radio>
                    </template>
                </el-table-column>

<!--                <el-table-column-->
<!--                        prop="username"-->
<!--                        label="用户名称"-->
<!--                        width="180"-->
<!--                        header-align="center"-->
<!--                        align="center"-->
<!--                >-->
<!--                </el-table-column>-->
                <el-table-column
                        prop="custName"
                        label="企业名称"
                        width="180"
                        header-align="center"
                        align="center"
                >
                </el-table-column>
                <el-table-column
                        prop="newTel"
                        label="联系方式"
                        width="180"
                        header-align="center"
                        align="center"
                >
                </el-table-column>
                <el-table-column
                        label="会员级别"
                        width="150"
                        header-align="center"
                        align="center"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.userLevel }}</span>
                    </template>
                </el-table-column>
<!--                <el-table-column-->
<!--                        label="授信用户"-->
<!--                        width="180"-->
<!--                        header-align="center"-->
<!--                        align="center"-->
<!--                >-->
<!--                    <template slot-scope="scope">-->
<!--                        <span>授信金额:{{ scope.row.userFinance }}元</span>-->
<!--                    </template>-->
<!--                </el-table-column>-->
                <el-table-column
                        prop="createTime"
                        label="注册时间"
                        header-align="center"
                        align="center"
                        width="200"
                >
                </el-table-column>
            </el-table>
            <div class="page">
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pages.currentPage"
                        :page-sizes="[5, 10, 20, 30]"
                        :page-size="pages.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import api from "../../api/webAdmin/webAdmin";

    export default {
        name: "CustomerService",
        data() {
            return {
                memberPhone: "", // 会员手机号
                custName: "", // 企业名称
                options: [],
                value: "", // 查询绑定
                pages: {
                    pageSize: 5,
                    currentPage: 1,
                    userLevel: "",
                },
                total: 0,
                tableData: [],
                userId: "", // 用户id
                radio: '',

                form: {
                    user_ID: '',
                    newTel: '',
                    custName: ''
                },

                userID: '',
                userType: ''

            }
        },
        created() {
            let userInfo = JSON.parse(window.sessionStorage.getItem('currentInfo'))
            console.log(userInfo)
            this.userID = userInfo.user_ID
            this.userType = userInfo.userType

            //获取会员列表
            this.init()
        },
        methods: {
            handleSizeChange(val) {
                this.pages.pageSize = val;
                this.init();
            },
            handleCurrentChange(val) {
                this.pages.currentPage = val;
                this.init();
            },

            // 刷新表格
            refresh() {
                this.pages.userLevel = this.memberPhone = this.custName = "";
                //获取会员列表
                this.init();
            },

            //获取会员列表
            init() {
                // 获取会员列表
                api.queryUser(this.pages.currentPage, this.pages.pageSize, this.pages.userLevel, this.memberPhone, this.custName, 0).then((res) => {
                    console.log("获取会员列表", res);
                    if (res.success) {
                        this.tableData = res.data.list;
                        this.total = res.data.total;
                    } else {
                        this.$message.error("获取用户信息失败");
                    }
                }).catch((error) => {
                    console.error("error", error);
                });
                // 获取所有等级
                api.getUserLeve().then((res) => {
                    console.log("获取所有等级", res);
                    if (res.success) {
                        console.log(11);
                        res.data.forEach((el) => {
                            el.value = el.id;
                            el.label = el.name;
                        });
                        this.options = res.data;
                    } else {
                        this.$message.error("获取会员等级失败");
                    }
                }).catch((error) => {
                    console.error("error", error);
                });
            },

            getCurrentRow(row) { //获取选中数据this.templateSelection = row;
                console.log('选中行', row)
                console.log('user_ID', row.user_ID)
                // console.log('用户名称', row.phone)
                console.log('联系方式', row.newTel)
                console.log('企业名称', row.custName)
                this.form.user_ID = row.user_ID
                this.form.newTel = row.newTel
                this.form.custName = row.custName
                this.$emit('transferUser', this.form)
                // this.servicetext=row.phone
            },
        }
    }
</script>

<style lang="scss" scoped>
    .card {
        width: 100%;

        .card-top {
            display: flex;
            align-items: center;
            height: 50px;
            border-bottom: 1px solid #e6e6e6;
            padding: 0 30px;
            line-height: 50px;

            span {
                display: inline-block;
                width: 5px;
                height: 17px;
                background: #058af2;
                margin-right: 20px;
            }

            h3 {
                color: #058af2;
            }
        }

        .query {
            display: flex;
            margin-top: 10px;

            .input {
                width: 300px;
                margin-left: 10px;
            }

            .btn {
                margin-left: 10px;
            }
        }

        .tab {
            padding: 10px;

            .type {
                margin-left: 15px;
            }
        }

        .page {
            margin-top: 20px;
        }

        .dig {
            text-align: center;
        }
    }
</style>

<style lang="scss">
    .tab {
        .el-radio__label {
            display: none !important;
        }
    }
</style>
