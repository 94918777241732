<template>
    <div class="orderNow">
        <!--手提袋-->
        <table v-if="productId==1015">
            <tr>
                <th>成品尺寸(高*宽*厚)</th>
                <th>印刷数量</th>
                <th>印刷颜色</th>
                <th>印刷纸张</th>
            </tr>
            <tr>
                <td>{{info.productSpec}}</td>
                <td>{{info.productNum}}</td>
                <td>{{info.printColor}}</td>
                <td>{{info.paperName}}</td>
            </tr>
            <tr class="packType">
                <th>后道工艺</th>
                <td colspan="4">{{info.aftDesc}}</td>
            </tr>
            <tr class="packType">
                <th>包装方式</th>
                <td colspan="4">{{info.packageType}}</td>
            </tr>
        </table>

        <!--纸卡盒_双插盒_普通双插盒-->
        <table v-if="productId==1023">
            <tr class="packType">
                <th>产品类型</th>
                <td>{{info.productType}}</td>
                <th>印刷数量</th>
                <td>{{info.productNum}}</td>
            </tr>
            <tr class="packType">
                <th>成品尺寸(长*宽*高)</th>
                <td>{{info.partsList[0].productSpec}}</td>
                <th>印刷材料</th>
                <td>{{info.partsList[0].maName}}</td>
            </tr>
            <tr class="packType">
                <th>印刷颜色</th>
                <td>{{info.partsList[0].color}}</td>
                <th>后道工艺</th>
                <td>{{info.partsList[0].aftDesc}}</td>
            </tr>
            <tr class="packType">
                <th>其他参数</th>
                <td colspan="4">{{info.partsList[0].otherParam}}</td>
            </tr>
            <tr class="packType">
                <th>包装方式</th>
                <td colspan="4">{{info.packageType}}</td>
            </tr>
        </table>

        <!--挂历-->
        <table v-if="productId==1036">
            <tr>
                <th>成品尺寸(高*宽)</th>
                <th>印刷数量</th>
                <th>装订方式</th>
            </tr>
            <tr>
                <td>{{info.productSpec}}</td>
                <td>{{info.productNum}}</td>
                <td>{{info.bindingType}}</td>
            </tr>
        </table>
        <table v-if="productId==1036">
            <tr>
                <th>部件名称</th>
                <th>部件数量</th>
                <th>印刷颜色</th>
                <th>印刷纸张</th>
                <th>后道工艺</th>
            </tr>
            <tr v-for="(item,index) in info.partsList" :key="index">
                <td style="font-weight: bold">{{item.partsName}}</td>
                <td>{{item.pNum}}</td>
                <td>{{item.printColor}}</td>
                <td>{{item.paperName}}</td>
                <td>{{item.aftDesc}}</td>
            </tr>
            <tr class="packType">
                <th v-if="orderProduct.needDesign === '1'">设计</th>
                <td v-if="orderProduct.needDesign === '1'">{{orderProduct.pNums}}p</td>
                <td style="font-weight: bold">包装方式</td>
                <td :colspan="orderProduct.needDesign === '1'?2:4">{{info.packageType}}</td>
            </tr>
        </table>

        <div class="cost"
             style="display: flex;margin-top: 10px;justify-content: space-evenly;line-height: 30px;">
            <div>
                <span class="text" style="color: #999; font-size: 12px">系统报价：</span>
                <span style="color: #ed6d00;font-size: 14px;font-weight: 700;font-family: tahoma;">￥{{orderProduct.sysFee}}</span>
            </div>
            <div>
                <span class="text" style="color: #999; font-size: 12px">运费：</span>
                <span style="color: #ed6d00;font-size: 14px;font-weight: 700;font-family: tahoma;">￥{{info.postFee}}</span>
            </div>
            <div>
              <span class="text" style="color: #999; font-size: 12px">税费：</span>
              <span style="color: #ed6d00;font-size: 14px;font-weight: 700;font-family: tahoma;">￥{{info.invoiceFee}}</span>
            </div>
            <div>
                <span class="text" style="color: #999; font-size: 12px">成交价：</span>
                <span style="color: #ed6d00;font-size: 24px;font-weight: 700;font-family: tahoma;">￥{{orderProduct.productFee}}</span>
            </div>
          <div >
            <el-button type="text"  v-if="userType == 1" @click="openFeeDetail1(orderProduct.orderExtendInfoJson)">报价明细</el-button>
          </div>
        </div>

        <!--下单信息-->
        <div class="ordernowList">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px"
                     class="demo-ruleForm">
                <el-form-item label="印件名称" prop="name">
                    <el-input v-model="ruleForm.name" placeholder="请选择印件名称"></el-input>
                    <span class="grey">请输入印刷品最显著的标题</span>
                </el-form-item>
                <div class="line">

                    <el-form-item label="色彩要求" prop="color">
                        <el-select v-model="ruleForm.color" placeholder="请选择色彩要求">
                            <el-option v-for="(item,index) in colourList" :key="index" :label="item"
                                       :value="item"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="发货日期" required>
                        <el-form-item prop="date">
                            <el-date-picker type="date" placeholder="选择日期" v-model="ruleForm.date"
                                            :picker-options="pickerOptions0"
                                            style="width: 100%;"></el-date-picker>
                        </el-form-item>
                    </el-form-item>

                </div>

                <div class="line" v-if="mustaddr!=1">

                    <el-form-item label="收货人" prop="consignee">
                        <el-input v-model="ruleForm.consignee" placeholder="请选择收货人"></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话" prop="tel">
                        <el-input v-model="ruleForm.tel" placeholder="请选择联系电话"></el-input>
                    </el-form-item>

                </div>

                <el-form-item label="收货地址" v-if="mustaddr!=1">
                    <span class="black">{{orderProduct.quoteData.dest}}</span>
                    <el-input v-model="ruleForm.address"></el-input>
                    <span class="grey">如需配送多个地址，请下单后在订单管理页面修改</span>
                </el-form-item>

                <el-form-item label="其他备注">
                    <el-input type="textarea" v-model="ruleForm.desc"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')"
                               v-loading.fullscreen.lock="fullscreenLoading2">立即下单
                    </el-button>
                    <el-button @click="resetForm('ruleForm')">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
      <el-dialog title="报价明细" :visible.sync="feeDialogVisible"       :append-to-body="true">
        <el-table :data="feeList" border>
          <el-table-column prop="broadHeading" label="大类"></el-table-column>
          <el-table-column prop="pronname" label="小类"></el-table-column>
          <el-table-column prop="provalue" label="公式"></el-table-column>
          <el-table-column prop="value" label="价格"></el-table-column>
        </el-table>
      </el-dialog>
    </div>

</template>

<script>
    import api from "../../api/goods/goods";

    export default {
        name: "quotationDetails",
        props: ["productId","userfatherid", "user_ID", "info", "orderProduct", "mustaddr", "consignee", "tel", "address"],
        data() {
            // 手机验证
            var validateMobilePhone = (rule, value, callback) => {
                if (value === "") {
                    callback(new Error("手机号不可为空"));
                } else {
                    if (value !== "") {
                        var reg = /^1[3456789]\d{9}$/;
                        if (!reg.test(value)) {
                            callback(new Error("请输入有效的手机号码"));
                        }
                    }
                    callback();
                }
            };

            return {
              userType: '',
              feeList: [],
              feeDialogVisible: false,
                // 设置只能选择当前日期及之后的日期
                pickerOptions0: {
                    disabledDate(time) {
                        //如果没有后面的-8.64e7就是不可以选择今天的
                        return time.getTime() < Date.now() - 8.64e7;
                    }
                },

                //报价详情_指令加载方式
                fullscreenLoading2: false,

                colourList: ['正常印刷，无特别要求', '显示屏，鲜艳饱和', '有印样，严格追样（不含数码样）', '有样稿，仅供参考', '样稿未到，到样时间：', '看兰纸', '预约上机看样', '其他'],//色彩要求列表
                paymentMethodList: ['全款下单', '预付30%下单', '货到付款', '带款提货', '账期客户'],//付款方式列表
                ruleForm: {
                    name: '',//印件名称
                    color: '',//色彩要求
                    date: '',//发货日期
                    consignee: '',//收货人
                    tel: '',//联系电话
                    address: '',//收货地址
                    paymentMethod: '',//付款方式
                    desc: ''//备注
                },
                rules: {
                    name: [
                        {required: true, message: '请输入印件名称', trigger: 'blur'},
                        {min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur'}
                    ],
                    // color: [
                    //     {required: true, message: '请选择色彩要求', trigger: 'change'}
                    // ],
                    date: [
                        {type: 'date', required: true, message: '请选择发货日期', trigger: 'change'}
                    ],
                    consignee: [
                        {required: true, message: '请输入收货人', trigger: 'blur'},
                    ],
                    tel: [
                        {required: true, message: '请输入联系电话', validator: validateMobilePhone, trigger: 'blur'},
                    ],
                    // address: [
                    //     {required: true, message: '请输入收货地址', trigger: 'blur'},
                    // ]
                },

            }
        },
        created() {
           let userInfo = JSON.parse(window.sessionStorage.getItem('currentInfo'))
            this.userType = userInfo.userType
            console.log(this.info)
            //产品类型ID
            console.log(this.productId)
            console.log(this.orderProduct)
            // this.orderProduct.quoteData = JSON.parse(this.orderProduct.quoteData)
            console.log(this.mustaddr)
            this.ruleForm.consignee = this.consignee
            this.ruleForm.address = this.address
            this.ruleForm.tel = this.tel
            //色彩要求
            this.ruleForm.color = this.colourList[0]
        },
        methods: {
          openFeeDetail1(feeJson){
            this.feeList = JSON.parse(feeJson);
            this.feeDialogVisible = true
          },
            //发货日期
            //时间转换
            //中国标准时间字符串，转换成y-m-d:h-m-s格式
            time(date) {
                var y = date.getFullYear()
                var m = date.getMonth() + 1
                m = m < 10 ? '0' + m : m
                var d = date.getDate()
                d = d < 10 ? '0' + d : d
                var h = date.getHours()
                h = h < 10 ? '0' + h : h
                var minute = date.getMinutes()
                minute = minute < 10 ? '0' + minute : minute
                var second = date.getSeconds()
                second = second < 10 ? '0' + second : second
                // return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second
                return y + '-' + m + '-' + d
            },

            //立即下单
            submitForm(formName) {

                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        // alert('submit!');
                        console.log(this.ruleForm);
                        // this.$emit('transferUser ',this.ruleForm)
                        // this.$router.push({ name: this.name, params: { xinxi: this.ruleForm } });

                        let userInfo = JSON.parse(window.sessionStorage.getItem('currentInfo'))
                        console.log(userInfo)

                        let ruleForm = this.ruleForm

                        this.centerDialogVisible2 = false
                        let data = {
                            //---- 产品信息【点击（产品报价）返回信息】
                            orderProduct: this.orderProduct,
                            //---- 印件名称
                            orderName: ruleForm.name,
                            //---- 色彩要求【暂无】
                            printType: ruleForm.color,
                            //---- 发货日期
                            completiontime: this.time(new Date(ruleForm.date)),
                            //---- 收货人
                            contacts: ruleForm.consignee,
                            //---- 印厂标识【相当于前面的userfatherid】
                            enterpriseId: this.userfatherid,
                            //---- 客户其他备注
                            customSupply: ruleForm.desc,
                            //---- 联系方式
                            telephone: ruleForm.tel,
                            //---- 订单费用
                            orderFee: this.info.totalFee,
                            //---- 送货地址【报价当中的dest + 详情】
                            sendAddrs: ruleForm.address,
                            //---- 配送方式ID
                            // postTypeId:'',
                            //---- 预付款金额
                            preFee: this.info.totalFee * 0.3,
                            //---- 付款方式
                            // paytype: ruleForm.paymentMethod,
                            //---- 用户唯一性标识
                            userid: userInfo.user_ID,
                            //---- 代客下单中客户user_id
                            customerId: this.user_ID == '' ? userInfo.user_ID : this.user_ID,
                        }
                        console.log(data)

                        this.fullscreenLoading2 = true;
                        setTimeout(() => {
                            api.createMallOrder(data).then((res) => {
                                console.log(res)
                                this.fullscreenLoading2 = false;
                                if (res.code == 200) {
                                    this.$message({
                                        message: "下单成功",
                                        type: "success",
                                    });
                                    this.$router.push({name: "Personal", params: {chiecked: 2}});
                                } else {
                                    this.$message.error(res.message);
                                }
                            })
                        }, 2000)
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },

            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
        }
    }
</script>

<style lang="scss" scoped>
    /*立即下单详细信息*/
    .ordernowList {
        margin: 20px auto 0px;
        /*border: 1px solid #EBEEF5;*/

        /*请输入印刷品最显著的标题*/
        .grey {
            line-height: 43px;
            font-size: 14px;
            color: #999;
            font-weight: normal;
            margin-left: 15px;
        }

        /*地址*/
        .black {
            line-height: 43px;
            font-size: 14px;
            color: #333;
            font-weight: normal;
            margin-right: 15px;
        }
    }

    .el-checkbox-group {
        ::v-deep .el-checkbox {
            width: auto !important;
        }
    }
</style>
