<template>
  <div>
    <div class="personal">
      <div class="nav">
        <div class="ban">
          <ul class="nav-top">
            <li class="yinyou">
              <!-- <i class="img">我的会员</i> -->
              <span class="myProduct">全部产品分类</span>
            </li>
            <li v-for="item in classInfo" :key="item.id" @click="changes(item)">
              <span>{{ item.text }}</span>
              <i :class="chiecked == item.id ? 'chiecked' : ''"></i>
            </li>
          </ul>
        </div>
      </div>
      <div class="ban">
        <digital ref="digital" v-show="chiecked == 0"></digital>
        <business ref="business" v-show="chiecked == 1"></business>
        <packages  ref="packages"  v-show="chiecked == 2"></packages>
        <postprocess ref="postprocess" v-show="chiecked == 3"></postprocess>
        <office ref="office" v-show="chiecked == 4"></office>
        <live ref="live" v-show="chiecked == 5"></live>
      </div>
    </div>
  </div>
</template>

<script>
import digital from "@/components/Goods/DigitalMenu";
import business from "@/components/Goods/BusinessMenu";
import packages from "@/components/Goods/PackageMenu";
import postprocess from "@/components/Goods/PostProcessMenu";
import office from "@/components/Goods/OfficeMenu";
import live from "@/components/Goods/LiveMenu";
export default {
  name: "quotation",
  components: {
    digital,
    business,
    packages,
    postprocess,
    office,
    live,
  },
  data() {
    return {
      chiecked: 0,
      classInfo: [
        {
          id: 0,
          img: "icon_product10.png",
          text: "数码快印",
          info: "数码快印 小批量定制",

        },
        {
          id: 1,
          img: "icon_product01.png",
          text: "商务印刷",
          info: "手拎袋 画册",

        },
        {
          id: 2,
          img: "icon_product02.png",
          text: "产品包装",
          info: "单层卡纸彩盒 瓦楞彩箱",

        },
        {
          id: 3,
          img: "icon_product03.png",
          text: "后期加工",
          info: "喷画 条幅 标牌",

        },
        // {
        //   id: 4,
        //   img: "icon_product04.png",
        //   text: "办公系列",
        //   info: "无碳联单 不干胶",
        //
        // },
        // {
        //   id: 5,
        //   img: "icon_product05.png",
        //   text: "生活常用",
        //   info: "挂历 台历",
        //
        // },
      ],

      userType: 0,
    };
  },
  computed: {

  },
  created() {
    let userObj = JSON.parse(window.sessionStorage.getItem("userInfo"));
    if (userObj === null) {
      // this.$message.warning("请先登录！");
      this.$router.push({ path: "/login" });
      return;
    }

    let id = window.sessionStorage.getItem("topMenu")
    if (id) {
      this.chiecked = id;
    }
  },
  mounted() {
    if (this.chiecked === 2) {
      console.log("执行了跳转");
      // this.$refs.order.init();
    }
  },
  methods: {

    // 动态添加下标
    changes(item) {
      this.chiecked = item.id;
      if(item.id == 0){
        this.$refs.digital.replace({id: 2028});
      }
      if(item.id == 1){
        this.$refs.business.replace({id: 1015});
      }
      if(item.id == 2){
        this.$refs.packages.replace({id: 1023});
      }
      if(item.id == 3){
        this.$refs.postprocess.replace({id: 1016});
      }
      if(item.id == 4){
        this.$refs.office.replace({id: 1013});
      }
      if(item.id == 5){
        this.$refs.live.replace({id: 1035});
      }


    },
  },
};
</script>

<style lang="scss" scoped>
.personal {
  width: 100%;
  background-color: #f8f8f8;
  .nav {
    width: 100%;
    background-color: #0289f2;
    .nav-top {
      display: flex;
      li {
        width: 160px;
        font-size: 16px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        color: #fff;
        position: relative;
        cursor: pointer;
        .chiecked {
          width: 12px;
          height: 8px;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: 0 auto;
          background: url("../../static/imgs/icon_triangle_white.png") no-repeat
          center;
          background-size: cover;
        }
      }
      .yinyou {
        width: 160px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        background-color: #047bd7;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        // .img {
        //   display: block;
        //   width: 98px;
        //   height: 23px;
        //   background: url("../../static/imgs/personalCenter/mineyy.png")
        //     no-repeat center;
        // }
        .myProduct {
          display: block;
          font-size: 20px;
          font-weight: 700;
          color: #fff;
        }
      }
    }
  }
  .ban {
    width: 1200px;
    margin: 0 auto;
    font-size: 16px;
  }
}
</style>
