<template>
    <div>
        <div>
            <div class="title">数码普通画册报价</div>
            <hr/>
        </div>
        <div class="body">

            <div class="neirong">
                <!--基本参数-->
                <div class="modular canshu">
                    <h4>基本参数</h4>
                    <hr/>
                    <el-form :model="form" label-width="80px" ref="form">
                      <div class="chengpin">
                        <el-form-item label="品质要求">
                          <el-select v-model="form.level" placeholder="请选择品质要求">
                            <el-option  v-for="item in qualitylist"  :label="item.name" :value="item.id"   @click.native ="showLevelPic(item)"></el-option>
                          </el-select>
                          <sapn @click="viewImgVisible = true" style="margin-left: 15px;" title="点击查看印刷机"><img src="../../static/imgs/help.png" /></sapn>
                          <span class="class">是否设计</span>
                          <el-select
                              v-model="form.needDesign" >
                            <el-option
                                label="否"
                                value="0"
                            ></el-option>
                            <el-option
                                label="是"
                                value="1"
                            ></el-option>
                          </el-select>

                          <span class="class"  v-if="form.needDesign==='1'" >  <el-input    placeholder="请输入需要设计的p数" v-model="form.pNums" style="width: 180px;" ></el-input> p</span>

                        </el-form-item>
                      </div>
                        <!--成品尺寸-->
                        <div class="chengpin">
                            <el-form-item label="成品尺寸">
                                <el-select placeholder="请选择成品尺寸" v-model="form.size">
                                    <el-option :key="item.commonspecid" :label="item.name" :value="item.commonspecid"
                                               v-for="item in specList"></el-option>
                                    <el-option :label="zdysize" value="1">
                                        <el-button @click="centerDialogVisible = true" type="text">自定义</el-button>
                                    </el-option>
                                </el-select>
                                <span class="class">(宽*高)mm</span>

                              <span class="class">印刷数量</span>
                                  <el-input
                                      type="number"
                                      v-model="form.states"
                                      placeholder="请输入印刷本数"
                                      :step="1"
                                      :min="minstates"
                                      style="width: 200px;"
                                      :controls = "false"
                                  />
                                  <span class="class">本</span>

                            </el-form-item>
                        </div>

                        <!--成品尺寸自定义对话框-->
                        <div class="zidingyi">
                            <el-dialog
                                    :visible.sync="centerDialogVisible"
                                    center
                                    title="设置自定义尺寸"
                                    width="30%"
                            >
                                <el-form :model="cpsize">
                                    <el-form-item :label-width="formLabelWidth" label="宽">
                                        <el-input autocomplete="off" v-model="cpsize.width"></el-input>
                                        <span>mm</span>
                                    </el-form-item>
                                    <el-form-item :label-width="formLabelWidth" label="高">
                                        <el-input autocomplete="off" v-model="cpsize.height"></el-input>
                                        <span>mm</span>
                                    </el-form-item>
                                </el-form>
                                <span class="dialog-footer" slot="footer">
                                  <!--<el-button @click="centerDialogVisible = false">取 消</el-button>-->
                                  <el-button @click="tijiao();"
                                             type="primary">提交</el-button>
                                </span>
                            </el-dialog>
                        </div>





                        <!--装订方式-->
                        <div class="chengpin">
                            <el-form-item label="装订方式">
                                <el-radio-group v-model="form.binding">
                                    <el-radio
                                            :key="item.binding"
                                            :label="item.binding"
                                            v-for="item in bindingList"
                                    >
                                        {{ item.name }}
                                    </el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </div>

                    </el-form>
                </div>

                <!--封面设置-->
                <div class="modular canshu fm">
                    <span class="titleh4" style="display: flex; justify-content: space-between">
                        <h4>封面设置</h4>
                        <el-button @click="coverVisible = true" type="text">特殊封面选择</el-button>
                    </span>

                    <el-dialog
                            :visible.sync="coverVisible"
                            center
                            title="选择封面类型"
                            width="30%"
                    >
                        <el-form :model="form">
                            <el-form-item label="">
                                <el-radio-group v-model="form.cover">
                                    <el-radio label="0" v-model="form.cover">无封面</el-radio>
                                    <el-radio label="1" v-model="form.cover">
                                        <el-input v-model="form.input1"></el-input>
                                        款封面
                                    </el-radio>
                                    <el-radio label="2" v-model="form.cover">封面封底加勒口</el-radio>
                                    <el-radio label="3" v-model="form.cover">封面加2P拉页</el-radio>
                                    <el-radio label="4" v-model="form.cover">封面加4P拉页</el-radio>
                                    <el-radio label="5" v-model="form.cover">封面封底各加2P拉页</el-radio>
                                    <!--<el-radio label="6" v-model="form.cover">-->
                                    <!--封三连体封套-->
                                    <!--<el-input v-model="form.input2"></el-input>-->
                                    <!--款-->
                                    <!--</el-radio>-->
                                    <!--<el-radio label="8" v-model="form.cover">封三分体封套</el-radio>-->
                                </el-radio-group>
                            </el-form-item>
                        </el-form>

                        <span class="dialog-footer" slot="footer">
                              <!--                          <el-button @click="coverVisible = false">取 消</el-button>-->
                                          <el-button @click="coverVisible = false;coverdetermine();"
                                                     type="primary">提 交</el-button>
                                        </span>
                    </el-dialog>

                    <hr/>

                    <!--无封面-->
                    <el-form :model="form" label-width="80px" ref="form" v-if="form.cover == 0"></el-form>
                    <!--?款封面-->
                    <el-form :model="form" label-width="80px" ref="form" v-if="form.cover == 1">
                        <div :key="index" v-for="(items, index) in +form.input1">
                            <!--封面类型&封面-->
                            <div class="chengpin">
                                <el-form-item label="封面类型">
                                    <span class="class">封面封底</span>
                                </el-form-item>
                                <el-form-item label="封面" v-if="form.input1 == '1'">
                                    <span class="class">4P</span>
                                </el-form-item>
                                <el-form-item :label="'封面' + items" class="fengmian" v-else>
                                    <el-input
                                            @change="inputChange"
                                            v-model="benshu[index]"
                                    ></el-input>
                                    本
                                    <span class="class">4P</span>
                                </el-form-item>
                            </div>

                            <!--封面纸张-->
                            <div class="chengpin">
                                <el-form-item label="封面纸张">
                                    <el-select @change="Changetype($event, index)" placeholder="请选择封面纸张"
                                               v-model="form.coverTypes[index]">
                                        <el-option
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.id"
                                                v-for="item in covermaList"
                                        ></el-option>
                                    </el-select>
                                    <span class="class"></span>
                                    <el-select placeholder="请选择克" v-model="form.coverGrams[index]">
                                        <el-option
                                                :key="item.weightid"
                                                :label="item.name"
                                                :value="item.weightid"
                                                v-for="item in productWeightList"
                                        ></el-option>
                                    </el-select>
                                    <span class="class">克</span>
                                    <el-checkbox v-model="isCustMafm">自带料</el-checkbox>
                                </el-form-item>
                            </div>

                            <!--印刷颜色-->
                            <div class="chengpin">
                                <el-form-item label="印刷颜色">
                                    <el-select placeholder="请选择单双面" v-model="form.singles[index]">
                                        <el-option label="单面" value="color"></el-option>
                                        <el-option label="双面" value="colors"></el-option>
                                    </el-select>
                                    <span class="class"></span>
                                    <el-select placeholder="请选择印刷颜色" v-model="form.colours[index]">
                                        <el-option
                                                :key="item.colorid"
                                                :label="item.name"
                                                :value="item.colorid"
                                                v-for="item in colorList"
                                        ></el-option>
                                        <el-option label="不印刷" value="">不印刷</el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="houdao">
                                <el-form-item label="封面其他工艺" label-width="100px">
                                    <el-checkbox v-model="checked[index]" @change="CheckedChange($event, index)">{{
                                        checkedtext }}
                                    </el-checkbox>
                                </el-form-item>
                                <el-dialog
                                        :visible.sync="centerDialogVisible4"
                                        center
                                        title="设置封面其他工艺"
                                        width="70%"
                                        :before-close="handleClose1"
                                >
                                    <div style="height: 300px; overflow: auto">
                                        <el-checkbox-group @change="handleCheckedChange" v-model="form.checkedCheckbox">
                                            <div v-for="item in processList" :key="item.id" style="width: 40%;">
                                              <el-checkbox :label="item.id" :value="item.id" v-if="item.mustchoose == 1 && item.defaultShow != 1"
                                                           :checked="true"
                                                           disabled>
                                                {{ item.name }}

                                                <div v-if="item.setacreage == 0">
                                                  <div v-for="i in setacreage0" :key="i.id">
                                                    <el-form ref="form" label-width="80px" v-if="item.id == i.id">
                                                      <el-form-item label="">
                                                        <el-select
                                                            v-model="i.models"
                                                            @change="selecChange"
                                                            :placeholder="'请选择' + i.name"
                                                            style="width: 80px;"
                                                        >
                                                          <el-option
                                                              v-for="(items, index) in i.processesSetList"
                                                              :key="index"
                                                              :label="items.name"
                                                              :value="items.id"
                                                          ></el-option>
                                                        </el-select>
                                                      </el-form-item>
                                                    </el-form>
                                                  </div>
                                                </div>
                                              </el-checkbox>

                                              <el-checkbox :label="item.id" :value="item.id" v-if="item.mustchoose !== 1"
                                                           :checked="item.defaultShow == 1">
                                                {{ item.name }}
                                              </el-checkbox>

                                                <div v-if="item.setacreage == 0 && item.mustchoose !== 1">
                                                    <div :key="i.id" v-for="i in setacreage0">
                                                        <el-form label-width="80px" ref="form" v-if="item.id == i.id">
                                                            <el-form-item label="">
                                                                <el-select :placeholder="'请选择' + i.name"
                                                                           style="width: 100px;"
                                                                           @change="selecChange" v-model="i.models">
                                                                    <el-option
                                                                            :key="indexx"
                                                                            :label="items.name"
                                                                            :value="items.id"
                                                                            v-for="(items, indexx) in i.processesSetList"
                                                                    ></el-option>
                                                                </el-select>
                                                            </el-form-item>
                                                        </el-form>
                                                    </div>
                                                </div>

                                                <div v-if="item.setacreage == 1">
                                                    <div v-for="i in setacreage0" :key="i.id">
                                                        <el-form ref="form" :model="form" label-width="80px"
                                                                 v-if="item.id == i.id">

                                                            <el-form-item label="" :label-width="formWidth"
                                                                          v-if="(i.num >0)&&item.processesSetList[0].id==i.processesSetList[0].id">

                                                                <el-form-item label="" class="input" :id="item.id"
                                                                              v-for="(a,index) in +i.num" :key="index">
                                                                    <el-form-item label="" :label-width="formWidth">
                                                                        <el-input v-model="i.inSize[index].width"
                                                                                  autocomplete="off"
                                                                                  :id="i.id"
                                                                                  @change="widthChange($event,i,index)"></el-input>
                                                                        <span>X</span>
                                                                    </el-form-item>
                                                                    <el-form-item label="" :label-width="formWidth">
                                                                        <el-input v-model="i.inSize[index].length"
                                                                                  autocomplete="off"
                                                                                  @change="lengthChange($event,i,index)"></el-input>
                                                                        <span>mm</span>
                                                                        <el-button v-if="a==1" type="primary"
                                                                                   class="newlyAdded"
                                                                                   @click="newAdded(item, i)">新增
                                                                        </el-button>
                                                                        <el-button v-else type="primary"
                                                                                   class="newlyAdded"
                                                                                   @click="del(item, i)">删除
                                                                        </el-button>
                                                                    </el-form-item>
                                                                </el-form-item>

                                                            </el-form-item>
                                                        </el-form>
                                                    </div>
                                                </div>

                                              <div v-if="item.setacreage == 2" style="margin-bottom: 20px;">
                                                <div v-for="i in setacreage0" :key="i.id">
                                                  <el-form
                                                      ref="form"
                                                      :model="form"
                                                      label-width="80px"
                                                      v-if="item.id == i.id"
                                                  >

                                                    <div label=""
                                                         v-if="(i.num >0)&&item.processesSetList[0].id==i.processesSetList[0].id"  style="margin-top: -30px;margin-bottom: 20px;">

                                                      <div label="" class="input" :id="item.id"
                                                           v-for="(a,index) in +i.num" :key="index" style="width: 380px; height: 41px;">

                                                        <el-form-item label="" v-if="item.id == i.id"   style="display:inline-flex;">
                                                          <select
                                                              v-model="i.inSize[index].models"
                                                              :placeholder="'请选择' + i.name"
                                                              class="myselect"
                                                              @change="selecChange1(i.inSize[index].models,i,index)"
                                                          >
                                                            <option
                                                                v-for="(items, index) in i.processesSetList"
                                                                :key="index"
                                                                :label="items.name"
                                                                :value="items.id + ',' + items.name"
                                                            ></option>
                                                          </select>
                                                        </el-form-item>
                                                        <el-form-item label=""  style="display:inline-flex;">
                                                          长<el-input-number v-model="i.inSize[index].width" autocomplete="off"
                                                                           placeholder="长度" :controls ="false"
                                                                           :id="i.id" style="width: 60px;"
                                                                           @change="widthChange($event,i,index)"></el-input-number>
                                                          <span >X</span>
                                                        </el-form-item>
                                                        <el-form-item label=""  style="display:inline-flex;">
                                                         宽<el-input-number v-model="i.inSize[index].length"   style="width: 60px;"
                                                                           placeholder="宽度" :controls ="false"
                                                                           @change="lengthChange($event,i,index)"></el-input-number>
                                                          <span >mm</span>

                                                        </el-form-item>
                                                        <el-form-item label=""  style="display:inline-flex;">

                                                          <el-button v-if="a==1" type="text" class="newlyAdded"  style="margin-top: 20px;"
                                                                     @click="newAdded(item, i)">增
                                                          </el-button>
                                                          <el-button v-else type="text" class="newlyAdded"  style="margin-top: 20px;"
                                                                     @click="del(item, i)">删
                                                          </el-button>
                                                        </el-form-item>

                                                      </div>

                                                    </div>



                                                  </el-form>
                                                </div>
                                              </div>

                                                <div v-if="item.setacreage == 3">
                                                    <div :key="i.id" v-for="i in setacreage0">
                                                        <el-form :model="form" label-width="80px" ref="form"
                                                                 v-if="item.id == i.id">
                                                            <el-form-item label="">
                                                                <el-select :placeholder="'请选择' + i.name"
                                                                           @change="selecChange" v-model="i.models">
                                                                    <el-option
                                                                            :key="items.id"
                                                                            :label="items.name"
                                                                            :value="items.id"
                                                                            v-for="items in i.processesSetList"
                                                                    ></el-option>
                                                                </el-select>
                                                            </el-form-item>
                                                            <el-form-item label="数量" label-width="45px"
                                                                          class="setacreage3_desc">
                                                                <el-input
                                                                        @change="textareaChange($event,i,index)"
                                                                        type="textarea"
                                                                        v-model="i.desc"
                                                                ></el-input>
                                                            </el-form-item>
                                                        </el-form>
                                                    </div>
                                                </div>
                                            </div>
                                        </el-checkbox-group>
                                    </div>

                                    <span class="dialog-footer" slot="footer">
                                        <el-button @click="cancel1(index)">取 消</el-button>
                                        <el-button @click="centerDialogVisible4 = false;Submit(index);" type="primary">提 交</el-button>
                                    </span>
                                </el-dialog>
                            </div>
                        </div>
                    </el-form>
                    <!--封面封底加勒口-->
                    <el-form :model="form" label-width="80px" ref="form" v-if="form.cover == 2">
                        <div :key="index" v-for="(items, index) in +StrangleMouth.length">
                            <!--封面类型&封面-->
                            <div class="chengpin">
                                <el-form-item label="封面类型">
                                    <span class="class">封面封底加勒口</span>
                                </el-form-item>
                                <el-form-item class="fengmian" label="勒口尺寸">
                                    <p>
                                        <span class="class">封面勒口</span>
                                        <el-input @change="inputChange" v-model="fm"></el-input>
                                        <span class="class">mm</span>
                                    </p>
                                    <p>
                                        <span class="class">封底勒口</span>
                                        <el-input @change="inputChange" v-model="fd"></el-input>
                                        <span class="class">mm</span>
                                    </p>
                                </el-form-item>
                            </div>

                            <!--封面纸张-->
                            <div class="chengpin">
                                <el-form-item label="封面纸张">
                                    <el-select @change="Changetype($event, index)" placeholder="请选择封面纸张"
                                               v-model="form.coverTypes[index]">
                                        <el-option
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.id"
                                                v-for="item in covermaList"
                                        ></el-option>
                                    </el-select>
                                    <span class="class"></span>
                                    <el-select placeholder="请选择克" v-model="form.coverGrams[index]">
                                        <el-option
                                                :key="item.weightid"
                                                :label="item.name"
                                                :value="item.weightid"
                                                v-for="item in productWeightList"
                                        ></el-option>
                                    </el-select>
                                    <span class="class">克</span>
                                    <el-checkbox v-model="isCustMafm">自带料</el-checkbox>
                                </el-form-item>
                            </div>

                            <!--印刷颜色-->
                            <div class="chengpin">
                                <el-form-item label="印刷颜色">
                                    <el-select placeholder="请选择单双面" v-model="form.singles[index]">
                                        <el-option label="单面" value="color"></el-option>
                                        <el-option label="双面" value="colors"></el-option>
                                    </el-select>
                                    <span class="class"></span>
                                    <el-select placeholder="请选择印刷颜色" v-model="form.colours[index]">
                                        <el-option
                                                :key="item.colorid"
                                                :label="item.name"
                                                :value="item.colorid"
                                                v-for="item in colorList"
                                        ></el-option>
                                        <el-option label="不印刷" value="">不印刷</el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="houdao">
                                <el-form-item label="封面其他工艺" label-width="100px">
                                    <el-checkbox v-model="checked[index]" @change="CheckedChange($event, index)">{{
                                        checkedtext }}
                                    </el-checkbox>
                                </el-form-item>
                                <el-dialog
                                        :visible.sync="centerDialogVisible4"
                                        center
                                        title="设置封面其他工艺"
                                        width="70%"
                                        :before-close="handleClose1"
                                >
                                    <div style="height: 452px; overflow: auto">
                                      <el-checkbox-group @change="handleCheckedChange" v-model="form.checkedCheckbox">
                                        <div v-for="item in processList" :key="item.id" style="width: 40%;">
                                          <el-checkbox :label="item.id" :value="item.id" v-if="item.mustchoose == 1 && item.defaultShow != 1"
                                                       :checked="true"
                                                       disabled>
                                            {{ item.name }}

                                            <div v-if="item.setacreage == 0">
                                              <div v-for="i in setacreage0" :key="i.id">
                                                <el-form ref="form" label-width="80px" v-if="item.id == i.id">
                                                  <el-form-item label="">
                                                    <el-select
                                                        v-model="i.models"
                                                        @change="selecChange"
                                                        :placeholder="'请选择' + i.name"
                                                        style="width: 80px;"
                                                    >
                                                      <el-option
                                                          v-for="(items, index) in i.processesSetList"
                                                          :key="index"
                                                          :label="items.name"
                                                          :value="items.id"
                                                      ></el-option>
                                                    </el-select>
                                                  </el-form-item>
                                                </el-form>
                                              </div>
                                            </div>
                                          </el-checkbox>

                                          <el-checkbox :label="item.id" :value="item.id" v-if="item.mustchoose !== 1"
                                                       :checked="item.defaultShow == 1">
                                            {{ item.name }}
                                          </el-checkbox>

                                          <div v-if="item.setacreage == 0 && item.mustchoose !== 1">
                                            <div :key="i.id" v-for="i in setacreage0">
                                              <el-form label-width="80px" ref="form" v-if="item.id == i.id">
                                                <el-form-item label="">
                                                  <el-select :placeholder="'请选择' + i.name"
                                                             style="width: 100px;"
                                                             @change="selecChange" v-model="i.models">
                                                    <el-option
                                                        :key="indexx"
                                                        :label="items.name"
                                                        :value="items.id"
                                                        v-for="(items, indexx) in i.processesSetList"
                                                    ></el-option>
                                                  </el-select>
                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 1">
                                            <div v-for="i in setacreage0" :key="i.id">
                                              <el-form ref="form" :model="form" label-width="80px"
                                                       v-if="item.id == i.id">

                                                <el-form-item label="" :label-width="formWidth"
                                                              v-if="(i.num >0)&&item.processesSetList[0].id==i.processesSetList[0].id">

                                                  <el-form-item label="" class="input" :id="item.id"
                                                                v-for="(a,index) in +i.num" :key="index">
                                                    <el-form-item label=""  >
                                                      <el-input v-model="i.inSize[index].width"
                                                                autocomplete="off"
                                                                :id="i.id"
                                                                @change="widthChange($event,i,index)"></el-input>
                                                      <span>X</span>
                                                    </el-form-item>
                                                    <el-form-item label=""  >
                                                      <el-input v-model="i.inSize[index].length"
                                                                autocomplete="off"
                                                                @change="lengthChange($event,i,index)"></el-input>
                                                      <span>mm</span>
                                                      <el-button v-if="a==1" type="primary"
                                                                 class="newlyAdded"
                                                                 @click="newAdded(item, i)">新增
                                                      </el-button>
                                                      <el-button v-else type="primary"
                                                                 class="newlyAdded"
                                                                 @click="del(item, i)">删除
                                                      </el-button>
                                                    </el-form-item>
                                                  </el-form-item>

                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 2" style="margin-bottom: 20px;">
                                            <div v-for="i in setacreage0" :key="i.id">
                                              <el-form
                                                  ref="form"
                                                  :model="form"
                                                  label-width="80px"
                                                  v-if="item.id == i.id"
                                              >

                                                <div label=""
                                                     v-if="(i.num >0)&&item.processesSetList[0].id==i.processesSetList[0].id"  style="margin-top: -30px;margin-bottom: 20px;">

                                                  <div label="" class="input" :id="item.id"
                                                       v-for="(a,index) in +i.num" :key="index" style="width: 380px; height: 41px;">

                                                    <el-form-item label="" v-if="item.id == i.id"   style="display:inline-flex;">
                                                      <select
                                                          v-model="i.inSize[index].models"
                                                          :placeholder="'请选择' + i.name"
                                                          class="myselect"
                                                          @change="selecChange1(i.inSize[index].models,i,index)"
                                                      >
                                                        <option
                                                            v-for="(items, index) in i.processesSetList"
                                                            :key="index"
                                                            :label="items.name"
                                                            :value="items.id + ',' + items.name"
                                                        ></option>
                                                      </select>
                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">
                                                      长<el-input-number v-model="i.inSize[index].width" autocomplete="off"
                                                                       placeholder="长度" :controls ="false"
                                                                       :id="i.id" style="width: 60px;"
                                                                       @change="widthChange($event,i,index)"></el-input-number>
                                                      <span >X</span>
                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">
                                                      宽<el-input-number v-model="i.inSize[index].length"   style="width: 60px;"
                                                                       placeholder="宽度" :controls ="false"
                                                                       @change="lengthChange($event,i,index)"></el-input-number>
                                                      <span >mm</span>

                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">

                                                      <el-button v-if="a==1" type="text" class="newlyAdded"  style="margin-top: 20px;"
                                                                 @click="newAdded(item, i)">增
                                                      </el-button>
                                                      <el-button v-else type="text" class="newlyAdded"  style="margin-top: 20px;"
                                                                 @click="del(item, i)">删
                                                      </el-button>
                                                    </el-form-item>

                                                  </div>

                                                </div>



                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 3">
                                            <div :key="i.id" v-for="i in setacreage0">
                                              <el-form :model="form" label-width="80px" ref="form"
                                                       v-if="item.id == i.id">
                                                <el-form-item label="">
                                                  <el-select :placeholder="'请选择' + i.name"
                                                             @change="selecChange" v-model="i.models">
                                                    <el-option
                                                        :key="items.id"
                                                        :label="items.name"
                                                        :value="items.id"
                                                        v-for="items in i.processesSetList"
                                                    ></el-option>
                                                  </el-select>
                                                </el-form-item>
                                                <el-form-item label="数量" label-width="45px"
                                                              class="setacreage3_desc">
                                                  <el-input
                                                      @change="textareaChange($event,i,index)"
                                                      type="textarea"
                                                      v-model="i.desc"
                                                  ></el-input>
                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>
                                        </div>
                                      </el-checkbox-group>
                                    </div>

                                    <span class="dialog-footer" slot="footer">
                                        <el-button @click="cancel1(index)">取 消</el-button>
                                        <el-button @click="centerDialogVisible4 = false;Submit(index);" type="primary">提 交</el-button>
                                    </span>
                                </el-dialog>
                            </div>
                        </div>
                    </el-form>
                    <!--封面加2P拉页-->
                    <el-form :model="form" label-width="80px" ref="form" v-if="form.cover == 3">
                        <div :key="index" v-for="(items, index) in +PullPage2p.length">
                            <!--封面类型&封面-->
                            <div class="chengpin">
                                <el-form-item label="封面类型">
                                    <span class="class">封面封底加拉页</span>
                                </el-form-item>
                                <el-form-item class="fengmian" label="拉页P数">
                                    <p>
                                        <span class="class">封面</span>
                                        <el-input @change="inputChange" v-model="fm"></el-input>
                                        <span class="class">P</span>
                                    </p>
                                    <p>
                                        <span class="class">封底</span>
                                        <el-input @change="inputChange" v-model="fd"></el-input>
                                        <span class="class">P</span>
                                    </p>
                                </el-form-item>
                            </div>

                            <!--封面纸张-->
                            <div class="chengpin">
                                <el-form-item label="封面纸张">
                                    <el-select @change="Changetype($event, index)" placeholder="请选择封面纸张"
                                               v-model="form.coverTypes[index]">
                                        <el-option
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.id"
                                                v-for="item in covermaList"
                                        ></el-option>
                                    </el-select>
                                    <span class="class"></span>
                                    <el-select placeholder="请选择克" v-model="form.coverGrams[index]">
                                        <el-option
                                                :key="item.weightid"
                                                :label="item.name"
                                                :value="item.weightid"
                                                v-for="item in productWeightList"
                                        ></el-option>
                                    </el-select>
                                    <span class="class">克</span>
                                    <el-checkbox v-model="isCustMafm">自带料</el-checkbox>
                                </el-form-item>
                            </div>

                            <!--印刷颜色-->
                            <div class="chengpin">
                                <el-form-item label="印刷颜色">
                                    <el-select placeholder="请选择单双面" v-model="form.singles[index]">
                                        <el-option label="单面" value="color"></el-option>
                                        <el-option label="双面" value="colors"></el-option>
                                    </el-select>
                                    <span class="class"></span>
                                    <el-select placeholder="请选择印刷颜色" v-model="form.colours[index]">
                                        <el-option
                                                :key="item.colorid"
                                                :label="item.name"
                                                :value="item.colorid"
                                                v-for="item in colorList"
                                        ></el-option>
                                        <el-option label="不印刷" value="">不印刷</el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="houdao">
                                <el-form-item
                                        label="封面其他工艺"
                                        label-width="100px">
                                    <el-checkbox v-model="checked[index]" @change="CheckedChange($event, index)"
                                    >{{ checkedtext }}
                                    </el-checkbox>
                                </el-form-item>
                                <el-dialog
                                        :visible.sync="centerDialogVisible4"
                                        center
                                        title="设置封面其他工艺"
                                        width="50%"
                                        :before-close="handleClose1"
                                >
                                    <div style="height: 300px; overflow: auto">
                                      <el-checkbox-group @change="handleCheckedChange" v-model="form.checkedCheckbox">
                                        <div v-for="item in processList" :key="item.id" style="width: 40%;">
                                          <el-checkbox :label="item.id" :value="item.id" v-if="item.mustchoose == 1 && item.defaultShow != 1"
                                                       :checked="true"
                                                       disabled>
                                            {{ item.name }}

                                            <div v-if="item.setacreage == 0">
                                              <div v-for="i in setacreage0" :key="i.id">
                                                <el-form ref="form" label-width="80px" v-if="item.id == i.id">
                                                  <el-form-item label="">
                                                    <el-select
                                                        v-model="i.models"
                                                        @change="selecChange"
                                                        :placeholder="'请选择' + i.name"
                                                        style="width: 80px;"
                                                    >
                                                      <el-option
                                                          v-for="(items, index) in i.processesSetList"
                                                          :key="index"
                                                          :label="items.name"
                                                          :value="items.id"
                                                      ></el-option>
                                                    </el-select>
                                                  </el-form-item>
                                                </el-form>
                                              </div>
                                            </div>
                                          </el-checkbox>

                                          <el-checkbox :label="item.id" :value="item.id" v-if="item.mustchoose !== 1"
                                                       :checked="item.defaultShow == 1">
                                            {{ item.name }}
                                          </el-checkbox>

                                          <div v-if="item.setacreage == 0 && item.mustchoose !== 1">
                                            <div :key="i.id" v-for="i in setacreage0">
                                              <el-form label-width="80px" ref="form" v-if="item.id == i.id">
                                                <el-form-item label="">
                                                  <el-select :placeholder="'请选择' + i.name"
                                                             style="width: 100px;"
                                                             @change="selecChange" v-model="i.models">
                                                    <el-option
                                                        :key="indexx"
                                                        :label="items.name"
                                                        :value="items.id"
                                                        v-for="(items, indexx) in i.processesSetList"
                                                    ></el-option>
                                                  </el-select>
                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 1">
                                            <div v-for="i in setacreage0" :key="i.id">
                                              <el-form ref="form" :model="form" label-width="80px"
                                                       v-if="item.id == i.id">

                                                <el-form-item label="" :label-width="formWidth"
                                                              v-if="(i.num >0)&&item.processesSetList[0].id==i.processesSetList[0].id">

                                                  <el-form-item label="" class="input" :id="item.id"
                                                                v-for="(a,index) in +i.num" :key="index">
                                                    <el-form-item label=""  >
                                                      <el-input v-model="i.inSize[index].width"
                                                                autocomplete="off"
                                                                :id="i.id"
                                                                @change="widthChange($event,i,index)"></el-input>
                                                      <span>X</span>
                                                    </el-form-item>
                                                    <el-form-item label=""  >
                                                      <el-input v-model="i.inSize[index].length"
                                                                autocomplete="off"
                                                                @change="lengthChange($event,i,index)"></el-input>
                                                      <span>mm</span>
                                                      <el-button v-if="a==1" type="primary"
                                                                 class="newlyAdded"
                                                                 @click="newAdded(item, i)">新增
                                                      </el-button>
                                                      <el-button v-else type="primary"
                                                                 class="newlyAdded"
                                                                 @click="del(item, i)">删除
                                                      </el-button>
                                                    </el-form-item>
                                                  </el-form-item>

                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 2" style="margin-bottom: 20px;">
                                            <div v-for="i in setacreage0" :key="i.id">
                                              <el-form
                                                  ref="form"
                                                  :model="form"
                                                  label-width="80px"
                                                  v-if="item.id == i.id"
                                              >

                                                <div label=""
                                                     v-if="(i.num >0)&&item.processesSetList[0].id==i.processesSetList[0].id"  style="margin-top: -30px;margin-bottom: 20px;">

                                                  <div label="" class="input" :id="item.id"
                                                       v-for="(a,index) in +i.num" :key="index" style="width: 380px; height: 41px;">

                                                    <el-form-item label="" v-if="item.id == i.id"   style="display:inline-flex;">
                                                      <select
                                                          v-model="i.inSize[index].models"
                                                          :placeholder="'请选择' + i.name"
                                                          class="myselect"
                                                          @change="selecChange1(i.inSize[index].models,i,index)"
                                                      >
                                                        <option
                                                            v-for="(items, index) in i.processesSetList"
                                                            :key="index"
                                                            :label="items.name"
                                                            :value="items.id + ',' + items.name"
                                                        ></option>
                                                      </select>
                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">
                                                      长<el-input-number v-model="i.inSize[index].width" autocomplete="off"
                                                                       placeholder="长度" :controls ="false"
                                                                       :id="i.id" style="width: 60px;"
                                                                       @change="widthChange($event,i,index)"></el-input-number>
                                                      <span >X</span>
                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">
                                                      宽<el-input-number v-model="i.inSize[index].length"   style="width: 60px;"
                                                                       placeholder="宽度" :controls ="false"
                                                                       @change="lengthChange($event,i,index)"></el-input-number>
                                                      <span >mm</span>

                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">

                                                      <el-button v-if="a==1" type="text" class="newlyAdded"  style="margin-top: 20px;"
                                                                 @click="newAdded(item, i)">增
                                                      </el-button>
                                                      <el-button v-else type="text" class="newlyAdded"  style="margin-top: 20px;"
                                                                 @click="del(item, i)">删
                                                      </el-button>
                                                    </el-form-item>

                                                  </div>

                                                </div>



                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 3">
                                            <div :key="i.id" v-for="i in setacreage0">
                                              <el-form :model="form" label-width="80px" ref="form"
                                                       v-if="item.id == i.id">
                                                <el-form-item label="">
                                                  <el-select :placeholder="'请选择' + i.name"
                                                             @change="selecChange" v-model="i.models">
                                                    <el-option
                                                        :key="items.id"
                                                        :label="items.name"
                                                        :value="items.id"
                                                        v-for="items in i.processesSetList"
                                                    ></el-option>
                                                  </el-select>
                                                </el-form-item>
                                                <el-form-item label="数量" label-width="45px"
                                                              class="setacreage3_desc">
                                                  <el-input
                                                      @change="textareaChange($event,i,index)"
                                                      type="textarea"
                                                      v-model="i.desc"
                                                  ></el-input>
                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>
                                        </div>
                                      </el-checkbox-group>
                                    </div>

                                    <span class="dialog-footer" slot="footer">
                                        <el-button @click="cancel1(index)">取 消</el-button>
                                        <el-button @click="centerDialogVisible4 = false;Submit(index);" type="primary">提 交</el-button>
                                    </span>
                                </el-dialog>
                            </div>
                        </div>
                    </el-form>
                    <!--封面加4P拉页-->
                    <el-form :model="form" label-width="80px" ref="form" v-if="form.cover == 4">
                        <div :key="index" v-for="(items, index) in +PullPage4p.length">
                            <!--封面类型&封面-->
                            <div class="chengpin">
                                <el-form-item label="封面类型">
                                    <span class="class">封面封底加拉页</span>
                                </el-form-item>
                                <el-form-item class="fengmian" label="拉页P数">
                                    <p>
                                        <span class="class">封面</span>
                                        <el-input @change="inputChange" v-model="fm"></el-input>
                                        <span class="class">P</span>
                                    </p>
                                    <p>
                                        <span class="class">封底</span>
                                        <el-input @change="inputChange" v-model="fd"></el-input>
                                        <span class="class">P</span>
                                    </p>
                                </el-form-item>
                            </div>

                            <!--封面纸张-->
                            <div class="chengpin">
                                <el-form-item label="封面纸张">
                                    <el-select @change="Changetype($event, index)" placeholder="请选择封面纸张"
                                               v-model="form.coverTypes[index]">
                                        <el-option :key="item.id" :label="item.name" :value="item.id"
                                                   v-for="item in covermaList"></el-option>
                                    </el-select>
                                    <span class="class"></span>
                                    <el-select placeholder="请选择克" v-model="form.coverGrams[index]">
                                        <el-option :key="item.weightid" :label="item.name" :value="item.weightid"
                                                   v-for="item in productWeightList"></el-option>
                                    </el-select>
                                    <span class="class">克</span>
                                    <el-checkbox v-model="isCustMafm">自带料</el-checkbox>
                                </el-form-item>
                            </div>

                            <!--印刷颜色-->
                            <div class="chengpin">
                                <el-form-item label="印刷颜色">
                                    <el-select placeholder="请选择单双面" v-model="form.singles[index]">
                                        <el-option label="单面" value="color"></el-option>
                                        <el-option label="双面" value="colors"></el-option>
                                    </el-select>
                                    <span class="class"></span>
                                    <el-select placeholder="请选择印刷颜色" v-model="form.colours[index]">
                                        <el-option :key="item.colorid" :label="item.name" :value="item.colorid"
                                                   v-for="item in colorList"></el-option>
                                        <el-option label="不印刷" value="">不印刷</el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="houdao">
                                <el-form-item
                                        label="封面其他工艺"
                                        label-width="100px">
                                    <el-checkbox v-model="checked[index]" @change="CheckedChange($event, index)">{{
                                        checkedtext }}
                                    </el-checkbox>
                                </el-form-item>
                                <el-dialog
                                        :visible.sync="centerDialogVisible4"
                                        center
                                        title="设置封面其他工艺"
                                        width="50%"
                                        :before-close="handleClose1"
                                >
                                    <div style="height: 300px; overflow: auto">
                                      <el-checkbox-group @change="handleCheckedChange" v-model="form.checkedCheckbox">
                                        <div v-for="item in processList" :key="item.id" style="width: 40%;">
                                          <el-checkbox :label="item.id" :value="item.id" v-if="item.mustchoose == 1 && item.defaultShow != 1"
                                                       :checked="true"
                                                       disabled>
                                            {{ item.name }}

                                            <div v-if="item.setacreage == 0">
                                              <div v-for="i in setacreage0" :key="i.id">
                                                <el-form ref="form" label-width="80px" v-if="item.id == i.id">
                                                  <el-form-item label="">
                                                    <el-select
                                                        v-model="i.models"
                                                        @change="selecChange"
                                                        :placeholder="'请选择' + i.name"
                                                        style="width: 80px;"
                                                    >
                                                      <el-option
                                                          v-for="(items, index) in i.processesSetList"
                                                          :key="index"
                                                          :label="items.name"
                                                          :value="items.id"
                                                      ></el-option>
                                                    </el-select>
                                                  </el-form-item>
                                                </el-form>
                                              </div>
                                            </div>
                                          </el-checkbox>

                                          <el-checkbox :label="item.id" :value="item.id" v-if="item.mustchoose !== 1"
                                                       :checked="item.defaultShow == 1">
                                            {{ item.name }}
                                          </el-checkbox>

                                          <div v-if="item.setacreage == 0 && item.mustchoose !== 1">
                                            <div :key="i.id" v-for="i in setacreage0">
                                              <el-form label-width="80px" ref="form" v-if="item.id == i.id">
                                                <el-form-item label="">
                                                  <el-select :placeholder="'请选择' + i.name"
                                                             style="width: 100px;"
                                                             @change="selecChange" v-model="i.models">
                                                    <el-option
                                                        :key="indexx"
                                                        :label="items.name"
                                                        :value="items.id"
                                                        v-for="(items, indexx) in i.processesSetList"
                                                    ></el-option>
                                                  </el-select>
                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 1">
                                            <div v-for="i in setacreage0" :key="i.id">
                                              <el-form ref="form" :model="form" label-width="80px"
                                                       v-if="item.id == i.id">

                                                <el-form-item label="" :label-width="formWidth"
                                                              v-if="(i.num >0)&&item.processesSetList[0].id==i.processesSetList[0].id">

                                                  <el-form-item label="" class="input" :id="item.id"
                                                                v-for="(a,index) in +i.num" :key="index">
                                                    <el-form-item label=""  >
                                                      <el-input v-model="i.inSize[index].width"
                                                                autocomplete="off"
                                                                :id="i.id"
                                                                @change="widthChange($event,i,index)"></el-input>
                                                      <span>X</span>
                                                    </el-form-item>
                                                    <el-form-item label=""  >
                                                      <el-input v-model="i.inSize[index].length"
                                                                autocomplete="off"
                                                                @change="lengthChange($event,i,index)"></el-input>
                                                      <span>mm</span>
                                                      <el-button v-if="a==1" type="primary"
                                                                 class="newlyAdded"
                                                                 @click="newAdded(item, i)">新增
                                                      </el-button>
                                                      <el-button v-else type="primary"
                                                                 class="newlyAdded"
                                                                 @click="del(item, i)">删除
                                                      </el-button>
                                                    </el-form-item>
                                                  </el-form-item>

                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 2" style="margin-bottom: 20px;">
                                            <div v-for="i in setacreage0" :key="i.id">
                                              <el-form
                                                  ref="form"
                                                  :model="form"
                                                  label-width="80px"
                                                  v-if="item.id == i.id"
                                              >

                                                <div label=""
                                                     v-if="(i.num >0)&&item.processesSetList[0].id==i.processesSetList[0].id"  style="margin-top: -30px;margin-bottom: 20px;">

                                                  <div label="" class="input" :id="item.id"
                                                       v-for="(a,index) in +i.num" :key="index" style="width: 380px; height: 41px;">

                                                    <el-form-item label="" v-if="item.id == i.id"   style="display:inline-flex;">
                                                      <select
                                                          v-model="i.inSize[index].models"
                                                          :placeholder="'请选择' + i.name"
                                                          class="myselect"
                                                          @change="selecChange1(i.inSize[index].models,i,index)"
                                                      >
                                                        <option
                                                            v-for="(items, index) in i.processesSetList"
                                                            :key="index"
                                                            :label="items.name"
                                                            :value="items.id + ',' + items.name"
                                                        ></option>
                                                      </select>
                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">
                                                      长<el-input-number v-model="i.inSize[index].width" autocomplete="off"
                                                                       placeholder="长度" :controls ="false"
                                                                       :id="i.id" style="width: 60px;"
                                                                       @change="widthChange($event,i,index)"></el-input-number>
                                                      <span >X</span>
                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">
                                                      宽<el-input-number v-model="i.inSize[index].length"   style="width: 60px;"
                                                                       placeholder="宽度" :controls ="false"
                                                                       @change="lengthChange($event,i,index)"></el-input-number>
                                                      <span >mm</span>

                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">

                                                      <el-button v-if="a==1" type="text" class="newlyAdded"  style="margin-top: 20px;"
                                                                 @click="newAdded(item, i)">增
                                                      </el-button>
                                                      <el-button v-else type="text" class="newlyAdded"  style="margin-top: 20px;"
                                                                 @click="del(item, i)">删
                                                      </el-button>
                                                    </el-form-item>

                                                  </div>

                                                </div>



                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 3">
                                            <div :key="i.id" v-for="i in setacreage0">
                                              <el-form :model="form" label-width="80px" ref="form"
                                                       v-if="item.id == i.id">
                                                <el-form-item label="">
                                                  <el-select :placeholder="'请选择' + i.name"
                                                             @change="selecChange" v-model="i.models">
                                                    <el-option
                                                        :key="items.id"
                                                        :label="items.name"
                                                        :value="items.id"
                                                        v-for="items in i.processesSetList"
                                                    ></el-option>
                                                  </el-select>
                                                </el-form-item>
                                                <el-form-item label="数量" label-width="45px"
                                                              class="setacreage3_desc">
                                                  <el-input
                                                      @change="textareaChange($event,i,index)"
                                                      type="textarea"
                                                      v-model="i.desc"
                                                  ></el-input>
                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>
                                        </div>
                                      </el-checkbox-group>
                                    </div>

                                    <span class="dialog-footer" slot="footer">
                                        <el-button @click="cancel1(index)">取 消</el-button>
                                        <el-button @click="centerDialogVisible4 = false;Submit(index);" type="primary">提 交</el-button>
                                    </span>
                                </el-dialog>
                            </div>
                        </div>
                    </el-form>
                    <!--封面封底各加2P拉页-->
                    <el-form :model="form" label-width="80px" ref="form" v-if="form.cover == 5">
                        <div :key="index" v-for="(items, index) in +PullPage22p.length">
                            <!--封面类型&封面-->
                            <div class="chengpin">
                                <el-form-item label="封面类型">
                                    <span class="class">封面封底加拉页</span>
                                </el-form-item>
                                <el-form-item class="fengmian" label="拉页P数">
                                    <p>
                                        <span class="class">封面</span>
                                        <el-input @change="inputChange" v-model="fm"></el-input>
                                        <span class="class">P</span>
                                    </p>
                                    <p>
                                        <span class="class">封底</span>
                                        <el-input @change="inputChange" v-model="fd"></el-input>
                                        <span class="class">P</span>
                                    </p>
                                </el-form-item>
                            </div>

                            <!--封面纸张-->
                            <div class="chengpin">
                                <el-form-item label="封面纸张">
                                    <el-select @change="Changetype($event, index)" placeholder="请选择封面纸张"
                                               v-model="form.coverTypes[index]">
                                        <el-option
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.id"
                                                v-for="item in covermaList"
                                        ></el-option>
                                    </el-select>
                                    <span class="class"></span>
                                    <el-select placeholder="请选择克" v-model="form.coverGrams[index]">
                                        <el-option
                                                :key="item.weightid"
                                                :label="item.name"
                                                :value="item.weightid"
                                                v-for="item in productWeightList"
                                        ></el-option>
                                    </el-select>
                                    <span class="class">克</span>
                                    <el-checkbox v-model="isCustMafm">自带料</el-checkbox>
                                </el-form-item>
                            </div>

                            <!--印刷颜色-->
                            <div class="chengpin">
                                <el-form-item label="印刷颜色">
                                    <el-select placeholder="请选择单双面" v-model="form.singles[index]">
                                        <el-option label="单面" value="color"></el-option>
                                        <el-option label="双面" value="colors"></el-option>
                                    </el-select>
                                    <span class="class"></span>
                                    <el-select placeholder="请选择印刷颜色" v-model="form.colours[index]">
                                        <el-option
                                                :key="item.colorid"
                                                :label="item.name"
                                                :value="item.colorid"
                                                v-for="item in colorList"
                                        ></el-option>
                                        <el-option label="不印刷" value="">不印刷</el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="houdao">
                                <el-form-item
                                        label="封面其他工艺"
                                        label-width="100px">
                                    <el-checkbox v-model="checked[index]" @change="CheckedChange($event, index)">{{
                                        checkedtext }}
                                    </el-checkbox>
                                </el-form-item>
                                <el-dialog
                                        :visible.sync="centerDialogVisible4"
                                        center
                                        title="设置封面其他工艺"
                                        width="50%"
                                        :before-close="handleClose1"
                                >
                                    <div style="height: 300px; overflow: auto">
                                      <el-checkbox-group @change="handleCheckedChange" v-model="form.checkedCheckbox">
                                        <div v-for="item in processList" :key="item.id" style="width: 40%;">
                                          <el-checkbox :label="item.id" :value="item.id" v-if="item.mustchoose == 1 && item.defaultShow != 1"
                                                       :checked="true"
                                                       disabled>
                                            {{ item.name }}

                                            <div v-if="item.setacreage == 0">
                                              <div v-for="i in setacreage0" :key="i.id">
                                                <el-form ref="form" label-width="80px" v-if="item.id == i.id">
                                                  <el-form-item label="">
                                                    <el-select
                                                        v-model="i.models"
                                                        @change="selecChange"
                                                        :placeholder="'请选择' + i.name"
                                                        style="width: 80px;"
                                                    >
                                                      <el-option
                                                          v-for="(items, index) in i.processesSetList"
                                                          :key="index"
                                                          :label="items.name"
                                                          :value="items.id"
                                                      ></el-option>
                                                    </el-select>
                                                  </el-form-item>
                                                </el-form>
                                              </div>
                                            </div>
                                          </el-checkbox>

                                          <el-checkbox :label="item.id" :value="item.id" v-if="item.mustchoose !== 1"
                                                       :checked="item.defaultShow == 1">
                                            {{ item.name }}
                                          </el-checkbox>

                                          <div v-if="item.setacreage == 0 && item.mustchoose !== 1">
                                            <div :key="i.id" v-for="i in setacreage0">
                                              <el-form label-width="80px" ref="form" v-if="item.id == i.id">
                                                <el-form-item label="">
                                                  <el-select :placeholder="'请选择' + i.name"
                                                             style="width: 100px;"
                                                             @change="selecChange" v-model="i.models">
                                                    <el-option
                                                        :key="indexx"
                                                        :label="items.name"
                                                        :value="items.id"
                                                        v-for="(items, indexx) in i.processesSetList"
                                                    ></el-option>
                                                  </el-select>
                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 1">
                                            <div v-for="i in setacreage0" :key="i.id">
                                              <el-form ref="form" :model="form" label-width="80px"
                                                       v-if="item.id == i.id">

                                                <el-form-item label="" :label-width="formWidth"
                                                              v-if="(i.num >0)&&item.processesSetList[0].id==i.processesSetList[0].id">

                                                  <el-form-item label="" class="input" :id="item.id"
                                                                v-for="(a,index) in +i.num" :key="index">
                                                    <el-form-item label=""  >
                                                      <el-input v-model="i.inSize[index].width"
                                                                autocomplete="off"
                                                                :id="i.id"
                                                                @change="widthChange($event,i,index)"></el-input>
                                                      <span>X</span>
                                                    </el-form-item>
                                                    <el-form-item label=""  >
                                                      <el-input v-model="i.inSize[index].length"
                                                                autocomplete="off"
                                                                @change="lengthChange($event,i,index)"></el-input>
                                                      <span>mm</span>
                                                      <el-button v-if="a==1" type="primary"
                                                                 class="newlyAdded"
                                                                 @click="newAdded(item, i)">新增
                                                      </el-button>
                                                      <el-button v-else type="primary"
                                                                 class="newlyAdded"
                                                                 @click="del(item, i)">删除
                                                      </el-button>
                                                    </el-form-item>
                                                  </el-form-item>

                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 2" style="margin-bottom: 20px;">
                                            <div v-for="i in setacreage0" :key="i.id">
                                              <el-form
                                                  ref="form"
                                                  :model="form"
                                                  label-width="80px"
                                                  v-if="item.id == i.id"
                                              >

                                                <div label=""
                                                     v-if="(i.num >0)&&item.processesSetList[0].id==i.processesSetList[0].id"  style="margin-top: -30px;margin-bottom: 20px;">

                                                  <div label="" class="input" :id="item.id"
                                                       v-for="(a,index) in +i.num" :key="index" style="width: 380px; height: 41px;">

                                                    <el-form-item label="" v-if="item.id == i.id"   style="display:inline-flex;">
                                                      <select
                                                          v-model="i.inSize[index].models"
                                                          :placeholder="'请选择' + i.name"
                                                          class="myselect"
                                                          @change="selecChange1(i.inSize[index].models,i,index)"
                                                      >
                                                        <option
                                                            v-for="(items, index) in i.processesSetList"
                                                            :key="index"
                                                            :label="items.name"
                                                            :value="items.id + ',' + items.name"
                                                        ></option>
                                                      </select>
                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">
                                                      长<el-input-number v-model="i.inSize[index].width" autocomplete="off"
                                                                       placeholder="长度" :controls ="false"
                                                                       :id="i.id" style="width: 60px;"
                                                                       @change="widthChange($event,i,index)"></el-input-number>
                                                      <span >X</span>
                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">
                                                      宽<el-input-number v-model="i.inSize[index].length"   style="width: 60px;"
                                                                       placeholder="宽度" :controls ="false"
                                                                       @change="lengthChange($event,i,index)"></el-input-number>
                                                      <span >mm</span>

                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">

                                                      <el-button v-if="a==1" type="text" class="newlyAdded"  style="margin-top: 20px;"
                                                                 @click="newAdded(item, i)">增
                                                      </el-button>
                                                      <el-button v-else type="text" class="newlyAdded"  style="margin-top: 20px;"
                                                                 @click="del(item, i)">删
                                                      </el-button>
                                                    </el-form-item>

                                                  </div>

                                                </div>



                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 3">
                                            <div :key="i.id" v-for="i in setacreage0">
                                              <el-form :model="form" label-width="80px" ref="form"
                                                       v-if="item.id == i.id">
                                                <el-form-item label="">
                                                  <el-select :placeholder="'请选择' + i.name"
                                                             @change="selecChange" v-model="i.models">
                                                    <el-option
                                                        :key="items.id"
                                                        :label="items.name"
                                                        :value="items.id"
                                                        v-for="items in i.processesSetList"
                                                    ></el-option>
                                                  </el-select>
                                                </el-form-item>
                                                <el-form-item label="数量" label-width="45px"
                                                              class="setacreage3_desc">
                                                  <el-input
                                                      @change="textareaChange($event,i,index)"
                                                      type="textarea"
                                                      v-model="i.desc"
                                                  ></el-input>
                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>
                                        </div>
                                      </el-checkbox-group>
                                    </div>

                                    <span class="dialog-footer" slot="footer">
                                        <el-button @click="cancel1(index)">取 消</el-button>
                                        <el-button @click="centerDialogVisible4 = false;Submit(index);" type="primary">提 交</el-button>
                                    </span>
                                </el-dialog>
                            </div>
                        </div>
                    </el-form>
                    <!--封三连体封套?款-->
                    <el-form :model="form" label-width="80px" ref="form" v-if="form.cover == 6">
                        <div :key="index" v-for="(items, index) in +form.input2">
                            <!--封面类型&封面-->
                            <div class="chengpin">
                                <el-form-item label="封面类型">
                                    <span class="class">连体封套</span>
                                </el-form-item>
                                <el-form-item :label="'连体封套' + items" class="fengmian" v-if="form.input2 != '1'">
                                    <el-input @change="inputChange" v-model="benshu[index]"></el-input>
                                    本
                                    <span class="class">4P</span>
                                </el-form-item>
                                <el-form-item class="fengmian" label="兜底高">
                                    <el-input @change="inputChange" v-model="doudi[index]"></el-input>
                                    <span class="class">mm</span>
                                </el-form-item>
                            </div>

                            <!--封面纸张-->
                            <div class="chengpin">
                                <el-form-item label="封面纸张">
                                    <el-select @change="Changetype($event, index)" placeholder="请选择封面纸张"
                                               v-model="form.coverTypes[index]">
                                        <el-option
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.id"
                                                v-for="item in covermaList"
                                        ></el-option>
                                    </el-select>
                                    <span class="class"></span>
                                    <el-select placeholder="请选择克" v-model="form.coverGrams[index]">
                                        <el-option
                                                :key="item.weightid"
                                                :label="item.name"
                                                :value="item.weightid"
                                                v-for="item in productWeightList"
                                        ></el-option>
                                    </el-select>
                                    <span class="class">克</span>
                                    <el-checkbox v-model="isCustMafm">自带料</el-checkbox>
                                </el-form-item>
                            </div>

                            <!--印刷颜色-->
                            <div class="chengpin">
                                <el-form-item label="印刷颜色">
                                    <el-select placeholder="请选择单双面" v-model="form.singles[index]">
                                        <el-option label="单面" value="color"></el-option>
                                        <el-option label="双面" value="colors"></el-option>
                                    </el-select>
                                    <span class="class"></span>
                                    <el-select placeholder="请选择印刷颜色" v-model="form.colours[index]">
                                        <el-option
                                                :key="item.colorid"
                                                :label="item.name"
                                                :value="item.colorid"
                                                v-for="item in colorList"
                                        ></el-option>
                                        <el-option label="不印刷" value="">不印刷</el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="houdao">
                                <el-form-item
                                        label="封面其他工艺"
                                        label-width="100px">
                                    <el-checkbox v-model="checked[index]" @change="CheckedChange($event, index)">{{
                                        checkedtext }}
                                    </el-checkbox>
                                </el-form-item>
                                <el-dialog
                                        :visible.sync="centerDialogVisible4"
                                        center
                                        title="设置封面其他工艺"
                                        width="50%"
                                        :before-close="handleClose1"
                                >
                                    <div style="height: 300px; overflow: auto">
                                      <el-checkbox-group @change="handleCheckedChange" v-model="form.checkedCheckbox">
                                        <div v-for="item in processList" :key="item.id" style="width: 40%;">
                                          <el-checkbox :label="item.id" :value="item.id" v-if="item.mustchoose == 1 && item.defaultShow != 1"
                                                       :checked="true"
                                                       disabled>
                                            {{ item.name }}

                                            <div v-if="item.setacreage == 0">
                                              <div v-for="i in setacreage0" :key="i.id">
                                                <el-form ref="form" label-width="80px" v-if="item.id == i.id">
                                                  <el-form-item label="">
                                                    <el-select
                                                        v-model="i.models"
                                                        @change="selecChange"
                                                        :placeholder="'请选择' + i.name"
                                                        style="width: 80px;"
                                                    >
                                                      <el-option
                                                          v-for="(items, index) in i.processesSetList"
                                                          :key="index"
                                                          :label="items.name"
                                                          :value="items.id"
                                                      ></el-option>
                                                    </el-select>
                                                  </el-form-item>
                                                </el-form>
                                              </div>
                                            </div>
                                          </el-checkbox>

                                          <el-checkbox :label="item.id" :value="item.id" v-if="item.mustchoose !== 1"
                                                       :checked="item.defaultShow == 1">
                                            {{ item.name }}
                                          </el-checkbox>

                                          <div v-if="item.setacreage == 0 && item.mustchoose !== 1">
                                            <div :key="i.id" v-for="i in setacreage0">
                                              <el-form label-width="80px" ref="form" v-if="item.id == i.id">
                                                <el-form-item label="">
                                                  <el-select :placeholder="'请选择' + i.name"
                                                             style="width: 100px;"
                                                             @change="selecChange" v-model="i.models">
                                                    <el-option
                                                        :key="indexx"
                                                        :label="items.name"
                                                        :value="items.id"
                                                        v-for="(items, indexx) in i.processesSetList"
                                                    ></el-option>
                                                  </el-select>
                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 1">
                                            <div v-for="i in setacreage0" :key="i.id">
                                              <el-form ref="form" :model="form" label-width="80px"
                                                       v-if="item.id == i.id">

                                                <el-form-item label="" :label-width="formWidth"
                                                              v-if="(i.num >0)&&item.processesSetList[0].id==i.processesSetList[0].id">

                                                  <el-form-item label="" class="input" :id="item.id"
                                                                v-for="(a,index) in +i.num" :key="index">
                                                    <el-form-item label=""  >
                                                      <el-input v-model="i.inSize[index].width"
                                                                autocomplete="off"
                                                                :id="i.id"
                                                                @change="widthChange($event,i,index)"></el-input>
                                                      <span>X</span>
                                                    </el-form-item>
                                                    <el-form-item label="" >
                                                      <el-input v-model="i.inSize[index].length"
                                                                autocomplete="off"
                                                                @change="lengthChange($event,i,index)"></el-input>
                                                      <span>mm</span>
                                                      <el-button v-if="a==1" type="primary"
                                                                 class="newlyAdded"
                                                                 @click="newAdded(item, i)">新增
                                                      </el-button>
                                                      <el-button v-else type="primary"
                                                                 class="newlyAdded"
                                                                 @click="del(item, i)">删除
                                                      </el-button>
                                                    </el-form-item>
                                                  </el-form-item>

                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 2" style="margin-bottom: 20px;">
                                            <div v-for="i in setacreage0" :key="i.id">
                                              <el-form
                                                  ref="form"
                                                  :model="form"
                                                  label-width="80px"
                                                  v-if="item.id == i.id"
                                              >

                                                <div label=""
                                                     v-if="(i.num >0)&&item.processesSetList[0].id==i.processesSetList[0].id"  style="margin-top: -30px;margin-bottom: 20px;">

                                                  <div label="" class="input" :id="item.id"
                                                       v-for="(a,index) in +i.num" :key="index" style="width: 380px; height: 41px;">

                                                    <el-form-item label="" v-if="item.id == i.id"   style="display:inline-flex;">
                                                      <select
                                                          v-model="i.inSize[index].models"
                                                          :placeholder="'请选择' + i.name"
                                                          class="myselect"
                                                          @change="selecChange1(i.inSize[index].models,i,index)"
                                                      >
                                                        <option
                                                            v-for="(items, index) in i.processesSetList"
                                                            :key="index"
                                                            :label="items.name"
                                                            :value="items.id + ',' + items.name"
                                                        ></option>
                                                      </select>
                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">
                                                      长<el-input-number v-model="i.inSize[index].width" autocomplete="off"
                                                                       placeholder="长度" :controls ="false"
                                                                       :id="i.id" style="width: 60px;"
                                                                       @change="widthChange($event,i,index)"></el-input-number>
                                                      <span >X</span>
                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">
                                                      宽<el-input-number v-model="i.inSize[index].length"   style="width: 60px;"
                                                                       placeholder="宽度" :controls ="false"
                                                                       @change="lengthChange($event,i,index)"></el-input-number>
                                                      <span >mm</span>

                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">

                                                      <el-button v-if="a==1" type="text" class="newlyAdded"  style="margin-top: 20px;"
                                                                 @click="newAdded(item, i)">增
                                                      </el-button>
                                                      <el-button v-else type="text" class="newlyAdded"  style="margin-top: 20px;"
                                                                 @click="del(item, i)">删
                                                      </el-button>
                                                    </el-form-item>

                                                  </div>

                                                </div>



                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 3">
                                            <div :key="i.id" v-for="i in setacreage0">
                                              <el-form :model="form" label-width="80px" ref="form"
                                                       v-if="item.id == i.id">
                                                <el-form-item label="">
                                                  <el-select :placeholder="'请选择' + i.name"
                                                             @change="selecChange" v-model="i.models">
                                                    <el-option
                                                        :key="items.id"
                                                        :label="items.name"
                                                        :value="items.id"
                                                        v-for="items in i.processesSetList"
                                                    ></el-option>
                                                  </el-select>
                                                </el-form-item>
                                                <el-form-item label="数量" label-width="45px"
                                                              class="setacreage3_desc">
                                                  <el-input
                                                      @change="textareaChange($event,i,index)"
                                                      type="textarea"
                                                      v-model="i.desc"
                                                  ></el-input>
                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>
                                        </div>
                                      </el-checkbox-group>
                                    </div>

                                    <span class="dialog-footer" slot="footer">
                                        <el-button @click="cancel1(index)">取 消</el-button>
                                        <el-button @click="centerDialogVisible4 = false;Submit(index);" type="primary">提 交</el-button>
                                    </span>
                                </el-dialog>
                            </div>
                        </div>
                    </el-form>
                    <!--封三分体封套-->
                    <el-form :model="form" label-width="80px" ref="form" v-if="form.cover == 8">
                        <div :key="index" v-for="(items, index) in 2">
                            <!--封面类型&封面-->
                            <div class="chengpin">
                                <el-form-item label="封面类型">
                                    <span class="class" v-if="index == 0">封面封底</span>
                                    <span class="class" v-if="index == 1">封三分体封套</span>
                                </el-form-item>
                                <el-form-item label="封面" v-if="index == 0">
                                    <span class="class">4P</span>
                                </el-form-item>
                                <el-form-item class="fengmian" label="兜底" v-if="index == 1">
                                    <span class="class">宽</span>
                                    <el-input @change="inputChange" v-model="kuan"></el-input>
                                    <span class="class">* 高</span>
                                    <el-input @change="inputChange" v-model="gao"></el-input>
                                    <span class="class">mm</span>
                                </el-form-item>
                            </div>

                            <!--封面纸张-->
                            <div class="chengpin">
                                <el-form-item label="封面纸张">
                                    <el-select @change="Changetype($event, index)" placeholder="请选择封面纸张"
                                               v-model="form.coverTypes[index]">
                                        <el-option
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.id"
                                                v-for="item in covermaList"
                                        ></el-option>
                                    </el-select>
                                    <span class="class"></span>
                                    <el-select placeholder="请选择克" v-model="form.coverGrams[index]">
                                        <el-option
                                                :key="item.weightid"
                                                :label="item.name"
                                                :value="item.weightid"
                                                v-for="item in productWeightList"
                                        ></el-option>
                                    </el-select>
                                    <span class="class">克</span>
                                    <el-checkbox v-model="isCustMafm">自带料</el-checkbox>
                                </el-form-item>
                            </div>

                            <!--印刷颜色-->
                            <div class="chengpin">
                                <el-form-item label="印刷颜色">
                                    <el-select placeholder="请选择单双面" v-model="form.singles[index]">
                                        <el-option label="单面" value="color"></el-option>
                                        <el-option label="双面" value="colors"></el-option>
                                    </el-select>
                                    <span class="class"></span>
                                    <el-select placeholder="请选择印刷颜色" v-model="form.colours[index]">
                                        <el-option
                                                :key="item.colorid"
                                                :label="item.name"
                                                :value="item.colorid"
                                                v-for="item in colorList"
                                        ></el-option>
                                        <el-option label="不印刷" value="">不印刷</el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="houdao">
                                <el-form-item
                                        label="封面其他工艺"
                                        label-width="100px">
                                    <el-checkbox v-model="checked[index]" @change="CheckedChange($event, index)">{{
                                        checkedtext }}
                                    </el-checkbox>
                                </el-form-item>
                                <el-dialog
                                        :visible.sync="centerDialogVisible4"
                                        center
                                        title="设置封面其他工艺"
                                        width="50%"
                                        :before-close="handleClose1"
                                >
                                    <div style="height: 300px; overflow: auto">
                                      <el-checkbox-group @change="handleCheckedChange" v-model="form.checkedCheckbox">
                                        <div v-for="item in processList" :key="item.id" style="width: 40%;">
                                          <el-checkbox :label="item.id" :value="item.id" v-if="item.mustchoose == 1 && item.defaultShow != 1"
                                                       :checked="true"
                                                       disabled>
                                            {{ item.name }}

                                            <div v-if="item.setacreage == 0">
                                              <div v-for="i in setacreage0" :key="i.id">
                                                <el-form ref="form" label-width="80px" v-if="item.id == i.id">
                                                  <el-form-item label="">
                                                    <el-select
                                                        v-model="i.models"
                                                        @change="selecChange"
                                                        :placeholder="'请选择' + i.name"
                                                        style="width: 80px;"
                                                    >
                                                      <el-option
                                                          v-for="(items, index) in i.processesSetList"
                                                          :key="index"
                                                          :label="items.name"
                                                          :value="items.id"
                                                      ></el-option>
                                                    </el-select>
                                                  </el-form-item>
                                                </el-form>
                                              </div>
                                            </div>
                                          </el-checkbox>

                                          <el-checkbox :label="item.id" :value="item.id" v-if="item.mustchoose !== 1"
                                                       :checked="item.defaultShow == 1">
                                            {{ item.name }}
                                          </el-checkbox>

                                          <div v-if="item.setacreage == 0 && item.mustchoose !== 1">
                                            <div :key="i.id" v-for="i in setacreage0">
                                              <el-form label-width="80px" ref="form" v-if="item.id == i.id">
                                                <el-form-item label="">
                                                  <el-select :placeholder="'请选择' + i.name"
                                                             style="width: 100px;"
                                                             @change="selecChange" v-model="i.models">
                                                    <el-option
                                                        :key="indexx"
                                                        :label="items.name"
                                                        :value="items.id"
                                                        v-for="(items, indexx) in i.processesSetList"
                                                    ></el-option>
                                                  </el-select>
                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 1">
                                            <div v-for="i in setacreage0" :key="i.id">
                                              <el-form ref="form" :model="form" label-width="80px"
                                                       v-if="item.id == i.id">

                                                <el-form-item label="" :label-width="formWidth"
                                                              v-if="(i.num >0)&&item.processesSetList[0].id==i.processesSetList[0].id">

                                                  <el-form-item label="" class="input" :id="item.id"
                                                                v-for="(a,index) in +i.num" :key="index">
                                                    <el-form-item label="" >
                                                      <el-input v-model="i.inSize[index].width"
                                                                autocomplete="off"
                                                                :id="i.id"
                                                                @change="widthChange($event,i,index)"></el-input>
                                                      <span>X</span>
                                                    </el-form-item>
                                                    <el-form-item label=""  >
                                                      <el-input v-model="i.inSize[index].length"
                                                                autocomplete="off"
                                                                @change="lengthChange($event,i,index)"></el-input>
                                                      <span>mm</span>
                                                      <el-button v-if="a==1" type="primary"
                                                                 class="newlyAdded"
                                                                 @click="newAdded(item, i)">新增
                                                      </el-button>
                                                      <el-button v-else type="primary"
                                                                 class="newlyAdded"
                                                                 @click="del(item, i)">删除
                                                      </el-button>
                                                    </el-form-item>
                                                  </el-form-item>

                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 2" style="margin-bottom: 20px;">
                                            <div v-for="i in setacreage0" :key="i.id">
                                              <el-form
                                                  ref="form"
                                                  :model="form"
                                                  label-width="80px"
                                                  v-if="item.id == i.id"
                                              >

                                                <div label=""
                                                     v-if="(i.num >0)&&item.processesSetList[0].id==i.processesSetList[0].id"  style="margin-top: -30px;margin-bottom: 20px;">

                                                  <div label="" class="input" :id="item.id"
                                                       v-for="(a,index) in +i.num" :key="index" style="width: 380px; height: 41px;">

                                                    <el-form-item label="" v-if="item.id == i.id"   style="display:inline-flex;">
                                                      <select
                                                          v-model="i.inSize[index].models"
                                                          :placeholder="'请选择' + i.name"
                                                          class="myselect"
                                                          @change="selecChange1(i.inSize[index].models,i,index)"
                                                      >
                                                        <option
                                                            v-for="(items, index) in i.processesSetList"
                                                            :key="index"
                                                            :label="items.name"
                                                            :value="items.id + ',' + items.name"
                                                        ></option>
                                                      </select>
                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">
                                                      长<el-input-number v-model="i.inSize[index].width" autocomplete="off"
                                                                       placeholder="长度" :controls ="false"
                                                                       :id="i.id" style="width: 60px;"
                                                                       @change="widthChange($event,i,index)"></el-input-number>
                                                      <span >X</span>
                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">
                                                      宽<el-input-number v-model="i.inSize[index].length"   style="width: 60px;"
                                                                       placeholder="宽度" :controls ="false"
                                                                       @change="lengthChange($event,i,index)"></el-input-number>
                                                      <span >mm</span>

                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">

                                                      <el-button v-if="a==1" type="text" class="newlyAdded"  style="margin-top: 20px;"
                                                                 @click="newAdded(item, i)">增
                                                      </el-button>
                                                      <el-button v-else type="text" class="newlyAdded"  style="margin-top: 20px;"
                                                                 @click="del(item, i)">删
                                                      </el-button>
                                                    </el-form-item>

                                                  </div>

                                                </div>



                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 3">
                                            <div :key="i.id" v-for="i in setacreage0">
                                              <el-form :model="form" label-width="80px" ref="form"
                                                       v-if="item.id == i.id">
                                                <el-form-item label="">
                                                  <el-select :placeholder="'请选择' + i.name"
                                                             @change="selecChange" v-model="i.models">
                                                    <el-option
                                                        :key="items.id"
                                                        :label="items.name"
                                                        :value="items.id"
                                                        v-for="items in i.processesSetList"
                                                    ></el-option>
                                                  </el-select>
                                                </el-form-item>
                                                <el-form-item label="数量" label-width="45px"
                                                              class="setacreage3_desc">
                                                  <el-input
                                                      @change="textareaChange($event,i,index)"
                                                      type="textarea"
                                                      v-model="i.desc"
                                                  ></el-input>
                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>
                                        </div>
                                      </el-checkbox-group>
                                    </div>

                                    <span class="dialog-footer" slot="footer">
                                        <el-button @click="cancel1(index)">取 消</el-button>
                                        <el-button @click="centerDialogVisible4 = false;Submit(index);" type="primary">提 交</el-button>
                                    </span>
                                </el-dialog>
                            </div>
                        </div>
                    </el-form>
                </div>

                <!--内页设置-->
                <div class="modular canshu ny">
                <span class="titleh4" style="display: flex; justify-content: space-between">
                  <h4>内页设置</h4>
                  <el-button @click="coverVisible2 = true" type="text">内页部件添加</el-button>
                </span>

                    <el-dialog
                            :visible.sync="coverVisible2"
                            center
                            title="选择内页类型"
                            width="30%"
                    >
                        <el-form :model="form">
                            <el-form-item label="">
                                <el-radio-group v-model="form.insidepage">
                                    <el-radio label="11" v-model="form.insidepage">内页</el-radio>
                                    <el-radio label="12" v-model="form.insidepage">扉页</el-radio>
                                    <el-radio label="13" v-model="form.insidepage">插页</el-radio>
                                    <el-radio label="14" v-model="form.insidepage">拉页
                                        <el-select placeholder="请选择" v-model="form.pagenumber">
                                            <el-option label="4P" value="4"></el-option>
                                            <el-option label="6P" value="6"></el-option>
                                            <el-option label="8P" value="8"></el-option>
                                        </el-select>
                                    </el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-form>

                        <span class="dialog-footer" slot="footer">
                          <!--<el-button @click="coverVisible2 = false">取 消</el-button>-->
                          <el-button @click="coverVisible2 = false;coverdetermine2();" type="primary">提 交</el-button>
                        </span>
                    </el-dialog>

                    <hr/>

                    <!--内页-->
                    <el-form :model="form" label-width="80px" ref="form">
                        <div :key="index" v-for="(items, index) in neiyearr">
                            <!--内页类型&内页-->
                            <div class="chengpin">
                                <el-form-item label="内页类型">
                                    <span class="class">内页</span>
                                    <span v-if="index != 0">
                                        <span>[</span>
                                        <el-link :underline="false" @click="deleteny($event, index)"
                                                 type="primary">删除</el-link>
                                        <span>]</span>
                                    </span>
                                </el-form-item>
                                <el-form-item label="内页P数">
                                    <el-select allow-create default-first-option filterable placeholder="请输入内页P数"
                                               v-model="form.pagenums[index]">
                                        <el-option :key="item" :label="item" :value="item"
                                                   v-for="item in pagenums"></el-option>
                                    </el-select>
                                    <span class="class">P</span>
                                </el-form-item>
                            </div>

                            <!--内页纸张-->
                            <div class="chengpin">
                                <el-form-item label="内页纸张">
                                    <el-select @change="Changetypepagenumber($event, index)" placeholder="请选择内页纸张"
                                               v-model="form.pagenumberTypeny[index]">
                                        <el-option :key="item.id" :label="item.name" :value="item.id" :disabled="normNames &&  !normNames.includes(item.name)"
                                                   v-for="item in pagenumberList"></el-option>
                                    </el-select>
                                    <span class="class"></span>
                                    <el-select placeholder="请选择克" v-model="form.pagenumberGramny[index]">
                                        <el-option :key="item.weightid" :label="item.name" :value="item.weightid" :disabled=" normWeights && !normWeights.includes(item.name)"
                                                   v-for="item in pagenumbertWeightList"></el-option>
                                    </el-select>
                                    <span class="class">克</span>
                                    <el-checkbox v-model="isCustMany[index]">自带料</el-checkbox>
                                </el-form-item>
                            </div>

                            <!--印刷颜色-->
                            <div class="chengpin">
                                <el-form-item label="印刷颜色">
                                    <el-select placeholder="请选择印刷颜色" v-model="form.coloursny[index]">
                                        <el-option
                                                :key="item.colorid"
                                                :label="item.name"
                                                :value="item.colorid"
                                                v-for="item in colorList"
                                        ></el-option>
                                        <el-option label="不印刷" value="">不印刷</el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <!--内页后道必选项_折页-->
                            <div class="chengpin" v-for="item in pagenumberprocessListbx">
                                <el-form-item :label="item.name">
                                    <el-select :placeholder="'请选择'+item.name" v-model="form.nybx[index]">
                                        <el-option
                                                v-for="i in item.processesSetList"
                                                :key="i.id"
                                                :label="i.name"
                                                :value="i.id"
                                        ></el-option>
                                        <!--<el-option label="不印刷" value="">不印刷</el-option>-->
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="houdao">
                                <el-form-item label="内页工艺" label-width="100px">
                                    <el-checkbox v-model="checkedny[index]" @change="CheckedChangeny($event, index)">{{
                                        checkedtextny[index] }}
                                    </el-checkbox>
                                </el-form-item>

                                <el-dialog
                                        :visible.sync="centerDialogVisible5"
                                        center
                                        title="设置内页其他工艺"
                                        width="70%"
                                        :id="index"
                                        :before-close="handleClose2"
                                >
                                    <div style="height: 452px; overflow: auto">
                                      <el-checkbox-group @change="handleCheckedChangeny" v-model="form.checkedCheckboxny">
                                        <div v-for="item in pagenumberprocessList" :key="item.id" style="width: 40%;">
                                          <el-checkbox :label="item.id" :value="item.id" v-if="item.mustchoose == 1 && item.defaultShow != 1"
                                                       :checked="true"
                                                       disabled>
                                            {{ item.name }}

                                            <div v-if="item.setacreage == 0">
                                              <div v-for="i in setacreageny0" :key="i.id">
                                                <el-form ref="form" label-width="80px" v-if="item.id == i.id">
                                                  <el-form-item label="">
                                                    <el-select
                                                        v-model="i.models"
                                                        @change="selecChangeny"
                                                        :placeholder="'请选择' + i.name"
                                                        style="width: 80px;"
                                                    >
                                                      <el-option
                                                          v-for="(items, index) in i.processesSetList"
                                                          :key="index"
                                                          :label="items.name"
                                                          :value="items.id"
                                                      ></el-option>
                                                    </el-select>
                                                  </el-form-item>
                                                </el-form>
                                              </div>
                                            </div>
                                          </el-checkbox>

                                          <el-checkbox :label="item.id" :value="item.id" v-if="item.mustchoose !== 1"
                                                       :checked="item.defaultShow == 1">
                                            {{ item.name }}
                                          </el-checkbox>

                                          <div v-if="item.setacreage == 0 && item.mustchoose !== 1">
                                            <div :key="i.id" v-for="i in setacreageny0">
                                              <el-form label-width="80px" ref="form" v-if="item.id == i.id">
                                                <el-form-item label="">
                                                  <el-select :placeholder="'请选择' + i.name"
                                                             style="width: 100px;"
                                                             @change="selecChangeny" v-model="i.models">
                                                    <el-option
                                                        :key="indexx"
                                                        :label="items.name"
                                                        :value="items.id"
                                                        v-for="(items, indexx) in i.processesSetList"
                                                    ></el-option>
                                                  </el-select>
                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 1">
                                            <div v-for="i in setacreageny0" :key="i.id">
                                              <el-form ref="form" :model="form" label-width="80px"
                                                       v-if="item.id == i.id">

                                                <el-form-item label=""
                                                              v-if="(i.num >0)&&item.processesSetList[0].id==i.processesSetList[0].id">

                                                  <el-form-item label="" class="input" :id="item.id"
                                                                v-for="(a,index) in +i.num" :key="index">
                                                    <el-form-item label=""  >
                                                      <el-input v-model="i.inSize[index].width"
                                                                autocomplete="off"
                                                                :id="i.id"
                                                                @change="widthChangeny($event,i,index)"></el-input>
                                                      <span>X</span>
                                                    </el-form-item>
                                                    <el-form-item label=""  >
                                                      <el-input v-model="i.inSize[index].length"
                                                                autocomplete="off"
                                                                @change="lengthChangeny($event,i,index)"></el-input>
                                                      <span>mm</span>
                                                      <el-button v-if="a==1" type="primary"
                                                                 class="newnyAdded"
                                                                 @click="newnyAdded(item, i)">新增
                                                      </el-button>
                                                      <el-button v-else type="primary"
                                                                 class="newnyAdded"
                                                                 @click="delny(item, i)">删除
                                                      </el-button>
                                                    </el-form-item>
                                                  </el-form-item>

                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 2" style="margin-bottom: 20px;">
                                            <div v-for="i in setacreageny0" :key="i.id">
                                              <el-form
                                                  ref="form"
                                                  :model="form"
                                                  label-width="80px"
                                                  v-if="item.id == i.id"
                                              >

                                                <div label=""
                                                     v-if="(i.num >0)&&item.processesSetList[0].id==i.processesSetList[0].id"  style="margin-top: -30px;margin-bottom: 20px;">

                                                  <div label="" class="input" :id="item.id"
                                                       v-for="(a,index) in +i.num" :key="index" style="width: 380px; height: 41px;">

                                                    <el-form-item label="" v-if="item.id == i.id"   style="display:inline-flex;">
                                                      <select
                                                          v-model="i.inSize[index].models"
                                                          :placeholder="'请选择' + i.name"
                                                          class="myselect"
                                                          @change="selecChange1ny(i.inSize[index].models,i,index)"
                                                      >
                                                        <option
                                                            v-for="(items, index) in i.processesSetList"
                                                            :key="index"
                                                            :label="items.name"
                                                            :value="items.id + ',' + items.name"
                                                        ></option>
                                                      </select>
                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">
                                                      长<el-input-number v-model="i.inSize[index].width" autocomplete="off"
                                                                       placeholder="长度" :controls ="false"
                                                                       :id="i.id" style="width: 60px;"
                                                                       @change="widthChangeny($event,i,index)"></el-input-number>
                                                      <span >X</span>
                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">
                                                      宽<el-input-number v-model="i.inSize[index].length"   style="width: 60px;"
                                                                       placeholder="宽度" :controls ="false"
                                                                       @change="lengthChangeny($event,i,index)"></el-input-number>
                                                      <span >mm</span>

                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">

                                                      <el-button v-if="a==1" type="text" class="newnyAdded"  style="margin-top: 20px;"
                                                                 @click="newnyAdded(item, i)">增
                                                      </el-button>
                                                      <el-button v-else type="text" class="newnyAdded"  style="margin-top: 20px;"
                                                                 @click="delny(item, i)">删
                                                      </el-button>
                                                    </el-form-item>

                                                  </div>

                                                </div>



                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 3">
                                            <div :key="i.id" v-for="i in setacreageny0">
                                              <el-form :model="form" label-width="80px" ref="form"
                                                       v-if="item.id == i.id">
                                                <el-form-item label="">
                                                  <el-select :placeholder="'请选择' + i.name"
                                                             @change="selecChangeny" v-model="i.models">
                                                    <el-option
                                                        :key="items.id"
                                                        :label="items.name"
                                                        :value="items.id"
                                                        v-for="items in i.processesSetList"
                                                    ></el-option>
                                                  </el-select>
                                                </el-form-item>
                                                <el-form-item label="数量" label-width="45px"
                                                              class="setacreage3_desc">
                                                  <el-input
                                                      @change="textareaChangeny($event,i,index)"
                                                      type="textarea"
                                                      v-model="i.desc"
                                                  ></el-input>
                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>
                                        </div>
                                      </el-checkbox-group>
                                    </div>

                                    <span class="dialog-footer" slot="footer">
                                      <el-button @click="cancel2(index)">取 消</el-button>
                                      <el-button @click="centerDialogVisible5 = false;Submitny(index);" type="primary">提 交</el-button>
                                    </span>
                                </el-dialog>
                            </div>
                        </div>
                    </el-form>
                    <!--扉页-->
                    <el-form :model="form" label-width="80px" ref="form">
                        <div :key="index" v-for="(items, index) in +feiyearr.length">
                            <!--内页类型&扉页-->
                            <div class="chengpin">
                                <el-form-item label="内页类型">
                                    <span class="class">扉页</span>
                                    <span>[</span>
                                    <el-link :underline="false" @click="deletefy($event, index)" type="primary">删除
                                    </el-link>
                                    <span>]</span>
                                </el-form-item>
                                <el-form-item label="扉页数">
                                    <span class="class">1张</span>
                                </el-form-item>
                            </div>

                            <!--扉页纸张-->
                            <div class="chengpin">
                                <el-form-item label="扉页纸张">
                                    <el-select @change="Changetypepagenumberfy($event, index)" placeholder="请选择扉页纸张"
                                               v-model="form.pagenumberTypefy[index]">
                                        <el-option :key="item.id" :label="item.name" :value="item.id"
                                                   v-for="item in pagenumberList"
                                        ></el-option>
                                    </el-select>
                                    <span class="class"></span>
                                    <el-select placeholder="请选择克" v-model="form.pagenumberGramfy[index]">
                                        <el-option
                                                :key="item.weightid"
                                                :label="item.name"
                                                :value="item.weightid"
                                                v-for="item in pagenumbertWeightList"
                                        ></el-option>
                                    </el-select>
                                    <span class="class">克</span>
                                    <el-checkbox v-model="isCustMafy[index]">自带料</el-checkbox>
                                </el-form-item>
                            </div>

                            <!--印刷颜色-->
                            <div class="chengpin">
                                <el-form-item label="印刷颜色">
                                    <el-select placeholder="请选择单双面" v-model="form.singlesfy[index]">
                                        <el-option label="单面" value="color"></el-option>
                                        <el-option label="双面" value="colors"></el-option>
                                    </el-select>
                                    <span class="class"></span>
                                    <el-select placeholder="请选择印刷颜色" v-model="form.coloursfy[index]">
                                        <el-option
                                                :key="item.colorid"
                                                :label="item.name"
                                                :value="item.colorid"
                                                v-for="item in colorList"
                                        ></el-option>
                                        <el-option label="不印刷" value="">不印刷</el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <!--扉页后道必选项_折页-->
                            <div class="chengpin" v-for="item in pagenumberprocessListbx">
                                <el-form-item :label="item.name">
                                    <el-select :placeholder="'请选择'+item.name" v-model="form.fybx[index]">
                                        <el-option
                                                v-for="i in item.processesSetList"
                                                :key="i.id"
                                                :label="i.name"
                                                :value="i.id"
                                        ></el-option>
                                        <!--<el-option label="不印刷" value="">不印刷</el-option>-->
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="houdao">
                                <el-form-item label="扉页工艺">
                                    <el-checkbox v-model="checkedfy[index]" @change="CheckedChangefy($event, index)">{{
                                        checkedtextfy[index] }}
                                    </el-checkbox>
                                </el-form-item>

                                <el-dialog
                                        :visible.sync="centerDialogVisible6"
                                        center
                                        title="设置扉页其他工艺"
                                        width="50%"
                                        :before-close="handleClose3"
                                >
                                    <div style="height: 300px; overflow: auto">
                                      <el-checkbox-group @change="handleCheckedChangefy" v-model="form.checkedCheckboxfy">
                                        <div v-for="item in pagenumberprocessList" :key="item.id" style="width: 40%;">
                                          <el-checkbox :label="item.id" :value="item.id" v-if="item.mustchoose == 1 && item.defaultShow != 1"
                                                       :checked="true"
                                                       disabled>
                                            {{ item.name }}

                                            <div v-if="item.setacreage == 0">
                                              <div v-for="i in setacreagefy0" :key="i.id">
                                                <el-form ref="form" label-width="80px" v-if="item.id == i.id">
                                                  <el-form-item label="">
                                                    <el-select
                                                        v-model="i.models"
                                                        @change="selecChangefy"
                                                        :placeholder="'请选择' + i.name"
                                                        style="width: 80px;"
                                                    >
                                                      <el-option
                                                          v-for="(items, index) in i.processesSetList"
                                                          :key="index"
                                                          :label="items.name"
                                                          :value="items.id"
                                                      ></el-option>
                                                    </el-select>
                                                  </el-form-item>
                                                </el-form>
                                              </div>
                                            </div>
                                          </el-checkbox>

                                          <el-checkbox :label="item.id" :value="item.id" v-if="item.mustchoose !== 1"
                                                       :checked="item.defaultShow == 1">
                                            {{ item.name }}
                                          </el-checkbox>

                                          <div v-if="item.setacreage == 0 && item.mustchoose !== 1">
                                            <div :key="i.id" v-for="i in setacreagefy0">
                                              <el-form label-width="80px" ref="form" v-if="item.id == i.id">
                                                <el-form-item label="">
                                                  <el-select :placeholder="'请选择' + i.name"
                                                             style="width: 100px;"
                                                             @change="selecChangefy" v-model="i.models">
                                                    <el-option
                                                        :key="indexx"
                                                        :label="items.name"
                                                        :value="items.id"
                                                        v-for="(items, indexx) in i.processesSetList"
                                                    ></el-option>
                                                  </el-select>
                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 1">
                                            <div v-for="i in setacreagefy0" :key="i.id">
                                              <el-form ref="form" :model="form" label-width="80px"
                                                       v-if="item.id == i.id">

                                                <el-form-item label="" :label-width="formWidth"
                                                              v-if="(i.num >0)&&item.processesSetList[0].id==i.processesSetList[0].id">

                                                  <el-form-item label="" class="input" :id="item.id"
                                                                v-for="(a,index) in +i.num" :key="index">
                                                    <el-form-item label=""  >
                                                      <el-input v-model="i.inSize[index].width"
                                                                autocomplete="off"
                                                                :id="i.id"
                                                                @change="widthChangefy($event,i,index)"></el-input>
                                                      <span>X</span>
                                                    </el-form-item>
                                                    <el-form-item label=""  >
                                                      <el-input v-model="i.inSize[index].length"
                                                                autocomplete="off"
                                                                @change="lengthChangefy($event,i,index)"></el-input>
                                                      <span>mm</span>
                                                      <el-button v-if="a==1" type="primary"
                                                                 class="newlyAdded"
                                                                 @click="newfyAdded(item, i)">新增
                                                      </el-button>
                                                      <el-button v-else type="primary"
                                                                 class="newlyAdded"
                                                                 @click="delfy(item, i)">删除
                                                      </el-button>
                                                    </el-form-item>
                                                  </el-form-item>

                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 2" style="margin-bottom: 20px;">
                                            <div v-for="i in setacreagefy0" :key="i.id">
                                              <el-form
                                                  ref="form"
                                                  :model="form"
                                                  label-width="80px"
                                                  v-if="item.id == i.id"
                                              >

                                                <div label=""
                                                     v-if="(i.num >0)&&item.processesSetList[0].id==i.processesSetList[0].id"  style="margin-top: -30px;margin-bottom: 20px;">

                                                  <div label="" class="input" :id="item.id"
                                                       v-for="(a,index) in +i.num" :key="index" style="width: 380px; height: 41px;">

                                                    <el-form-item label="" v-if="item.id == i.id"   style="display:inline-flex;">
                                                      <select
                                                          v-model="i.inSize[index].models"
                                                          :placeholder="'请选择' + i.name"
                                                          class="myselect"
                                                          @change="selecChange1fy(i.inSize[index].models,i,index)"
                                                      >
                                                        <option
                                                            v-for="(items, index) in i.processesSetList"
                                                            :key="index"
                                                            :label="items.name"
                                                            :value="items.id + ',' + items.name"
                                                        ></option>
                                                      </select>
                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">
                                                      长<el-input-number v-model="i.inSize[index].width" autocomplete="off"
                                                                       placeholder="长度" :controls ="false"
                                                                       :id="i.id" style="width: 60px;"
                                                                       @change="widthChangefy($event,i,index)"></el-input-number>
                                                      <span >X</span>
                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">
                                                      宽<el-input-number v-model="i.inSize[index].length"   style="width: 60px;"
                                                                       placeholder="宽度" :controls ="false"
                                                                       @change="lengthChangefy($event,i,index)"></el-input-number>
                                                      <span >mm</span>

                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">

                                                      <el-button v-if="a==1" type="text" class="newlyAdded"  style="margin-top: 20px;"
                                                                 @click="newfyAdded(item, i)">增
                                                      </el-button>
                                                      <el-button v-else type="text" class="newlyAdded"  style="margin-top: 20px;"
                                                                 @click="delfy(item, i)">删
                                                      </el-button>
                                                    </el-form-item>

                                                  </div>

                                                </div>



                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 3">
                                            <div :key="i.id" v-for="i in setacreagefy0">
                                              <el-form :model="form" label-width="80px" ref="form"
                                                       v-if="item.id == i.id">
                                                <el-form-item label="">
                                                  <el-select :placeholder="'请选择' + i.name"
                                                             @change="selecChangefy" v-model="i.models">
                                                    <el-option
                                                        :key="items.id"
                                                        :label="items.name"
                                                        :value="items.id"
                                                        v-for="items in i.processesSetList"
                                                    ></el-option>
                                                  </el-select>
                                                </el-form-item>
                                                <el-form-item label="数量" label-width="45px"
                                                              class="setacreage3_desc">
                                                  <el-input
                                                      @change="textareaChangefy($event,i,index)"
                                                      type="textarea"
                                                      v-model="i.desc"
                                                  ></el-input>
                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>
                                        </div>
                                      </el-checkbox-group>


                                    </div>

                                    <span class="dialog-footer" slot="footer">
                                      <el-button @click="cancel3(index)">取 消</el-button>
                                      <el-button @click="centerDialogVisible6 = false;Submitfy(index);" type="primary">提 交</el-button>
                                    </span>
                                </el-dialog>
                            </div>
                        </div>
                    </el-form>
                    <!--插页-->
                    <el-form :model="form" label-width="80px" ref="form">
                        <div :key="index" v-for="(items, index) in +chayearr.length">
                            <!--内页类型&插页-->
                            <div class="chengpin">
                                <el-form-item label="内页类型">
                                    <span class="class">插页</span>
                                    <span>[</span>
                                    <el-link :underline="false" @click="deletecy($event, index)" type="primary">删除
                                    </el-link>
                                    <span>]</span>
                                </el-form-item>
                                <el-form-item label="插页数">
                                    <span class="class"><el-input-number v-model="form.cynumber[index]"   style="width: 60px;" :controls ="false" ></el-input-number>张</span>
                                </el-form-item>
                            </div>

                            <!--插页纸张-->
                            <div class="chengpin">
                                <el-form-item label="插页纸张">
                                    <el-select @change="Changetypepagenumbercy($event, index)" placeholder="请选择插页纸张"
                                               v-model="form.pagenumberTypecy[index]">
                                        <el-option
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.id"
                                                v-for="item in pagenumberList"
                                        ></el-option>
                                    </el-select>
                                    <span class="class"></span>
                                    <el-select placeholder="请选择克" v-model="form.pagenumberGramcy[index]">
                                        <el-option
                                                :key="item.weightid"
                                                :label="item.name"
                                                :value="item.weightid"
                                                v-for="item in pagenumbertWeightList"
                                        ></el-option>
                                    </el-select>
                                    <span class="class">克</span>
                                    <el-checkbox v-model="isCustMacy[index]">自带料</el-checkbox>
                                </el-form-item>
                            </div>

                            <!--印刷颜色-->
                            <div class="chengpin">
                                <el-form-item label="印刷颜色">
                                    <el-select placeholder="请选择单双面" v-model="form.singlescy[index]">
                                        <el-option label="单面" value="color"></el-option>
                                        <el-option label="双面" value="colors"></el-option>
                                    </el-select>
                                    <span class="class"></span>
                                    <el-select placeholder="请选择印刷颜色" v-model="form.colourscy[index]">
                                        <el-option
                                                :key="item.colorid"
                                                :label="item.name"
                                                :value="item.colorid"
                                                v-for="item in colorList"
                                        ></el-option>
                                        <el-option label="不印刷" value="">不印刷</el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <!--插页后道必选项_折页-->
                            <div class="chengpin" v-for="item in pagenumberprocessListbx">
                                <el-form-item :label="item.name">
                                    <el-select :placeholder="'请选择'+item.name" v-model="form.cybx[index]">
                                        <el-option
                                                v-for="i in item.processesSetList"
                                                :key="i.id"
                                                :label="i.name"
                                                :value="i.id"
                                        ></el-option>
                                        <!--<el-option label="不印刷" value="">不印刷</el-option>-->
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="houdao">
                                <el-form-item label="插页工艺">
                                    <el-checkbox v-model="checkedcy[index]" @change="CheckedChangecy($event, index)">{{
                                        checkedtextcy[index] }}
                                    </el-checkbox>
                                </el-form-item>

                                <el-dialog
                                        :visible.sync="centerDialogVisible7"
                                        center
                                        title="设置插页其他工艺"
                                        width="50%"
                                        :before-close="handleClose4"
                                >
                                    <div style="height: 300px; overflow: auto">
                                      <el-checkbox-group @change="handleCheckedChangecy" v-model="form.checkedCheckboxcy">
                                        <div v-for="item in pagenumberprocessList" :key="item.id" style="width: 40%;">
                                          <el-checkbox :label="item.id" :value="item.id" v-if="item.mustchoose == 1 && item.defaultShow != 1"
                                                       :checked="true"
                                                       disabled>
                                            {{ item.name }}

                                            <div v-if="item.setacreage == 0">
                                              <div v-for="i in setacreagecy0" :key="i.id">
                                                <el-form ref="form" label-width="80px" v-if="item.id == i.id">
                                                  <el-form-item label="">
                                                    <el-select
                                                        v-model="i.models"
                                                        @change="selecChangecy"
                                                        :placeholder="'请选择' + i.name"
                                                        style="width: 80px;"
                                                    >
                                                      <el-option
                                                          v-for="(items, index) in i.processesSetList"
                                                          :key="index"
                                                          :label="items.name"
                                                          :value="items.id"
                                                      ></el-option>
                                                    </el-select>
                                                  </el-form-item>
                                                </el-form>
                                              </div>
                                            </div>
                                          </el-checkbox>

                                          <el-checkbox :label="item.id" :value="item.id" v-if="item.mustchoose !== 1"
                                                       :checked="item.defaultShow == 1">
                                            {{ item.name }}
                                          </el-checkbox>

                                          <div v-if="item.setacreage == 0 && item.mustchoose !== 1">
                                            <div :key="i.id" v-for="i in setacreagecy0">
                                              <el-form label-width="80px" ref="form" v-if="item.id == i.id">
                                                <el-form-item label="">
                                                  <el-select :placeholder="'请选择' + i.name"
                                                             style="width: 100px;"
                                                             @change="selecChangecy" v-model="i.models">
                                                    <el-option
                                                        :key="indexx"
                                                        :label="items.name"
                                                        :value="items.id"
                                                        v-for="(items, indexx) in i.processesSetList"
                                                    ></el-option>
                                                  </el-select>
                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 1">
                                            <div v-for="i in setacreagecy0" :key="i.id">
                                              <el-form ref="form" :model="form" label-width="80px"
                                                       v-if="item.id == i.id">

                                                <el-form-item label="" :label-width="formWidth"
                                                              v-if="(i.num >0)&&item.processesSetList[0].id==i.processesSetList[0].id">

                                                  <el-form-item label="" class="input" :id="item.id"
                                                                v-for="(a,index) in +i.num" :key="index">
                                                    <el-form-item label=""  >
                                                      <el-input v-model="i.inSize[index].width"
                                                                autocomplete="off"
                                                                :id="i.id"
                                                                @change="widthChangecy($event,i,index)"></el-input>
                                                      <span>X</span>
                                                    </el-form-item>
                                                    <el-form-item label="">
                                                      <el-input v-model="i.inSize[index].length"
                                                                autocomplete="off"
                                                                @change="lengthChangecy($event,i,index)"></el-input>
                                                      <span>mm</span>
                                                      <el-button v-if="a==1" type="primary"
                                                                 class="newcyAdded"
                                                                 @click="newcyAdded(item, i)">新增
                                                      </el-button>
                                                      <el-button v-else type="primary"
                                                                 class="newcyAdded"
                                                                 @click="delcy(item, i)">删除
                                                      </el-button>
                                                    </el-form-item>
                                                  </el-form-item>

                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 2" style="margin-bottom: 20px;">
                                            <div v-for="i in setacreagecy0" :key="i.id">
                                              <el-form
                                                  ref="form"
                                                  :model="form"
                                                  label-width="80px"
                                                  v-if="item.id == i.id"
                                              >

                                                <div label=""
                                                     v-if="(i.num >0)&&item.processesSetList[0].id==i.processesSetList[0].id"  style="margin-top: -30px;margin-bottom: 20px;">

                                                  <div label="" class="input" :id="item.id"
                                                       v-for="(a,index) in +i.num" :key="index" style="width: 380px; height: 41px;">

                                                    <el-form-item label="" v-if="item.id == i.id"   style="display:inline-flex;">
                                                      <select
                                                          v-model="i.inSize[index].models"
                                                          :placeholder="'请选择' + i.name"
                                                          class="myselect"
                                                          @change="selecChange1cy(i.inSize[index].models,i,index)"
                                                      >
                                                        <option
                                                            v-for="(items, index) in i.processesSetList"
                                                            :key="index"
                                                            :label="items.name"
                                                            :value="items.id + ',' + items.name"
                                                        ></option>
                                                      </select>
                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">
                                                      长<el-input-number v-model="i.inSize[index].width" autocomplete="off"
                                                                       placeholder="长度" :controls ="false"
                                                                       :id="i.id" style="width: 60px;"
                                                                       @change="widthChangecy($event,i,index)"></el-input-number>
                                                      <span >X</span>
                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">
                                                      宽<el-input-number v-model="i.inSize[index].length"   style="width: 60px;"
                                                                       placeholder="宽度" :controls ="false"
                                                                       @change="lengthChangecy($event,i,index)"></el-input-number>
                                                      <span >mm</span>

                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">

                                                      <el-button v-if="a==1" type="text" class="newcyAdded"  style="margin-top: 20px;"
                                                                 @click="newcyAdded(item, i)">增
                                                      </el-button>
                                                      <el-button v-else type="text" class="newcyAdded"  style="margin-top: 20px;"
                                                                 @click="delcy(item, i)">删
                                                      </el-button>
                                                    </el-form-item>

                                                  </div>

                                                </div>



                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 3">
                                            <div :key="i.id" v-for="i in setacreagecy0">
                                              <el-form :model="form" label-width="80px" ref="form"
                                                       v-if="item.id == i.id">
                                                <el-form-item label="">
                                                  <el-select :placeholder="'请选择' + i.name"
                                                             @change="selecChangecy" v-model="i.models">
                                                    <el-option
                                                        :key="items.id"
                                                        :label="items.name"
                                                        :value="items.id"
                                                        v-for="items in i.processesSetList"
                                                    ></el-option>
                                                  </el-select>
                                                </el-form-item>
                                                <el-form-item label="数量" label-width="45px"
                                                              class="setacreage3_desc">
                                                  <el-input
                                                      @change="textareaChangecy($event,i,index)"
                                                      type="textarea"
                                                      v-model="i.desc"
                                                  ></el-input>
                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>
                                        </div>
                                      </el-checkbox-group>
                                    </div>

                                    <span class="dialog-footer" slot="footer">
                                      <el-button @click="cancel4(index)">取 消</el-button>
                                      <el-button @click="centerDialogVisible7 = false;Submitcy(index);" type="primary">提 交</el-button>
                                    </span>
                                </el-dialog>
                            </div>
                        </div>
                    </el-form>
                    <!--拉页-->
                    <el-form :model="form" label-width="80px" ref="form">
                        <div :key="index" v-for="(items, index) in +layearr.length">
                            <!--内页类型&拉页-->
                            <div class="chengpin">
                                <el-form-item label="内页类型">
                                    <span class="class">拉页</span>
                                    <span>[</span>
                                    <el-link :underline="false" @click="deletely($event, index)" type="primary">删除
                                    </el-link>
                                    <span>]</span>
                                </el-form-item>
                                <el-form-item label="拉页P数">
                                    <el-input v-model="form.pagenumberarr[index]" placeholder="请输入偶数值的拉页P数"
                                              :style="pNum_width"
                                              @input="ChangepNum">{{form.pagenumberarr[index]}}
                                    </el-input>
                                    <span class="class">P</span>
                                </el-form-item>
                            </div>

                            <!--拉页纸张-->
                            <div class="chengpin">
                                <el-form-item label="扉页纸张">
                                    <el-select @change="Changetypepagenumberly($event, index)" placeholder="请选择扉页纸张"
                                               v-model="form.pagenumberTypely[index]">
                                        <el-option
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.id"
                                                v-for="item in pagenumberList"
                                        ></el-option>
                                    </el-select>
                                    <span class="class"></span>
                                    <el-select placeholder="请选择克" v-model="form.pagenumberGramly[index]">
                                        <el-option
                                                :key="item.weightid"
                                                :label="item.name"
                                                :value="item.weightid"
                                                v-for="item in pagenumbertWeightList"
                                        ></el-option>
                                    </el-select>
                                    <span class="class">克</span>
                                    <el-checkbox v-model="isCustMaly[index]">自带料</el-checkbox>
                                </el-form-item>
                            </div>

                            <!--印刷颜色-->
                            <div class="chengpin">
                                <el-form-item label="印刷颜色">
                                    <el-select placeholder="请选择单双面" v-model="form.singlesly[index]">
                                        <el-option label="单面" value="color"></el-option>
                                        <el-option label="双面" value="colors"></el-option>
                                    </el-select>
                                    <span class="class"></span>
                                    <el-select placeholder="请选择印刷颜色" v-model="form.coloursly[index]">
                                        <el-option
                                                :key="item.colorid"
                                                :label="item.name"
                                                :value="item.colorid"
                                                v-for="item in colorList"
                                        ></el-option>
                                        <el-option label="不印刷" value="">不印刷</el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <!--拉页后道必选项_折页-->
                            <div class="chengpin" v-for="item in pagenumberprocessListbx">
                                <el-form-item :label="item.name">
                                    <el-select :placeholder="'请选择'+item.name" v-model="form.lybx[index]">
                                        <el-option
                                                v-for="i in item.processesSetList"
                                                :key="i.id"
                                                :label="i.name"
                                                :value="i.id"
                                        ></el-option>
                                        <!--<el-option label="不印刷" value="">不印刷</el-option>-->
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="houdao">
                                <el-form-item label="拉页工艺">
                                    <el-checkbox v-model="checkedly[index]" @change="CheckedChangely($event, index)">{{
                                        checkedtextly[index] }}
                                    </el-checkbox>
                                </el-form-item>

                                <el-dialog
                                        :visible.sync="centerDialogVisible8"
                                        center
                                        title="设置拉页其他工艺"
                                        width="50%"
                                        :before-close="handleClose5"
                                >
                                    <div style="height: 300px; overflow: auto">

                                      <el-checkbox-group @change="handleCheckedChangely" v-model="form.checkedCheckboxly">
                                        <div v-for="item in pagenumberprocessList" :key="item.id" style="width: 40%;">
                                          <el-checkbox :label="item.id" :value="item.id" v-if="item.mustchoose == 1 && item.defaultShow != 1"
                                                       :checked="true"
                                                       disabled>
                                            {{ item.name }}

                                            <div v-if="item.setacreage == 0">
                                              <div v-for="i in setacreagely0" :key="i.id">
                                                <el-form ref="form" label-width="80px" v-if="item.id == i.id">
                                                  <el-form-item label="">
                                                    <el-select
                                                        v-model="i.models"
                                                        @change="selecChangely"
                                                        :placeholder="'请选择' + i.name"
                                                        style="width: 80px;"
                                                    >
                                                      <el-option
                                                          v-for="(items, index) in i.processesSetList"
                                                          :key="index"
                                                          :label="items.name"
                                                          :value="items.id"
                                                      ></el-option>
                                                    </el-select>
                                                  </el-form-item>
                                                </el-form>
                                              </div>
                                            </div>
                                          </el-checkbox>

                                          <el-checkbox :label="item.id" :value="item.id" v-if="item.mustchoose !== 1"
                                                       :checked="item.defaultShow == 1">
                                            {{ item.name }}
                                          </el-checkbox>

                                          <div v-if="item.setacreage == 0 && item.mustchoose !== 1">
                                            <div :key="i.id" v-for="i in setacreagely0">
                                              <el-form label-width="80px" ref="form" v-if="item.id == i.id">
                                                <el-form-item label="">
                                                  <el-select :placeholder="'请选择' + i.name"
                                                             style="width: 100px;"
                                                             @change="selecChangely" v-model="i.models">
                                                    <el-option
                                                        :key="indexx"
                                                        :label="items.name"
                                                        :value="items.id"
                                                        v-for="(items, indexx) in i.processesSetList"
                                                    ></el-option>
                                                  </el-select>
                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 1">
                                            <div v-for="i in setacreagely0" :key="i.id">
                                              <el-form ref="form" :model="form" label-width="80px"
                                                       v-if="item.id == i.id">

                                                <el-form-item label="" :label-width="formWidth"
                                                              v-if="(i.num >0)&&item.processesSetList[0].id==i.processesSetList[0].id">

                                                  <el-form-item label="" class="input" :id="item.id"
                                                                v-for="(a,index) in +i.num" :key="index">
                                                    <el-form-item label="" :label-width="formWidth">
                                                      <el-input v-model="i.inSize[index].width"
                                                                autocomplete="off"
                                                                :id="i.id"
                                                                @change="widthChangely($event,i,index)"></el-input>
                                                      <span>X</span>
                                                    </el-form-item>
                                                    <el-form-item label="" :label-width="formWidth">
                                                      <el-input v-model="i.inSize[index].length"
                                                                autocomplete="off"
                                                                @change="lengthChangely($event,i,index)"></el-input>
                                                      <span>mm</span>
                                                      <el-button v-if="a==1" type="primary"
                                                                 class="newlyAdded"
                                                                 @click="newlyAdded(item, i)">新增
                                                      </el-button>
                                                      <el-button v-else type="primary"
                                                                 class="newlyAdded"
                                                                 @click="delly(item, i)">删除
                                                      </el-button>
                                                    </el-form-item>
                                                  </el-form-item>

                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 2" style="margin-bottom: 20px;">
                                            <div v-for="i in setacreagely0" :key="i.id">
                                              <el-form
                                                  ref="form"
                                                  :model="form"
                                                  label-width="80px"
                                                  v-if="item.id == i.id"
                                              >

                                                <div label=""
                                                     v-if="(i.num >0)&&item.processesSetList[0].id==i.processesSetList[0].id"  style="margin-top: -30px;margin-bottom: 20px;">

                                                  <div label="" class="input" :id="item.id"
                                                       v-for="(a,index) in +i.num" :key="index" style="width: 380px; height: 41px;">

                                                    <el-form-item label="" v-if="item.id == i.id"   style="display:inline-flex;">
                                                      <select
                                                          v-model="i.inSize[index].models"
                                                          :placeholder="'请选择' + i.name"
                                                          class="myselect"
                                                          @change="selecChange1ly(i.inSize[index].models,i,index)"
                                                      >
                                                        <option
                                                            v-for="(items, index) in i.processesSetList"
                                                            :key="index"
                                                            :label="items.name"
                                                            :value="items.id + ',' + items.name"
                                                        ></option>
                                                      </select>
                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">
                                                      长<el-input-number v-model="i.inSize[index].width" autocomplete="off"
                                                                       placeholder="长度" :controls ="false"
                                                                       :id="i.id" style="width: 60px;"
                                                                       @change="widthChangely($event,i,index)"></el-input-number>
                                                      <span >X</span>
                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">
                                                      宽<el-input-number v-model="i.inSize[index].length"   style="width: 60px;"
                                                                       placeholder="宽度" :controls ="false"
                                                                       @change="lengthChangely($event,i,index)"></el-input-number>
                                                      <span >mm</span>

                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">

                                                      <el-button v-if="a==1" type="text" class="newlyAdded"  style="margin-top: 20px;"
                                                                 @click="newlyAdded(item, i)">增
                                                      </el-button>
                                                      <el-button v-else type="text" class="newlyAdded"  style="margin-top: 20px;"
                                                                 @click="delly(item, i)">删
                                                      </el-button>
                                                    </el-form-item>

                                                  </div>

                                                </div>



                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 3">
                                            <div :key="i.id" v-for="i in setacreagely0">
                                              <el-form :model="form" label-width="80px" ref="form"
                                                       v-if="item.id == i.id">
                                                <el-form-item label="">
                                                  <el-select :placeholder="'请选择' + i.name"
                                                             @change="selecChangely" v-model="i.models">
                                                    <el-option
                                                        :key="items.id"
                                                        :label="items.name"
                                                        :value="items.id"
                                                        v-for="items in i.processesSetList"
                                                    ></el-option>
                                                  </el-select>
                                                </el-form-item>
                                                <el-form-item label="数量" label-width="45px"
                                                              class="setacreage3_desc">
                                                  <el-input
                                                      @change="textareaChangely($event,i,index)"
                                                      type="textarea"
                                                      v-model="i.desc"
                                                  ></el-input>
                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>
                                        </div>
                                      </el-checkbox-group>

                                    </div>

                                    <span class="dialog-footer" slot="footer">
                                      <el-button @click="cancel5(index)">取 消</el-button>
                                      <el-button @click="centerDialogVisible8 = false;Submitly(index);" type="primary">提 交</el-button>
                                    </span>
                                </el-dialog>
                            </div>
                        </div>
                    </el-form>
                </div>

                <!--画册添加特殊部件/工艺-->
                <div class="modular canshu ny">
                    <span class="titleh4" style="display: flex; justify-content: space-between">
                        <h4></h4>
                        <el-button @click="coverVisible3 = true" type="text">画册添加特殊部件/工艺</el-button>
                    </span>

                    <el-dialog
                            :visible.sync="coverVisible3"
                            center
                            title="增加画册其他工艺（部件）"
                            width="30%"
                    >
                        <el-form :model="form">
                            <el-form-item label="">
                                <el-radio-group v-model="form.other">
                                    <el-radio label="21" v-model="form.other">画册加护封</el-radio>
                                    <el-radio label="22" v-model="form.other">画册加腰带</el-radio>
                                    <el-radio label="23" v-model="form.other">
                                        自定义部件
                                        <el-input v-model="form.input3"></el-input>
                                    </el-radio>
                                    <el-radio label="24" v-model="form.other">
                                        书本打孔
                                        <el-input v-model="form.input4"></el-input>
                                        处/本
                                    </el-radio>
                                    <el-radio label="25" v-model="form.other">画册塑封</el-radio>
                                    <el-radio label="26" v-model="form.other">画册套自封袋</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-form>

                        <span class="dialog-footer" slot="footer">
                              <!--<el-button @click="coverVisible3 = false">取 消</el-button>-->
                            <el-button @click="coverVisible3 = false;coverdetermine3();" type="primary">提 交</el-button>
                        </span>
                    </el-dialog>

                    <hr/>

                    <!--画册加护封-->
                    <el-form :model="form" label-width="80px" ref="form">
                        <div :key="index" v-for="(items, index) in +HuaceDustJacket.length">
                            <!--画册加护封-->
                            <div style="display: flex">
                                <h4>画册加护封</h4>
                                <span style="margin-left: 12px">
                                    <span>[</span>
                                    <el-link :underline="false" @click="deleteDustJacket($event, index)"
                                             type="primary">删除</el-link>
                                    <span>]</span>
                                </span>
                            </div>

                            <!--展开尺寸-->
                            <div class="chengpin">
                                <el-form-item class="fengmian" label="展开尺寸">
                                    <span>宽</span>
                                    <el-input v-model="form.HuaceDustJacket_width"></el-input>
                                    <span>* 高</span>
                                    <el-input v-model="form.HuaceDustJacket_height"></el-input>
                                    <span>mm</span>
                                </el-form-item>
                            </div>

                            <!--纸张克重-->
                            <div class="chengpin">
                                <el-form-item label="纸张克重">
                                    <el-select @change="Changetypepagenumberhf($event, index)" placeholder="请选择纸张克重"
                                               v-model="form.pagenumberTypehf[index]">
                                        <el-option
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.id"
                                                v-for="item in otherpartsList"
                                        ></el-option>
                                    </el-select>
                                    <span class="class"></span>
                                    <el-select placeholder="请选择克" v-model="form.pagenumberGramhf[index]">
                                        <el-option
                                                :key="item.weightid"
                                                :label="item.name"
                                                :value="item.weightid"
                                                v-for="item in otherWeightList"
                                        ></el-option>
                                    </el-select>
                                    <span class="class">克</span>
                                    <el-checkbox v-model="isCustMahf">自带料</el-checkbox>
                                </el-form-item>
                            </div>

                            <!--印刷颜色-->
                            <div class="chengpin">
                                <el-form-item label="印刷颜色">
                                    <el-select placeholder="请选择单双面" v-model="form.singlesDustJacket[index]">
                                        <el-option label="单面" value="color"></el-option>
                                        <el-option label="双面" value="colors"></el-option>
                                    </el-select>
                                    <span class="class"></span>
                                    <el-select placeholder="请选择印刷颜色" v-model="form.coloursDustJacket[index]">
                                        <el-option
                                                :key="item.colorid"
                                                :label="item.name"
                                                :value="item.colorid"
                                                v-for="item in colorList"
                                        ></el-option>
                                        <el-option label="不印刷" value="">不印刷</el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="houdao">
                                <el-form-item label="其他工艺">
                                    <el-checkbox v-model="checkedDustJacket[index]"
                                                 @change="CheckedChangeDustJacket($event, index)">
                                        {{ checkedtextDustJacket[index] }}
                                    </el-checkbox>
                                </el-form-item>

                                <el-dialog
                                        :visible.sync="centerDialogVisible9"
                                        center
                                        title="设置封面其他工艺"
                                        width="50%"
                                        :before-close="handleClose6"
                                >
                                    <div style="height: 300px; overflow: auto">
                                      <el-checkbox-group @change="handleCheckedChangehf" v-model="form.checkedCheckboxhf">
                                        <div v-for="item in otherList" :key="item.id" style="width: 40%;">
                                          <el-checkbox :label="item.id" :value="item.id" v-if="item.mustchoose == 1 && item.defaultShow != 1"
                                                       :checked="true"
                                                       disabled>
                                            {{ item.name }}

                                            <div v-if="item.setacreage == 0">
                                              <div v-for="i in setacreagehf0" :key="i.id">
                                                <el-form ref="form" label-width="80px" v-if="item.id == i.id">
                                                  <el-form-item label="">
                                                    <el-select
                                                        v-model="i.models"
                                                        @change="selecChangehf"
                                                        :placeholder="'请选择' + i.name"
                                                        style="width: 80px;"
                                                    >
                                                      <el-option
                                                          v-for="(items, index) in i.processesSetList"
                                                          :key="index"
                                                          :label="items.name"
                                                          :value="items.id"
                                                      ></el-option>
                                                    </el-select>
                                                  </el-form-item>
                                                </el-form>
                                              </div>
                                            </div>
                                          </el-checkbox>

                                          <el-checkbox :label="item.id" :value="item.id" v-if="item.mustchoose !== 1"
                                                       :checked="item.defaultShow == 1">
                                            {{ item.name }}
                                          </el-checkbox>

                                          <div v-if="item.setacreage == 0 && item.mustchoose !== 1">
                                            <div :key="i.id" v-for="i in setacreagehf0">
                                              <el-form label-width="80px" ref="form" v-if="item.id == i.id">
                                                <el-form-item label="">
                                                  <el-select :placeholder="'请选择' + i.name"
                                                             style="width: 100px;"
                                                             @change="selecChangehf" v-model="i.models">
                                                    <el-option
                                                        :key="indexx"
                                                        :label="items.name"
                                                        :value="items.id"
                                                        v-for="(items, indexx) in i.processesSetList"
                                                    ></el-option>
                                                  </el-select>
                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 1">
                                            <div v-for="i in setacreagehf0" :key="i.id">
                                              <el-form ref="form" :model="form" label-width="80px"
                                                       v-if="item.id == i.id">

                                                <el-form-item label="" :label-width="formWidth"
                                                              v-if="(i.num >0)&&item.processesSetList[0].id==i.processesSetList[0].id">

                                                  <el-form-item label="" class="input" :id="item.id"
                                                                v-for="(a,index) in +i.num" :key="index">
                                                    <el-form-item label="" :label-width="formWidth">
                                                      <el-input v-model="i.inSize[index].width"
                                                                autocomplete="off"
                                                                :id="i.id"
                                                                @change="widthChangehf($event,i,index)"></el-input>
                                                      <span>X</span>
                                                    </el-form-item>
                                                    <el-form-item label="" :label-width="formWidth">
                                                      <el-input v-model="i.inSize[index].length"
                                                                autocomplete="off"
                                                                @change="lengthChangehf($event,i,index)"></el-input>
                                                      <span>mm</span>
                                                      <el-button v-if="a==1" type="primary"
                                                                 class="newlyAdded"
                                                                 @click="newhfAdded(item, i)">新增
                                                      </el-button>
                                                      <el-button v-else type="primary"
                                                                 class="newlyAdded"
                                                                 @click="delhf(item, i)">删除
                                                      </el-button>
                                                    </el-form-item>
                                                  </el-form-item>

                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 2" style="margin-bottom: 20px;">
                                            <div v-for="i in setacreagehf0" :key="i.id">
                                              <el-form
                                                  ref="form"
                                                  :model="form"
                                                  label-width="80px"
                                                  v-if="item.id == i.id"
                                              >

                                                <div label=""
                                                     v-if="(i.num >0)&&item.processesSetList[0].id==i.processesSetList[0].id"  style="margin-top: -30px;margin-bottom: 20px;">

                                                  <div label="" class="input" :id="item.id"
                                                       v-for="(a,index) in +i.num" :key="index" style="width: 380px; height: 41px;">

                                                    <el-form-item label="" v-if="item.id == i.id"   style="display:inline-flex;">
                                                      <select
                                                          v-model="i.inSize[index].models"
                                                          :placeholder="'请选择' + i.name"
                                                          class="myselect"
                                                          @change="selecChange1hf(i.inSize[index].models,i,index)"
                                                      >
                                                        <option
                                                            v-for="(items, index) in i.processesSetList"
                                                            :key="index"
                                                            :label="items.name"
                                                            :value="items.id + ',' + items.name"
                                                        ></option>
                                                      </select>
                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">
                                                      长<el-input-number v-model="i.inSize[index].width" autocomplete="off"
                                                                       placeholder="长度" :controls ="false"
                                                                       :id="i.id" style="width: 60px;"
                                                                       @change="widthChangehf($event,i,index)"></el-input-number>
                                                      <span >X</span>
                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">
                                                      宽<el-input-number v-model="i.inSize[index].length"   style="width: 60px;"
                                                                       placeholder="宽度" :controls ="false"
                                                                       @change="lengthChangehf($event,i,index)"></el-input-number>
                                                      <span >mm</span>

                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">

                                                      <el-button v-if="a==1" type="text" class="newlyAdded"  style="margin-top: 20px;"
                                                                 @click="newhfAdded(item, i)">增
                                                      </el-button>
                                                      <el-button v-else type="text" class="newlyAdded"  style="margin-top: 20px;"
                                                                 @click="delhf(item, i)">删
                                                      </el-button>
                                                    </el-form-item>

                                                  </div>

                                                </div>



                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 3">
                                            <div :key="i.id" v-for="i in setacreagehf0">
                                              <el-form :model="form" label-width="80px" ref="form"
                                                       v-if="item.id == i.id">
                                                <el-form-item label="">
                                                  <el-select :placeholder="'请选择' + i.name"
                                                             @change="selecChangehf" v-model="i.models">
                                                    <el-option
                                                        :key="items.id"
                                                        :label="items.name"
                                                        :value="items.id"
                                                        v-for="items in i.processesSetList"
                                                    ></el-option>
                                                  </el-select>
                                                </el-form-item>
                                                <el-form-item label="数量" label-width="45px"
                                                              class="setacreage3_desc">
                                                  <el-input
                                                      @change="textareaChangehf($event,i,index)"
                                                      type="textarea"
                                                      v-model="i.desc"
                                                  ></el-input>
                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>
                                        </div>
                                      </el-checkbox-group>


                                    </div>

                                    <span class="dialog-footer" slot="footer">
                                        <el-button @click="cancel6(index)">取 消</el-button>
                                        <el-button @click="centerDialogVisible9 = false;SubmitDustJacket(index);"
                                                   type="primary">提 交</el-button>
                                    </span>
                                </el-dialog>
                            </div>
                        </div>
                    </el-form>

                    <!--画册加腰带-->
                    <el-form :model="form" label-width="80px" ref="form">
                        <div :key="index" v-for="(items, index) in +HuaceBelt.length">
                            <!--画册加腰带-->
                            <div style="display: flex">
                                <h4>画册加腰带</h4>
                                <span style="margin-left: 12px">
                                    <span>[</span>
                                    <el-link :underline="false" @click="deleteBelt($event, index)"
                                             type="primary">删除</el-link>
                                    <span>]</span>
                                </span>
                            </div>

                            <!--展开尺寸-->
                            <div class="chengpin">
                                <el-form-item class="fengmian" label="展开尺寸">
                                    <span>宽</span>
                                    <el-input v-model="form.HuaceBelt_width"></el-input>
                                    <span>* 高</span>
                                    <el-input v-model="form.HuaceBelt_height"></el-input>
                                    <span>mm</span>
                                </el-form-item>
                            </div>

                            <!--纸张克重-->
                            <div class="chengpin">
                                <el-form-item label="纸张克重">
                                    <el-select @change="Changetypepagenumberyd($event, index)" placeholder="请选择纸张克重"
                                               v-model="form.pagenumberTypeyd[index]">
                                        <el-option
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.id"
                                                v-for="item in otherpartsList"
                                        ></el-option>
                                    </el-select>
                                    <span class="class"></span>
                                    <el-select placeholder="请选择克" v-model="form.pagenumberGramyd[index]">
                                        <el-option
                                                :key="item.weightid"
                                                :label="item.name"
                                                :value="item.weightid"
                                                v-for="item in otherWeightList"
                                        ></el-option>
                                    </el-select>
                                    <span class="class">克</span>
                                    <el-checkbox v-model="isCustMayd">自带料</el-checkbox>
                                </el-form-item>
                            </div>

                            <!--印刷颜色-->
                            <div class="chengpin">
                                <el-form-item label="印刷颜色">
                                    <el-select placeholder="请选择单双面" v-model="form.singlesBelt[index]">
                                        <el-option label="单面" value="color"></el-option>
                                        <el-option label="双面" value="colors"></el-option>
                                    </el-select>
                                    <span class="class"></span>
                                    <el-select placeholder="请选择印刷颜色" v-model="form.coloursBelt[index]">
                                        <el-option
                                                :key="item.colorid"
                                                :label="item.name"
                                                :value="item.colorid"
                                                v-for="item in colorList"
                                        ></el-option>
                                        <el-option label="不印刷" value="">不印刷</el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="houdao">
                                <el-form-item label="其他工艺">
                                    <el-checkbox v-model="checkedBelt[index]"
                                                 @change="CheckedChangeBelt($event, index)">{{ checkedtextBelt[index] }}
                                    </el-checkbox>
                                </el-form-item>

                                <el-dialog
                                        :visible.sync="centerDialogVisible10"
                                        center
                                        title="设置封面其他工艺"
                                        width="50%"
                                        :before-close="handleClose7"
                                >
                                    <div style="height: 300px; overflow: auto">

                                      <el-checkbox-group @change="handleCheckedChangeyd" v-model="form.checkedCheckboxyd">
                                        <div v-for="item in otherList" :key="item.id" style="width: 40%;">
                                          <el-checkbox :label="item.id" :value="item.id" v-if="item.mustchoose == 1 && item.defaultShow != 1"
                                                       :checked="true"
                                                       disabled>
                                            {{ item.name }}

                                            <div v-if="item.setacreage == 0">
                                              <div v-for="i in setacreageyd0" :key="i.id">
                                                <el-form ref="form" label-width="80px" v-if="item.id == i.id">
                                                  <el-form-item label="">
                                                    <el-select
                                                        v-model="i.models"
                                                        @change="selecChangeyd"
                                                        :placeholder="'请选择' + i.name"
                                                        style="width: 80px;"
                                                    >
                                                      <el-option
                                                          v-for="(items, index) in i.processesSetList"
                                                          :key="index"
                                                          :label="items.name"
                                                          :value="items.id"
                                                      ></el-option>
                                                    </el-select>
                                                  </el-form-item>
                                                </el-form>
                                              </div>
                                            </div>
                                          </el-checkbox>

                                          <el-checkbox :label="item.id" :value="item.id" v-if="item.mustchoose !== 1"
                                                       :checked="item.defaultShow == 1">
                                            {{ item.name }}
                                          </el-checkbox>

                                          <div v-if="item.setacreage == 0 && item.mustchoose !== 1">
                                            <div :key="i.id" v-for="i in setacreageyd0">
                                              <el-form label-width="80px" ref="form" v-if="item.id == i.id">
                                                <el-form-item label="">
                                                  <el-select :placeholder="'请选择' + i.name"
                                                             style="width: 100px;"
                                                             @change="selecChangeyd" v-model="i.models">
                                                    <el-option
                                                        :key="indexx"
                                                        :label="items.name"
                                                        :value="items.id"
                                                        v-for="(items, indexx) in i.processesSetList"
                                                    ></el-option>
                                                  </el-select>
                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 1">
                                            <div v-for="i in setacreageyd0" :key="i.id">
                                              <el-form ref="form" :model="form" label-width="80px"
                                                       v-if="item.id == i.id">

                                                <el-form-item label="" :label-width="formWidth"
                                                              v-if="(i.num >0)&&item.processesSetList[0].id==i.processesSetList[0].id">

                                                  <el-form-item label="" class="input" :id="item.id"
                                                                v-for="(a,index) in +i.num" :key="index">
                                                    <el-form-item label="" :label-width="formWidth">
                                                      <el-input v-model="i.inSize[index].width"
                                                                autocomplete="off"
                                                                :id="i.id"
                                                                @change="widthChangeyd($event,i,index)"></el-input>
                                                      <span>X</span>
                                                    </el-form-item>
                                                    <el-form-item label="" :label-width="formWidth">
                                                      <el-input v-model="i.inSize[index].length"
                                                                autocomplete="off"
                                                                @change="lengthChangeyd($event,i,index)"></el-input>
                                                      <span>mm</span>
                                                      <el-button v-if="a==1" type="primary"
                                                                 class="newlyAdded"
                                                                 @click="newydAdded(item, i)">新增
                                                      </el-button>
                                                      <el-button v-else type="primary"
                                                                 class="newlyAdded"
                                                                 @click="delyd(item, i)">删除
                                                      </el-button>
                                                    </el-form-item>
                                                  </el-form-item>

                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 2" style="margin-bottom: 20px;">
                                            <div v-for="i in setacreageyd0" :key="i.id">
                                              <el-form
                                                  ref="form"
                                                  :model="form"
                                                  label-width="80px"
                                                  v-if="item.id == i.id"
                                              >

                                                <div label=""
                                                     v-if="(i.num >0)&&item.processesSetList[0].id==i.processesSetList[0].id"  style="margin-top: -30px;margin-bottom: 20px;">

                                                  <div label="" class="input" :id="item.id"
                                                       v-for="(a,index) in +i.num" :key="index" style="width: 380px; height: 41px;">

                                                    <el-form-item label="" v-if="item.id == i.id"   style="display:inline-flex;">
                                                      <select
                                                          v-model="i.inSize[index].models"
                                                          :placeholder="'请选择' + i.name"
                                                          class="myselect"
                                                          @change="selecChange1yd(i.inSize[index].models,i,index)"
                                                      >
                                                        <option
                                                            v-for="(items, index) in i.processesSetList"
                                                            :key="index"
                                                            :label="items.name"
                                                            :value="items.id + ',' + items.name"
                                                        ></option>
                                                      </select>
                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">
                                                      长<el-input-number v-model="i.inSize[index].width" autocomplete="off"
                                                                       placeholder="长度" :controls ="false"
                                                                       :id="i.id" style="width: 60px;"
                                                                       @change="widthChangeyd($event,i,index)"></el-input-number>
                                                      <span >X</span>
                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">
                                                      宽<el-input-number v-model="i.inSize[index].length"   style="width: 60px;"
                                                                       placeholder="宽度" :controls ="false"
                                                                       @change="lengthChangeyd($event,i,index)"></el-input-number>
                                                      <span >mm</span>

                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">

                                                      <el-button v-if="a==1" type="text" class="newlyAdded"  style="margin-top: 20px;"
                                                                 @click="newydAdded(item, i)">增
                                                      </el-button>
                                                      <el-button v-else type="text" class="newlyAdded"  style="margin-top: 20px;"
                                                                 @click="delyd(item, i)">删
                                                      </el-button>
                                                    </el-form-item>

                                                  </div>

                                                </div>



                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 3">
                                            <div :key="i.id" v-for="i in setacreageyd0">
                                              <el-form :model="form" label-width="80px" ref="form"
                                                       v-if="item.id == i.id">
                                                <el-form-item label="">
                                                  <el-select :placeholder="'请选择' + i.name"
                                                             @change="selecChangeyd" v-model="i.models">
                                                    <el-option
                                                        :key="items.id"
                                                        :label="items.name"
                                                        :value="items.id"
                                                        v-for="items in i.processesSetList"
                                                    ></el-option>
                                                  </el-select>
                                                </el-form-item>
                                                <el-form-item label="数量" label-width="45px"
                                                              class="setacreage3_desc">
                                                  <el-input
                                                      @change="textareaChangeyd($event,i,index)"
                                                      type="textarea"
                                                      v-model="i.desc"
                                                  ></el-input>
                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>
                                        </div>
                                      </el-checkbox-group>

                                    </div>

                                    <span class="dialog-footer" slot="footer">
                                        <el-button @click="cancel7(index)">取 消</el-button>
                                        <el-button @click="centerDialogVisible10 = false;SubmitBelt(index);"
                                                   type="primary">提 交</el-button>
                                    </span>
                                </el-dialog>
                            </div>
                        </div>
                    </el-form>

                    <!--自定义部件-->
                    <el-form :model="form" label-width="80px" ref="form">
                        <div :key="index" v-for="(items, index) in +CustomParts.length">
                            <!--自定义部件-->
                            <div style="display: flex">
                                <h4>自定义部件({{ form.input3 }})</h4>
                                <span style="margin-left: 12px">
                                    <span>[</span>
                                    <el-link :underline="false" @click="deletezdy($event, index)"
                                             type="primary">删除</el-link>
                                    <span>]</span>
                                </span>
                            </div>

                            <!--展开尺寸-->
                            <div class="chengpin">
                                <el-form-item class="fengmian" label="展开尺寸">
                                    <span>宽</span>
                                    <el-input v-model="form.CustomParts_width"></el-input>
                                    <span>* 高</span>
                                    <el-input v-model="form.CustomParts_height"></el-input>
                                    <span>mm</span>
                                </el-form-item>
                            </div>

                            <!--纸张克重-->
                            <div class="chengpin">
                                <el-form-item label="纸张克重">
                                    <el-select @change="Changetypepagenumberzdy($event, index)" placeholder="请选择纸张克重"
                                               v-model="form.pagenumberTypezdy[index]">
                                        <el-option
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.id"
                                                v-for="item in otherpartsList"
                                        ></el-option>
                                    </el-select>
                                    <span class="class"></span>
                                    <el-select placeholder="请选择克" v-model="form.pagenumberGramzdy[index]">
                                        <el-option
                                                :key="item.weightid"
                                                :label="item.name"
                                                :value="item.weightid"
                                                v-for="item in otherWeightList"
                                        ></el-option>
                                    </el-select>
                                    <span class="class">克</span>
                                    <el-checkbox v-model="isCustMazdy">自带料</el-checkbox>
                                </el-form-item>
                            </div>

                            <!--印刷颜色-->
                            <div class="chengpin">
                                <el-form-item label="印刷颜色">
                                    <el-select placeholder="请选择单双面" v-model="form.singleszdy[index]">
                                        <el-option label="单面" value="color"></el-option>
                                        <el-option label="双面" value="colors"></el-option>
                                    </el-select>
                                    <span class="class"></span>
                                    <el-select placeholder="请选择印刷颜色" v-model="form.colourszdy[index]">
                                        <el-option
                                                :key="item.colorid"
                                                :label="item.name"
                                                :value="item.colorid"
                                                v-for="item in colorList"
                                        ></el-option>
                                        <el-option label="不印刷" value="">不印刷</el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="houdao">
                                <el-form-item label="其他工艺">
                                    <el-checkbox v-model="checkedzdy[index]" @change="CheckedChangezdy($event, index)">
                                        {{ checkedtextzdy[index] }}
                                    </el-checkbox>
                                </el-form-item>

                                <el-dialog
                                        :visible.sync="centerDialogVisible11"
                                        center
                                        title="设置封面其他工艺"
                                        width="50%"
                                        :before-close="handleClose8"
                                >
                                    <div style="height: 300px; overflow: auto">


                                      <el-checkbox-group @change="handleCheckedChangezdy" v-model="form.checkedCheckboxzdy">
                                        <div v-for="item in otherList" :key="item.id" style="width: 40%;">
                                          <el-checkbox :label="item.id" :value="item.id" v-if="item.mustchoose == 1 && item.defaultShow != 1"
                                                       :checked="true"
                                                       disabled>
                                            {{ item.name }}

                                            <div v-if="item.setacreage == 0">
                                              <div v-for="i in setacreagezdy0" :key="i.id">
                                                <el-form ref="form" label-width="80px" v-if="item.id == i.id">
                                                  <el-form-item label="">
                                                    <el-select
                                                        v-model="i.models"
                                                        @change="selecChangezdy"
                                                        :placeholder="'请选择' + i.name"
                                                        style="width: 80px;"
                                                    >
                                                      <el-option
                                                          v-for="(items, index) in i.processesSetList"
                                                          :key="index"
                                                          :label="items.name"
                                                          :value="items.id"
                                                      ></el-option>
                                                    </el-select>
                                                  </el-form-item>
                                                </el-form>
                                              </div>
                                            </div>
                                          </el-checkbox>

                                          <el-checkbox :label="item.id" :value="item.id" v-if="item.mustchoose !== 1"
                                                       :checked="item.defaultShow == 1">
                                            {{ item.name }}
                                          </el-checkbox>

                                          <div v-if="item.setacreage == 0 && item.mustchoose !== 1">
                                            <div :key="i.id" v-for="i in setacreagezdy0">
                                              <el-form label-width="80px" ref="form" v-if="item.id == i.id">
                                                <el-form-item label="">
                                                  <el-select :placeholder="'请选择' + i.name"
                                                             style="width: 100px;"
                                                             @change="selecChangezdy" v-model="i.models">
                                                    <el-option
                                                        :key="indexx"
                                                        :label="items.name"
                                                        :value="items.id"
                                                        v-for="(items, indexx) in i.processesSetList"
                                                    ></el-option>
                                                  </el-select>
                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 1">
                                            <div v-for="i in setacreagezdy0" :key="i.id">
                                              <el-form ref="form" :model="form" label-width="80px"
                                                       v-if="item.id == i.id">

                                                <el-form-item label="" :label-width="formWidth"
                                                              v-if="(i.num >0)&&item.processesSetList[0].id==i.processesSetList[0].id">

                                                  <el-form-item label="" class="input" :id="item.id"
                                                                v-for="(a,index) in +i.num" :key="index">
                                                    <el-form-item label="" :label-width="formWidth">
                                                      <el-input v-model="i.inSize[index].width"
                                                                autocomplete="off"
                                                                :id="i.id"
                                                                @change="widthChangezdy($event,i,index)"></el-input>
                                                      <span>X</span>
                                                    </el-form-item>
                                                    <el-form-item label="" :label-width="formWidth">
                                                      <el-input v-model="i.inSize[index].length"
                                                                autocomplete="off"
                                                                @change="lengthChangezdy($event,i,index)"></el-input>
                                                      <span>mm</span>
                                                      <el-button v-if="a==1" type="primary"
                                                                 class="newlyAdded"
                                                                 @click="newzdyAdded(item, i)">新增
                                                      </el-button>
                                                      <el-button v-else type="primary"
                                                                 class="newlyAdded"
                                                                 @click="delzdy(item, i)">删除
                                                      </el-button>
                                                    </el-form-item>
                                                  </el-form-item>

                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 2" style="margin-bottom: 20px;">
                                            <div v-for="i in setacreagezdy0" :key="i.id">
                                              <el-form
                                                  ref="form"
                                                  :model="form"
                                                  label-width="80px"
                                                  v-if="item.id == i.id"
                                              >

                                                <div label=""
                                                     v-if="(i.num >0)&&item.processesSetList[0].id==i.processesSetList[0].id"  style="margin-top: -30px;margin-bottom: 20px;">

                                                  <div label="" class="input" :id="item.id"
                                                       v-for="(a,index) in +i.num" :key="index" style="width: 380px; height: 41px;">

                                                    <el-form-item label="" v-if="item.id == i.id"   style="display:inline-flex;">
                                                      <select
                                                          v-model="i.inSize[index].models"
                                                          :placeholder="'请选择' + i.name"
                                                          class="myselect"
                                                          @change="selecChange1zdy(i.inSize[index].models,i,index)"
                                                      >
                                                        <option
                                                            v-for="(items, index) in i.processesSetList"
                                                            :key="index"
                                                            :label="items.name"
                                                            :value="items.id + ',' + items.name"
                                                        ></option>
                                                      </select>
                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">
                                                      长<el-input-number v-model="i.inSize[index].width" autocomplete="off"
                                                                       placeholder="长度" :controls ="false"
                                                                       :id="i.id" style="width: 60px;"
                                                                       @change="widthChangezdy($event,i,index)"></el-input-number>
                                                      <span >X</span>
                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">
                                                      宽<el-input-number v-model="i.inSize[index].length"   style="width: 60px;"
                                                                       placeholder="宽度" :controls ="false"
                                                                       @change="lengthChangezdy($event,i,index)"></el-input-number>
                                                      <span >mm</span>

                                                    </el-form-item>
                                                    <el-form-item label=""  style="display:inline-flex;">

                                                      <el-button v-if="a==1" type="text" class="newlyAdded"  style="margin-top: 20px;"
                                                                 @click="newzdyAdded(item, i)">增
                                                      </el-button>
                                                      <el-button v-else type="text" class="newlyAdded"  style="margin-top: 20px;"
                                                                 @click="delzdy(item, i)">删
                                                      </el-button>
                                                    </el-form-item>

                                                  </div>

                                                </div>



                                              </el-form>
                                            </div>
                                          </div>

                                          <div v-if="item.setacreage == 3">
                                            <div :key="i.id" v-for="i in setacreagezdy0">
                                              <el-form :model="form" label-width="80px" ref="form"
                                                       v-if="item.id == i.id">
                                                <el-form-item label="">
                                                  <el-select :placeholder="'请选择' + i.name"
                                                             @change="selecChangezdy" v-model="i.models">
                                                    <el-option
                                                        :key="items.id"
                                                        :label="items.name"
                                                        :value="items.id"
                                                        v-for="items in i.processesSetList"
                                                    ></el-option>
                                                  </el-select>
                                                </el-form-item>
                                                <el-form-item label="数量" label-width="45px"
                                                              class="setacreage3_desc">
                                                  <el-input
                                                      @change="textareaChangezdy($event,i,index)"
                                                      type="textarea"
                                                      v-model="i.desc"
                                                  ></el-input>
                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>
                                        </div>
                                      </el-checkbox-group>

                                    </div>

                                    <span class="dialog-footer" slot="footer">
                                        <el-button @click="cancel8(index)">取 消</el-button>
                                        <el-button @click="centerDialogVisible11 = false;Submitzdy(index);"
                                                   type="primary">提 交</el-button>
                                    </span>
                                </el-dialog>
                            </div>
                        </div>
                    </el-form>

                    <!--书本打孔?处/本-->
                    <el-form :model="form" label-width="80px" ref="form">
                        <div :key="index" v-for="(items, index) in +Drilling.length">
                            <!--书本打孔?处/本-->
                            <div class="chengpin">
                                <el-form-item label="书本打孔">
                                    <span>{{ form.input4 }}</span>
                                    <span class="class">处/本</span>
                                    <span>[</span>
                                    <el-link :underline="false" @click="deletedk($event, index)" type="primary">删除
                                    </el-link>
                                    <span>]</span>
                                </el-form-item>
                            </div>
                        </div>
                    </el-form>

                    <!--画册塑封-->
                    <el-form :model="form" label-width="80px" ref="form">
                        <div :key="index" v-for="(items, index) in +Plastic.length">
                            <!--画册塑封-->
                            <div class="chengpin">
                                <el-form-item label="画册塑封">
                                    <span>[</span>
                                    <el-link :underline="false" @click="deletehcsf($event, index)" type="primary">删除
                                    </el-link>
                                    <span>]</span>
                                </el-form-item>
                            </div>
                        </div>
                    </el-form>

                    <!--画册套自封袋-->
                    <el-form :model="form" ref="form">
                        <div :key="index" v-for="(items, index) in +SelfSealingBag.length">
                            <!--画册套自封袋-->
                            <div class="chengpin">
                                <el-form-item label="画册套自封袋">
                                    <span>[</span>
                                    <el-link :underline="false" @click="deletehczfd($event, index)" type="primary">删除
                                    </el-link>
                                    <span>]</span>
                                </el-form-item>
                            </div>
                        </div>
                    </el-form>
                </div>

                <!--包装方式-->
                <div class="modular">
                    <h4>包装方式</h4>
                    <hr/>
                    <el-form :model="form" label-width="80px" ref="form">
                        <!--包装方式-->
                        <div class="chengpin">
                            <el-form-item label="包装方式">
                                <el-select placeholder="请选择包装方式" v-model="form.packing">
                                    <el-option
                                            :key="item.id"
                                            :label="item.typename"
                                            :value="item.id"
                                            v-for="item in packageList"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </el-form>
                </div>

                <!--配送方式-->
                <div class="modular">
                    <h4>配送方式</h4>
                    <hr/>
                    <el-form :model="form" label-width="80px" ref="form">
                        <!--包装方式-->
                        <div class="chengpin">
                            <el-form-item label="配送方式">
                                <el-select
                                        placeholder="请选择配送方式"
                                        v-model="form.distribution"
                                        @change="selecChangepsfs($event)"
                                >
                                    <el-option
                                            :key="item.id"
                                            :label="item.typename"
                                            :value="item.id"
                                            v-for="item in postList"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </div>

                        <!--服务客户-->
                        <div class="chengpin" v-if="this.userType !== 0" @click="service = true">
                            <el-form-item label="服务客户">
                                <el-input placeholder="请选择服务客户" v-model="servicetext" style="width: 25%;"></el-input>
                            </el-form-item>
                        </div>

                        <!--目的地-->
                        <div class="chengpin" v-if="this.form.distribution_mustaddr != 1">
                            <el-form-item label="目的地" prop="selectedOptions">
                                <el-cascader
                                        :options="options"
                                        @change="handleChange"
                                        size="large"
                                        v-model="form.selectedOptions"
                                >
                                </el-cascader>
                            </el-form-item>
                        </div>

                      <div class="chengpin">
                        <el-form-item label="发票要求" prop="invoice">
                          <el-select v-model="form.invoice"   >
                            <el-option  v-for="i  in invoiceList" :label="i.name" :value="i.num"></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                    </el-form>
                </div>

                <!--立即报价-->
                <div class="baojia">
                    <el-form>
                        <el-form-item>
                            <el-button
                                    @click="onSubmit()"
                                    type="primary"
                                    v-loading.fullscreen.lock="fullscreenLoading"
                            >立即报价
                            </el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>

            <!--服务客户对话框-->
            <el-dialog title="服务客户" :visible.sync="service" width="50%" center>
                <customer-service @transferUser="change($event)"></customer-service>
                <span slot="footer" class="dialog-footer">
                  <el-button @click="cancel">取 消</el-button>
                  <el-button type="primary" @click="determine">确 定</el-button>
                </span>
            </el-dialog>

            <!--立即下单对话框-->
            <div class="orderNow">
                <el-dialog :visible.sync="centerDialogVisible2" title="报价详情">
                    <table>
                        <tr>
                            <th>成品尺寸(宽*高)</th>
                            <th>印刷数量</th>
                            <th>装订方式</th>
                        </tr>
                        <tr>
                            <td>{{info.productSpec}}</td>
                            <td>{{info.productNum}}</td>
                            <td>{{info.bindingType}}</td>
                        </tr>
                    </table>
                    <table>
                        <tr>
                            <th>部件名称</th>
                            <th>P数/尺寸</th>
                            <th>印刷颜色</th>
                            <th>印刷纸张</th>
                            <th>后道工艺</th>
                        </tr>
                        <tr v-for="(item,index) in info.partsList" :key="index">
                            <td style="font-weight: bold">{{item.partsName}}</td>
                            <td>{{item.pNum}}</td>
                            <td>{{item.printColor}}</td>
                            <td>{{item.paperName}}</td>
                            <td>{{item.aftDesc}}</td>
                        </tr>
                        <tr class="packType" v-if="info.processName!=null">
                            <td style="font-weight: bold">成品工艺</td>
                            <td colspan="4">{{info.processName}}</td>
                        </tr>
                        <tr class="packType">
                          <th v-if="orderProduct.needDesign === '1'">设计</th>
                          <td v-if="orderProduct.needDesign === '1'">{{orderProduct.pNums}}p</td>
                          <th style="font-weight: bold">包装方式</th>
                          <td :colspan="orderProduct.needDesign === '1'?2:4">{{info.packageType}}</td>
                        </tr>
                    </table>

                  <div class="cost"
                       style="display: flex;margin-top: 10px;justify-content: space-evenly;line-height: 30px;">

                    <div>
                      <span class="text" style="color: #999; font-size: 12px">系统报价：</span>
                      <span style="color: #ed6d00;font-size: 14px;font-weight: 700;font-family: tahoma;">￥{{orderProduct.sysFee}}</span>
                    </div>
                    <div>
                      <span class="text" style="color: #999; font-size: 12px">运费：</span>
                      <span style="color: #ed6d00;font-size: 14px;font-weight: 700;font-family: tahoma;">￥{{info.postFee}}</span>
                    </div>
                    <div>
                      <span class="text" style="color: #999; font-size: 12px">税费：</span>
                      <span style="color: #ed6d00;font-size: 14px;font-weight: 700;font-family: tahoma;">￥{{info.invoiceFee}}</span>
                    </div>
                    <div>
                      <span class="text" style="color: #999; font-size: 12px">成交价：</span>
                      <span style="color: #ed6d00;font-size: 24px;font-weight: 700;font-family: tahoma;">￥{{orderProduct.productFee}}</span>
                    </div>


                    <div >
                      <el-button type="text"  v-if="userType == 1 && (btnType === '2'|| quoteList.length == 0 )" @click="openFeeDetail1(orderProduct.orderExtendInfoJson)">报价明细</el-button>
                    </div>
                    <div >
                      <el-button type="text"  v-if="userType == 1" @click="openFeeDetail2">报价明细</el-button>
                    </div>

                  </div>

                    <!--下单信息-->
                    <!--                    <order-now @transferUser="change($event)"></order-now>-->
                    <div class="ordernowList">
                        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px"
                                 class="demo-ruleForm">
                            <el-form-item label="印件名称" prop="name">
                                <el-input v-model="ruleForm.name" placeholder="请选择印件名称"></el-input>
                                <span class="grey">请输入印刷品最显著的标题</span>
                            </el-form-item>
                            <div class="line">

                                <el-form-item label="色彩要求" prop="color">
                                    <el-select v-model="ruleForm.color" placeholder="请选择色彩要求">
                                        <el-option v-for="(item,index) in colourList" :key="index" :label="item"
                                                   :value="item"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="发货日期" required>
                                    <el-form-item prop="date">
                                        <el-date-picker type="date" placeholder="选择日期" v-model="ruleForm.date"
                                                        :picker-options="pickerOptions0"
                                                        style="width: 100%;"></el-date-picker>
                                    </el-form-item>
                                </el-form-item>

                            </div>

                            <div class="line" v-if="this.form.distribution_mustaddr!=1">

                                <el-form-item label="收货人" prop="consignee">
                                    <el-input v-model="ruleForm.consignee" placeholder="请选择收货人"></el-input>
                                </el-form-item>
                                <el-form-item label="联系电话" prop="tel">
                                    <el-input v-model="ruleForm.tel" placeholder="请选择联系电话"></el-input>
                                </el-form-item>

                            </div>

                            <el-form-item label="收货地址" v-if="this.form.distribution_mustaddr!=1">
                                <span class="black">{{orderProduct.dest}}</span>
                                <el-input v-model="ruleForm.address"></el-input>
                                <span class="grey">如需配送多个地址，请下单后在订单管理页面修改</span>
                            </el-form-item>

                            <el-form-item label="其他备注">
                                <el-input type="textarea" v-model="ruleForm.desc"></el-input>
                            </el-form-item>

                            <!--                            <el-form-item class="paymentMethod" label="付款方式">-->
                            <!--                                <el-select v-model="ruleForm.paymentMethod" placeholder="请选择付款方式">-->
                            <!--                                    <el-option v-for="(item,index) in paymentMethodList" :key="index" :label="item"-->
                            <!--                                               :value="item"></el-option>-->
                            <!--                                </el-select>-->
                            <!--                            </el-form-item>-->

                          <el-form-item style="margin-top: 10px;">
                                <el-button type="primary" @click="centerDialogVisible2; submitForm('ruleForm')"
                                           v-loading.fullscreen.lock="fullscreenLoading2">立即下单
                                </el-button>
                                <el-button @click="resetForm('ruleForm')">重置</el-button>
                            </el-form-item>
                        </el-form>
                    </div>

                </el-dialog>
            </div>
        </div>
      <el-dialog title="印刷机器示意图" :visible.sync="viewImgVisible">
        <img :src="picImage" v-if="viewImgVisible" style="width: 100%">
      </el-dialog>

      <el-dialog title="报价明细" :visible.sync="feeDialogVisible">
        <el-table :data="feeList" border>
          <el-table-column prop="broadHeading" label="大类"></el-table-column>
          <el-table-column prop="pronname" label="小类"></el-table-column>
          <el-table-column prop="provalue" label="公式"></el-table-column>
          <el-table-column prop="value" label="价格"></el-table-column>
        </el-table>
      </el-dialog>
      <el-dialog title="报价明细" :visible.sync="quoteListDialogVisible">
        <div v-for="item in quoteList">
          <div   style="margin-top: 10px; font-size: 12px;  line-height: 36px;">
            <span>{{ item.name }}</span>
          </div>
          <el-table :data="item.list" border v-if="item.type ==='1'"     :span-method="spanMethodOfParent">
            <el-table-column prop="name" label="">
              <template slot-scope="scope">
                            <span :style="classObje(scope.row.selected)">
                              {{ scope.row.name }}
                            </span>
              </template>

            </el-table-column>
            <el-table-column   label="项目">
              <template slot-scope="scope">
                <el-table
                    :data="scope.row.list"
                    :show-header="false"
                    size="small"
                >
                  <el-table-column
                      prop="pronname"
                      label="项目"
                  />
                  <el-table-column
                      prop="provalue"
                      label="公式"
                  />
                  <el-table-column
                      prop="value"
                      label=" 金额"
                  />
                </el-table>
              </template>
            </el-table-column>
            <el-table-column   label="公式"></el-table-column>
            <el-table-column    label="金额"></el-table-column>
            <el-table-column prop="fee" label="合计">
              <template slot-scope="scope">
                            <span :style="classObje(scope.row.selected)">
                              {{ scope.row.fee }}
                            </span>
              </template>
            </el-table-column>

          </el-table>
          <el-table :data="item.list" border v-if="item.type ==='0'">
            <el-table-column prop="pronname" label="项目"></el-table-column>
            <el-table-column prop="provalue" label="公式"></el-table-column>
            <el-table-column prop="value" label="金额"></el-table-column>
          </el-table>
        </div>
      </el-dialog>
        <!--纸张材质&贴心服务&下单流程-->
<!--        <goods-list></goods-list>-->
    </div>
</template>

<script>
    import {regionData, CodeToText} from "element-china-area-data";
    import {getusername} from "../../api/myAuth";
    // import goodsList from "../goodsList/goodslist";
    import CustomerService from "../CustomerService/CustomerService";
    // import {username} from "../../api/myAuth";

    export default {
        name: "DigitalAlbum",
        components: {
            // goodsList,
            CustomerService
        },
        data() {
            // 手机验证
            var validateMobilePhone = (rule, value, callback) => {
                if (value === "") {
                    callback(new Error("手机号不可为空"));
                } else {
                    if (value !== "") {
                        var reg = /^1[3456789]\d{9}$/;
                        if (!reg.test(value)) {
                            callback(new Error("请输入有效的手机号码"));
                        }
                    }
                    callback();
                }
            };
            return {
              minstates: '1',
              normNames: '',
              normWeights: '',
              quoteList:[],
              btnType: '2',
              quoteListDialogVisible: false,
              viewImgVisible: false,
              picImage: '',
                // 设置只能选择当前日期及之后的日期
                pickerOptions0: {
                    disabledDate(time) {
                        //如果没有后面的-8.64e7就是不可以选择今天的
                        return time.getTime() < Date.now() - 8.64e7;
                    }
                },

                isCustMafm: false,//封面自带料
                isCustMany: [false],//內頁自带料
                isCustMafy: [false],//扉頁自带料
                isCustMacy: [false],//插頁自带料
                isCustMaly: [false],//拉頁自带料

                isCustMahf: false,//护封自带料
                isCustMayd: false,//腰带自带料
                isCustMazdy: false,//自定义自带料


                specList: [], //产品规格列表(基本参数)
                restaurants: [], //印刷数量（款）
                restaurantspages: [
                    500,
                    1000,
                    2000,
                    3000,
                    4000,
                    5000,
                    6000,
                    7000,
                    8000,
                    9000,
                    10000,
                    15000,
                    20000,
                    25000,
                    30000,
                    35000,
                    40000,
                    45000,
                    50000,
                    55000,
                    60000,
                    65000,
                    70000,
                    75000,
                    80000,
                    85000,
                    90000,
                    95000,
                    100000,
                ], //印刷本数（本）
                pagenums: [
                    4,
                    8,
                    12,
                    16,
                    20,
                    24,
                    28,
                    32,
                    36,
                    40,
                    44,
                    48,
                    52,
                    56,
                    60,
                    64,
                    68,
                    72,
                    76,
                    80,
                    84,
                    88,
                    92,
                    96,
                    100,
                    104,
                    108,
                    112,
                    116,
                    120,
                    124,
                    128,
                    132,
                    136,
                    140,
                    144,
                    148,
                    152,
                    156,
                    160,
                    164,
                    168,
                    172,
                    176,
                    180,
                    184,
                    188,
                    192,
                    194,
                    200,
                ], //内页P数（P）
                bindingList: [], //装订方式
                maList: [], //纸张克重（类型）
                covermaList: [], //parts=='封面'的封面纸张
                pagenumberList: [], //parts=='内页'的内页纸张
                otherpartsList: [], //parts=='其他'的内页纸张

                productWeightList: [], //纸张克重（克）
                pagenumbertWeightList: [], //内页纸张克重（克）
                otherWeightList: [], //其他纸张克重（克）

                colorList: [], //印刷颜色
                processLists: [], //后道设置
                processList: [], //parts=='封面' 后道设置
                pagenumberprocessList: [], //parts=='内页' 后道设置
                pagenumberprocessListbx: [], //parts=='内页' 后道设置 必选项
                otherList: [], //parts=='其他' 后道设置

                processesSetList: [], //后道设置（子类）
                packageList: [], //包装方式
                postList: [], //配送方式

                //基本参数
                form: {
                    processLists: [], //后道设置

                    userfatherid: "", //印厂标识【必填】
                    productId: "", //产品类型ID【必填，1028】
                    size: "", //成品尺寸
                    sizes: [], //成品尺寸
                    state: "", //印刷数量（款）
                    states: "", //印刷本数（本）
                    statess: [], //印刷本数（本）
                    pagenums: [], //内页P数（P）

                    binding: "", //装订方式
                    cover: "1", //特殊封面选择（默认特殊封面选择）
                    input1: "1", //特殊封面选择 cover:2 ?款封面
                    input2: "1", //特殊封面选择 cover:7 封三连体封套?款

                    insidepage: "", //内页部件添加（默认内页部件添加）
                    pagenumber: "4", //内页部件添加_拉页?P（默认4P）
                    pagenumberarr: [], //内页部件添加_拉页?P（数组）

                    other: "", //画册添加特殊部件/工艺（默认画册添加特殊部件/工艺）
                    HuaceDustJacket_width: "", //画册添加特殊部件/工艺 other:23 画册加护封? 宽
                    HuaceDustJacket_height: "", //画册添加特殊部件/工艺 other:23 画册加护封? 高
                    HuaceBelt_width: "", //画册添加特殊部件/工艺 other:23 画册加腰带? 宽
                    HuaceBelt_height: "", //画册添加特殊部件/工艺 other:23 画册加腰带? 高
                    CustomParts_width: "", //画册添加特殊部件/工艺 other:23 自定义部件? 宽
                    CustomParts_height: "", //画册添加特殊部件/工艺 other:23 自定义部件? 高
                    input3: "", //画册添加特殊部件/工艺 other:23 自定义部件?
                    input4: "", //画册添加特殊部件/工艺 other:24 书本打孔?处/本

                    coverType: "", //封面纸张克重（类型）
                    coverGram: "", //封面纸张克重（克）
                    coverTypes: [], //封面纸张克重（类型）
                    coverGrams: [], //封面纸张克重（克）

                    pagenumberType: "", //内页纸张克重（类型）
                    pagenumberGram: "", //内页纸张克重（克）
                    pagenumberTypeny: [], //内页纸张克重（类型）
                    pagenumberGramny: [], //内页纸张克重（克）
                    pagenumberTypefy: [], //扉页纸张克重（类型）
                    cynumber: [],// 插页张树
                    pagenumberGramfy: [], //扉页纸张克重（克）
                    pagenumberTypecy: [], //插页纸张克重（类型）
                    pagenumberGramcy: [], //插页纸张克重（克）
                    pagenumberTypely: [], //拉页纸张克重（类型）
                    pagenumberGramly: [], //拉页纸张克重（克）

                    otherType: "", //其他纸张克重（类型）
                    otherGram: "", //其他纸张克重（克）
                    pagenumberTypehf: [], //护封纸张克重（类型）
                    pagenumberGramhf: [], //护封纸张克重（克）
                    pagenumberTypeyd: [], //腰带纸张克重（类型）
                    pagenumberGramyd: [], //腰带纸张克重（克）
                    pagenumberTypezdy: [], //自定义纸张克重（类型）
                    pagenumberGramzdy: [], //自定义纸张克重（克）

                    single: "", //印刷颜色（单双面）
                    singles: [], //印刷颜色（单双面）
                    singlesny: [], //内页 印刷颜色（单双面）
                    singlesfy: [], //扉页 印刷颜色（单双面）
                    singlescy: [], //插页 印刷颜色（单双面）
                    singlesly: [], //拉页 印刷颜色（单双面）
                    singlesDustJacket: [], //画册加护封 印刷颜色（单双面）
                    singlesBelt: [], //画册加腰带 印刷颜色（单双面）
                    singleszdy: [], //自定义部件 印刷颜色（单双面）

                    colour: "", //印刷颜色（颜色）
                    colours: [], //印刷颜色（颜色）
                    coloursny: [], //内页_印刷颜色（颜色）
                    coloursfy: [], //扉页_印刷颜色（颜色）
                    colourscy: [], //插页_印刷颜色（颜色）
                    coloursly: [], //拉页_印刷颜色（颜色）
                    coloursDustJacket: [], //画册加护封_印刷颜色（颜色）
                    coloursBelt: [], //画册加腰带_印刷颜色（颜色）
                    colourszdy: [], //自定义部件_印刷颜色（颜色）

                    checkedCheckbox: [], //封面后道设置
                    checkedCheckboxny: [], //内页后道设置
                    checkedCheckboxfy: [], //扉页后道设置
                    checkedCheckboxcy: [], //插页后道设置
                    checkedCheckboxly: [], //拉页后道设置
                    checkedCheckboxhf: [], //护封后道设置
                    checkedCheckboxyd: [], //腰带后道设置
                    checkedCheckboxzdy: [], //自定义后道设置

                    nybx: [], //内页_后道必选项
                    nybxid: '',//内页_后道必选项id
                    fybx: [], //扉页_后道必选项
                    fybxid: '',//扉页_后道必选项id
                    cybx: [], //插页_后道必选项
                    cybxid: '',//插页_后道必选项id
                    lybx: [], //拉页_后道必选项
                    lybxid: '',//拉页_后道必选项id

                    packing: "", //包装方式
                    distribution: "", //配送方式
                    distribution_mustaddr: "", //配送方式_mustaddr(mustaddr:1,不填写目的地)
                    selectedOptions: [], //目的地
                    dest: "", //省市区
                    provinceId: "", //省ID
                    cityId: "", //市ID
                    areaId: "", //区ID
                    level: "", // 品质要求
                  needDesign: '0',
                  pNums: '1',
                  invoice: ""
                },
              invoiceList: [],
                checkedCheckbox: [], //封面后道设置
                checkedCheckbox1: [], //封面后道设置
                checkedCheckboxny: [[]], //内页后道设置
                checkedCheckboxny1: [[]], //内页后道设置
                checkedCheckboxfy: [], //扉页后道设置
                checkedCheckboxfy1: [], //扉页后道设置
                checkedCheckboxcy: [], //插页后道设置
                checkedCheckboxcy1: [], //插页后道设置
                checkedCheckboxly: [], //拉页后道设置
                checkedCheckboxly1: [], //拉页后道设置
                checkedCheckboxhf: [], //护封后道设置
                checkedCheckboxhf1: [], //护封后道设置
                checkedCheckboxyd: [], //腰带后道设置
                checkedCheckboxyd1: [], //腰带后道设置
                checkedCheckboxzdy: [], //自定义后道设置
                checkedCheckboxzdy1: [], //自定义后道设置

                // technologyfm: [], //封面后道设置工艺_后道工艺列表
                // technologyny: [], //内页后道设置工艺_后道工艺列表
                // technologyfy: [], //扉页后道设置工艺_后道工艺列表
                // technologycy: [], //插页后道设置工艺_后道工艺列表
                // technologyly: [], //拉页后道设置工艺_后道工艺列表
                // technologyhf: [], //画册加护封后道设置工艺_后道工艺列表
                // technologyyd: [], //画册加腰带后道设置工艺_后道工艺列表
                // technologyzdy: [], //自定义后道设置工艺_后道工艺列表

                selectedOptions: [], //目的地
                //成品尺寸自定义宽高
                cpsize: [],
                //成品尺寸自定义宽高
                // cpsizeswidth: [],
                // cpsizesheight: [],

                formLabelWidth: "50px", //成品尺寸自定义宽高输入框宽度

                formWidth: "20px", //后道设置新增宽高输入框宽度

                //自定义尺寸
                zdysize: "自定义",

                setacreage0: [], //封面下拉框
                setacreageny0: [], //内页下拉框
                setacreagefy0: [], //扉页下拉框
                setacreagecy0: [], //插页下拉框
                setacreagely0: [], //拉页下拉框
                setacreagehf0: [], //护封下拉框
                setacreageyd0: [], //腰带下拉框
                setacreagezdy0: [], //自定义下拉框

                // state: '',

                centerDialogVisible: false, //成品尺寸自定义对话框
                feeList: [],
                feeDialogVisible: false,
                centerDialogVisible2: false, //立即下单对话框
                // centerDialogVisible3: false, //立即下单对话框
                centerDialogVisible4: false, //设置封面其他工艺
                centerDialogVisible5: false, //设置内页_内页其他工艺
                centerDialogVisible6: false, //设置内页_扉页其他工艺
                centerDialogVisible7: false, //设置内页_插页其他工艺
                centerDialogVisible8: false, //设置内页_拉页其他工艺
                centerDialogVisible9: false, //画册加护封_其他工艺
                centerDialogVisible10: false, //画册加腰带_其他工艺
                centerDialogVisible11: false, //自定义部件_其他工艺

                addressList: [], // 地址列表
                options: regionData, //省市区三级联动数据

                coverVisible: false, //特殊封面选择弹出层
                benshu: [], //?款封面_?本4P || 封三连体封套?款_连体封套?本
                doudi: [], //封三连体封套?款_兜底高
                StrangleMouth: [1], //封面封底加勒口
                PullPage2p: [1], //封面加2P拉页
                PullPage4p: [1], //封面加4P拉页
                PullPage22p: [1], //封面封底各加2P拉页

                checked: [false], //封面其他工艺
                checkedtext: '', //封面其他工艺_内容
                index: 0,
                fm: "", //封面封底加勒口_勒口尺寸_封面勒口 || 封面加2P拉页_封面封底加拉页_封面 || 封面加4P拉页_封面封底加拉页_封面 || 封面加4P拉页_封面封底加拉页_封面 || 封面封底各加2P拉页_封面封底加拉页_封面
                fd: "", //封面封底加勒口_勒口尺寸_封底勒口 || 封面加2P拉页_封面封底加拉页_封底 || 封面加4P拉页_封面封底加拉页_封底 || 封面加4P拉页_封面封底加拉页_封底 || 封面封底各加2P拉页_封面封底加拉页_封底

                kuan: "", //封三分体封套_宽
                gao: "", //封三分体封套_高

                coverVisible2: false, //内页部件添加弹出层
                checkedny: [], //内页其他工艺
                checkedtextny: [], //内页工艺_内容
                checkedfy: [], //扉页其他工艺
                checkedtextfy: [], //扉页工艺_内容
                checkedcy: [], //插页其他工艺
                checkedtextcy: [], //插页工艺_内容
                checkedly: [], //拉页其他工艺
                checkedtextly: [], //拉页工艺_内容

                indexny: 0, //内页工艺下标
                neiyearr: [1], //选择内页类型_内页
                neiye: 1,
                indexfy: 0, //扉页工艺下标
                feiyearr: [], //选择内页类型_扉页
                feiye: 0,
                indexcy: 0, //插页工艺下标
                chayearr: [], //选择内页类型_插页
                chaye: 0,
                indexly: 0, //拉页工艺下标
                layearr: [], //选择内页类型_拉页
                laye: 0,

                coverVisible3: false, //画册添加特殊部件/工艺弹出层
                checkedDustJacket: [], //画册加护封_其他工艺
                checkedtextDustJacket: [], //画册加护封_其他工艺_内容

                checkedBelt: [], //画册加腰带_其他工艺
                checkedtextBelt: [], //画册加腰带_其他工艺_内容

                checkedzdy: [], //自定义部件_其他工艺
                checkedtextzdy: [], //自定义部件_其他工艺_内容

                HuaceDustJacket: [], //画册加护封
                HuaceBelt: [], //画册加腰带
                CustomParts: [], //自定义部件
                Drilling: [], //书本打孔?处/本
                Plastic: [], //画册塑封
                SelfSealingBag: [], //画册套自封袋

                //立即报价_指令加载方式
                fullscreenLoading: false,

                //报价信息
                //---- 弹出框展示数据
                info: [],
                //---- 部件产品信息
                orderProduct: [],

                colourList: ['正常印刷，无特别要求', '显示屏，鲜艳饱和', '有印样，严格追样（不含数码样）', '有样稿，仅供参考', '样稿未到，到样时间：', '看兰纸', '预约上机看样', '其他'],//色彩要求列表
                paymentMethodList: ['全款下单', '预付30%下单', '货到付款', '带款提货', '账期客户'],//付款方式列表
                ruleForm: {
                    name: '',//印件名称
                    color: '',//色彩要求
                    date: '',//发货日期
                    consignee: '',//收货人
                    tel: '',//联系电话
                    address: '',//收货地址
                    paymentMethod: '',//付款方式
                    desc: ''//备注
                },
                rules: {
                    name: [
                        {required: true, message: '请输入印件名称', trigger: 'blur'},
                        {min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur'}
                    ],
                    // color: [
                    //     {required: true, message: '请选择色彩要求', trigger: 'change'}
                    // ],
                    date: [
                        {type: 'date', required: true, message: '请选择发货日期', trigger: 'change'}
                    ],
                    consignee: [
                        {required: true, message: '请输入收货人', trigger: 'blur'},
                        // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
                    ],
                    tel: [
                        {required: true, message: '请输入联系电话', validator: validateMobilePhone, trigger: 'blur'},
                        // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
                    ],
                    // address: [
                    //     {required: true, message: '请输入收货地址', trigger: 'blur'},
                    // ]
                },

                name: '',

                //报价详情_指令加载方式
                fullscreenLoading2: false,

                //是否是管理员账号
                userType: '',
                //服务客户
                service: false,
                //服务客户_用户名称
                servicetext: '',
                //服务客户_user_ID
                user_ID: '',
                //服务客户_信息
                userList: '',
                qualitylist: [],
                //拉页P数输入框宽度
                pNum_width: 'width:40px;text-align: center;'
            };
        },
        created() {
            // console.log(getusername().userfatherid);
            // this.form.userfatherid = getusername().userfatherid;
            let obj=JSON.parse(window.sessionStorage.getItem("shoppingInfo"))
            console.log(obj.userfatherid)
            this.form.userfatherid = obj.userfatherid;

            var id = this.$route.params.id;
            if (id != 2020) {
                id = 2020
            }
            this.form.productId = id;
            console.log(id);

            //获取默认地址
            this.GetAlladdress()

            //是否是管理员账号
            this.getuserType()

            this.$http
                .get("/cpshttp/getQuoteData?productId=" + id)
                .then((res) => {
                    console.log(res);
                    if (res.status == 200) {
                        // this.$message({
                        //     message: "报价页面加载成功",
                        //     type: "success",
                        // });
                      this.qualitylist = res.data.qualityList;
                      this.qualitylist.forEach(i=>{
                        if(i.selected ==='1'){
                          this.form.level = i.id
                          this.picImage = i.pictures.split(',')[0]
                          this.normNames = i.normNames
                          this.normWeights = i.normWeights
                          this.form.states = (this.form.level == 1002 ?10:1)
                          this.minstates = this.form.states
                          for(let i = 0;i< this.pagenumberList.length;i++){
                            if(this.normNames && this.normNames.includes(this.pagenumberList[i].name)){
                              this.form.pagenumberTypeny[0] = this.pagenumberList[i].id
                              this.Changetypepagenumber(this.pagenumberList[i].id,0)
                            }
                        }
                      }
                     })
                        //产品规格列表(基本参数)
                        this.specList = res.data.specList;
                        //默认成品尺寸
                        this.form.size = res.data.specList[0].commonspecid;

                        //装订方式
                        this.bindingList = res.data.bindingList;
                        //默认装订方式
                        for (let a = 0; a < res.data.bindingList.length; a++) {
                            //true：默认选择；false：不选
                            if (res.data.bindingList[a].setDefault == true) {
                                this.form.binding = res.data.bindingList[a].binding;
                            }
                        }

                        //纸张克重（类型）
                        this.maList = res.data.maList;
                      this.invoiceList = res.data.invoiceList;
                      this.form.invoice = this.invoiceList[0].num
                        //获取parts=='封面'的封面纸张
                        let cover = [];
                        for (let a = 0; a < res.data.maList.length; a++) {
                            if (res.data.maList[a].parts == "封面") {
                                cover.push(this.maList[a]);
                            }
                        }
                        this.covermaList = cover;
                        console.log(this.covermaList);

                        //获取parts=='内页'的封面纸张
                        let pagenumber = [];
                        for (let a = 0; a < res.data.maList.length; a++) {
                            if (res.data.maList[a].parts == "内页") {
                                pagenumber.push(this.maList[a]);
                            }
                        }
                        this.pagenumberList = pagenumber;
                        console.log(this.pagenumberList);

                        //获取parts=='内页'的封面纸张
                        let other = [];
                        for (let a = 0; a < res.data.maList.length; a++) {
                            if (res.data.maList[a].parts == "其他") {
                                other.push(this.maList[a]);
                            }
                        }
                        this.otherpartsList = other;
                        console.log(this.otherpartsList);

                        //默认封面纸张（类型）
                        this.form.coverType = this.covermaList[0].id;
                        //默认封面纸张克重（克）
                        // this.form.coverGram = this.covermaList[0].defaultweight;
                        //默认封面纸张克重列表（克）
                        this.productWeightList = this.covermaList[0].productWeightList;

                        //默认内页纸张（类型）
                        this.form.pagenumberType = this.pagenumberList[0].id;
                        //默认内页纸张克重（克）
                        // this.form.pagenumberGram = this.pagenumberList[0].defaultweight;
                        //默认内页纸张克重列表（克）
                        this.pagenumbertWeightList = this.pagenumberList[0].productWeightList;

                        //默认内页纸张（类型）
                        this.form.otherType = this.otherpartsList[0].id;
                        //默认内页纸张克重（克）
                        // this.form.otherGram = this.otherpartsList[0].defaultweight;
                        //默认内页纸张克重列表（克）
                        this.otherWeightList = this.otherpartsList[0].productWeightList;


                        //印刷颜色
                        this.colorList = res.data.colorList;
                        //默认印刷颜色
                        // this.form.colour = res.data.colorList[0].colorid;
                        let colorId = ''
                        for (let a = 0; a < res.data.colorList.length; a++) {
                            if (res.data.colorList[a].setDefault) {
                                colorId = res.data.colorList[a].colorid;
                            }
                        }
                        this.form.colour = colorId!='' ? colorId : res.data.colorList[0].colorid;

                        //封面后道设置列表
                        this.form.processLists = res.data.processList;
                        // this.processList = res.data.processList
                        //循环封面后道设置
                        let arr = [];
                        //循环内页后道设置
                        let arrny = [];
                        //循环内页后道设置 必选项
                        let arrnybx = [];
                        //循环其他后道设置
                        let arrqt = [];
                        for (let a = 0; a < res.data.processList.length; a++) {
                            if (res.data.processList[a].parts == "封面" && res.data.processList[a].dispaly == 1) {
                                arr.push({
                                    id: res.data.processList[a].id,
                                    name: res.data.processList[a].name,
                                    parts: res.data.processList[a].parts,
                                    setacreage: res.data.processList[a].setacreage,
                                    mustchoose: res.data.processList[a].mustchoose,
                                    defaultShow:  res.data.processList[a].defaultShow,
                                    checked : res.data.processList[a].setacreage === '2'?false: true,
                                    processesSetList: res.data.processList[a].processesSetList,
                                });
                            }
                            if (res.data.processList[a].parts == "内页" && res.data.processList[a].dispaly == 1) {
                                arrny.push({
                                    id: res.data.processList[a].id,
                                    name: res.data.processList[a].name,
                                    parts: res.data.processList[a].parts,
                                    setacreage: res.data.processList[a].setacreage,
                                    mustchoose: res.data.processList[a].mustchoose,
                                    defaultShow:  res.data.processList[a].defaultShow,
                                    checked : res.data.processList[a].setacreage === '2'?false: true,
                                    processesSetList: res.data.processList[a].processesSetList,
                                });
                            }
                            if (res.data.processList[a].parts == "内页" && res.data.processList[a].dispaly == 1 &&   res.data.processList[a].mustchoose == 1){

                                arrnybx.push({
                                    id: res.data.processList[a].id,
                                    name: res.data.processList[a].name,
                                    parts: res.data.processList[a].parts,
                                    setacreage: res.data.processList[a].setacreage,
                                    mustchoose: res.data.processList[a].mustchoose,
                                    defaultShow:  res.data.processList[a].defaultShow,
                                    checked : res.data.processList[a].setacreage === '2'?false: true,
                                    processesSetList: res.data.processList[a].processesSetList,
                                });
                            }
                            if (res.data.processList[a].parts == "其他" && res.data.processList[a].dispaly == 1) {
                                arrqt.push({
                                    id: res.data.processList[a].id,
                                    name: res.data.processList[a].name,
                                    parts: res.data.processList[a].parts,
                                    setacreage: res.data.processList[a].setacreage,
                                    mustchoose: res.data.processList[a].mustchoose,
                                    defaultShow:  res.data.processList[a].defaultShow,
                                    checked : res.data.processList[a].setacreage === '2'?false: true,
                                    processesSetList: res.data.processList[a].processesSetList,
                                });
                            }
                        }
                        this.processList = arr;
                        console.log(this.processList);
                        this.Mandatory()
                        this.pagenumberprocessList = arrny;
                        this.Mandatoryny()
                        this.pagenumberprocessListbx = arrnybx;
                        this.Mandatorybx()

                        //默认内页必选项
                      if(this.pagenumberprocessListbx && this.pagenumberprocessListbx.length >0 ){

                        this.form.nybxid = this.pagenumberprocessListbx[0].processesSetList[0].id;
                        //默认扉页必选项
                        this.form.fybxid = this.pagenumberprocessListbx[0].processesSetList[0].id;
                        //默认插页必选项
                        this.form.cybxid = this.pagenumberprocessListbx[0].processesSetList[0].id;
                        //默认拉页必选项
                        this.form.lybxid = this.pagenumberprocessListbx[0].processesSetList[0].id;

                      }

                        this.otherList = arrqt;



                        //包装方式列表
                        this.packageList = res.data.packageList;
                        this.form.packing = res.data.packageList[0].id;

                        //配送方式列表
                        this.postList = res.data.postList;
                        this.form.distribution = res.data.postList[0].id;
                      this.form.distribution_mustaddr = res.data.postList[0].mustaddr;
                      if (this.form.distribution_mustaddr == 1) {
                        this.form.dest = "";
                        this.form.provinceId = "";
                        this.form.cityId = "";
                        this.form.areaId = "";
                      }
                        this.changeInput1();

                        // this.changeInput1()

                        // for (var i = 0; i < res.data.maList[0].productWeightList.length; i++) {
                        //     if (res.data.maList[0].defaultweight == res.data.maList[0].productWeightList[i].name) {
                        //         // console.log(res.data.maList[0].productWeightList[i].id)
                        //         //默认纸张克重（克）
                        //         this.form.gram = res.data.maList[0].productWeightList[i].weightid
                        //         return;
                        //     } else if (res.data.maList[0].defaultweight != res.data.maList[0].productWeightList[i].name) {
                        //         //默认纸张克重（克）
                        //         this.form.gram = res.data.maList[0].productWeightList[0].weightid
                        //     }
                        // }
                    } else {
                        this.$message.error("报价页面加载失败");
                    }
                })
                .catch((error) => {
                    console.error("Error", error);
                });

            //定义印刷数量
            for (var i = 1; i <= 5; i++) {
                this.restaurants.push(i);
            }
            //默认印刷数量
            this.form.state = 1;

            //默认印刷颜色（单双面）
            // this.form.single = 'colors'

            //默认印刷本数（本）
            this.form.states = 1;

            // let year = new Date().getFullYear(); //得到年份
            // let month = new Date().getMonth();//得到月份
            // let date = new Date().getDate();//得到日期
            // // let hour = new Date().getHours();//得到小时
            // // let minu = new Date().getMinutes();//得到分钟
            // let hour = 17;//得到小时
            // let minu = 0;//得到分钟
            //
            // month = month + 1;
            // if (month < 10) month = "0" + month;
            // if (date < 10) date = "0" + date;
            // if (hour < 10) hour = "0" + hour;
            // if (minu < 10) minu = "0" + minu;
            //色彩要求
            this.ruleForm.color = this.colourList[0]
            //付款方式
            this.ruleForm.paymentMethod = this.paymentMethodList[0]
            //发货日期
            // this.ruleForm.date = year + '-' + month + '-' + date

        },
      computed: {
        classObje(selected) {
          return (selected) => {
            if (selected === '1') {
              return { color: "red" }
            }
          }
        },
      },
        methods: {
          Mandatory() {
            let text = []
            let arr = []
            console.log(this.processList)
            for (let i = 0; i < this.processList.length; i++) {
              if (this.processList[i].setacreage == 0 && (this.processList[i].mustchoose == 1 || this.processList[i].defaultShow == 1)) {
                console.log(this.processList[i])
                this.checkedCheckbox.push({'id': this.processList[i].processesSetList[0].id})
                arr.push({
                  name: this.processList[i].name,
                  id: this.processList[i].id,
                  setacreage: this.processList[i].setacreage,
                  processesSetList: this.processList[i].processesSetList,
                  num: 1,
                  desc: "",
                  models: this.processList[i].processesSetList[0].id,
                  inSize: [{'width': '', 'length': '',}],
                });
                this.setacreage0 = arr
              }
              for (let a = 0; a < this.checkedCheckbox.length; a++) {
                if (this.checkedCheckbox[a].id == this.processList[i].processesSetList[0].id) {
                  console.log(this.processList[i].processesSetList[0].name);
                  text.push(this.processList[i].processesSetList[0].name);
                }
              }
            }
            if(text.length>0){
              this.checked[this.index] = true
              this.checkedtext = text
            }
            console.log(text);
            console.log(this.checkedCheckbox)
          },
          Mandatoryny() {
            let text = []
            let arr = []
            console.log(this.pagenumberprocessList)
            for (let i = 0; i < this.pagenumberprocessList.length; i++) {
              if (this.pagenumberprocessList[i].setacreage == 0 && (this.pagenumberprocessList[i].mustchoose == 1 || this.pagenumberprocessList[i].defaultShow == 1)) {
                console.log(this.pagenumberprocessList[i])
                this.checkedCheckboxny[this.indexny].push({'id': this.pagenumberprocessList[i].processesSetList[0].id})
                arr.push({
                  name: this.pagenumberprocessList[i].name,
                  id: this.pagenumberprocessList[i].id,
                  setacreage: this.pagenumberprocessList[i].setacreage,
                  processesSetList: this.pagenumberprocessList[i].processesSetList,
                  num: 1,
                  desc: "",
                  models: this.pagenumberprocessList[i].processesSetList[0].id,
                  inSize: [{'width': '', 'length': '',}],
                });
                this.setacreage0 = arr
              }
              for (let a = 0; a < this.checkedCheckboxny.length; a++) {
                if (this.checkedCheckboxny[a].id == this.pagenumberprocessList[i].processesSetList[0].id) {
                  console.log(this.pagenumberprocessList[i].processesSetList[0].name);
                  text.push(this.pagenumberprocessList[i].processesSetList[0].name);
                }
              }
            }
            if(text.length>0){
              this.checkedny[this.indexny] = true
              this.checkedtextny = text
            }
            console.log(text);
            console.log(this.checkedCheckboxny)
          },
          Mandatorybx() {
            let text = []
            let arr = []
            console.log(this.pagenumberprocessListbx)
            for (let i = 0; i < this.pagenumberprocessListbx.length; i++) {
              if (this.pagenumberprocessListbx[i].setacreage == 0 && (this.pagenumberprocessListbx[i].mustchoose == 1 || this.pagenumberprocessListbx[i].defaultShow == 1)) {

                this.checkedCheckboxfy.push({'id': this.pagenumberprocessListbx[i].processesSetList[0].id})
                arr.push({
                  name: this.pagenumberprocessListbx[i].name,
                  id: this.pagenumberprocessListbx[i].id,
                  setacreage: this.pagenumberprocessListbx[i].setacreage,
                  processesSetList: this.pagenumberprocessListbx[i].processesSetList,
                  num: 1,
                  desc: "",
                  models: this.pagenumberprocessListbx[i].processesSetList[0].id,
                  inSize: [{'width': '', 'length': '',}],
                });
                this.setacreage0 = arr
              }
              for (let a = 0; a < this.checkedCheckboxfy.length; a++) {
                if (this.checkedCheckboxfy[a].id == this.pagenumberprocessListbx[i].processesSetList[0].id) {
                  console.log(this.pagenumberprocessListbx[i].processesSetList[0].name);
                  text.push(this.pagenumberprocessListbx[i].processesSetList[0].name);
                }
              }
            }
            if(text.length>0){
              this.checkedfy[this.index] = true
              this.checkedtextfy = text
            }
            console.log(text);
            console.log(this.checkedCheckboxfy)
          },
          Mandatoryqt() {
            let text = []
            let arr = []
            console.log(this.otherList)
            for (let i = 0; i < this.otherList.length; i++) {
              if (this.otherList[i].setacreage == 0 && (this.otherList[i].mustchoose == 1 || this.otherList[i].defaultShow == 1)) {
                console.log(this.otherList[i])
                this.checkedCheckboxyd.push({'id': this.otherList[i].processesSetList[0].id})
                arr.push({
                  name: this.otherList[i].name,
                  id: this.otherList[i].id,
                  setacreage: this.otherList[i].setacreage,
                  processesSetList: this.otherList[i].processesSetList,
                  num: 1,
                  desc: "",
                  models: this.otherList[i].processesSetList[0].id,
                  inSize: [{'width': '', 'length': '',}],
                });
                this.setacreage0 = arr
              }
              for (let a = 0; a < this.checkedCheckbox.length; a++) {
                if (this.checkedCheckbox[a].id == this.otherList[i].processesSetList[0].id) {
                  console.log(this.otherList[i].processesSetList[0].name);
                  text.push(this.otherList[i].processesSetList[0].name);
                }
              }
            }
            if(text.length>0){
              this.checked[this.index] = true
              this.checkedtext = text
            }
            console.log(text);
            console.log(this.checkedCheckbox)
          },



          spanMethodOfParent({row, column, rowIndex, columnIndex}) {
            // 从父Table的第2列开始，合并之后的4列（包含第2列），将4列合同成一个单元格，容纳子Table
            if (columnIndex === 1) {
              return [1, 3]
            }
            // 隐藏父Table中的第3列到第7列
            if (columnIndex > 1 && columnIndex < 4) {
              return [0, 0]
            }
          },
          openFeeDetail1(feeJson){
            this.feeList = JSON.parse(feeJson);
            this.feeDialogVisible = true
          },
          openFeeDetail2(){
            this.quoteListDialogVisible = true
          },
          showLevelPic(item) {
            this.picImage = item.pictures.split(',')[0]
            this.normNames = item.normNames
            this.normWeights = item.normWeights
            this.form.states = (item.id == 1002 ?10:1)
            this.minstates = this.form.states
            for(let i = 0;i< this.pagenumberList.length;i++){
               if(this.normNames && this.normNames.includes(this.pagenumberList[i].name)){
                  this.form.pagenumberTypeny[0] = this.pagenumberList[i].id
                   this.Changetypepagenumber(this.pagenumberList[i].id,0)
               }
            }

          },
            //服务客户_信息_子组件传值
            change(data) {
                // this.user=msg
                console.log(data)
                this.userList = data
                // this.xiadanList = data
            },

            //服务客户_取消
            cancel() {
                this.service = false
                this.servicetext = ''
                this.user_ID = ''
            },

            //服务客户_确定
            determine() {
                this.service = false
                if(this.userList.newTel != '' && this.userList.newTel != null){
                  this.servicetext = this.userList.custName + '(' + this.userList.newTel + ')'
                } else {
                  this.servicetext = this.userList.custName
                }
                this.user_ID = this.userList.user_ID
                this.GetAlladdress()
            },

            //是否是管理员账号
            getuserType() {
                let userInfo = JSON.parse(window.sessionStorage.getItem('currentInfo'))
                console.log(userInfo)
                if (userInfo.userType == 1) {
                    this.userType = userInfo.userType
                    console.log('管理员账号')
                } else {
                    this.userType = userInfo.userType
                    console.log('游客账号')
                }
            },

            //提交自定义尺寸
            tijiao() {
              if(this.cpsize.width && this.cpsize.height){
                if(Math.max(this.cpsize.width ,this.cpsize.height) > 300  ||  Math.min(this.cpsize.width ,this.cpsize.height) > 216 ){
                  this.$message.error("成品尺寸不能大于A4")
                  return
                }

                this.zdysize = this.cpsize.width + "*" + this.cpsize.height + "mm";
                this.centerDialogVisible = false;
              } else {
                this.$message.error("宽和高都不能为空")
                return
              }

            },

            //重定义
            changeInput1() {
                //封面纸张克重
                for (let i = 0; i < this.covermaList[0].productWeightList.length; i++) {
                    if (this.covermaList[0].defaultweight === this.covermaList[0].productWeightList[i].name) {
                        // console.log(this.covermaList[0].productWeightList[i].id)
                        //默认封面纸张克重（克）
                        this.form.coverGram = this.covermaList[0].productWeightList[i].weightid;
                        break
                        // this.form.coverGrams[a] = this.covermaList[0].productWeightList[i].weightid
                        // return;
                    } else {
                        //默认封面纸张克重（克）
                        this.form.coverGram = this.covermaList[0].productWeightList[0].weightid;
                        // this.form.coverGrams[a] = this.covermaList[0].productWeightList[0].weightid

                    }
                }

                //内页纸张克重
                for (let i = 0; i < this.pagenumberList[0].productWeightList.length; i++) {
                    if (this.pagenumberList[0].defaultweight === this.pagenumberList[0].productWeightList[i].name) {
                        // console.log(this.pagenumberList[0].productWeightList[i].id)
                        //默认内页纸张克重（克）
                        this.form.pagenumberGram = this.pagenumberList[0].productWeightList[i].weightid;
                        break
                        // this.form.coverGrams[a] = this.pagenumberList[0].productWeightList[i].weightid
                        // return;

                    } else {
                        //默认内页纸张克重（克）
                        this.form.pagenumberGram = this.pagenumberList[0].productWeightList[0].weightid;
                        // this.form.coverGrams[a] = this.pagenumberList[0].productWeightList[0].weightid
                    }
                }

                //其他纸张克重
                for (let i = 0; i < this.otherpartsList[0].productWeightList.length; i++) {
                    if (this.otherpartsList[0].defaultweight === this.otherpartsList[0].productWeightList[i].name) {
                        // console.log(this.otherpartsList[0].productWeightList[i].id)
                        //默认内页纸张克重（克）
                        this.form.otherGram = this.otherpartsList[0].productWeightList[i].weightid;
                        break
                        // this.form.coverGrams[a] = this.otherpartsList[0].productWeightList[i].weightid
                        // return;
                    } else {
                        //默认内页纸张克重（克）
                        this.form.otherGram = this.otherpartsList[0].productWeightList[0].weightid;
                        // this.form.coverGrams[a] = this.otherpartsList[0].productWeightList[0].weightid
                    }
                }

                //?款封面
                if (this.form.cover == 1) {
                    //?款封面
                    // let array = []
                    for (let i = 0; i < this.form.input1; i++) {
                        // array.push(false)
                        // this.checked = array
                        if (this.benshu[i] == undefined || this.benshu[i] == "") {
                            this.benshu[i] = "500";
                        }
                        if (this.form.sizes[i] == undefined || this.form.sizes[i] == "") {
                            this.form.sizes[i] = this.form.size;
                        }
                        if (this.form.statess[i] == undefined || this.form.statess[i] == "") {
                            this.form.statess[i] = this.form.states;
                        }
                        if (this.form.coverTypes[i] == undefined || this.form.coverTypes[i] == "") {
                            this.form.coverTypes[i] = this.form.coverType;
                        }
                        if (this.form.coverGrams[i] == undefined || this.form.coverGrams[i] == "") {
                            this.form.coverGrams[i] = this.form.coverGram;
                        }
                        if (this.form.singles[i] == undefined || this.form.singles[i] == "") {
                            this.form.singles[i] = "colors";
                        }
                        if (this.form.colours[i] == undefined || this.form.colours[i] == "") {
                            this.form.colours[i] = this.form.colour;
                        }
                        // if (this.form.checked[i] == undefined || this.form.checked[i] == '') {
                        //     this.form.checked[i] = false
                        // }
                        // console.log(this.form.size)
                        // console.log(this.form)
                        // console.log(this.form.sizes)
                        // console.log(this.form.statess)
                        // console.log(this.form.coverTypes)
                        // console.log(this.form.coverGrams)
                        // console.log(this.form.sizes[i])
                        // console.log(this.form.singles)
                    }
                }
                //封面封底加勒口
                if (this.form.cover == 2) {
                    //封面封底加勒口
                    for (let i = 0; i < this.StrangleMouth.length; i++) {
                        if (this.benshu[i] == undefined || this.benshu[i] == "") {
                            this.benshu[i] = "500";
                        }
                        if (this.form.sizes[i] == undefined || this.form.sizes[i] == "") {
                            this.form.sizes[i] = this.form.size;
                        }
                        if (this.form.statess[i] == undefined || this.form.statess[i] == "") {
                            this.form.statess[i] = this.form.states;
                        }
                        if (
                            this.form.coverTypes[i] == undefined ||
                            this.form.coverTypes[i] == ""
                        ) {
                            this.form.coverTypes[i] = this.form.coverType;
                        }
                        if (
                            this.form.coverGrams[i] == undefined ||
                            this.form.coverGrams[i] == ""
                        ) {
                            this.form.coverGrams[i] = this.form.coverGram;
                        }
                        if (this.form.singles[i] == undefined || this.form.singles[i] == "") {
                            this.form.singles[i] = "colors";
                        }
                        if (this.form.colours[i] == undefined || this.form.colours[i] == "") {
                            this.form.colours[i] = this.form.colour;
                        }
                        // if (this.form.checked[i] == undefined || this.form.checked[i] == '') {
                        //     this.form.checked[i] = false
                        // }
                        // console.log(this.form.size)
                        // console.log(this.form)
                        // console.log(this.form.sizes)
                        // console.log(this.form.statess)
                        // console.log(this.form.coverTypes)
                        // console.log(this.form.coverGrams)
                        // console.log(this.form.sizes[i])
                        // console.log(this.form.singles)
                    }
                }
                //封面加2P拉页
                if (this.form.cover == 3) {
                    //封面加2P拉页
                    for (let i = 0; i < this.PullPage2p.length; i++) {
                        if (this.benshu[i] == undefined || this.benshu[i] == "") {
                            this.benshu[i] = "500";
                        }
                        if (this.form.sizes[i] == undefined || this.form.sizes[i] == "") {
                            this.form.sizes[i] = this.form.size;
                        }
                        if (this.form.statess[i] == undefined || this.form.statess[i] == "") {
                            this.form.statess[i] = this.form.states;
                        }
                        if (
                            this.form.coverTypes[i] == undefined ||
                            this.form.coverTypes[i] == ""
                        ) {
                            this.form.coverTypes[i] = this.form.coverType;
                        }
                        if (
                            this.form.coverGrams[i] == undefined ||
                            this.form.coverGrams[i] == ""
                        ) {
                            this.form.coverGrams[i] = this.form.coverGram;
                        }
                        if (this.form.singles[i] == undefined || this.form.singles[i] == "") {
                            this.form.singles[i] = "colors";
                        }
                        if (this.form.colours[i] == undefined || this.form.colours[i] == "") {
                            this.form.colours[i] = this.form.colour;
                        }
                        // if (this.form.checked[i] == undefined || this.form.checked[i] == '') {
                        //     this.form.checked[i] = false
                        // }
                        // console.log(this.form.size)
                        // console.log(this.form)
                        // console.log(this.form.sizes)
                        // console.log(this.form.statess)
                        // console.log(this.form.coverTypes)
                        // console.log(this.form.coverGrams)
                        // console.log(this.form.sizes[i])
                        // console.log(this.form.singles)
                    }
                }
                //封面加4P拉页
                if (this.form.cover == 4) {
                    //封面加4P拉页
                    for (let i = 0; i < this.PullPage4p.length; i++) {
                        if (this.benshu[i] == undefined || this.benshu[i] == "") {
                            this.benshu[i] = "500";
                        }
                        if (this.form.sizes[i] == undefined || this.form.sizes[i] == "") {
                            this.form.sizes[i] = this.form.size;
                        }
                        if (this.form.statess[i] == undefined || this.form.statess[i] == "") {
                            this.form.statess[i] = this.form.states;
                        }
                        if (
                            this.form.coverTypes[i] == undefined ||
                            this.form.coverTypes[i] == ""
                        ) {
                            this.form.coverTypes[i] = this.form.coverType;
                        }
                        if (
                            this.form.coverGrams[i] == undefined ||
                            this.form.coverGrams[i] == ""
                        ) {
                            this.form.coverGrams[i] = this.form.coverGram;
                        }
                        if (this.form.singles[i] == undefined || this.form.singles[i] == "") {
                            this.form.singles[i] = "colors";
                        }
                        if (this.form.colours[i] == undefined || this.form.colours[i] == "") {
                            this.form.colours[i] = this.form.colour;
                        }
                        // if (this.form.checked[i] == undefined || this.form.checked[i] == '') {
                        //     this.form.checked[i] = false
                        // }
                        // console.log(this.form.size)
                        // console.log(this.form)
                        // console.log(this.form.sizes)
                        // console.log(this.form.statess)
                        // console.log(this.form.coverTypes)
                        // console.log(this.form.coverGrams)
                        // console.log(this.form.sizes[i])
                        // console.log(this.form.singles)
                    }
                }
                //封面封底各加2P拉页
                if (this.form.cover == 5) {
                    //封面封底各加2P拉页
                    for (let i = 0; i < this.PullPage22p.length; i++) {
                        if (this.benshu[i] == undefined || this.benshu[i] == "") {
                            this.benshu[i] = "500";
                        }
                        if (this.form.sizes[i] == undefined || this.form.sizes[i] == "") {
                            this.form.sizes[i] = this.form.size;
                        }
                        if (this.form.statess[i] == undefined || this.form.statess[i] == "") {
                            this.form.statess[i] = this.form.states;
                        }
                        if (
                            this.form.coverTypes[i] == undefined ||
                            this.form.coverTypes[i] == ""
                        ) {
                            this.form.coverTypes[i] = this.form.coverType;
                        }
                        if (
                            this.form.coverGrams[i] == undefined ||
                            this.form.coverGrams[i] == ""
                        ) {
                            this.form.coverGrams[i] = this.form.coverGram;
                        }
                        if (this.form.singles[i] == undefined || this.form.singles[i] == "") {
                            this.form.singles[i] = "colors";
                        }
                        if (this.form.colours[i] == undefined || this.form.colours[i] == "") {
                            this.form.colours[i] = this.form.colour;
                        }
                        // if (this.form.checked[i] == undefined || this.form.checked[i] == '') {
                        //     this.form.checked[i] = false
                        // }
                        // console.log(this.form.size)
                        // console.log(this.form)
                        // console.log(this.form.sizes)
                        // console.log(this.form.statess)
                        // console.log(this.form.coverTypes)
                        // console.log(this.form.coverGrams)
                        // console.log(this.form.sizes[i])
                        // console.log(this.form.singles)
                    }
                }
                //封三连体封套?款
                if (this.form.cover == 6) {
                    //封三连体封套?款
                    for (let i = 0; i < this.form.input2; i++) {
                        if (this.benshu[i] == undefined || this.benshu[i] == "") {
                            this.benshu[i] = "500";
                        }
                        if (this.doudi[i] == undefined || this.doudi[i] == "") {
                            this.doudi[i] = "";
                        }
                        if (
                            this.form.coverTypes[i] == undefined ||
                            this.form.coverTypes[i] == ""
                        ) {
                            this.form.coverTypes[i] = this.form.coverType;
                        }
                        if (
                            this.form.coverGrams[i] == undefined ||
                            this.form.coverGrams[i] == ""
                        ) {
                            this.form.coverGrams[i] = this.form.coverGram;
                        }
                        if (this.form.singles[i] == undefined || this.form.singles[i] == "") {
                            this.form.singles[i] = "colors";
                        }
                        if (this.form.colours[i] == undefined || this.form.colours[i] == "") {
                            this.form.colours[i] = this.form.colour;
                        }
                    }
                }

                //内页
                // let arrayny = []
                for (let i = 0; i < this.neiyearr.length; i++) {
                    // arrayny.push(false)
                    // this.checkedny = arrayny
                    //内页P数
                    if (this.form.pagenums[i] == undefined || this.form.pagenums[i] == "") {
                        this.form.pagenums[i] = "4";
                    }
                  if (this.form.pagenumberGramny[i] == undefined || this.form.pagenumberGramny[i] == "") {
                    this.form.pagenumberGramny[i] = this.form.pagenumberGram;
                  }
                    if (this.form.pagenumberTypeny[i] == undefined || this.form.pagenumberTypeny[i] == "") {
                        this.form.pagenumberTypeny[i] = this.form.pagenumberType;
                    }

                    if (this.form.pagenumberGramfy[i] == undefined || this.form.pagenumberGramfy[i] == "") {
                        this.form.pagenumberGramfy[i] = this.form.pagenumberGram;
                    }
                    if (this.form.singlesny[i] == undefined || this.form.singlesny[i] == "") {
                        this.form.singlesny[i] = "colors";
                    }
                    if (this.form.coloursny[i] == undefined || this.form.coloursny[i] == "") {
                        this.form.coloursny[i] = this.form.colour;
                    }
                    if (this.form.nybx[i] == undefined || this.form.nybx[i] == "") {
                        this.form.nybx[i] = this.form.nybxid;
                    }
                    if (this.isCustMany[i] == undefined || this.isCustMany[i] == "") {
                        this.isCustMany[i] = false;
                    }
                    // this.checkedCheckboxny[i] = {}
                    // console.log(this.checkedCheckboxny)
                }

                //扉页
                for (let i = 0; i < this.feiyearr.length; i++) {
                    if (this.form.pagenumberTypefy[i] == undefined || this.form.pagenumberTypefy[i] == "") {
                        this.form.pagenumberTypefy[i] = this.form.pagenumberType;
                    }
                    if (this.form.pagenumberGramfy[i] == undefined || this.form.pagenumberGramfy[i] == "") {
                        this.form.pagenumberGramfy[i] = this.form.pagenumberGram;
                    }
                    if (this.form.singlesfy[i] == undefined || this.form.singlesfy[i] == "") {
                        this.form.singlesfy[i] = "colors";
                    }
                    if (this.form.coloursfy[i] == undefined || this.form.coloursfy[i] == "") {
                        this.form.coloursfy[i] = this.form.colour;
                    }
                    if (this.form.fybx[i] == undefined || this.form.fybx[i] == "") {
                        this.form.fybx[i] = this.form.fybxid;
                    }
                    if (this.isCustMafy[i] == undefined || this.isCustMafy[i] == "") {
                        this.isCustMafy[i] = false;
                    }
                }

                //插页
                for (let i = 0; i < this.chayearr.length; i++) {
                    if (this.form.pagenumberTypecy[i] == undefined || this.form.pagenumberTypecy[i] == "") {
                        this.form.pagenumberTypecy[i] = this.form.pagenumberType;
                    }
                    if (this.form.cynumber[i] == undefined || this.form.cynumber[i] == "") {
                      this.form.cynumber[i] = 1;
                    }
                    if (this.form.pagenumberGramcy[i] == undefined || this.form.pagenumberGramcy[i] == "") {
                        this.form.pagenumberGramcy[i] = this.form.pagenumberGram;
                    }
                    if (this.form.singlescy[i] == undefined || this.form.singlescy[i] == "") {
                        this.form.singlescy[i] = "colors";
                    }
                    if (this.form.colourscy[i] == undefined || this.form.colourscy[i] == "") {
                        this.form.colourscy[i] = this.form.colour;
                    }
                    if (this.form.cybx[i] == undefined || this.form.cybx[i] == "") {
                        this.form.cybx[i] = this.form.cybxid;
                    }
                    if (this.isCustMacy[i] == undefined || this.isCustMacy[i] == "") {
                        this.isCustMacy[i] = false;
                    }
                }
                //拉页
                for (let i = 0; i < this.layearr.length; i++) {
                    if (this.form.pagenumberTypely[i] == undefined || this.form.pagenumberTypely[i] == "") {
                        this.form.pagenumberTypely[i] = this.form.pagenumberType;
                    }
                    if (this.form.pagenumberGramly[i] == undefined || this.form.pagenumberGramly[i] == "") {
                        this.form.pagenumberGramly[i] = this.form.pagenumberGram;
                    }
                    if (this.form.singlesly[i] == undefined || this.form.singlesly[i] == "") {
                        this.form.singlesly[i] = "colors";
                    }
                    if (this.form.coloursly[i] == undefined || this.form.coloursly[i] == "") {
                        this.form.coloursly[i] = this.form.colour;
                    }
                    if (this.form.lybx[i] == undefined || this.form.lybx[i] == "") {
                        this.form.lybx[i] = this.form.lybxid;
                    }
                    if (this.isCustMaly[i] == undefined || this.isCustMaly[i] == "") {
                        this.isCustMaly[i] = false;
                    }
                }
                //画册加护封
                for (let i = 0; i < this.HuaceDustJacket.length; i++) {
                    if (
                        this.form.pagenumberTypehf[i] == undefined ||
                        this.form.pagenumberTypehf[i] == ""
                    ) {
                        this.form.pagenumberTypehf[i] = this.form.otherType;
                    }
                    if (
                        this.form.pagenumberGramhf[i] == undefined ||
                        this.form.pagenumberGramhf[i] == ""
                    ) {
                        this.form.pagenumberGramhf[i] = this.form.otherGram;
                    }
                    if (
                        this.form.singlesDustJacket[i] == undefined ||
                        this.form.singlesDustJacket[i] == ""
                    ) {
                        this.form.singlesDustJacket[i] = "colors";
                    }
                    if (
                        this.form.coloursDustJacket[i] == undefined ||
                        this.form.coloursDustJacket[i] == ""
                    ) {
                        this.form.coloursDustJacket[i] = this.form.colour;
                    }
                }
                //画册加腰带
                for (let i = 0; i < this.HuaceBelt.length; i++) {
                    if (
                        this.form.pagenumberTypeyd[i] == undefined ||
                        this.form.pagenumberTypeyd[i] == ""
                    ) {
                        this.form.pagenumberTypeyd[i] = this.form.otherType;
                    }
                    if (
                        this.form.pagenumberGramyd[i] == undefined ||
                        this.form.pagenumberGramyd[i] == ""
                    ) {
                        this.form.pagenumberGramyd[i] = this.form.otherGram;
                    }
                    if (
                        this.form.singlesBelt[i] == undefined ||
                        this.form.singlesBelt[i] == ""
                    ) {
                        this.form.singlesBelt[i] = "colors";
                    }
                    if (
                        this.form.coloursBelt[i] == undefined ||
                        this.form.coloursBelt[i] == ""
                    ) {
                        this.form.coloursBelt[i] = this.form.colour;
                    }
                }
                //自定义部件
                for (let i = 0; i < this.CustomParts.length; i++) {
                    if (
                        this.form.pagenumberTypezdy[i] == undefined ||
                        this.form.pagenumberTypezdy[i] == ""
                    ) {
                        this.form.pagenumberTypezdy[i] = this.form.otherType;
                    }
                    if (
                        this.form.pagenumberGramzdy[i] == undefined ||
                        this.form.pagenumberGramzdy[i] == ""
                    ) {
                        this.form.pagenumberGramzdy[i] = this.form.otherGram;
                    }
                    if (
                        this.form.singleszdy[i] == undefined ||
                        this.form.singleszdy[i] == ""
                    ) {
                        this.form.singleszdy[i] = "colors";
                    }
                    if (
                        this.form.colourszdy[i] == undefined ||
                        this.form.colourszdy[i] == ""
                    ) {
                        this.form.colourszdy[i] = this.form.colour;
                    }
                }
            },

            //确定特殊封面选择
            coverdetermine() {
                this.isCustMafm = false
                // this.checkedCheckbox=[]
                // this.checkedtext = [];
                // this.checkedCheckbox = [];
                this.changeInput1();
                if (this.form.cover == 1) {
                    console.log(this.checked)
                    console.log(this.form.cover + "," + this.form.input1 + "款封面");
                } else if (this.form.cover == 2) {
                    this.fm = "";
                    this.fd = "";
                } else if (this.form.cover == 3) {
                    this.fm = "4";
                    this.fd = "2";
                } else if (this.form.cover == 4) {
                    this.fm = "6";
                    this.fd = "2";
                } else if (this.form.cover == 5) {
                    this.fm = "4";
                    this.fd = "4";
                } else if (this.form.cover == 6) {
                    console.log(
                        this.form.cover + ",封三连体封套" + this.form.input2 + "款"
                    );
                } else {
                    //cover:    特殊封面选择
                    console.log(this.form.cover);
                }
            },

            //确定内页部件添加
            coverdetermine2() {
                console.log(this.form.insidepage);
                //内页
                if (this.form.insidepage == 11) {
                    if (this.neiyearr[0] == 1 && this.neiyearr.length == 1) {
                        this.neiye = 1;
                    }
                    this.neiye++;
                    this.neiyearr.push(this.neiye);
                    this.checkedCheckboxny.push([]);
                    this.checkedCheckboxny1.push([]);
                    console.log(this.neiyearr)
                    console.log(this.checkedCheckboxny)
                }
                //扉页
                if (this.form.insidepage == 12) {
                    if (this.feiyearr.length == 0) {
                        this.feiye = 0;
                    }
                    this.feiye++;
                    this.checkedCheckboxfy.push([]);
                    this.checkedCheckboxfy1.push([]);
                    this.feiyearr.push(this.feiye);
                    console.log(this.feiyearr);
                }
                //插页
                if (this.form.insidepage == 13) {
                    if (this.chayearr.length == 0) {
                        this.chaye = 0;
                    }
                    this.chaye++;
                    this.checkedCheckboxcy.push([]);
                    this.checkedCheckboxcy1.push([]);
                    this.chayearr.push(this.chaye);
                    console.log(this.chayearr);
                }
                //拉页
                if (this.form.insidepage == 14) {
                    console.log(this.form.pagenumber + "P");
                    this.form.pagenumberarr.push(this.form.pagenumber);
                    this.laye++;
                    this.checkedCheckboxly.push([]);
                    this.checkedCheckboxly1.push([]);
                    this.layearr.push(this.laye);
                    console.log(this.layearr);
                    console.log(this.form.pagenumberarr);
                }
                this.changeInput1();
            },

            //偶数值的拉页P数
            ChangepNum(value) {
                console.log(value)
                // console.log(value / 2)
                // console.log(value % 2)
                if (value % 2 != 0 || value == 0 || value == '') {
                    this.$message.error("请输入偶数值的拉页P数");
                } else {
                    if (value.length > 1) {
                        this.pNum_width = 'width:50px;text-align: center;'
                    } else {
                        this.pNum_width = 'width:40px;text-align: center;'
                    }
                }
            },

            //确定画册添加特殊部件/工艺
            coverdetermine3() {
                console.log(this.form.other);
                // console.log(this.Drilling)
                //画册加护封
                if (this.form.other == 21) {
                    if (this.HuaceDustJacket.length == 0) {
                        this.HuaceDustJacket.push(1);
                    }
                }
                //画册加腰带
                if (this.form.other == 22) {
                    if (this.HuaceBelt.length == 0) {
                        this.HuaceBelt.push(1);
                    }
                }
                //自定义部件?
                if (this.form.other == 23) {
                    if (this.form.input3 == "") {
                        this.coverVisible3 = true;
                        this.$message.error("填写定义部件名称");
                    } else {
                        if (this.CustomParts.length == 0) {
                            this.CustomParts.push(1);
                        }
                        console.log("自定义部件" + this.form.input3);
                    }
                }
                //书本打孔?处/本
                if (this.form.other == 24) {
                    if (this.form.input4 == "") {
                        this.coverVisible3 = true;
                        this.$message.error("打孔数量必须为正整数");
                    } else {
                        if (this.Drilling.length == 0) {
                            this.Drilling.push(1);
                        }
                        console.log("书本打孔" + this.form.input4 + "处/本");
                    }
                }
                //画册塑封
                if (this.form.other == 25) {
                    //画册塑封 和 画册套自封袋 二选一
                    if (this.Plastic.length == 0) {
                        this.Plastic.push(1);
                        this.SelfSealingBag = [];
                        console.log(this.SelfSealingBag);
                    }
                }
                //画册套自封袋
                if (this.form.other == 26) {
                    //画册塑封 和 画册套自封袋 二选一
                    if (this.SelfSealingBag.length == 0) {
                        this.SelfSealingBag.push(1);
                        this.Plastic = [];
                        console.log(this.Plastic);
                    }
                }
                this.changeInput1();
            },

            //内页类型_内页_删除
            deleteny(item, index) {
                console.log("移除内页下标:" + index);
                // for (let a=0;a<this.neiye.length;a++){
                this.neiyearr.splice(index, 1);
                this.checkedCheckboxny.splice(index, 1);
                this.checkedCheckboxny1.splice(index, 1);
                // this.checkedCheckboxny.splice(index, 1);
                // }
                // console.log(this.neiye)
                console.log(this.neiyearr);
                // console.log(this.checkedCheckboxny);
            },

            //内页类型_扉页_删除
            deletefy(item, index) {
                console.log("移除扉页下标:" + index);
                // for (let a=0;a<this.neiye.length;a++){
                this.feiyearr.splice(index, 1);
                this.checkedCheckboxfy.splice(index, 1);
                this.checkedCheckboxfy1.splice(index, 1);
                // }
                // console.log(this.neiye)
                console.log(this.feiyearr);
            },

            //内页类型_插页_删除
            deletecy(item, index) {
                console.log("移除插页下标:" + index);
                // for (let a=0;a<this.neiye.length;a++){
                this.chayearr.splice(index, 1);
                this.checkedCheckboxcy.splice(index, 1);
                this.checkedCheckboxcy1.splice(index, 1);
                // delete this.chayearr[index]
                // }
                // console.log(this.neiye)
                console.log(this.chayearr);
            },

            //内页类型_拉页_删除
            deletely(item, index) {
                console.log("移除拉页下标:" + index);
                this.layearr.splice(index, 1);
                this.checkedCheckboxly.splice(index, 1);
                this.checkedCheckboxly1.splice(index, 1);
                // this.layearr.splice(index, 1, 'str')
                // delete this.layearr[index]
                // console.log(this.neiye)
                console.log(this.layearr);
            },

            //其他_画册加护封_删除
            deleteDustJacket(item, index) {
                console.log("移除画册加护封下标:" + index);
                // for (let a=0;a<this.neiye.length;a++){
                this.HuaceDustJacket.splice(index, 1);
                // }
                // console.log(this.neiye)
                console.log(this.HuaceDustJacket);
                this.checkedCheckboxhf=[]
            },

            //其他_画册加腰带_删除
            deleteBelt(item, index) {
                console.log("移除画册加腰带下标:" + index);
                // for (let a=0;a<this.neiye.length;a++){
                this.HuaceBelt.splice(index, 1);
                // }
                // console.log(this.neiye)
                console.log(this.HuaceBelt);
                this.checkedCheckboxyd=[]
            },

            //其他_自定义部件_删除
            deletezdy(item, index) {
                console.log("移除自定义部件下标:" + index);
                // for (let a=0;a<this.neiye.length;a++){
                this.CustomParts.splice(index, 1);
                // }
                // console.log(this.neiye)
                console.log(this.CustomParts);
                this.checkedCheckboxzdy=[]
            },

            //其他_书本打孔?处/本_删除
            deletedk(item, index) {
                console.log("移除书本打孔?处/本下标:" + index);
                this.Drilling.splice(index, 1);
                console.log(this.Drilling);
            },

            //其他_画册塑封_删除
            deletehcsf(item, index) {
                console.log("移除画册塑封下标:" + index);
                this.Plastic.splice(index, 1);
                console.log(this.Plastic);
            },

            //其他_画册套自封袋_删除
            deletehczfd(item, index) {
                console.log("移除画册套自封袋下标:" + index);
                this.SelfSealingBag.splice(index, 1);
                console.log(this.SelfSealingBag);
            },

            //?款封面中?本4P
            inputChange(value) {
                console.log(this.form.cover);
                console.log("封面:" + this.fm + ",封底:" + this.fd);
                console.log(value);
                console.log("封面" + this.benshu + "本");
                console.log("兜底高" + this.doudi);
                console.log("封三分体封套_宽" + this.kuan);
                console.log("封三分体封套_高" + this.gao);
            },

            //封面纸张克重（类型）发生改变
            //返回该类型ID
            Changetype(item, index) {
                console.log(index);
                console.log(item);
                //循环材料列表，判断当前材料ID是否与材料列表中某一组材料的ID相同
                //赋值当前材料的默认克重ID
                //循环当前材料的克重
                for (let i = 0; i < this.covermaList.length; i++) {
                    if (item == this.covermaList[i].id) {
                        console.log(this.covermaList[i]);
                        // this.form.gram=this.maList[i].defaultweight
                        this.productWeightList = this.covermaList[i].productWeightList;
                        //循环材料列表中克重列表，判断当前默认克重是否与克重列表中某一组克重的相同
                        //取出克重ID
                        //否则默认纸张克重（克）为克重列表第一组数据ID
                        for (let a = 0; a < this.covermaList[i].productWeightList.length; a++) {
                            if (this.covermaList[i].defaultweight == this.covermaList[i].productWeightList[a].name) {
                                console.log("克重ID:" + this.covermaList[i].productWeightList[a].weightid);
                                //默认封面纸张克重（克）
                                this.form.coverGrams[index] = this.covermaList[i].productWeightList[a].weightid;
                                return;
                            } else {
                                console.log(this.covermaList[i].productWeightList[0].weightid);
                                //默认封面纸张克重（克）
                                this.form.coverGrams[index] = this.covermaList[i].productWeightList[0].weightid;
                            }
                        }
                        console.log(this.form.coverGrams);

                        // this.changeInput1()
                    }
                }
            },

            //内页纸张克重（类型）发生改变
            //返回该类型ID
            Changetypepagenumber(item, index) {
                console.log(index);
                console.log(item);
                //循环材料列表，判断当前材料ID是否与材料列表中某一组材料的ID相同
                //赋值当前材料的默认克重ID
                //循环当前材料的克重
                for (let i = 0; i < this.pagenumberList.length; i++) {
                    if (item == this.pagenumberList[i].id) {
                        console.log(this.pagenumberList[i]);
                        // this.form.gram=this.maList[i].defaultweight
                        this.pagenumbertWeightList = this.pagenumberList[i].productWeightList;
                        //循环材料列表中克重列表，判断当前默认克重是否与克重列表中某一组克重的相同
                        //取出克重ID
                        //否则默认纸张克重（克）为克重列表第一组数据ID
                        for (let a = 0; a < this.pagenumberList[i].productWeightList.length; a++) {
                            if (this.pagenumberList[i].defaultweight == this.pagenumberList[i].productWeightList[a].name) {
                                console.log(
                                    "克重:" +
                                    this.pagenumberList[i].productWeightList[a].name +
                                    ",克重ID:" +
                                    this.pagenumberList[i].productWeightList[a].weightid
                                );
                                //默认内页纸张克重（克）
                                this.form.pagenumberGramny[index] = this.pagenumberList[i].productWeightList[a].weightid;
                                return;
                            } else {
                                console.log(this.pagenumberList[i].productWeightList[0].weightid);
                                //默认内页纸张克重（克）
                                this.form.pagenumberGramny[index] = this.pagenumberList[i].productWeightList[0].weightid;
                            }
                        }
                        // console.log(this.form.pagenumberGrams)

                        // this.changeInput1()
                    }
                }
            },

            //扉页纸张克重（类型）发生改变
            //返回该类型ID
            Changetypepagenumberfy(item, index) {
                console.log(index);
                console.log(item);
                //循环材料列表，判断当前材料ID是否与材料列表中某一组材料的ID相同
                //赋值当前材料的默认克重ID
                //循环当前材料的克重
                for (let i = 0; i < this.pagenumberList.length; i++) {
                    if (item == this.pagenumberList[i].id) {
                        console.log(this.pagenumberList[i]);
                        // this.form.gram=this.maList[i].defaultweight
                        this.pagenumbertWeightList = this.pagenumberList[i].productWeightList;
                        //循环材料列表中克重列表，判断当前默认克重是否与克重列表中某一组克重的相同
                        //取出克重ID
                        //否则默认纸张克重（克）为克重列表第一组数据ID
                        for (
                            let a = 0;
                            a < this.pagenumberList[i].productWeightList.length;
                            a++
                        ) {
                            if (
                                this.pagenumberList[i].defaultweight ==
                                this.pagenumberList[i].productWeightList[a].name
                            ) {
                                console.log(
                                    "克重:" +
                                    this.pagenumberList[i].productWeightList[a].name +
                                    ",克重ID:" +
                                    this.pagenumberList[i].productWeightList[a].weightid
                                );
                                //默认扉页纸张克重（克）
                                this.form.pagenumberGramfy[index] = this.pagenumberList[
                                    i
                                    ].productWeightList[a].weightid;
                                return;
                            } else {
                                console.log(this.pagenumberList[i].productWeightList[0].weightid);
                                //默认扉页纸张克重（克）
                                this.form.pagenumberGramfy[index] = this.pagenumberList[
                                    i
                                    ].productWeightList[0].weightid;
                            }
                        }
                        // console.log(this.form.pagenumberGrams)

                        // this.changeInput1()
                    }
                }
            },

            //插页纸张克重（类型）发生改变
            //返回该类型ID
            Changetypepagenumbercy(item, index) {
                console.log(index);
                console.log(item);
                //循环材料列表，判断当前材料ID是否与材料列表中某一组材料的ID相同
                //赋值当前材料的默认克重ID
                //循环当前材料的克重
                for (let i = 0; i < this.pagenumberList.length; i++) {
                    if (item == this.pagenumberList[i].id) {
                        console.log(this.pagenumberList[i]);
                        // this.form.gram=this.maList[i].defaultweight
                        this.pagenumbertWeightList = this.pagenumberList[i].productWeightList;
                        //循环材料列表中克重列表，判断当前默认克重是否与克重列表中某一组克重的相同
                        //取出克重ID
                        //否则默认纸张克重（克）为克重列表第一组数据ID
                        for (
                            let a = 0;
                            a < this.pagenumberList[i].productWeightList.length;
                            a++
                        ) {
                            if (
                                this.pagenumberList[i].defaultweight ==
                                this.pagenumberList[i].productWeightList[a].name
                            ) {
                                console.log(
                                    "克重:" +
                                    this.pagenumberList[i].productWeightList[a].name +
                                    ",克重ID:" +
                                    this.pagenumberList[i].productWeightList[a].weightid
                                );
                                //默认插页纸张克重（克）
                                this.form.pagenumberGramcy[index] = this.pagenumberList[
                                    i
                                    ].productWeightList[a].weightid;
                                return;
                            } else {
                                console.log(this.pagenumberList[i].productWeightList[0].weightid);
                                //默认插页纸张克重（克）
                                this.form.pagenumberGramcy[index] = this.pagenumberList[
                                    i
                                    ].productWeightList[0].weightid;
                            }
                        }
                        // console.log(this.form.pagenumberGrams)

                        // this.changeInput1()
                    }
                }
            },

            //拉页纸张克重（类型）发生改变
            //返回该类型ID
            Changetypepagenumberly(item, index) {
                console.log(index);
                console.log(item);
                //循环材料列表，判断当前材料ID是否与材料列表中某一组材料的ID相同
                //赋值当前材料的默认克重ID
                //循环当前材料的克重
                for (let i = 0; i < this.pagenumberList.length; i++) {
                    if (item == this.pagenumberList[i].id) {
                        console.log(this.pagenumberList[i]);
                        // this.form.gram=this.maList[i].defaultweight
                        this.pagenumbertWeightList = this.pagenumberList[i].productWeightList;
                        //循环材料列表中克重列表，判断当前默认克重是否与克重列表中某一组克重的相同
                        //取出克重ID
                        //否则默认纸张克重（克）为克重列表第一组数据ID
                        for (
                            let a = 0;
                            a < this.pagenumberList[i].productWeightList.length;
                            a++
                        ) {
                            if (
                                this.pagenumberList[i].defaultweight ==
                                this.pagenumberList[i].productWeightList[a].name
                            ) {
                                console.log(
                                    "克重:" +
                                    this.pagenumberList[i].productWeightList[a].name +
                                    ",克重ID:" +
                                    this.pagenumberList[i].productWeightList[a].weightid
                                );
                                //默认拉页纸张克重（克）
                                this.form.pagenumberGramly[index] = this.pagenumberList[
                                    i
                                    ].productWeightList[a].weightid;
                                return;
                            } else {
                                console.log(this.pagenumberList[i].productWeightList[0].weightid);
                                //默认拉页纸张克重（克）
                                this.form.pagenumberGramly[index] = this.pagenumberList[
                                    i
                                    ].productWeightList[0].weightid;
                            }
                        }
                        // console.log(this.form.pagenumberGrams)

                        // this.changeInput1()
                    }
                }
            },

            //画册加护封纸张克重（类型）发生改变
            //返回该类型ID
            Changetypepagenumberhf(item, index) {
                console.log(index);
                console.log(item);
                //循环材料列表，判断当前材料ID是否与材料列表中某一组材料的ID相同
                //赋值当前材料的默认克重ID
                //循环当前材料的克重
                for (let i = 0; i < this.otherpartsList.length; i++) {
                    if (item == this.otherpartsList[i].id) {
                        console.log(this.otherpartsList[i]);
                        // this.form.gram=this.maList[i].defaultweight
                        this.otherWeightList = this.otherpartsList[i].productWeightList;
                        //循环材料列表中克重列表，判断当前默认克重是否与克重列表中某一组克重的相同
                        //取出克重ID
                        //否则默认纸张克重（克）为克重列表第一组数据ID
                        for (
                            let a = 0;
                            a < this.otherpartsList[i].productWeightList.length;
                            a++
                        ) {
                            if (
                                this.otherpartsList[i].defaultweight ==
                                this.otherpartsList[i].productWeightList[a].name
                            ) {
                                console.log(
                                    "克重:" +
                                    this.otherpartsList[i].productWeightList[a].name +
                                    ",克重ID:" +
                                    this.otherpartsList[i].productWeightList[a].weightid
                                );
                                //默认画册加护封纸张克重（克）
                                this.form.pagenumberGramhf[index] = this.otherpartsList[
                                    i
                                    ].productWeightList[a].weightid;
                                return;
                            } else {
                                console.log(this.otherpartsList[i].productWeightList[0].weightid);
                                //默认画册加护封纸张克重（克）
                                this.form.pagenumberGramhf[index] = this.otherpartsList[
                                    i
                                    ].productWeightList[0].weightid;
                            }
                        }
                        // console.log(this.form.pagenumberGrams)

                        // this.changeInput1()
                    }
                }
            },

            //画册加腰带纸张克重（类型）发生改变
            //返回该类型ID
            Changetypepagenumberyd(item, index) {
                console.log(index);
                console.log(item);
                //循环材料列表，判断当前材料ID是否与材料列表中某一组材料的ID相同
                //赋值当前材料的默认克重ID
                //循环当前材料的克重
                for (let i = 0; i < this.otherpartsList.length; i++) {
                    if (item == this.otherpartsList[i].id) {
                        console.log(this.otherpartsList[i]);
                        // this.form.gram=this.maList[i].defaultweight
                        this.otherWeightList = this.otherpartsList[i].productWeightList;
                        //循环材料列表中克重列表，判断当前默认克重是否与克重列表中某一组克重的相同
                        //取出克重ID
                        //否则默认纸张克重（克）为克重列表第一组数据ID
                        for (
                            let a = 0;
                            a < this.otherpartsList[i].productWeightList.length;
                            a++
                        ) {
                            if (
                                this.otherpartsList[i].defaultweight ==
                                this.otherpartsList[i].productWeightList[a].name
                            ) {
                                console.log(
                                    "克重:" +
                                    this.otherpartsList[i].productWeightList[a].name +
                                    ",克重ID:" +
                                    this.otherpartsList[i].productWeightList[a].weightid
                                );
                                //默认画册加腰带纸张克重（克）
                                this.form.pagenumberGramyd[index] = this.otherpartsList[
                                    i
                                    ].productWeightList[a].weightid;
                                return;
                            } else {
                                console.log(this.otherpartsList[i].productWeightList[0].weightid);
                                //默认画册加腰带纸张克重（克）
                                this.form.pagenumberGramyd[index] = this.otherpartsList[
                                    i
                                    ].productWeightList[0].weightid;
                            }
                        }
                        // console.log(this.form.pagenumberGrams)

                        // this.changeInput1()
                    }
                }
            },

            //自定义部件纸张克重（类型）发生改变
            //返回该类型ID
            Changetypepagenumberzdy(item, index) {
                console.log(index);
                console.log(item);
                //循环材料列表，判断当前材料ID是否与材料列表中某一组材料的ID相同
                //赋值当前材料的默认克重ID
                //循环当前材料的克重
                for (let i = 0; i < this.otherpartsList.length; i++) {
                    if (item == this.otherpartsList[i].id) {
                        console.log(this.otherpartsList[i]);
                        // this.form.gram=this.maList[i].defaultweight
                        this.otherWeightList = this.otherpartsList[i].productWeightList;
                        //循环材料列表中克重列表，判断当前默认克重是否与克重列表中某一组克重的相同
                        //取出克重ID
                        //否则默认纸张克重（克）为克重列表第一组数据ID
                        for (
                            let a = 0;
                            a < this.otherpartsList[i].productWeightList.length;
                            a++
                        ) {
                            if (
                                this.otherpartsList[i].defaultweight ==
                                this.otherpartsList[i].productWeightList[a].name
                            ) {
                                console.log(
                                    "克重:" +
                                    this.otherpartsList[i].productWeightList[a].name +
                                    ",克重ID:" +
                                    this.otherpartsList[i].productWeightList[a].weightid
                                );
                                //默认自定义部件纸张克重（克）
                                this.form.pagenumberGramzdy[index] = this.otherpartsList[
                                    i
                                    ].productWeightList[a].weightid;
                                return;
                            } else {
                                console.log(this.otherpartsList[i].productWeightList[0].weightid);
                                //默认自定义部件纸张克重（克）
                                this.form.pagenumberGramzdy[index] = this.otherpartsList[
                                    i
                                    ].productWeightList[0].weightid;
                            }
                        }
                        // console.log(this.form.pagenumberGrams)

                        // this.changeInput1()
                    }
                }
            },

            // 地址级联选择器
            handleChange(value) {
                console.log(value);
                this.addressCode = [...value];
                // 地址反显
                this.showAddress = `${CodeToText[value[0]]}${CodeToText[value[1]]}${
                    CodeToText[value[2]]
                }`;
                console.log(this.showAddress);
                console.log(this.addressCode);
                if (this.form.dest != this.showAddress) {
                    //详情收货地址
                    this.ruleForm.address = ''
                }
                //省市区
                this.form.dest = this.showAddress;
                //省ID
                this.form.provinceId = this.addressCode[0];
                //市ID
                this.form.cityId = this.addressCode[1];
                //区ID
                this.form.areaId = this.addressCode[2];

                // let id = uuid.v4();
                // console.log(id);
            },

            //获取默认地址
            GetAlladdress() {
                let userInfo = JSON.parse(window.sessionStorage.getItem('currentInfo'))
                console.log(this.user_ID)
                console.log(userInfo.user_ID)

                let userid = ''
                if (this.user_ID == '') {
                    userid = userInfo.user_ID
                } else {
                    userid = this.user_ID
                }
                console.log(userid)
                let array = []

                //非普通用户 并且 未选择服务客户
                console.log(userInfo.userType)
                console.log(this.user_ID)
                if (userInfo.userType != 0 && userid == ''){
                    this.form.selectedOptions=[];//目的地
                    this.form.dest= ''; //省市区
                    this.form.provinceId=''; //省ID
                    this.form.cityId=''; //市ID
                    this.form.areaId='';//区ID
                }else {
                    this.$http.get("/receiveadderss/listAll?operatorId=" + userid).then((res) => {
                        console.log('获取默认地址', res)
                        if (res.data.code == 200) {
                            if (res.data.data.length != 0) {
                                array.push(res.data.data[0].provinceCode, res.data.data[0].cityCode, res.data.data[0].areaCode)
                                //省市区
                                this.form.dest = res.data.data[0].provinceZh + res.data.data[0].cityZh + res.data.data[0].areaZh
                                //收货人姓名
                                this.ruleForm.consignee = res.data.data[0].receiveName
                                //收货人电话
                                this.ruleForm.tel = res.data.data[0].receivePhone
                                //详情收货地址
                                this.ruleForm.address = res.data.data[0].detailAddress
                            }
                        }
                        this.form.selectedOptions = array
                        console.log(array)
                        console.log(this.form.dest)
                        if (array.length != 0) {
                            //省ID
                            this.form.provinceId = array[0]
                            //市ID
                            this.form.cityId = array[1]
                            //区ID
                            this.form.areaId = array[2]
                        }
                    })
                }
            },

            //封面其他工艺
            CheckedChange(value, index) {
                console.log(index);
                console.log(value);
                console.log(this.checkedtext);
                this.index = index;
                this.centerDialogVisible4 = true;
                // this.form.checkedCheckbox = false;
                console.log(this.form.checkedCheckbox);
                console.log(this.centerDialogVisible4);
                if (value == false && this.checkedtext[index] != "") {
                    value = true;
                }
            },

            //内页工艺
            CheckedChangeny(value, index) {
                console.log(index);
                console.log(value);
                console.log(this.checkedtextny);
                this.indexny = index;
                this.centerDialogVisible5 = true;
                console.log(this.centerDialogVisible5);
                if (value == false && this.checkedtextny[index] != "") {
                    value = true;
                }
            },

            //扉页工艺
            CheckedChangefy(value, index) {
                console.log(index);
                console.log(value);
                console.log(this.checkedtextfy);
                this.indexfy = index;
                this.centerDialogVisible6 = true;
                console.log(this.centerDialogVisible6);
                console.log(this.checkedtextfy[index])
                if (value == false && this.checkedtextfy[index] != "") {
                    value = true;
                }
            },

            //插页工艺
            CheckedChangecy(value, index) {
                console.log(index);
                console.log(value);
                console.log(this.checkedtextcy);
                this.indexcy = index;
                this.centerDialogVisible7 = true;
                console.log(this.centerDialogVisible7);
                if (value == false && this.checkedtextcy[index] != "") {
                    value = true;
                }
            },

            //拉页工艺
            CheckedChangely(value, index) {
                console.log(index);
                console.log(value);
                console.log(this.checkedtextly);
                this.indexly = index;
                this.centerDialogVisible8 = true;
                console.log(this.centerDialogVisible8);
                if (value == false && this.checkedtextly[index] != "") {
                    value = true;
                }
            },

            //画册加护封_其他工艺
            CheckedChangeDustJacket(value, index) {
                console.log(index);
                console.log(value);
                console.log(this.checkedtextDustJacket);
                this.centerDialogVisible9 = true;
                console.log(this.centerDialogVisible9);
                if (value == false && this.checkedtextDustJacket[index] != "") {
                    value = true;
                }
            },

            //画册加腰带_其他工艺
            CheckedChangeBelt(value, index) {
                console.log(index);
                console.log(value);
                console.log(this.checkedtextBelt);
                this.centerDialogVisible10 = true;
                console.log(this.centerDialogVisible10);
                if (value == false && this.checkedtextBelt[index] != "") {
                    value = true;
                }
            },

            //自定义部件_其他工艺
            CheckedChangezdy(value, index) {
                console.log(index);
                console.log(value);
                console.log(this.checkedtextzdy);
                this.centerDialogVisible11 = true;
                console.log(this.centerDialogVisible11);
                if (value == false && this.checkedtextzdy[index] != "") {
                    value = true;
                }
            },

            //封面其他工艺_取消
            cancel1(index) {
                console.log(index)
                console.log(this.index)
                console.log(this.checked)
                console.log(this.checkedtext)
                this.centerDialogVisible4 = false
                this.checkedtext = ''
                this.checkedCheckbox = ''
                this.checked[this.index] = false
            },

            //封面其他工艺_右上角关闭
            handleClose1() {
                this.centerDialogVisible4 = false
                this.checkedtext = ''
                this.checkedCheckbox = ''
                this.checked[this.index] = false
            },

            //封面其他工艺_提交
            Submit(index) {
                console.log(index);
                console.log(this.checkedCheckbox,this.checkedCheckbox.length,this.setacreage0);
                this.checked[this.index] = true

                for (let c = 0; c < this.checkedCheckbox.length; c++) {
                    for (let a = 0; a < this.setacreage0.length; a++) {
                        if (this.setacreage0[a].setacreage == 1 ) {
                          if (this.checkedCheckbox[c].id == this.setacreage0[a].processesSetList[0].id) {
                            console.log(this.setacreage0[a].inSize)
                            this.checkedCheckbox[c].inSize = this.setacreage0[a].inSize
                          }
                        }
                        if(this.setacreage0[a].setacreage == 2){
                          if (this.checkedCheckbox[c].id == this.setacreage0[a].id) {
                            console.log(this.setacreage0[a].inSize)
                            this.checkedCheckbox[c].inSize = this.setacreage0[a].inSize
                          }
                        }
                    }
                }


                let text = [];
                console.log(this.setacreage0);
                console.log(this.checkedCheckbox);
                for (let a = 0; a < this.checkedCheckbox.length; a++) {
                    for (let b = 0; b < this.setacreage0.length; b++) {
                        for (let c = 0; c < this.setacreage0[b].processesSetList.length; c++) {
                            if (this.setacreage0[b].setacreage == 0) {
                                if (this.checkedCheckbox[a].id == this.setacreage0[b].processesSetList[c].id) {
                                    console.log(this.setacreage0[b].processesSetList[c].name);
                                    text.push(this.setacreage0[b].processesSetList[c].name);
                                }
                            } else if (this.setacreage0[b].setacreage == 1) {
                                if (this.checkedCheckbox[a].id == this.setacreage0[b].processesSetList[0].id) {

                                    let size = []

                                    for (let d = 0; d < this.checkedCheckbox[b].inSize.length; d++) {
                                        console.log(this.setacreage0[b].processesSetList[c].name);
                                        size.push(
                                            this.checkedCheckbox[a].inSize[d].width + "*" + this.checkedCheckbox[a].inSize[d].length
                                        );
                                    }
                                    text.push(
                                        this.setacreage0[b].processesSetList[c].name + "(" + size + ")"
                                    );
                                }
                            }
                            // else if (this.setacreage0[b].setacreage == 2) {
                            //     if (this.checkedCheckbox[a].id == this.setacreage0[b].processesSetList[c].id) {
                            //
                            //         let size = []
                            //
                            //         for (let d = 0; d < this.checkedCheckbox[b].inSize.length; d++) {
                            //             console.log(this.setacreage0[b].processesSetList[c].name);
                            //             size.push(
                            //                 this.checkedCheckbox[a].inSize[d].width + "*" + this.checkedCheckbox[a].inSize[d].length
                            //             );
                            //         }
                            //         text.push(
                            //             this.setacreage0[b].processesSetList[c].name + "(" + size + ")" + this.setacreage0[b].desc
                            //         )
                            //     }
                            // }
                            else if (this.setacreage0[b].setacreage == 3) {
                                if (this.checkedCheckbox[a].id == this.setacreage0[b].processesSetList[c].id) {
                                    text.push(
                                        this.setacreage0[b].processesSetList[c].name + "(" + this.setacreage0[b].desc + ")"
                                    )
                                }
                            }
                        }
                    }
                }

                for (let b = 0; b < this.setacreage0.length; b++) {

                    if (this.setacreage0[b].setacreage == 2) {
                          for (let i = 0; i < Number(this.setacreage0[b].num); i++) {
                              text.push(
                                      this.setacreage0[b].inSize[i].modelsName + "(" +
                                      this.setacreage0[b].inSize[i].width + "*" + this.setacreage0[b].inSize[i].length+")"
                             )
                          }
                    }

                }

                console.log(text);
                this.checkedtext = text;
            },


            //内页工艺_取消
            cancel2(index) {
                console.log(index)
                this.centerDialogVisible5 = false
                console.log(this.checkedtextny)
                console.log(this.checkedCheckboxny)
                this.checkedtextny[this.indexny] = ''
                this.checkedCheckboxny[this.indexny] = ''
                this.checkedny[index] = false
            },

            //内页工艺_右上角关闭
            handleClose2() {
                this.centerDialogVisible5 = false
                this.checkedtextny[this.indexny] = ''
                this.checkedCheckboxny[this.indexny] = ''
                this.checkedny[this.indexny] = false
            },

            //内页工艺_提交
            Submitny(index) {
                console.log(index);

                // this.checkedCheckboxny = this.checkedCheckboxny1
                this.checkedCheckboxny[this.indexny] = this.checkedCheckboxny1[this.indexny]

                console.log(this.checkedCheckboxny1);
                console.log(this.checkedCheckboxny1[this.indexny]);
                // console.log(value)
                this.checkedny[this.indexny] = true
                // this.technologyny[index] = this.checkedCheckboxny;
                // console.log(this.technologyny);

                for (let c = 0; c < this.checkedCheckboxny[this.indexny].length; c++) {
                    for (let a = 0; a < this.setacreageny0.length; a++) {
                        if (this.setacreageny0[a].setacreage == 1) {
                            if (this.checkedCheckboxny[this.indexny][c].id == this.setacreageny0[a].processesSetList[0].id) {
                                console.log(this.setacreageny0[a].inSize)
                                this.checkedCheckboxny[this.indexny][c].inSize = this.setacreageny0[a].inSize
                            }
                        }
                    }
                }

                let text = [];
                console.log(this.setacreageny0);
                console.log(this.checkedCheckboxny[this.indexny]);
                for (let a = 0; a < this.checkedCheckboxny[this.indexny].length; a++) {
                    for (let b = 0; b < this.setacreageny0.length; b++) {
                        for (let c = 0; c < this.setacreageny0[b].processesSetList.length; c++) {
                            if (this.setacreageny0[b].setacreage == 0) {
                                if (this.checkedCheckboxny[this.indexny][a].id == this.setacreageny0[b].processesSetList[c].id) {
                                    console.log(this.setacreageny0[b].processesSetList[c].name);
                                    text.push(this.setacreageny0[b].processesSetList[c].name);
                                }
                            } else if (this.setacreageny0[b].setacreage == 1) {
                                if (this.checkedCheckboxny[this.indexny][a].id == this.setacreageny0[b].processesSetList[0].id) {
                                    let size = []
                                    for (let d = 0; d < this.checkedCheckboxny[this.indexny][b].inSize.length; d++) {
                                        console.log(this.setacreageny0[b].processesSetList[c].name);
                                        size.push(
                                            this.checkedCheckboxny[this.indexny][a].inSize[d].width + "*" + this.checkedCheckboxny[this.indexny][a].inSize[d].length
                                        );
                                    }
                                    text.push(
                                        this.setacreageny0[b].processesSetList[c].name + "(" + size + ")"
                                    );
                                }
                            }   else if (this.setacreageny0[b].setacreage == 3) {
                                if (this.checkedCheckboxny[this.indexny][a].id == this.setacreageny0[b].processesSetList[c].id) {
                                    text.push(
                                        this.setacreageny0[b].processesSetList[c].name + "(" + this.setacreageny0[b].desc + ")"
                                    );
                                }
                            }
                        }
                    }
                }

              for (let b = 0; b < this.setacreageny0.length; b++) {

                if (this.setacreageny0[b].setacreage == 2) {
                  for (let i = 0; i < Number(this.setacreageny0[b].num); i++) {
                    text.push(
                        this.setacreageny0[b].inSize[i].modelsName + "(" +
                        this.setacreageny0[b].inSize[i].width + "*" + this.setacreageny0[b].inSize[i].length+")"
                    )
                  }
                }

              }

                console.log(text);
                console.log(this.checkedCheckboxny);
                console.log(this.checkedCheckboxny[this.indexny]);
                console.log(this.checkedCheckboxny1[this.indexny]);
                this.checkedtextny[this.indexny] = text;
            },


            //扉页工艺_取消
            cancel3(index) {
                console.log(index)
                this.centerDialogVisible6 = false
                this.checkedtextfy[this.indexfy] = ''
                this.checkedCheckboxfy[this.indexfy] = ''
                this.checkedfy[this.indexfy] = false
            },

            //扉页工艺_右上角关闭
            handleClose3() {
                this.centerDialogVisible6 = false
                this.checkedtextfy[this.indexfy] = ''
                this.checkedCheckboxfy[this.indexfy] = ''
                this.checkedfy[this.indexfy] = false
            },

            //扉页工艺_提交
            Submitfy(index) {
                console.log(index);
                // this.checkedCheckboxfy = this.checkedCheckboxfy1
                this.checkedCheckboxfy[this.indexfy] = this.checkedCheckboxfy1[this.indexfy]

                console.log(this.checkedCheckboxfy1);
                console.log(this.checkedCheckboxfy1[this.indexfy]);

                this.checkedfy[this.indexfy] = true
                // this.technologyfy[index] = this.checkedCheckboxfy;
                // console.log(this.technologyfy);

                for (let c = 0; c < this.checkedCheckboxfy[this.indexfy].length; c++) {
                    for (let a = 0; a < this.setacreagefy0.length; a++) {
                        if (this.setacreagefy0[a].setacreage == 1) {
                            if (this.checkedCheckboxfy[this.indexfy][c].id == this.setacreagefy0[a].processesSetList[0].id) {
                                console.log(this.setacreagefy0[a].inSize)
                                this.checkedCheckboxfy[this.indexfy][c].inSize = this.setacreagefy0[a].inSize
                            }
                        }
                    }
                }

                // this.checkedCheckboxfy = this.checkedCheckboxfy1

                let text = [];
                // console.log(this.setacreagefy0);
                console.log(this.checkedCheckboxfy[this.indexfy]);
                for (let a = 0; a < this.checkedCheckboxfy[this.indexfy].length; a++) {
                    for (let b = 0; b < this.setacreagefy0.length; b++) {
                        for (let c = 0; c < this.setacreagefy0[b].processesSetList.length; c++) {
                            if (this.setacreagefy0[b].setacreage == 0) {
                                if (this.checkedCheckboxfy[this.indexfy][a].id == this.setacreagefy0[b].processesSetList[c].id) {
                                    console.log(this.setacreagefy0[b].processesSetList[c].name);
                                    text.push(this.setacreagefy0[b].processesSetList[c].name);
                                }
                            } else if (this.setacreagefy0[b].setacreage == 1) {
                                if (this.checkedCheckboxfy[this.indexfy][a].id == this.setacreagefy0[b].processesSetList[c].id) {
                                    let size = []
                                    for (let d = 0; d < this.checkedCheckboxfy[this.indexfy][b].inSize.length; d++) {
                                        // if(this.checkedCheckboxfy[a].inSize[d].width==undefined||this.checkedCheckboxfy[a].inSize[d].length==undefined){
                                        //     this.$message.error('请填写完整内容');
                                        // }
                                        console.log(this.setacreagefy0[b].processesSetList[c].name);
                                        size.push(
                                            this.checkedCheckboxfy[this.indexfy][a].inSize[d].width + "*" + this.checkedCheckboxfy[this.indexfy][a].inSize[d].length
                                        );
                                    }
                                    text.push(
                                        this.setacreagefy0[b].processesSetList[c].name + "(" + size + ")"
                                    );
                                }
                            }   else if (this.setacreagefy0[b].setacreage == 3) {
                                if (this.checkedCheckboxfy[this.indexfy][a].id == this.setacreagefy0[b].processesSetList[c].id) {
                                    text.push(
                                        this.setacreagefy0[b].processesSetList[c].name + "(" + this.setacreagefy0[b].desc + ")"
                                    );
                                }
                            }
                        }
                    }
                }
              for (let b = 0; b < this.setacreagefy0.length; b++) {

                if (this.setacreagefy0[b].setacreage == 2) {
                  for (let i = 0; i < Number(this.setacreagefy0[b].num); i++) {
                    text.push(
                        this.setacreagefy0[b].inSize[i].modelsName + "(" +
                        this.setacreagefy0[b].inSize[i].width + "*" + this.setacreagefy0[b].inSize[i].length+")"
                    )
                  }
                }

              }
                console.log(text);
                console.log(this.checkedCheckboxfy);
                console.log(this.checkedCheckboxfy[this.indexfy]);
                console.log(this.checkedCheckboxfy1[this.indexfy]);
                this.checkedtextfy[this.indexfy] = text;
            },


            //插页工艺_取消
            cancel4(index) {
                console.log(index)
                this.centerDialogVisible7 = false
                this.checkedtextcy[this.indexcy] = ''
                this.checkedCheckboxcy[this.indexcy] = ''
                this.checkedcy[this.indexcy] = false
            },

            //插页工艺_右上角关闭
            handleClose4() {
                this.centerDialogVisible7 = false
                this.checkedtextcy[this.indexcy] = ''
                this.checkedCheckboxcy[this.indexcy] = ''
                this.checkedcy[this.indexcy] = false
            },

            //插页工艺_提交
            Submitcy(index) {
                console.log(index);

                // this.checkedCheckboxcy = this.checkedCheckboxcy1
                this.checkedCheckboxcy[this.indexcy] = this.checkedCheckboxcy1[this.indexcy]

                this.checkedcy[this.indexcy] = true
                // this.technologycy[index] = this.checkedCheckboxcy;
                // console.log(this.technologycy);

                for (let c = 0; c < this.checkedCheckboxcy[this.indexcy].length; c++) {
                    for (let a = 0; a < this.setacreagecy0.length; a++) {
                        if (this.setacreagecy0[a].setacreage == 1) {
                            if (this.checkedCheckboxcy[this.indexcy][c].id == this.setacreagecy0[a].processesSetList[0].id) {
                                console.log(this.setacreagecy0[a].inSize)
                                this.checkedCheckboxcy[this.indexcy][c].inSize = this.setacreagecy0[a].inSize
                            }
                        }
                    }
                }

                // this.checkedCheckboxcy = this.checkedCheckboxcy1

                let text = [];
                console.log(this.setacreagecy0);
                console.log(this.checkedCheckboxcy[this.indexcy]);
                for (let a = 0; a < this.checkedCheckboxcy[this.indexcy].length; a++) {
                    for (let b = 0; b < this.setacreagecy0.length; b++) {
                        for (let c = 0; c < this.setacreagecy0[b].processesSetList.length; c++) {
                            if (this.setacreagecy0[b].setacreage == 0) {
                                if (this.checkedCheckboxcy[this.indexcy][a].id == this.setacreagecy0[b].processesSetList[c].id) {
                                    console.log(this.setacreagecy0[b].processesSetList[c].name);
                                    text.push(this.setacreagecy0[b].processesSetList[c].name);
                                }
                            } else if (this.setacreagecy0[b].setacreage == 1) {
                                if (this.checkedCheckboxcy[this.indexcy][a].id == this.setacreagecy0[b].processesSetList[c].id) {
                                    let size = []
                                    for (let d = 0; d < this.checkedCheckboxcy[this.indexcy][b].inSize.length; d++) {
                                        // if(this.checkedCheckboxcy[a].inSize[d].width==undefined||this.checkedCheckboxcy[a].inSize[d].length==undefined){
                                        //     this.$message.error('请填写完整内容');
                                        // }
                                        console.log(this.setacreagecy0[b].processesSetList[c].name);
                                        size.push(
                                            this.checkedCheckboxcy[this.indexcy][a].inSize[d].width + "*" + this.checkedCheckboxcy[this.indexcy][a].inSize[d].length
                                        )
                                    }
                                    text.push(
                                        this.setacreagecy0[b].processesSetList[c].name + "(" + size + ")"
                                    );
                                }
                            }   else if (this.setacreagecy0[b].setacreage == 3) {
                                if (this.checkedCheckboxcy[this.indexcy][a].id == this.setacreagecy0[b].processesSetList[c].id) {
                                    let size = []
                                    for (let d = 0; d < this.checkedCheckboxcy[b].inSize.length; d++) {
                                        text.push(
                                            this.setacreagecy0[b].processesSetList[c].name + "(" + this.setacreagecy0[b].desc + ")"
                                        );
                                    }
                                }
                            }
                        }
                    }
                }
                for (let b = 0; b < this.setacreagely0.length; b++) {

                  if (this.setacreagecy0[b].setacreage == 2) {
                    for (let i = 0; i < Number(this.setacreagecy0[b].num); i++) {
                      text.push(
                          this.setacreagecy0[b].inSize[i].modelsName + "(" +
                          this.setacreagecy0[b].inSize[i].width + "*" + this.setacreagecy0[b].inSize[i].length+")"
                      )
                    }
                  }

                }
                console.log(text);
                this.checkedtextcy[this.indexcy] = text;
            },


            //拉页工艺_取消
            cancel5(index) {
                console.log(index)
                this.centerDialogVisible8 = false
                this.checkedtextly[this.indexly] = ''
                this.checkedCheckboxly[this.indexly] = ''
                this.checkedly[this.indexly] = false
            },

            //拉页工艺_右上角关闭
            handleClose5() {
                this.centerDialogVisible8 = false
                this.checkedtextly[this.indexly] = ''
                this.checkedCheckboxly[this.indexly] = ''
                this.checkedly[this.indexly] = false
            },

            //拉页工艺_提交
            Submitly(index) {
                console.log(index);

                // this.checkedCheckboxly = this.checkedCheckboxly1
                this.checkedCheckboxly[this.indexly] = this.checkedCheckboxly1[this.indexly]

                this.checkedly[this.indexly] = true
                // this.technologyly[index] = this.checkedCheckboxly;
                // console.log(this.technologyly);

                for (let c = 0; c < this.checkedCheckboxly[this.indexly].length; c++) {
                    for (let a = 0; a < this.setacreagely0.length; a++) {
                        if (this.setacreagely0[a].setacreage == 1) {
                            if (this.checkedCheckboxly[this.indexly][c].id == this.setacreagely0[a].processesSetList[0].id) {
                                console.log(this.setacreagely0[a].inSize)
                                this.checkedCheckboxly[this.indexly][c].inSize = this.setacreagely0[a].inSize
                            }
                        }
                    }
                }

                // this.checkedCheckboxly = this.checkedCheckboxly1

                let text = [];
                console.log(this.setacreagely0);
                console.log(this.checkedCheckboxly);
                console.log(this.checkedCheckboxly[this.indexly]);
                for (let a = 0; a < this.checkedCheckboxly[this.indexly].length; a++) {
                    for (let b = 0; b < this.setacreagely0.length; b++) {
                        for (let c = 0; c < this.setacreagely0[b].processesSetList.length; c++) {
                            if (this.setacreagely0[b].setacreage == 0) {
                                if (this.checkedCheckboxly[this.indexly][a].id == this.setacreagely0[b].processesSetList[c].id) {
                                    console.log(this.setacreagely0[b].processesSetList[c].name);
                                    text.push(this.setacreagely0[b].processesSetList[c].name);
                                }
                            } else if (this.setacreagely0[b].setacreage == 1) {
                                if (this.checkedCheckboxly[this.indexly][a].id == this.setacreagely0[b].processesSetList[c].id) {
                                    let size = []
                                    for (let d = 0; d < this.checkedCheckboxly[this.indexly][b].inSize.length; d++) {
                                        console.log(this.setacreagely0[b].processesSetList[c].name);
                                        size.push(
                                            this.checkedCheckboxly[this.indexly][a].inSize[d].width + "*" + this.checkedCheckboxly[this.indexly][a].inSize[d].length
                                        )
                                    }
                                    text.push(
                                        this.setacreagely0[b].processesSetList[c].name + "(" + size + ")"
                                    );
                                }
                            }   else if (this.setacreagely0[b].setacreage == 3) {
                                if (this.checkedCheckboxly[this.indexly][a].id == this.setacreagely0[b].processesSetList[c].id) {
                                    text.push(
                                        this.setacreagely0[b].processesSetList[c].name + "(" + this.setacreagely0[b].desc + ")"
                                    );
                                }
                            }
                        }
                    }
                }


              for (let b = 0; b < this.setacreagely0.length; b++) {

                if (this.setacreagely0[b].setacreage == 2) {
                  for (let i = 0; i < Number(this.setacreagely0[b].num); i++) {
                    text.push(
                        this.setacreagely0[b].inSize[i].modelsName + "(" +
                        this.setacreagely0[b].inSize[i].width + "*" + this.setacreagely0[b].inSize[i].length+")"
                    )
                  }
                }

              }


                console.log(text);
                this.checkedtextly[this.indexly] = text;
            },


            //画册加护封_其他工艺_取消
            cancel6(index) {
                console.log(index)
                this.centerDialogVisible9 = false
                this.checkedtextDustJacket[0] = ''
                this.checkedCheckboxhf[0] = ''
                this.checkedDustJacket[0] = false
            },

            //画册加护封_其他工艺_右上角关闭
            handleClose6() {
                this.centerDialogVisible9 = false
                this.checkedtextDustJacket[0] = ''
                this.checkedCheckboxhf[0] = ''
                this.checkedDustJacket[0] = false
            },

            //画册加护封_其他工艺_提交
            SubmitDustJacket(index) {
                console.log(index);
                // console.log(value)
                this.checkedDustJacket[0] = true
                // this.technologyhf[index] = this.checkedCheckboxhf;
                // console.log(this.technologyhf);

                for (let c = 0; c < this.checkedCheckboxhf.length; c++) {
                    for (let a = 0; a < this.setacreagehf0.length; a++) {
                        if (this.setacreagehf0[a].setacreage == 1) {
                            if (this.checkedCheckboxhf[c].id == this.setacreagehf0[a].processesSetList[0].id) {
                                console.log(this.setacreagehf0[a].inSize)
                                this.checkedCheckboxhf[c].inSize = this.setacreagehf0[a].inSize
                            }
                        }
                    }
                }

                this.checkedCheckboxhf = this.checkedCheckboxhf1

                let text = [];
                console.log(this.setacreagehf0);
                console.log(this.checkedCheckboxhf);
                for (let a = 0; a < this.checkedCheckboxhf.length; a++) {
                    for (let b = 0; b < this.setacreagehf0.length; b++) {
                        for (let c = 0; c < this.setacreagehf0[b].processesSetList.length; c++) {
                            if (this.setacreagehf0[b].setacreage == 0) {
                                if (this.checkedCheckboxhf[a].id == this.setacreagehf0[b].processesSetList[c].id) {
                                    console.log(this.setacreagehf0[b].processesSetList[c].name);
                                    text.push(this.setacreagehf0[b].processesSetList[c].name);
                                }
                            } else if (this.setacreagehf0[b].setacreage == 1) {
                                if (this.checkedCheckboxhf[a].id == this.setacreagehf0[b].processesSetList[c].id) {
                                    let size = []
                                    for (let d = 0; d < this.checkedCheckboxhf[b].inSize.length; d++) {
                                        console.log(this.setacreagehf0[b].processesSetList[c].name);
                                        size.push(
                                            this.checkedCheckboxhf[a].inSize[d].width + "*" + this.checkedCheckboxhf[a].inSize[d].length
                                        )
                                    }
                                    text.push(
                                        this.setacreagehf0[b].processesSetList[c].name + "(" + size + ")"
                                    );
                                }
                            }
                            // else if (this.setacreagehf0[b].setacreage == 2) {
                            //     if (this.checkedCheckboxhf[a].id == this.setacreagehf0[b].processesSetList[c].id) {
                            //         let size = []
                            //         for (let d = 0; d < this.checkedCheckboxhf[b].inSize.length; d++) {
                            //             console.log(this.setacreagehf0[b].processesSetList[c].name);
                            //             size.push(
                            //                 this.checkedCheckboxhf[a].inSize[d].width + "*" + this.checkedCheckboxhf[a].inSize[d].length
                            //             )
                            //         }
                            //         text.push(
                            //             this.setacreagehf0[b].processesSetList[c].name + "(" + size + ")" + this.setacreagehf0[b].desc
                            //         );
                            //     }
                            // }
                            else if (this.setacreagehf0[b].setacreage == 3) {
                                if (this.checkedCheckboxhf[a].id == this.setacreagehf0[b].processesSetList[c].id) {
                                    text.push(
                                        this.setacreagehf0[b].processesSetList[c].name + "(" + this.setacreagehf0[b].desc + ")"
                                    );
                                }
                            }
                        }
                    }
                }


               for (let b = 0; b < this.setacreagehf0.length; b++) {

                if (this.setacreagehf0[b].setacreage == 2) {
                  for (let i = 0; i < Number(this.setacreagehf0[b].num); i++) {
                    text.push(
                        this.setacreagehf0[b].inSize[i].modelsName + "(" +
                        this.setacreagehf0[b].inSize[i].width + "*" + this.setacreagehf0[b].inSize[i].length+")"
                    )
                  }
                }

              }

                console.log(text);
                this.checkedtextDustJacket[0] = text;
            },


            //画册加腰带_其他工艺_取消
            cancel7(index) {
                console.log(index)
                this.centerDialogVisible10 = false
                this.checkedtextBelt[0] = ''
                this.checkedCheckboxyd[0] = ''
                this.checkedBelt[0] = false
            },

            //画册加腰带_其他工艺_右上角关闭
            handleClose7() {
                this.centerDialogVisible10 = false
                this.checkedtextBelt[0] = ''
                this.checkedCheckboxyd[0] = ''
                this.checkedBelt[0] = false
            },

            //画册加腰带_其他工艺_提交
            SubmitBelt(index) {
                console.log(index);
                // console.log(value)
                this.checkedBelt[0] = true
                // this.technologyyd[index] = this.checkedCheckboxyd;
                // console.log(this.technologyyd);

                for (let c = 0; c < this.checkedCheckboxyd.length; c++) {
                    for (let a = 0; a < this.setacreageyd0.length; a++) {
                        if (this.setacreageyd0[a].setacreage == 1) {
                            if (this.checkedCheckboxyd[c].id == this.setacreageyd0[a].processesSetList[0].id) {
                                console.log(this.setacreageyd0[a].inSize)
                                this.checkedCheckboxyd[c].inSize = this.setacreageyd0[a].inSize
                            }
                        }
                    }
                }

                this.checkedCheckboxyd = this.checkedCheckboxyd1

                let text = [];
                console.log(this.setacreageyd0);
                console.log(this.checkedCheckboxyd);
                for (let a = 0; a < this.checkedCheckboxyd.length; a++) {
                    for (let b = 0; b < this.setacreageyd0.length; b++) {
                        for (let c = 0; c < this.setacreageyd0[b].processesSetList.length; c++) {
                            if (this.setacreageyd0[b].setacreage == 0) {
                                if (this.checkedCheckboxyd[a].id == this.setacreageyd0[b].processesSetList[c].id) {
                                    console.log(this.setacreageyd0[b].processesSetList[c].name);
                                    text.push(this.setacreageyd0[b].processesSetList[c].name);
                                }
                            } else if (this.setacreageyd0[b].setacreage == 1) {
                                if (this.checkedCheckboxyd[a].id == this.setacreageyd0[b].processesSetList[c].id) {
                                    let size = []
                                    for (let d = 0; d < this.checkedCheckboxyd[b].inSize.length; d++) {
                                        console.log(this.setacreageyd0[b].processesSetList[c].name);
                                        size.push(this.checkedCheckboxyd[a].inSize[d].width + "*" + this.checkedCheckboxyd[a].inSize[d].length
                                        )
                                    }
                                    text.push(
                                        this.setacreageyd0[b].processesSetList[c].name + "(" + size + ")"
                                    );
                                }
                            }   else if (this.setacreageyd0[b].setacreage == 3) {
                                if (this.checkedCheckboxyd[a].id == this.setacreageyd0[b].processesSetList[c].id) {
                                    text.push(
                                        this.setacreageyd0[b].processesSetList[c].name + "(" + this.setacreageyd0[b].desc + ")"
                                    );
                                }
                            }
                        }
                    }
                }

              for (let b = 0; b < this.setacreageyd0.length; b++) {

                if (this.setacreageyd0[b].setacreage == 2) {
                  for (let i = 0; i < Number(this.setacreageyd0[b].num); i++) {
                    text.push(
                        this.setacreageyd0[b].inSize[i].modelsName + "(" +
                        this.setacreageyd0[b].inSize[i].width + "*" + this.setacreageyd0[b].inSize[i].length +")"
                    )
                  }
                }

              }

                console.log(text);
                this.checkedtextBelt[0] = text;
            },


            //自定义部件_其他工艺_取消
            cancel8(index) {
                console.log(index)
                this.centerDialogVisible11 = false
                this.checkedtextzdy[0] = ''
                this.checkedCheckboxzdy[0] = ''
                this.checkedzdy[0] = false
            },

            //自定义部件_其他工艺_右上角关闭
            handleClose8() {
                this.centerDialogVisible11 = false
                this.checkedtextzdy[0] = ''
                this.checkedCheckboxzdy[0] = ''
                this.checkedzdy[0] = false
            },

            //自定义部件_其他工艺_提交
            Submitzdy(index) {
                console.log(index);
                // console.log(value)
                this.checkedzdy[0] = true
                // this.technologyzdy[index] = this.checkedCheckboxzdy;
                // console.log(this.technologyzdy);

                for (let c = 0; c < this.checkedCheckboxzdy.length; c++) {
                    for (let a = 0; a < this.setacreagezdy0.length; a++) {
                        if (this.setacreagezdy0[a].setacreage == 1) {
                            if (this.checkedCheckboxzdy[c].id == this.setacreagezdy0[a].processesSetList[0].id) {
                                console.log(this.setacreagezdy0[a].inSize)
                                this.checkedCheckboxzdy[c].inSize = this.setacreagezdy0[a].inSize
                            }
                        }
                    }
                }

                this.checkedCheckboxzdy = this.checkedCheckboxzdy1

                let text = [];
                console.log(this.setacreagezdy0);
                console.log(this.checkedCheckboxzdy);
                for (let a = 0; a < this.checkedCheckboxzdy.length; a++) {
                    for (let b = 0; b < this.setacreagezdy0.length; b++) {
                        for (let c = 0; c < this.setacreagezdy0[b].processesSetList.length; c++) {
                            if (this.setacreagezdy0[b].setacreage == 0) {
                                if (this.checkedCheckboxzdy[a].id == this.setacreagezdy0[b].processesSetList[c].id) {
                                    console.log(this.setacreagezdy0[b].processesSetList[c].name);
                                    text.push(this.setacreagezdy0[b].processesSetList[c].name);
                                }
                            } else if (this.setacreagezdy0[b].setacreage == 1) {
                                if (this.checkedCheckboxzdy[a].id == this.setacreagezdy0[b].processesSetList[c].id) {
                                    let size = []
                                    for (let d = 0; d < this.checkedCheckboxzdy[b].inSize.length; d++) {
                                        console.log(this.setacreagezdy0[b].processesSetList[c].name);
                                        size.push(
                                            this.checkedCheckboxzdy[a].inSize[d].width + "*" + this.checkedCheckboxzdy[a].inSize[d].length
                                        )
                                    }
                                    text.push(
                                        this.setacreagezdy0[b].processesSetList[c].name + "(" + size + ")"
                                    );
                                }
                            }   else if (this.setacreagezdy0[b].setacreage == 3) {
                                if (this.checkedCheckboxzdy[a].id == this.setacreagezdy0[b].processesSetList[c].id) {
                                    text.push(
                                        this.setacreagezdy0[b].processesSetList[c].name + "(" + this.setacreagezdy0[b].desc + ")"
                                    );
                                }
                            }
                        }
                    }
                }

              for (let b = 0; b < this.setacreagezdy0.length; b++) {

                if (this.setacreagezdy0[b].setacreage == 2) {
                  for (let i = 0; i < Number(this.setacreagezdy0[b].num); i++) {
                    text.push(
                        this.setacreagezdy0[b].inSize[i].modelsName + "(" +
                        this.setacreagezdy0[b].inSize[i].width + "*" + this.setacreagezdy0[b].inSize[i].length +")"
                    )
                  }
                }

              }
                console.log(text);
                this.checkedtextzdy[0] = text;
            },


            //多选框发送改变_封面
            handleCheckedChange(value) {
                let arr = [];
                console.log(value)
                console.log(this.checkedCheckbox1)
               if (value.length != 0) {

                for (let v = 0; v < value.length; v++) {
                  let flag = false
                  for (let i = 0; i < this.setacreage0.length; i++) {
                    if (value[v] == this.setacreage0[i].id) {
                      flag = true  // 以前存在
                      arr.push(this.setacreage0[i])
                      break
                    }
                  }
                  if (!flag) { // 以前不存在的
                    for (let i = 0; i < this.processList.length; i++) {
                      if (value[v] == this.processList[i].id) {   //取最后加入的那个
                        arr.push({
                          name: this.processList[i].name,
                          id: this.processList[i].id,
                          setacreage: this.processList[i].setacreage,
                          processesSetList: this.processList[i].processesSetList,
                          num: 1,
                          desc: "",
                          models: this.processList[i].setacreage == '2' ? this.processList[i].id:  this.processList[i].processesSetList[0].id,
                          inSize: this.processList[i].setacreage == '2' ? [{
                            'id': this.processList[i].processesSetList[0].id,
                            'models': this.processList[i].processesSetList[0].id + ','+ this.processList[i].processesSetList[0].name,
                            'modelsName': this.processList[i].processesSetList[0].name,
                            'width': '100',
                            'length': '100'
                          }] : [{'width': '', 'length': ''}],
                        });
                      }
                    }
                  }
                }
                this.setacreage0 = arr
                console.log('1:' , value)

              } else {
                this.setacreage0 = [];
              }
                //
                //
                //setacreage:0  默认下拉值
                let arrcheckedCheckbox = [];
                // this.models = [];
                for (let b = 0; b < this.setacreage0.length; b++) {
                    if (this.setacreage0[b].setacreage == 0) {
                        arrcheckedCheckbox.push({
                            id: this.setacreage0[b].models,
                        });
                    } else if (this.setacreage0[b].setacreage == 1) {

                        arrcheckedCheckbox.push({
                            id: this.setacreage0[b].models,
                            inSize: this.setacreage0[b].inSize,
                        });
                        // arrcheckedCheckbox.inSize.push(this.setacreage0[b].inSize)
                    }
                    else if (this.setacreage0[b].setacreage == 2) {
                        arrcheckedCheckbox.push({
                            id: this.setacreage0[b].id,
                            inSize: this.setacreage0[b].inSize,
                        });
                    }
                    else if (this.setacreage0[b].setacreage == 3) {
                        arrcheckedCheckbox.push({
                            id: this.setacreage0[b].models,
                            desc: this.setacreage0[b].desc,
                        });
                    }
                }
                console.log(this.checkedCheckbox)
                for (let b = 0; b < this.checkedCheckbox.length; b++) {
                    for (let a = 0; a < arrcheckedCheckbox.length; a++) {
                        if (arrcheckedCheckbox[a].id == this.checkedCheckbox[b].id) {
                            arrcheckedCheckbox[a].inSize = this.checkedCheckbox[b].inSize
                        }
                    }
                    for (let c=0;c<this.setacreage0.length;c++){
                        if (this.setacreage0[c].models==this.checkedCheckbox[b].id){
                            this.setacreage0[c].inSize = this.checkedCheckbox[b].inSize
                        }
                    }
                }
                console.log(this.setacreage0)
                this.checkedCheckbox = arrcheckedCheckbox;
                this.checkedCheckbox1 = arrcheckedCheckbox;
                console.log(arrcheckedCheckbox);
            },

            //多选框发送改变_内页
            handleCheckedChangeny(value) {
                let arr = [];
                console.log(value)
                console.log(this.indexny)
                console.log(this.checkedCheckboxny1)
                console.log(this.checkedCheckboxny1[this.indexny])
                // console.log(this.form.checkedCheckboxny)
              if (value.length != 0) {

                for (let v = 0; v < value.length; v++) {
                  let flag = false
                  for (let i = 0; i < this.setacreageny0.length; i++) {
                    if (value[v] == this.setacreageny0[i].id) {
                      flag = true  // 以前存在
                      arr.push(this.setacreageny0[i])
                      break
                    }
                  }
                  if (!flag) { // 以前不存在的
                    for (let i = 0; i < this.pagenumberprocessList.length; i++) {
                      if (value[v] == this.pagenumberprocessList[i].id) {   //取最后加入的那个
                        arr.push({
                          name: this.pagenumberprocessList[i].name,
                          id: this.pagenumberprocessList[i].id,
                          setacreage: this.pagenumberprocessList[i].setacreage,
                          processesSetList: this.pagenumberprocessList[i].processesSetList,
                          num: 1,
                          desc: "",
                          models: this.pagenumberprocessList[i].setacreage == '2' ? this.pagenumberprocessList[i].id:  this.pagenumberprocessList[i].processesSetList[0].id,
                          inSize: this.pagenumberprocessList[i].setacreage == '2' ? [{
                            'id': this.pagenumberprocessList[i].processesSetList[0].id,
                            'models': this.pagenumberprocessList[i].processesSetList[0].id + ','+ this.pagenumberprocessList[i].processesSetList[0].name,
                            'modelsName': this.pagenumberprocessList[i].processesSetList[0].name,
                            'width': '100',
                            'length': '100'
                          }] : [{'width': '', 'length': ''}],
                        });
                      }
                    }
                  }
                }
                this.setacreageny0 = arr
                console.log('1:' , value)

              } else {
                this.setacreageny0 = [];
              }

                //setacreage:0  默认下拉值
                let arrcheckedCheckbox = [];
                for (let b = 0; b < this.setacreageny0.length; b++) {
                    if (this.setacreageny0[b].setacreage == 0) {

                        arrcheckedCheckbox.push({
                            id: this.setacreageny0[b].models,
                        });
                    } else if (this.setacreageny0[b].setacreage == 1) {

                        arrcheckedCheckbox.push({
                            id: this.setacreageny0[b].models,
                            inSize: this.setacreageny0[b].inSize,
                        });
                        // arrcheckedCheckbox.inSize.push(this.setacreageny0[b].inSize)
                    } else if (this.setacreageny0[b].setacreage == 2) {
                        arrcheckedCheckbox.push({
                            id: this.setacreageny0[b].id,
                            inSize: this.setacreageny0[b].inSize,
                        });
                    } else if (this.setacreageny0[b].setacreage == 3) {
                        arrcheckedCheckbox.push({
                            id: this.setacreageny0[b].models,
                            desc: this.setacreageny0[b].desc,
                        });
                    }
                }
                console.log(this.checkedCheckboxny)
                for (let b = 0; b < this.checkedCheckboxny.length; b++) {
                    for (let a = 0; a < arrcheckedCheckbox.length; a++) {
                        if (arrcheckedCheckbox[a].id == this.checkedCheckboxny[b].id) {
                            arrcheckedCheckbox[a].inSize = this.checkedCheckboxny[b].inSize
                        }
                    }
                    for (let c=0;c<this.setacreageny0.length;c++){
                        if (this.setacreageny0[c].models==this.checkedCheckboxny[b].id){
                            this.setacreageny0[c].inSize = this.checkedCheckboxny[b].inSize
                        }
                    }
                }
                console.log(this.setacreageny0)
                this.checkedCheckboxny[this.indexny] = arrcheckedCheckbox;
                this.checkedCheckboxny1[this.indexny] = arrcheckedCheckbox;
                console.log(this.checkedCheckboxny);
                console.log(arrcheckedCheckbox);
            },

            //多选框发送改变_扉页
            handleCheckedChangefy(value) {
                let arr = [];
                console.log(value)
                console.log(this.checkedCheckboxfy1)
                console.log(this.checkedCheckboxfy1[this.indexfy])
              if (value.length != 0) {

                for (let v = 0; v < value.length; v++) {
                  let flag = false
                  for (let i = 0; i < this.setacreagefy0.length; i++) {
                    if (value[v] == this.setacreagefy0[i].id) {
                      flag = true  // 以前存在
                      arr.push(this.setacreagefy0[i])
                      break
                    }
                  }
                  if (!flag) { // 以前不存在的
                    for (let i = 0; i < this.pagenumberprocessList.length; i++) {
                      if (value[v] == this.pagenumberprocessList[i].id) {   //取最后加入的那个
                        arr.push({
                          name: this.pagenumberprocessList[i].name,
                          id: this.pagenumberprocessList[i].id,
                          setacreage: this.pagenumberprocessList[i].setacreage,
                          processesSetList: this.pagenumberprocessList[i].processesSetList,
                          num: 1,
                          desc: "",
                          models: this.pagenumberprocessList[i].setacreage == '2' ? this.pagenumberprocessList[i].id:  this.pagenumberprocessList[i].processesSetList[0].id,
                          inSize: this.pagenumberprocessList[i].setacreage == '2' ? [{
                            'id': this.pagenumberprocessList[i].processesSetList[0].id,
                            'models': this.pagenumberprocessList[i].processesSetList[0].id + ','+ this.pagenumberprocessList[i].processesSetList[0].name,
                            'modelsName': this.pagenumberprocessList[i].processesSetList[0].name,
                            'width': '100',
                            'length': '100'
                          }] : [{'width': '', 'length': ''}],
                        });
                      }
                    }
                  }
                }
                this.setacreagefy0 = arr
                console.log('1:' , value)

              } else {
                this.setacreagefy0 = [];
              }

                //setacreage:0  默认下拉值
                let arrcheckedCheckbox = [];
                // this.models = [];
                for (let b = 0; b < this.setacreagefy0.length; b++) {
                    if (this.setacreagefy0[b].setacreage == 0) {
                        arrcheckedCheckbox.push({
                            id: this.setacreagefy0[b].models,
                        });
                    } else if (this.setacreagefy0[b].setacreage == 1) {
                        arrcheckedCheckbox.push({
                            id: this.setacreagefy0[b].models,
                            inSize: this.setacreagefy0[b].inSize,
                        });
                        // arrcheckedCheckbox.inSize.push(this.setacreagefy0[b].inSize)
                    } else if (this.setacreagefy0[b].setacreage == 2) {
                        console.log(this.setacreagefy0[b].processesSetList[0].id);
                        arrcheckedCheckbox.push({
                            id: this.setacreagefy0[b].id,
                            inSize: this.setacreagefy0[b].inSize,
                        });
                    } else if (this.setacreagefy0[b].setacreage == 3) {
                        arrcheckedCheckbox.push({
                            id: this.setacreagefy0[b].models,
                            desc: this.setacreagefy0[b].desc,
                        });
                    }
                }
                console.log(this.checkedCheckboxfy)
                for (let b = 0; b < this.checkedCheckboxfy.length; b++) {
                    for (let a = 0; a < arrcheckedCheckbox.length; a++) {
                        if (arrcheckedCheckbox[a].id == this.checkedCheckboxfy[b].id) {
                            arrcheckedCheckbox[a].inSize = this.checkedCheckboxfy[b].inSize
                        }
                    }
                    for (let c=0;c<this.setacreagefy0.length;c++){
                        if (this.setacreagefy0[c].models==this.checkedCheckboxfy[b].id){
                            this.setacreagefy0[c].inSize = this.checkedCheckboxfy[b].inSize
                        }
                    }
                }
                console.log(this.setacreagefy0)
                this.checkedCheckboxfy[this.indexfy] = arrcheckedCheckbox;
                this.checkedCheckboxfy1[this.indexfy] = arrcheckedCheckbox;
                console.log(arrcheckedCheckbox);
            },

            //多选框发送改变_插页
            handleCheckedChangecy(value) {
                let arr = [];
                console.log(value)
                console.log(this.checkedCheckboxcy1)
                console.log(this.checkedCheckboxcy1[this.indexcy])

              if (value.length != 0) {

                for (let v = 0; v < value.length; v++) {
                  let flag = false
                  for (let i = 0; i < this.setacreagecy0.length; i++) {
                    if (value[v] == this.setacreagecy0[i].id) {
                      flag = true  // 以前存在
                      arr.push(this.setacreagecy0[i])
                      break
                    }
                  }
                  if (!flag) { // 以前不存在的
                    for (let i = 0; i < this.pagenumberprocessList.length; i++) {
                      if (value[v] == this.pagenumberprocessList[i].id) {   //取最后加入的那个
                        arr.push({
                          name: this.pagenumberprocessList[i].name,
                          id: this.pagenumberprocessList[i].id,
                          setacreage: this.pagenumberprocessList[i].setacreage,
                          processesSetList: this.pagenumberprocessList[i].processesSetList,
                          num: 1,
                          desc: "",
                          models: this.pagenumberprocessList[i].setacreage == '2' ? this.pagenumberprocessList[i].id:  this.pagenumberprocessList[i].processesSetList[0].id,
                          inSize: this.pagenumberprocessList[i].setacreage == '2' ? [{
                            'id': this.pagenumberprocessList[i].processesSetList[0].id,
                            'models': this.pagenumberprocessList[i].processesSetList[0].id + ','+ this.pagenumberprocessList[i].processesSetList[0].name,
                            'modelsName': this.pagenumberprocessList[i].processesSetList[0].name,
                            'width': '100',
                            'length': '100'
                          }] : [{'width': '', 'length': ''}],
                        });
                      }
                    }
                  }
                }
                this.setacreagecy0 = arr
                console.log('1:' , value)

              } else {
                this.setacreagecy0 = [];
              }


                //setacreage:0  默认下拉值
                let arrcheckedCheckbox = [];
                for (let b = 0; b < this.setacreagecy0.length; b++) {
                    if (this.setacreagecy0[b].setacreage == 0) {
                        arrcheckedCheckbox.push({
                            id: this.setacreagecy0[b].models,
                        });
                    } else if (this.setacreagecy0[b].setacreage == 1) {
                        arrcheckedCheckbox.push({
                            id: this.setacreagecy0[b].models,
                            inSize: this.setacreagecy0[b].inSize,
                        });
                        // arrcheckedCheckbox.inSize.push(this.setacreagecy0[b].inSize)
                    } else if (this.setacreagecy0[b].setacreage == 2) {
                        arrcheckedCheckbox.push({
                            id: this.setacreagecy0[b].id,

                            inSize: this.setacreagecy0[b].inSize,
                        });
                    } else if (this.setacreagecy0[b].setacreage == 3) {
                        arrcheckedCheckbox.push({
                            id: this.setacreagecy0[b].models,
                            desc: this.setacreagecy0[b].desc,
                        });
                    }
                }
                console.log(this.checkedCheckboxcy)
                for (let b = 0; b < this.checkedCheckboxcy.length; b++) {
                    for (let a = 0; a < arrcheckedCheckbox.length; a++) {
                        if (arrcheckedCheckbox[a].id == this.checkedCheckboxcy[b].id) {
                            arrcheckedCheckbox[a].inSize = this.checkedCheckboxcy[b].inSize
                        }
                    }
                    for (let c=0;c<this.setacreagecy0.length;c++){
                        if (this.setacreagecy0[c].models==this.checkedCheckboxcy[b].id){
                            this.setacreagecy0[c].inSize = this.checkedCheckboxcy[b].inSize
                        }
                    }
                }
                console.log(this.setacreagecy0)
                this.checkedCheckboxcy[this.indexcy] = arrcheckedCheckbox;
                this.checkedCheckboxcy1[this.indexcy] = arrcheckedCheckbox;
                console.log(arrcheckedCheckbox);
            },

            //多选框发送改变_拉页
            handleCheckedChangely(value) {
                let arr = [];
                console.log(value)
                console.log(this.checkedCheckboxly1)
                console.log(this.checkedCheckboxly1[this.indexly])

              if (value.length != 0) {

                for (let v = 0; v < value.length; v++) {
                  let flag = false
                  for (let i = 0; i < this.setacreagely0.length; i++) {
                    if (value[v] == this.setacreagely0[i].id) {
                      flag = true  // 以前存在
                      arr.push(this.setacreagely0[i])
                      break
                    }
                  }
                  if (!flag) { // 以前不存在的
                    for (let i = 0; i < this.pagenumberprocessList.length; i++) {
                      if (value[v] == this.pagenumberprocessList[i].id) {   //取最后加入的那个
                        arr.push({
                          name: this.pagenumberprocessList[i].name,
                          id: this.pagenumberprocessList[i].id,
                          setacreage: this.pagenumberprocessList[i].setacreage,
                          processesSetList: this.pagenumberprocessList[i].processesSetList,
                          num: 1,
                          desc: "",
                          models: this.pagenumberprocessList[i].setacreage == '2' ? this.pagenumberprocessList[i].id:  this.pagenumberprocessList[i].processesSetList[0].id,
                          inSize: this.pagenumberprocessList[i].setacreage == '2' ? [{
                            'id': this.pagenumberprocessList[i].processesSetList[0].id,
                            'models': this.pagenumberprocessList[i].processesSetList[0].id + ','+ this.pagenumberprocessList[i].processesSetList[0].name,
                            'modelsName': this.pagenumberprocessList[i].processesSetList[0].name,
                            'width': '100',
                            'length': '100'
                          }] : [{'width': '', 'length': ''}],
                        });
                      }
                    }
                  }
                }
                this.setacreagely0 = arr
                console.log('1:' , value)

              } else {
                this.setacreagely0 = [];
              }
                //setacreage:0  默认下拉值
                let arrcheckedCheckbox = [];
                // this.models = [];
                for (let b = 0; b < this.setacreagely0.length; b++) {
                    if (this.setacreagely0[b].setacreage == 0) {
                        arrcheckedCheckbox.push({
                            id: this.setacreagely0[b].models,
                        });
                    } else if (this.setacreagely0[b].setacreage == 1) {

                        arrcheckedCheckbox.push({
                            id: this.setacreagely0[b].models,
                            inSize: this.setacreagely0[b].inSize,
                        });
                        // arrcheckedCheckbox.inSize.push(this.setacreagely0[b].inSize)
                    }
                    else if (this.setacreagely0[b].setacreage == 2) {
                      arrcheckedCheckbox.push({
                        id: this.setacreagely0[b].id,
                        inSize: this.setacreagely0[b].inSize,
                      });
                    }
                    else if (this.setacreagely0[b].setacreage == 3) {
                        arrcheckedCheckbox.push({
                            id: this.setacreagely0[b].models,
                            desc: this.setacreagely0[b].desc,
                        });
                    }
                }
                console.log(this.checkedCheckboxly)
                for (let b = 0; b < this.checkedCheckboxly.length; b++) {
                    for (let a = 0; a < arrcheckedCheckbox.length; a++) {
                        if (arrcheckedCheckbox[a].id == this.checkedCheckboxly[b].id) {
                            arrcheckedCheckbox[a].inSize = this.checkedCheckboxly[b].inSize
                        }
                    }
                    for (let c=0;c<this.setacreagely0.length;c++){
                        if (this.setacreagely0[c].models==this.checkedCheckboxly[b].id){
                            this.setacreagely0[c].inSize = this.checkedCheckboxly[b].inSize
                        }
                    }
                }
                console.log(this.setacreagely0)
                this.checkedCheckboxly[this.indexly] = arrcheckedCheckbox;
                this.checkedCheckboxly1[this.indexly] = arrcheckedCheckbox;
                console.log(arrcheckedCheckbox);
            },

            //多选框发送改变_护封
            handleCheckedChangehf(value) {
               let arr = [];
                console.log(value)
              if (value.length != 0) {

                for (let v = 0; v < value.length; v++) {
                  let flag = false
                  for (let i = 0; i < this.setacreagehf0.length; i++) {
                    if (value[v] == this.setacreagehf0[i].id) {
                      flag = true  // 以前存在
                      arr.push(this.setacreagehf0[i])
                      break
                    }
                  }
                  if (!flag) { // 以前不存在的
                    for (let i = 0; i < this.otherList.length; i++) {
                      if (value[v] == this.otherList[i].id) {   //取最后加入的那个
                        arr.push({
                          name: this.otherList[i].name,
                          id: this.otherList[i].id,
                          setacreage: this.otherList[i].setacreage,
                          processesSetList: this.otherList[i].processesSetList,
                          num: 1,
                          desc: "",
                          models: this.otherList[i].setacreage == '2' ? this.otherList[i].id:  this.otherList[i].processesSetList[0].id,
                          inSize: this.otherList[i].setacreage == '2' ? [{
                            'id': this.otherList[i].processesSetList[0].id,
                            'models': this.otherList[i].processesSetList[0].id + ','+ this.otherList[i].processesSetList[0].name,
                            'modelsName': this.otherList[i].processesSetList[0].name,
                            'width': '100',
                            'length': '100'
                          }] : [{'width': '', 'length': ''}],
                        });
                      }
                    }
                  }
                }
                this.setacreagehf0 = arr
                console.log('1:' , value)

              } else {
                this.setacreagehf0 = [];
              }


                //setacreage:0  默认下拉值
                let arrcheckedCheckbox = [];
                // this.models = [];
                for (let b = 0; b < this.setacreagehf0.length; b++) {
                    if (this.setacreagehf0[b].setacreage == 0) {

                        arrcheckedCheckbox.push({
                            id: this.setacreagehf0[b].models,
                        });
                    } else if (this.setacreagehf0[b].setacreage == 1) {

                        arrcheckedCheckbox.push({
                            id: this.setacreagehf0[b].models,
                            inSize: this.setacreagehf0[b].inSize,
                        });
                        // arrcheckedCheckbox.inSize.push(this.setacreagehf0[b].inSize)
                    }
                    else if (this.setacreagehf0[b].setacreage == 2) {
                      arrcheckedCheckbox.push({
                        id: this.setacreagehf0[b].id,
                        inSize: this.setacreagehf0[b].inSize,
                      });
                    }
                    else if (this.setacreagehf0[b].setacreage == 3) {

                        arrcheckedCheckbox.push({
                            id: this.setacreagehf0[b].models,
                            desc: this.setacreagehf0[b].desc,
                        });
                    }
                }
                console.log(this.checkedCheckboxhf)
                for (let b = 0; b < this.checkedCheckboxhf.length; b++) {
                    for (let a = 0; a < arrcheckedCheckbox.length; a++) {
                        if (arrcheckedCheckbox[a].id == this.checkedCheckboxhf[b].id) {
                            arrcheckedCheckbox[a].inSize = this.checkedCheckboxhf[b].inSize
                        }
                    }
                    for (let c=0;c<this.setacreagehf0.length;c++){
                        if (this.setacreagehf0[c].models==this.checkedCheckboxhf[b].id){
                            this.setacreagehf0[c].inSize = this.checkedCheckboxhf[b].inSize
                        }
                    }
                }
                console.log(this.setacreagehf0)
                this.checkedCheckboxhf = arrcheckedCheckbox;
                this.checkedCheckboxhf1 = arrcheckedCheckbox;
                console.log(arrcheckedCheckbox);
            },

            //多选框发送改变_腰带
            handleCheckedChangeyd(value) {
                let arr = [];
                console.log(value)
                console.log(this.checkedCheckboxyd1)
              if (value.length != 0) {

                for (let v = 0; v < value.length; v++) {
                  let flag = false
                  for (let i = 0; i < this.setacreageyd0.length; i++) {
                    if (value[v] == this.setacreageyd0[i].id) {
                      flag = true  // 以前存在
                      arr.push(this.setacreageyd0[i])
                      break
                    }
                  }
                  if (!flag) { // 以前不存在的
                    for (let i = 0; i < this.otherList.length; i++) {
                      if (value[v] == this.otherList[i].id) {   //取最后加入的那个
                        arr.push({
                          name: this.otherList[i].name,
                          id: this.otherList[i].id,
                          setacreage: this.otherList[i].setacreage,
                          processesSetList: this.otherList[i].processesSetList,
                          num: 1,
                          desc: "",
                          models: this.otherList[i].setacreage == '2' ? this.otherList[i].id: this.otherList[i].processesSetList[0].id,
                          inSize: this.otherList[i].setacreage == '2' ? [{
                            'id': this.otherList[i].processesSetList[0].id,
                            'models': this.otherList[i].processesSetList[0].id + ','+ this.otherList[i].processesSetList[0].name,
                            'modelsName': this.otherList[i].processesSetList[0].name,
                            'width': '100',
                            'length': '100'
                          }] : [{'width': '', 'length': ''}],
                        });
                      }
                    }
                  }
                }
                this.setacreageyd0 = arr
                console.log('1:' , value)

              } else {
                this.setacreageyd0 = [];
              }


                //setacreage:0  默认下拉值
                let arrcheckedCheckbox = [];
                for (let b = 0; b < this.setacreageyd0.length; b++) {
                    if (this.setacreageyd0[b].setacreage == 0) {

                        arrcheckedCheckbox.push({
                            id: this.setacreageyd0[b].models,
                        });
                    } else if (this.setacreageyd0[b].setacreage == 1) {

                        arrcheckedCheckbox.push({
                            id: this.setacreageyd0[b].models,
                            inSize: this.setacreageyd0[b].inSize,
                        });
                        // arrcheckedCheckbox.inSize.push(this.setacreageyd0[b].inSize)
                    }
                    else if (this.setacreageyd0[b].setacreage == 2) {
                      arrcheckedCheckbox.push({
                        id: this.setacreageyd0[b].id,
                        inSize: this.setacreageyd0[b].inSize,
                      });
                    }
                   else if (this.setacreageyd0[b].setacreage == 3) {
                        arrcheckedCheckbox.push({
                            id: this.setacreageyd0[b].models,
                            desc: this.setacreageyd0[b].desc,
                        });
                    }
                }
                console.log(this.checkedCheckboxyd)
                for (let b = 0; b < this.checkedCheckboxyd.length; b++) {
                    for (let a = 0; a < arrcheckedCheckbox.length; a++) {
                        if (arrcheckedCheckbox[a].id == this.checkedCheckboxyd[b].id) {
                            arrcheckedCheckbox[a].inSize = this.checkedCheckboxyd[b].inSize
                        }
                    }
                    for (let c=0;c<this.setacreageyd0.length;c++){
                        if (this.setacreageyd0[c].models==this.checkedCheckboxyd[b].id){
                            this.setacreageyd0[c].inSize = this.checkedCheckboxyd[b].inSize
                        }
                    }
                }

                console.log(this.setacreageyd0)
                this.checkedCheckboxyd = arrcheckedCheckbox;
                this.checkedCheckboxyd1 = arrcheckedCheckbox;
                console.log(arrcheckedCheckbox);
            },

            //多选框发送改变_自定义
            handleCheckedChangezdy(value) {
                let arr = [];
                console.log(value)
                console.log(this.checkedCheckboxzdy1)
              if (value.length != 0) {

                for (let v = 0; v < value.length; v++) {
                  let flag = false
                  for (let i = 0; i < this.setacreagezdy0.length; i++) {
                    if (value[v] == this.setacreagezdy0[i].id) {
                      flag = true  // 以前存在
                      arr.push(this.setacreagezdy0[i])
                      break
                    }
                  }
                  if (!flag) { // 以前不存在的
                    for (let i = 0; i < this.otherList.length; i++) {
                      if (value[v] == this.otherList[i].id) {   //取最后加入的那个
                        arr.push({
                          name: this.otherList[i].name,
                          id: this.otherList[i].id,
                          setacreage: this.otherList[i].setacreage,
                          processesSetList: this.otherList[i].processesSetList,
                          num: 1,
                          desc: "",
                          models: this.otherList[i].setacreage == '2' ? this.otherList[i].id:  this.otherList[i].processesSetList[0].id,
                          inSize: this.otherList[i].setacreage == '2' ? [{
                            'id': this.otherList[i].processesSetList[0].id,
                            'models': this.otherList[i].processesSetList[0].id + ','+ this.otherList[i].processesSetList[0].name,
                            'modelsName': this.otherList[i].processesSetList[0].name,
                            'width': '100',
                            'length': '100'
                          }] : [{'width': '', 'length': ''}],
                        });
                      }
                    }
                  }
                }
                this.setacreagezdy0 = arr
                console.log('1:' , value)

              } else {
                this.setacreagezdy0 = [];
              }
                //setacreage:0  默认下拉值
                let arrcheckedCheckbox = [];
                // this.models = [];
                for (let b = 0; b < this.setacreagezdy0.length; b++) {
                    if (this.setacreagezdy0[b].setacreage == 0) {

                        arrcheckedCheckbox.push({
                            id: this.setacreagezdy0[b].models,
                        });
                    } else if (this.setacreagezdy0[b].setacreage == 1) {

                        arrcheckedCheckbox.push({
                            id: this.setacreagezdy0[b].models,
                            inSize: this.setacreagezdy0[b].inSize,
                        });
                        // arrcheckedCheckbox.inSize.push(this.setacreagezdy0[b].inSize)
                    } else if (this.setacreagezdy0[b].setacreage == 2) {
                        arrcheckedCheckbox.push({
                            id: this.setacreagezdy0[b].id,
                            inSize: this.setacreagezdy0[b].inSize,
                        });
                    } else if (this.setacreagezdy0[b].setacreage == 3) {

                        arrcheckedCheckbox.push({
                            id: this.setacreagezdy0[b].models,
                            desc: this.setacreagezdy0[b].desc,
                        });
                    }
                }
                console.log(this.checkedCheckboxzdy)
                for (let b = 0; b < this.checkedCheckboxzdy.length; b++) {
                    for (let a = 0; a < arrcheckedCheckbox.length; a++) {
                        if (arrcheckedCheckbox[a].id == this.checkedCheckboxzdy[b].id) {
                            arrcheckedCheckbox[a].inSize = this.checkedCheckboxzdy[b].inSize
                        }
                    }
                    for (let c=0;c<this.setacreagezdy0.length;c++){
                        if (this.setacreagezdy0[c].models==this.checkedCheckboxzdy[b].id){
                            this.setacreagezdy0[c].inSize = this.checkedCheckboxzdy[b].inSize
                        }
                    }
                }
                console.log(this.setacreagezdy0)
                this.checkedCheckboxzdy = arrcheckedCheckbox;
                this.checkedCheckboxzdy1 = arrcheckedCheckbox;
                console.log(arrcheckedCheckbox);
            },


            //封面后道设置_新增
            newAdded(item, i) {
                console.log(i);
                console.log(item);

                if (i.processesSetList[0].id == item.processesSetList[0].id) {
                    for (let a = 0; a < this.setacreage0.length; a++) {
                        if (this.setacreage0[a].processesSetList[0].id == i.processesSetList[0].id) {
                            this.setacreage0[a].num = ++this.setacreage0[a].num;
                          if (i.setacreage == 2) {
                            this.setacreage0[a].inSize.push({'models': '', 'modelsName': '', 'width': '100', 'length': '100'});
                          } else {
                            this.setacreage0[a].inSize.push({'width': '', 'length': ''});
                          }
                            console.log(this.setacreage0[a].num);
                        }
                    }
                }



                console.log(this.setacreage0);
            },

            //封面后道设置_删除
            del(item, i) {
                console.log(i);
                console.log(item);
                if (i.processesSetList[0].id == item.processesSetList[0].id) {
                    for (let a = 0; a < this.setacreage0.length; a++) {
                        if (this.setacreage0[a].processesSetList[0].id == i.processesSetList[0].id) {
                            this.setacreage0[a].num = --this.setacreage0[a].num;
                            this.setacreage0[a].inSize.pop();
                            console.log(this.setacreage0[a].num);
                        }
                    }
                }
            },


            //内页后道设置_新增
            newnyAdded(item, i, index) {
                console.log(i);
                console.log(item);
                console.log(index);

                if (i.processesSetList[0].id == item.processesSetList[0].id) {
                    for (let a = 0; a < this.setacreageny0.length; a++) {
                        if (this.setacreageny0[a].processesSetList[0].id == i.processesSetList[0].id) {
                            this.setacreageny0[a].num = ++this.setacreageny0[a].num;
                            if (i.setacreageny0 == 2) {
                              this.setacreageny0[a].inSize.push({'models': '', 'modelsName': '', 'width': '100', 'length': '100'});
                            } else {
                              this.setacreageny0[a].inSize.push({'width': '', 'length': ''});
                            }
                            console.log(this.setacreageny0[a].num);
                        }
                    }
                }



                console.log(this.setacreageny0);
            },

            //内页后道设置_删除
            delny(item, i, index) {
                console.log(i);
                console.log(item);
                console.log(index);
                console.log(this.form.checkedCheckboxny);
                if (i.processesSetList[0].id == item.processesSetList[0].id) {
                    for (let a = 0; a < this.setacreageny0.length; a++) {
                        if (this.setacreageny0[a].processesSetList[0].id == i.processesSetList[0].id) {
                            this.setacreageny0[a].num = --this.setacreageny0[a].num;
                            this.setacreageny0[a].inSize.pop();
                            console.log(this.setacreageny0[a].num);
                        }
                    }
                }
            },


            //扉页后道设置_新增
            newfyAdded(item, i) {
                console.log(i);
                console.log(item);

                if (i.processesSetList[0].id == item.processesSetList[0].id) {
                    for (let a = 0; a < this.setacreagefy0.length; a++) {
                        if (this.setacreagefy0[a].processesSetList[0].id == i.processesSetList[0].id) {
                            this.setacreagefy0[a].num = ++this.setacreagefy0[a].num;
                            if (i.setacreagefy0 == 2) {
                              this.setacreagefy0[a].inSize.push({'models': '', 'modelsName': '', 'width': '100', 'length': '100'});
                            } else {
                              this.setacreagefy0[a].inSize.push({'width': '', 'length': ''});
                            }
                            console.log(this.setacreagefy0[a].num);
                        }
                    }
                }



                console.log(this.setacreagefy0);
            },

            //扉页后道设置_删除
            delfy(item, i) {
                console.log(i);
                console.log(item);
                if (i.processesSetList[0].id == item.processesSetList[0].id) {
                    for (let a = 0; a < this.setacreagefy0.length; a++) {
                        if (this.setacreagefy0[a].processesSetList[0].id == i.processesSetList[0].id) {
                            this.setacreagefy0[a].num = --this.setacreagefy0[a].num;
                            this.setacreagefy0[a].inSize.pop();
                            console.log(this.setacreagefy0[a].num);
                        }
                    }
                }
            },


            //插页后道设置_新增
            newcyAdded(item, i) {
                console.log(i);
                console.log(item);

                if (i.processesSetList[0].id == item.processesSetList[0].id) {
                    for (let a = 0; a < this.setacreagecy0.length; a++) {
                        if (this.setacreagecy0[a].processesSetList[0].id == i.processesSetList[0].id) {
                            this.setacreagecy0[a].num = ++this.setacreagecy0[a].num;
                            if (i.setacreagecy0 == 2) {
                              this.setacreagecy0[a].inSize.push({'models': '', 'modelsName': '', 'width': '100', 'length': '100'});
                            } else {
                              this.setacreagecy0[a].inSize.push({'width': '', 'length': ''});
                            }
                            console.log(this.setacreagecy0[a].num);
                        }
                    }
                }

                console.log(this.setacreagecy0);
            },

            //插页后道设置_删除
            delcy(item, i) {
                console.log(i);
                console.log(item);
                if (i.processesSetList[0].id == item.processesSetList[0].id) {
                    for (let a = 0; a < this.setacreagecy0.length; a++) {
                        if (this.setacreagecy0[a].processesSetList[0].id == i.processesSetList[0].id) {
                            this.setacreagecy0[a].num = --this.setacreagecy0[a].num;
                            this.setacreagecy0[a].inSize.pop();
                            console.log(this.setacreagecy0[a].num);
                        }
                    }
                }
            },


            //拉页后道设置_新增
            newlyAdded(item, i) {
                console.log(i);
                console.log(item);

                if (i.processesSetList[0].id == item.processesSetList[0].id) {
                    for (let a = 0; a < this.setacreagely0.length; a++) {
                        if (this.setacreagely0[a].processesSetList[0].id == i.processesSetList[0].id) {
                            this.setacreagely0[a].num = ++this.setacreagely0[a].num;
                            if (i.setacreagely0 == 2) {
                              this.setacreagely0[a].inSize.push({'models': '', 'modelsName': '', 'width': '100', 'length': '100'});
                            } else {
                              this.setacreagely0[a].inSize.push({'width': '', 'length': ''});
                            }
                            console.log(this.setacreagely0[a].num);
                        }
                    }
                }

                for (let a = 0; a < this.checkedCheckboxly.length; a++) {
                    if (i.processesSetList[0].id == this.checkedCheckboxly[a].id) {
                        // this.checkedCheckboxly[a].inSize.push({'width': '', 'length': ''})
                    }
                }

                console.log(this.setacreagely0);
            },

            //拉页后道设置_删除
            delly(item, i) {
                console.log(i);
                console.log(item);
                if (i.processesSetList[0].id == item.processesSetList[0].id) {
                    for (let a = 0; a < this.setacreagely0.length; a++) {
                        if (this.setacreagely0[a].processesSetList[0].id == i.processesSetList[0].id) {
                            this.setacreagely0[a].num = --this.setacreagely0[a].num;
                            this.setacreagely0[a].inSize.pop();
                            console.log(this.setacreagely0[a].num);
                        }
                    }
                }
            },


            //护封后道设置_新增
            newhfAdded(item, i) {
                console.log(i);
                console.log(item);

                if (i.processesSetList[0].id == item.processesSetList[0].id) {
                    for (let a = 0; a < this.setacreagehf0.length; a++) {
                        if (this.setacreagehf0[a].processesSetList[0].id == i.processesSetList[0].id) {
                            this.setacreagehf0[a].num = ++this.setacreagehf0[a].num;
                            if (i.setacreagehf0 == 2) {
                              this.setacreagehf0[a].inSize.push({'models': '', 'modelsName': '', 'width': '100', 'length': '100'});
                            } else {
                              this.setacreagehf0[a].inSize.push({'width': '', 'length': ''});
                            }
                            console.log(this.setacreagehf0[a].num);
                        }
                    }
                }


                console.log(this.setacreagehf0);
            },

            //护封后道设置_删除
            delhf(item, i) {
                console.log(i);
                console.log(item);
                if (i.processesSetList[0].id == item.processesSetList[0].id) {
                    for (let a = 0; a < this.setacreagehf0.length; a++) {
                        if (this.setacreagehf0[a].processesSetList[0].id == i.processesSetList[0].id) {
                            this.setacreagehf0[a].num = --this.setacreagehf0[a].num;
                            this.setacreagehf0[a].inSize.pop();
                            console.log(this.setacreagehf0[a].num);
                        }
                    }
                }
            },


            //腰带后道设置_新增
            newydAdded(item, i) {
                console.log(i);
                console.log(item);

                if (i.processesSetList[0].id == item.processesSetList[0].id) {
                    for (let a = 0; a < this.setacreageyd0.length; a++) {
                        if (this.setacreageyd0[a].processesSetList[0].id == i.processesSetList[0].id) {
                            this.setacreageyd0[a].num = ++this.setacreageyd0[a].num;
                          if (i.setacreageyd0 == 2) {
                            this.setacreageyd0[a].inSize.push({'models': '', 'modelsName': '', 'width': '100', 'length': '100'});
                          } else {
                            this.setacreageyd0[a].inSize.push({'width': '', 'length': ''});
                          }
                            console.log(this.setacreageyd0[a].num);
                        }
                    }
                }



                console.log(this.setacreageyd0);
            },

            //腰带后道设置_删除
            delyd(item, i) {
                console.log(i);
                console.log(item);
                if (i.processesSetList[0].id == item.processesSetList[0].id) {
                    for (let a = 0; a < this.setacreageyd0.length; a++) {
                        if (this.setacreageyd0[a].processesSetList[0].id == i.processesSetList[0].id) {
                            this.setacreageyd0[a].num = --this.setacreageyd0[a].num;
                            this.setacreageyd0[a].inSize.pop();
                            console.log(this.setacreageyd0[a].num);
                        }
                    }
                }
            },


            //自定义后道设置_新增
            newzdyAdded(item, i) {
                console.log(i);
                console.log(item);

                if (i.processesSetList[0].id == item.processesSetList[0].id) {
                    for (let a = 0; a < this.setacreagezdy0.length; a++) {
                        if (this.setacreagezdy0[a].processesSetList[0].id == i.processesSetList[0].id) {
                            this.setacreagezdy0[a].num = ++this.setacreagezdy0[a].num;
                            if (i.setacreagezdy0 == 2) {
                              this.setacreagezdy0[a].inSize.push({'models': '', 'modelsName': '', 'width': '100', 'length': '100'});
                            } else {
                              this.setacreagezdy0[a].inSize.push({'width': '', 'length': ''});
                            }
                            console.log(this.setacreagezdy0[a].num);
                        }
                    }
                }

                console.log(this.setacreagezdy0);
            },

            //自定义后道设置_删除
            delzdy(item, i) {
                console.log(i);
                console.log(item);
                if (i.processesSetList[0].id == item.processesSetList[0].id) {
                    for (let a = 0; a < this.setacreagezdy0.length; a++) {
                        if (this.setacreagezdy0[a].processesSetList[0].id == i.processesSetList[0].id) {
                            this.setacreagezdy0[a].num = --this.setacreagezdy0[a].num;
                            this.setacreagezdy0[a].inSize.pop();
                            console.log(this.setacreagezdy0[a].num);
                        }
                    }
                }
            },


            //setacreage:0  封面下拉值发生改变
          selecChange1(value, i, index) {

            console.log(value)
            console.log(i)
            console.log(index)
            for (let a = 0; a < this.setacreage0.length; a++) {
              if (i.processesSetList[0].id == this.setacreage0[a].processesSetList[0].id) {
                this.setacreage0[a].inSize[index].models = value
                this.setacreage0[a].inSize[index].id = value.split(',')[0]
                this.setacreage0[a].inSize[index].modelsName = value.split(',')[1]
              }

            }

            console.log(this.setacreage0)

          },




          selecChange(value) {

            for (let b = 0; b < this.setacreage0.length; b++) {
              for (let c = 0; c < this.setacreage0[b].processesSetList.length; c++) {
                if (value == this.setacreage0[b].processesSetList[c].id) {
                    this.checkedCheckbox[b].id = value

                }
              }
            }
            console.log(this.checkedCheckbox);
          },

            //setacreage:0  内页下拉值发生改变
            selecChangeny(value) {

                console.log(value);
                console.log(this.setacreageny0);
                console.log(this.checkedCheckboxny);

                for (let b = 0; b < this.setacreageny0.length; b++) {
                    for (let c = 0; c < this.setacreageny0[b].processesSetList.length; c++) {
                        if (value == this.setacreageny0[b].processesSetList[c].id) {
                            this.checkedCheckboxny[this.indexny][b].id = value
                       }
                    }

                    // this.checkedCheckbox = arrcheckedCheckbox
                }
                // console.log(this.models);
                console.log(this.checkedCheckboxny);

            },

          selecChange1fy(value, i, index) {

            console.log(value)
            console.log(i)
            console.log(index)
            for (let a = 0; a < this.setacreagefy0.length; a++) {
              if (i.processesSetList[0].id == this.setacreagefy0[a].processesSetList[0].id) {
                this.setacreagefy0[a].inSize[index].models = value
                this.setacreagefy0[a].inSize[index].id = value.split(',')[0]
                this.setacreagefy0[a].inSize[index].modelsName = value.split(',')[1]
              }

            }

            console.log(this.setacreagefy0)

          },
          selecChange1hf(value, i, index) {

            console.log(value)
            console.log(i)
            console.log(index)
            for (let a = 0; a < this.setacreagehf0.length; a++) {
              if (i.processesSetList[0].id == this.setacreagehf0[a].processesSetList[0].id) {
                this.setacreagehf0[a].inSize[index].models = value
                this.setacreagehf0[a].inSize[index].id = value.split(',')[0]
                this.setacreagehf0[a].inSize[index].modelsName = value.split(',')[1]
              }

            }

            console.log(this.setacreagehf0)

          },
          selecChange1ly(value, i, index) {

            console.log(value)
            console.log(i)
            console.log(index)
            for (let a = 0; a < this.setacreagely0.length; a++) {
              if (i.processesSetList[0].id == this.setacreagehf0[a].processesSetList[0].id) {
                this.setacreagely0[a].inSize[index].models = value
                this.setacreagely0[a].inSize[index].id = value.split(',')[0]
                this.setacreagely0[a].inSize[index].modelsName = value.split(',')[1]
              }

            }

            console.log(this.setacreagely0)

          },
          selecChange1cy(value, i, index) {

            console.log(value)
            console.log(i)
            console.log(index)
            for (let a = 0; a < this.setacreagecy0.length; a++) {
              if (i.processesSetList[0].id == this.setacreagecy0[a].processesSetList[0].id) {
                this.setacreagecy0[a].inSize[index].models = value
                this.setacreagecy0[a].inSize[index].id = value.split(',')[0]
                this.setacreagecy0[a].inSize[index].modelsName = value.split(',')[1]
              }

            }

            console.log(this.setacreagecy0)

          },
          selecChange1ny(value, i, index) {

            console.log(value)
            console.log(i)
            console.log(index)
            for (let a = 0; a < this.setacreageny0.length; a++) {
              if (i.processesSetList[0].id == this.setacreageny0[a].processesSetList[0].id) {
                this.setacreageny0[a].inSize[index].models = value
                this.setacreageny0[a].inSize[index].id = value.split(',')[0]
                this.setacreageny0[a].inSize[index].modelsName = value.split(',')[1]
              }

            }

            console.log(this.setacreageny0)

          },
          selecChange1yd(value, i, index) {

            console.log(value)
            console.log(i)
            console.log(index)
            for (let a = 0; a < this.setacreageyd0.length; a++) {
              if (i.processesSetList[0].id == this.setacreageyd0[a].processesSetList[0].id) {
                this.setacreageyd0[a].inSize[index].models = value
                this.setacreageyd0[a].inSize[index].id = value.split(',')[0]
                this.setacreageyd0[a].inSize[index].modelsName = value.split(',')[1]
              }

            }

            console.log(this.setacreageyd0)

          },
          selecChange1zdy(value, i, index) {

            console.log(value)
            console.log(i)
            console.log(index)
            for (let a = 0; a < this.setacreagezdy0.length; a++) {
              if (i.processesSetList[0].id == this.setacreagezdy0[a].processesSetList[0].id) {
                this.setacreagezdy0[a].inSize[index].models = value
                this.setacreagezdy0[a].inSize[index].id = value.split(',')[0]
                this.setacreagezdy0[a].inSize[index].modelsName = value.split(',')[1]
              }

            }

            console.log(this.setacreagezdy0)

          },

            //setacreage:0  扉页下拉值发生改变
            selecChangefy(value) {

                console.log(value);
                console.log(this.checkedCheckboxfy);

                for (let b = 0; b < this.setacreagefy0.length; b++) {
                    for (let c = 0; c < this.setacreagefy0[b].processesSetList.length; c++) {
                        if (value == this.setacreagefy0[b].processesSetList[c].id) {
                               this.checkedCheckboxfy[this.indexfy][b].id = value

                        }
                    }

                }
                console.log(this.checkedCheckboxfy);
            },

            //setacreage:0  插页下拉值发生改变
            selecChangecy(value) {

                console.log(value);
                console.log(this.checkedCheckboxcy);

                for (let b = 0; b < this.setacreagecy0.length; b++) {
                    for (let c = 0; c < this.setacreagecy0[b].processesSetList.length; c++) {
                        if (value == this.setacreagecy0[b].processesSetList[c].id) {
                                this.checkedCheckboxcy[this.indexcy][b].id = value

                        }
                    }

                }
                console.log(this.checkedCheckboxcy);
            },

            //setacreage:0  拉页下拉值发生改变
            selecChangely(value) {

                console.log(value);
                console.log(this.checkedCheckboxly);

                for (let b = 0; b < this.setacreagely0.length; b++) {
                    for (let c = 0; c < this.setacreagely0[b].processesSetList.length; c++) {
                        if (value == this.setacreagely0[b].processesSetList[c].id) {
                                this.checkedCheckboxly[this.indexly][b].id = value

                        }
                    }

                }
                console.log(this.checkedCheckboxly);
            },

            //setacreage:0  护封下拉值发生改变
            selecChangehf(value) {

                console.log(value);
                console.log(this.checkedCheckboxhf);

                for (let b = 0; b < this.setacreagehf0.length; b++) {
                    for (let c = 0; c < this.setacreagehf0[b].processesSetList.length; c++) {
                        if (value == this.setacreagehf0[b].processesSetList[c].id) {
                                this.checkedCheckboxhf[b].id =value

                        }
                    }

                }
                console.log(this.checkedCheckboxhf);
            },

            //setacreage:0  腰带下拉值发生改变
            selecChangeyd(value) {

                console.log(value);
                console.log(this.checkedCheckboxyd);

                for (let b = 0; b < this.setacreageyd0.length; b++) {
                    for (let c = 0; c < this.setacreageyd0[b].processesSetList.length; c++) {
                        if (value == this.setacreageyd0[b].processesSetList[c].id) {

                                this.checkedCheckboxyd[b].id = value

                        }
                    }

                }
                console.log(this.checkedCheckboxyd);
            },

            //setacreage:0  自定义下拉值发生改变
            selecChangezdy(value) {

                console.log(value);
                console.log(this.checkedCheckboxzdy);

                for (let b = 0; b < this.setacreagezdy0.length; b++) {
                    for (let c = 0; c < this.setacreagezdy0[b].processesSetList.length; c++) {
                        if (value == this.setacreagezdy0[b].processesSetList[c].id) {
                                this.checkedCheckboxzdy[b].id = value

                        }
                    }

                }
                console.log(this.checkedCheckboxzdy);
            },


            //配送方式下拉改变
            selecChangepsfs(value) {
                console.log(value);
                for (let a = 0; a < this.postList.length; a++) {
                    if (this.postList[a].id == value) {
                        this.form.distribution_mustaddr = this.postList[a].mustaddr;
                    }
                    //如选中的送货方式mustaddr:1，目的地就为空
                    if (this.form.distribution_mustaddr == 1) {
                        this.form.dest = "";
                        this.form.provinceId = "";
                        this.form.cityId = "";
                        this.form.areaId = "";
                    }
                }
            },


            //封面宽发生改变
            widthChange(value, i, index) {
                console.log(value)
                console.log(i)
                console.log(index)
                console.log(this.checkedCheckbox)
                console.log(this.form.checkedCheckbox)

                for (let a = 0; a < this.checkedCheckbox.length; a++) {
                    if (i.processesSetList[0].id == this.checkedCheckbox[a].id) {
                        this.checkedCheckbox[a].inSize[index].width = value
                    }
                }

                for (let a = 0; a < this.setacreage0.length; a++) {
                    if (i.processesSetList[0].id == this.setacreage0[a].processesSetList[0].id) {
                        this.setacreage0[a].inSize[index].width = value
                    }
                }
                console.log(this.checkedCheckbox)
                console.log(this.setacreage0)
            },

            //封面高发生改变
            lengthChange(value, i, index) {
                console.log(value)
                console.log(i)
                console.log(index)
                console.log(this.checkedCheckbox)
                console.log(this.form.checkedCheckbox)

                for (let a = 0; a < this.checkedCheckbox.length; a++) {
                    if (i.processesSetList[0].id == this.checkedCheckbox[a].id) {
                        this.checkedCheckbox[a].inSize[index].length = value
                        console.log(this.checkedCheckbox[a].inSize[index]);
                    }
                }

                for (let a = 0; a < this.setacreage0.length; a++) {
                    if (i.processesSetList[0].id == this.setacreage0[a].processesSetList[0].id) {
                        this.setacreage0[a].inSize[index].length = value
                    }
                }

                console.log(this.checkedCheckbox)
                console.log(this.setacreage0)
            },

            //封面文本框发生改变
            textareaChange(value, i, index) {
                console.log(value);
                console.log(i);
                console.log(index);
                console.log(this.checkedCheckbox);
                for (let a = 0; a < this.checkedCheckbox.length; a++) {
                    for (let c = 0; c < i.processesSetList.length; c++) {
                        if (i.processesSetList[c].id == this.checkedCheckbox[a].id) {
                            this.checkedCheckbox[a].desc = value
                        }
                    }
                }

                for (let a = 0; a < this.setacreage0.length; a++) {
                    if (i.processesSetList[0].id == this.setacreage0[a].processesSetList[0].id) {
                        this.setacreage0[a].desc = value
                    }
                }
                console.log(this.checkedCheckbox)
                console.log(this.setacreage0)
                // this.handleCheckedChange(value);
            },


            //内页宽发生改变
            widthChangeny(value, i, index) {
                console.log(value)
                console.log(i)
                console.log(index)
                console.log(this.checkedCheckboxny)
                console.log(this.form.checkedCheckboxny)

                for (let a = 0; a < this.checkedCheckboxny.length; a++) {
                    if (i.processesSetList[0].id == this.checkedCheckboxny[a].id) {
                        this.checkedCheckboxny[a].inSize[index].width = value
                    }
                }

                for (let a = 0; a < this.setacreageny0.length; a++) {
                    if (i.processesSetList[0].id == this.setacreageny0[a].processesSetList[0].id) {
                        this.setacreageny0[a].inSize[index].width = value
                    }
                }
                console.log(this.checkedCheckboxny)
                console.log(this.setacreageny0)
            },

            //内页高发生改变
            lengthChangeny(value, i, index) {
                console.log(value)
                console.log(i)
                console.log(index)
                console.log(this.checkedCheckboxny)
                console.log(this.form.checkedCheckboxny)

                for (let a = 0; a < this.checkedCheckboxny.length; a++) {
                    if (i.processesSetList[0].id == this.checkedCheckboxny[a].id) {
                        this.checkedCheckboxny[a].inSize[index].length = value
                        console.log(this.checkedCheckboxny[a].inSize[index]);
                    }
                }

                for (let a = 0; a < this.setacreageny0.length; a++) {
                    if (i.processesSetList[0].id == this.setacreageny0[a].processesSetList[0].id) {
                        this.setacreageny0[a].inSize[index].length = value
                    }
                }

                console.log(this.checkedCheckboxny)
                console.log(this.setacreageny0)
            },

            //内页文本框发生改变
            textareaChangeny(value, i, index) {
                console.log(value);
                console.log(i);
                console.log(index);
                console.log(this.checkedCheckboxny);
                for (let a = 0; a < this.checkedCheckboxny.length; a++) {
                    for (let c = 0; c < i.processesSetList.length; c++) {
                        if (i.processesSetList[c].id == this.checkedCheckboxny[a].id) {
                            this.checkedCheckboxny[a].desc = value
                        }
                    }
                }

                for (let a = 0; a < this.setacreageny0.length; a++) {
                    if (i.processesSetList[0].id == this.setacreageny0[a].processesSetList[0].id) {
                        this.setacreageny0[a].desc = value
                    }
                }
                console.log(this.checkedCheckboxny)
                console.log(this.setacreageny0)
                // this.handleCheckedChange(value);
            },


            //扉页宽发生改变
            widthChangefy(value, i, index) {
                console.log(value)
                console.log(i)
                console.log(index)
                console.log(this.checkedCheckboxfy)
                console.log(this.form.checkedCheckboxfy)

                for (let a = 0; a < this.checkedCheckboxfy.length; a++) {
                    if (i.processesSetList[0].id == this.checkedCheckboxfy[a].id) {
                        this.checkedCheckboxfy[a].inSize[index].width = value
                    }
                }

                for (let a = 0; a < this.setacreagefy0.length; a++) {
                    if (i.processesSetList[0].id == this.setacreagefy0[a].processesSetList[0].id) {
                        this.setacreagefy0[a].inSize[index].width = value
                    }
                }
                console.log(this.checkedCheckboxfy)
                console.log(this.setacreagefy0)
            },

            //扉页高发生改变
            lengthChangefy(value, i, index) {
                console.log(value)
                console.log(i)
                console.log(index)
                console.log(this.checkedCheckboxfy)
                console.log(this.form.checkedCheckboxfy)

                for (let a = 0; a < this.checkedCheckboxfy.length; a++) {
                    if (i.processesSetList[0].id == this.checkedCheckboxfy[a].id) {
                        this.checkedCheckboxfy[a].inSize[index].length = value
                        console.log(this.checkedCheckboxfy[a].inSize[index]);
                    }
                }

                for (let a = 0; a < this.setacreagefy0.length; a++) {
                    if (i.processesSetList[0].id == this.setacreagefy0[a].processesSetList[0].id) {
                        this.setacreagefy0[a].inSize[index].length = value
                    }
                }

                console.log(this.checkedCheckboxfy)
                console.log(this.setacreagefy0)
            },

            //扉页文本框发生改变
            textareaChangefy(value, i, index) {
                console.log(value);
                console.log(i);
                console.log(index);
                console.log(this.checkedCheckboxfy);
                for (let a = 0; a < this.checkedCheckboxfy.length; a++) {
                    for (let c = 0; c < i.processesSetList.length; c++) {
                        if (i.processesSetList[c].id == this.checkedCheckboxfy[a].id) {
                            this.checkedCheckboxfy[a].desc = value
                        }
                    }
                }

                for (let a = 0; a < this.setacreagefy0.length; a++) {
                    if (i.processesSetList[0].id == this.setacreagefy0[a].processesSetList[0].id) {
                        this.setacreagefy0[a].desc = value
                    }
                }
                console.log(this.checkedCheckboxfy)
                console.log(this.setacreagefy0)
                // this.handleCheckedChange(value);
            },


            //插页宽发生改变
            widthChangecy(value, i, index) {
                console.log(value)
                console.log(i)
                console.log(index)
                console.log(this.checkedCheckboxcy)
                console.log(this.form.checkedCheckboxcy)

                for (let a = 0; a < this.checkedCheckboxcy.length; a++) {
                    if (i.processesSetList[0].id == this.checkedCheckboxcy[a].id) {
                        this.checkedCheckboxcy[a].inSize[index].width = value
                    }
                }

                for (let a = 0; a < this.setacreagecy0.length; a++) {
                    if (i.processesSetList[0].id == this.setacreagecy0[a].processesSetList[0].id) {
                        this.setacreagecy0[a].inSize[index].width = value
                    }
                }
                console.log(this.checkedCheckboxcy)
                console.log(this.setacreagecy0)
            },

            //插页高发生改变
            lengthChangecy(value, i, index) {
                console.log(value)
                console.log(i)
                console.log(index)
                console.log(this.checkedCheckboxcy)
                console.log(this.form.checkedCheckboxcy)

                for (let a = 0; a < this.checkedCheckboxcy.length; a++) {
                    if (i.processesSetList[0].id == this.checkedCheckboxcy[a].id) {
                        this.checkedCheckboxcy[a].inSize[index].length = value
                        console.log(this.checkedCheckboxcy[a].inSize[index]);
                    }
                }

                for (let a = 0; a < this.setacreagecy0.length; a++) {
                    if (i.processesSetList[0].id == this.setacreagecy0[a].processesSetList[0].id) {
                        this.setacreagecy0[a].inSize[index].length = value
                    }
                }

                console.log(this.checkedCheckboxcy)
                console.log(this.setacreagecy0)
            },

            //插页文本框发生改变
            textareaChangecy(value, i, index) {
                console.log(value);
                console.log(i);
                console.log(index);
                console.log(this.checkedCheckboxcy);
                for (let a = 0; a < this.checkedCheckboxcy.length; a++) {
                    for (let c = 0; c < i.processesSetList.length; c++) {
                        if (i.processesSetList[c].id == this.checkedCheckboxcy[a].id) {
                            this.checkedCheckboxcy[a].desc = value
                        }
                    }
                }

                for (let a = 0; a < this.setacreagecy0.length; a++) {
                    if (i.processesSetList[0].id == this.setacreagecy0[a].processesSetList[0].id) {
                        this.setacreagecy0[a].desc = value
                    }
                }
                console.log(this.checkedCheckboxcy)
                console.log(this.setacreagecy0)
                // this.handleCheckedChange(value);
            },


            //拉页宽发生改变
            widthChangely(value, i, index) {
                console.log(value)
                console.log(i)
                console.log(index)
                console.log(this.checkedCheckboxly)
                console.log(this.form.checkedCheckboxly)

                for (let a = 0; a < this.checkedCheckboxly.length; a++) {
                    if (i.processesSetList[0].id == this.checkedCheckboxly[a].id) {
                        this.checkedCheckboxly[a].inSize[index].width = value
                    }
                }

                for (let a = 0; a < this.setacreagely0.length; a++) {
                    if (i.processesSetList[0].id == this.setacreagely0[a].processesSetList[0].id) {
                        this.setacreagely0[a].inSize[index].width = value
                    }
                }
                console.log(this.checkedCheckboxly)
                console.log(this.setacreagely0)
            },

            //拉页高发生改变
            lengthChangely(value, i, index) {
                console.log(value)
                console.log(i)
                console.log(index)
                console.log(this.checkedCheckboxly)
                console.log(this.form.checkedCheckboxly)

                for (let a = 0; a < this.checkedCheckboxly.length; a++) {
                    if (i.processesSetList[0].id == this.checkedCheckboxly[a].id) {
                        this.checkedCheckboxly[a].inSize[index].length = value
                        console.log(this.checkedCheckboxly[a].inSize[index]);
                    }
                }

                for (let a = 0; a < this.setacreagely0.length; a++) {
                    if (i.processesSetList[0].id == this.setacreagely0[a].processesSetList[0].id) {
                        this.setacreagely0[a].inSize[index].length = value
                    }
                }

                console.log(this.checkedCheckboxly)
                console.log(this.setacreagely0)
            },

            //拉页文本框发生改变
            textareaChangely(value, i, index) {
                console.log(value);
                console.log(i);
                console.log(index);
                console.log(this.checkedCheckboxly);
                for (let a = 0; a < this.checkedCheckboxly.length; a++) {
                    for (let c = 0; c < i.processesSetList.length; c++) {
                        if (i.processesSetList[c].id == this.checkedCheckboxly[a].id) {
                            this.checkedCheckboxly[a].desc = value
                        }
                    }
                }

                for (let a = 0; a < this.setacreagely0.length; a++) {
                    if (i.processesSetList[0].id == this.setacreagely0[a].processesSetList[0].id) {
                        this.setacreagely0[a].desc = value
                    }
                }
                console.log(this.checkedCheckboxly)
                console.log(this.setacreagely0)
                // this.handleCheckedChange(value);
            },


            //护封宽发生改变
            widthChangehf(value, i, index) {
                console.log(value)
                console.log(i)
                console.log(index)
                console.log(this.checkedCheckboxhf)
                console.log(this.form.checkedCheckboxhf)

                for (let a = 0; a < this.checkedCheckboxhf.length; a++) {
                    if (i.processesSetList[0].id == this.checkedCheckboxhf[a].id) {
                        this.checkedCheckboxhf[a].inSize[index].width = value
                    }
                }

                for (let a = 0; a < this.setacreagehf0.length; a++) {
                    if (i.processesSetList[0].id == this.setacreagehf0[a].processesSetList[0].id) {
                        this.setacreagehf0[a].inSize[index].width = value
                    }
                }
                console.log(this.checkedCheckboxhf)
                console.log(this.setacreagehf0)
            },

            //护封高发生改变
            lengthChangehf(value, i, index) {
                console.log(value)
                console.log(i)
                console.log(index)
                console.log(this.checkedCheckboxhf)
                console.log(this.form.checkedCheckboxhf)

                for (let a = 0; a < this.checkedCheckboxhf.length; a++) {
                    if (i.processesSetList[0].id == this.checkedCheckboxhf[a].id) {
                        this.checkedCheckboxhf[a].inSize[index].length = value
                        console.log(this.checkedCheckboxhf[a].inSize[index]);
                    }
                }

                for (let a = 0; a < this.setacreagehf0.length; a++) {
                    if (i.processesSetList[0].id == this.setacreagehf0[a].processesSetList[0].id) {
                        this.setacreagehf0[a].inSize[index].length = value
                    }
                }

                console.log(this.checkedCheckboxhf)
                console.log(this.setacreagehf0)
            },

            //护封文本框发生改变
            textareaChangehf(value, i, index) {
                console.log(value);
                console.log(i);
                console.log(index);
                console.log(this.checkedCheckboxhf);
                for (let a = 0; a < this.checkedCheckboxhf.length; a++) {
                    for (let c = 0; c < i.processesSetList.length; c++) {
                        if (i.processesSetList[c].id == this.checkedCheckboxhf[a].id) {
                            this.checkedCheckboxhf[a].desc = value
                        }
                    }
                }

                for (let a = 0; a < this.setacreagehf0.length; a++) {
                    if (i.processesSetList[0].id == this.setacreagehf0[a].processesSetList[0].id) {
                        this.setacreagehf0[a].desc = value
                    }
                }
                console.log(this.checkedCheckboxhf)
                console.log(this.setacreagehf0)
                // this.handleCheckedChange(value);
            },


            //腰带宽发生改变
            widthChangeyd(value, i, index) {
                console.log(value)
                console.log(i)
                console.log(index)
                console.log(this.checkedCheckboxyd)
                console.log(this.form.checkedCheckboxyd)

                for (let a = 0; a < this.checkedCheckboxyd.length; a++) {
                    if (i.processesSetList[0].id == this.checkedCheckboxyd[a].id) {
                        this.checkedCheckboxyd[a].inSize[index].width = value
                    }
                }

                for (let a = 0; a < this.setacreageyd0.length; a++) {
                    if (i.processesSetList[0].id == this.setacreageyd0[a].processesSetList[0].id) {
                        this.setacreageyd0[a].inSize[index].width = value
                    }
                }
                console.log(this.checkedCheckboxyd)
                console.log(this.setacreageyd0)
            },

            //腰带高发生改变
            lengthChangeyd(value, i, index) {
                console.log(value)
                console.log(i)
                console.log(index)
                console.log(this.checkedCheckboxyd)
                console.log(this.form.checkedCheckboxyd)

                for (let a = 0; a < this.checkedCheckboxyd.length; a++) {
                    if (i.processesSetList[0].id == this.checkedCheckboxyd[a].id) {
                        this.checkedCheckboxyd[a].inSize[index].length = value
                        console.log(this.checkedCheckboxyd[a].inSize[index]);
                    }
                }

                for (let a = 0; a < this.setacreageyd0.length; a++) {
                    if (i.processesSetList[0].id == this.setacreageyd0[a].processesSetList[0].id) {
                        this.setacreageyd0[a].inSize[index].length = value
                    }
                }

                console.log(this.checkedCheckboxyd)
                console.log(this.setacreageyd0)
            },

            //腰带文本框发生改变
            textareaChangeyd(value, i, index) {
                console.log(value);
                console.log(i);
                console.log(index);
                console.log(this.checkedCheckboxyd);
                for (let a = 0; a < this.checkedCheckboxyd.length; a++) {
                    for (let c = 0; c < i.processesSetList.length; c++) {
                        if (i.processesSetList[c].id == this.checkedCheckboxyd[a].id) {
                            this.checkedCheckboxyd[a].desc = value
                        }
                    }
                }

                for (let a = 0; a < this.setacreageyd0.length; a++) {
                    if (i.processesSetList[0].id == this.setacreageyd0[a].processesSetList[0].id) {
                        this.setacreageyd0[a].desc = value
                    }
                }
                console.log(this.checkedCheckboxyd)
                console.log(this.setacreageyd0)
                // this.handleCheckedChange(value);
            },


            //自定义宽发生改变
            widthChangezdy(value, i, index) {
                console.log(value)
                console.log(i)
                console.log(index)
                console.log(this.checkedCheckboxzdy)
                console.log(this.form.checkedCheckboxzdy)

                for (let a = 0; a < this.checkedCheckboxzdy.length; a++) {
                    if (i.processesSetList[0].id == this.checkedCheckboxzdy[a].id) {
                        this.checkedCheckboxzdy[a].inSize[index].width = value
                    }
                }

                for (let a = 0; a < this.setacreagezdy0.length; a++) {
                    if (i.processesSetList[0].id == this.setacreagezdy0[a].processesSetList[0].id) {
                        this.setacreagezdy0[a].inSize[index].width = value
                    }
                }
                console.log(this.checkedCheckboxzdy)
                console.log(this.setacreagezdy0)
            },

            //自定义高发生改变
            lengthChangezdy(value, i, index) {
                console.log(value)
                console.log(i)
                console.log(index)
                console.log(this.checkedCheckboxzdy)
                console.log(this.form.checkedCheckboxzdy)

                for (let a = 0; a < this.checkedCheckboxzdy.length; a++) {
                    if (i.processesSetList[0].id == this.checkedCheckboxzdy[a].id) {
                        this.checkedCheckboxzdy[a].inSize[index].length = value
                        console.log(this.checkedCheckboxzdy[a].inSize[index]);
                    }
                }

                for (let a = 0; a < this.setacreagezdy0.length; a++) {
                    if (i.processesSetList[0].id == this.setacreagezdy0[a].processesSetList[0].id) {
                        this.setacreagezdy0[a].inSize[index].length = value
                    }
                }

                console.log(this.checkedCheckboxzdy)
                console.log(this.setacreagezdy0)
            },

            //自定义文本框发生改变
            textareaChangezdy(value, i, index) {
                console.log(value);
                console.log(i);
                console.log(index);
                console.log(this.checkedCheckboxzdy);
                for (let a = 0; a < this.checkedCheckboxzdy.length; a++) {
                    for (let c = 0; c < i.processesSetList.length; c++) {
                        if (i.processesSetList[c].id == this.checkedCheckboxzdy[a].id) {
                            this.checkedCheckboxzdy[a].desc = value
                        }
                    }
                }

                for (let a = 0; a < this.setacreagezdy0.length; a++) {
                    if (i.processesSetList[0].id == this.setacreagezdy0[a].processesSetList[0].id) {
                        this.setacreagezdy0[a].desc = value
                    }
                }
                console.log(this.checkedCheckboxzdy)
                console.log(this.setacreagezdy0)
                // this.handleCheckedChange(value);
            },


            //提交报价
            onSubmit() {
              // let len = this.userList.length;
              // if(len <= 0){
              //   this.$message.error('请选择代理的客户！');
              //   return;
              // }
                console.log(this.form.pagenums);
                console.log("submit!");
                console.log(this.form);
                //内页必选项id
                console.log(this.form.nybx);
                console.log(this.setacreage0);
                console.log(this.checkedCheckbox);
                console.log(this.checkedCheckboxny);
                console.log(this.checkedCheckboxfy);
                console.log(this.checkedCheckboxcy);
                console.log(this.checkedCheckboxly);
                console.log(this.checkedCheckboxhf);
                console.log(this.checkedCheckboxyd);
                console.log(this.checkedCheckboxzdy);

                //给内页后道设置追加进必选项
                if (this.checkedCheckboxny.length == 0) {
                    // for (let b = 0; b < this.checkedCheckboxny.length; b++) {
                    this.checkedCheckboxny.push([{'id': this.form.nybx[0]}])
                    // }
                } else {
                    for (let b = 0; b < this.checkedCheckboxny.length; b++) {
                        console.log(this.checkedCheckboxny[b])

                        //返回数组中满足条件的第一个元素的索引，如果没有找到，返回 -1.
                        let item = this.checkedCheckboxny[b].findIndex(item => {
                            return item.id == this.form.nybx[0];
                        });
                        console.log(item)
                        if (item == -1) {
                            this.checkedCheckboxny[b] = this.checkedCheckboxny[b].concat({'id': this.form.nybx[0]})
                        }
                    }
                }
                //给扉页后道设置追加进必选项
                if (this.checkedCheckboxfy.length == 0) {
                    this.checkedCheckboxfy.push([{'id': this.form.fybx[0]}])
                } else {
                    for (let b = 0; b < this.checkedCheckboxfy.length; b++) {
                        console.log(this.checkedCheckboxfy[b])

                        //返回数组中满足条件的第一个元素的索引，如果没有找到，返回 -1.
                        let item = this.checkedCheckboxfy[b].findIndex(item => {
                            return item.id == this.form.fybx[0];
                        });
                        console.log(item)
                        if (item == -1) {
                            this.checkedCheckboxfy[b] = this.checkedCheckboxfy[b].concat({'id': this.form.fybx[0]})
                        }
                    }
                }


                //给插页后道设置追加进必选项
                if (this.checkedCheckboxcy.length == 0) {
                    this.checkedCheckboxcy.push([{'id': this.form.cybx[0]}])
                } else {
                    for (let b = 0; b < this.checkedCheckboxcy.length; b++) {
                        console.log(this.checkedCheckboxcy[b])

                        //返回数组中满足条件的第一个元素的索引，如果没有找到，返回 -1.
                        let item = this.checkedCheckboxcy[b].findIndex(item => {
                            return item.id == this.form.cybx[0];
                        });
                        console.log(item)
                        if (item == -1) {
                            this.checkedCheckboxcy[b] = this.checkedCheckboxcy[b].concat({'id': this.form.cybx[0]})
                        }
                    }
                }

                //给拉页后道设置追加进必选项
                if (this.checkedCheckboxly.length == 0) {
                    this.checkedCheckboxly.push([{'id': this.form.lybx[0]}])
                } else {
                    for (let b = 0; b < this.checkedCheckboxly.length; b++) {
                        console.log(this.checkedCheckboxly[b])

                        //返回数组中满足条件的第一个元素的索引，如果没有找到，返回 -1.
                        let item = this.checkedCheckboxly[b].findIndex(item => {
                            return item.id == this.form.lybx[0];
                        });
                        console.log(item)
                        if (item == -1) {
                            this.checkedCheckboxly[b] = this.checkedCheckboxly[b].concat({'id': this.form.lybx[0]})
                        }
                    }
                }

                console.log(this.checkedCheckboxny);

                //封面设置
                console.log(this.form.cover);
                // console.log(this.technologyfm);

                let faceData = [];
                let afterIds = this.checkedCheckbox;
                if (this.form.cover == 0) {
                    faceData = [];
                } else if (this.form.cover == 1) {
                    for (let a = 0; a < this.form.input1; a++) {
                        faceData.push({
                            partsType: this.form.cover,
                            partsName: "封面" + parseInt(a + 1),
                            // ---- 材料ID【必填】
                            maId: this.form.coverTypes[a],
                            // ---- 克重ID【必填】
                            weight: this.form.coverGrams[a],
                            //---- 封面自來料【必填】
                            isCustMa: this.isCustMafm == false ? 0 : 1,
                            // ---- 正面印色ID【非必填】
                            color:
                                this.form.singles[a] == "colors"
                                    ? this.form.colours[a]
                                    : this.form.colours[a],
                            // ---- 反面印色ID【非必填】
                            colors:
                                this.form.singles[a] == "colors" ? this.form.colours[a] : "",
                            //----  本数（单个本数，多封面时使用）【多封面时，必填；其他非必填】
                            bookNum: this.form.input1 == "1" ? "" : this.benshu[a],
                            // //---- 封面P数【必填】
                            // 'pNum':4,
                            // ---- 后道工艺列表
                            afterIds: afterIds,
                        });
                    }
                } else if (this.form.cover == 2) {
                    for (let a = 0; a < this.StrangleMouth.length; a++) {
                        faceData.push({
                            partsType: this.form.cover,
                            partsName: "封面封底加勒口",
                            // ---- 材料ID【必填】
                            maId: this.form.coverTypes[a],
                            // ---- 克重ID【必填】
                            weight: this.form.coverGrams[a],
                            //---- 封面自來料【必填】
                            isCustMa: this.isCustMafm == false ? 0 : 1,
                            // ---- 正面印色ID【非必填】
                            color:
                                this.form.singles[a] == "colors"
                                    ? this.form.colours[a]
                                    : this.form.colours[a],
                            // ---- 反面印色ID【非必填】
                            colors:
                                this.form.singles[a] == "colors" ? this.form.colours[a] : "",
                            //----  本数（单个本数，多封面时使用）【多封面时，必填；其他非必填】
                            // 'bookNum':this.form.input1=='1'?'':this.benshu[a],
                            //---- 封面P数【必填】
                            // 'pNum':4,
                            //---- 封面勒口【封面勒口类型时，使用】
                            faceSize: this.fm,
                            //---- 封底勒口【封面勒口类型时，使用】
                            backSize: this.fd,
                            // ---- 后道工艺列表
                            afterIds: afterIds,
                        });
                    }
                } else if (this.form.cover == 3) {
                    for (let a = 0; a < this.PullPage2p.length; a++) {
                        faceData.push({
                            partsType: this.form.cover,
                            partsName: "封面封底加拉页",
                            // ---- 材料ID【必填】
                            maId: this.form.coverTypes[a],
                            // ---- 克重ID【必填】
                            weight: this.form.coverGrams[a],
                            //---- 封面自來料【必填】
                            isCustMa: this.isCustMafm == false ? 0 : 1,
                            // ---- 正面印色ID【非必填】
                            color:
                                this.form.singles[a] == "colors"
                                    ? this.form.colours[a]
                                    : this.form.colours[a],
                            // ---- 反面印色ID【非必填】
                            colors:
                                this.form.singles[a] == "colors" ? this.form.colours[a] : "",
                            //----  本数（单个本数，多封面时使用）【多封面时，必填；其他非必填】
                            bookNum: this.form.input1 == "1" ? "" : this.benshu[a],
                            //---- 封面P数【必填】
                            // 'pNum':4,
                            //---- 封面P数【封面封底加拉页时，使用】
                            faceNum: this.fm,
                            //---- 封底P数【封面封底加拉页时，使用】
                            backNum: this.fd,
                            // ---- 后道工艺列表
                            afterIds: afterIds,
                        });
                    }
                } else if (this.form.cover == 4) {
                    for (let a = 0; a < this.PullPage4p.length; a++) {
                        faceData.push({
                            partsType: this.form.cover,
                            partsName: "封面封底加拉页",
                            // ---- 材料ID【必填】
                            maId: this.form.coverTypes[a],
                            // ---- 克重ID【必填】
                            weight: this.form.coverGrams[a],
                            //---- 封面自來料【必填】
                            isCustMa: this.isCustMafm == false ? 0 : 1,
                            // ---- 正面印色ID【非必填】
                            color:
                                this.form.singles[a] == "colors"
                                    ? this.form.colours[a]
                                    : this.form.colours[a],
                            // ---- 反面印色ID【非必填】
                            colors:
                                this.form.singles[a] == "colors" ? this.form.colours[a] : "",
                            //----  本数（单个本数，多封面时使用）【多封面时，必填；其他非必填】
                            bookNum: this.form.input1 == "1" ? "" : this.benshu[a],
                            //---- 封面P数【必填】
                            // 'pNum':4,
                            //---- 封面P数【封面封底加拉页时，使用】
                            faceNum: this.fm,
                            //---- 封底P数【封面封底加拉页时，使用】
                            backNum: this.fd,
                            // ---- 后道工艺列表
                            afterIds: afterIds,
                        });
                    }
                } else if (this.form.cover == 5) {
                    for (let a = 0; a < this.PullPage22p.length; a++) {
                        faceData.push({
                            partsType: this.form.cover,
                            partsName: "封面封底加拉页",
                            // ---- 材料ID【必填】
                            maId: this.form.coverTypes[a],
                            // ---- 克重ID【必填】
                            weight: this.form.coverGrams[a],
                            //---- 封面自來料【必填】
                            isCustMa: this.isCustMafm == false ? 0 : 1,
                            // ---- 正面印色ID【非必填】
                            color:
                                this.form.singles[a] == "colors"
                                    ? this.form.colours[a]
                                    : this.form.colours[a],
                            // ---- 反面印色ID【非必填】
                            colors:
                                this.form.singles[a] == "colors" ? this.form.colours[a] : "",
                            //----  本数（单个本数，多封面时使用）【多封面时，必填；其他非必填】
                            bookNum: this.form.input1 == "1" ? "" : this.benshu[a],
                            //---- 封面P数【必填】
                            // 'pNum':4,
                            //---- 封面P数【封面封底加拉页时，使用】
                            faceNum: this.fm,
                            //---- 封底P数【封面封底加拉页时，使用】
                            backNum: this.fd,
                            // ---- 后道工艺列表
                            afterIds: afterIds,
                        });
                    }
                }

                //内页设置
                console.log(this.form.insidepage);
                // console.log(this.technologyny);
                // console.log(this.technologyfy);
                // console.log(this.technologycy);
                // console.log(this.technologyly);
                let innerData = [];
                let afterIdsny = this.checkedCheckboxny;
                let afterIdsfy = this.checkedCheckboxfy;
                let afterIdscy = this.checkedCheckboxcy;
                let afterIdsly = this.checkedCheckboxly;
                for (let ny = 0; ny < this.neiyearr.length; ny++) {
                    if (this.form.pagenumberGramny[ny] == '' || this.form.pagenumberGramny.length == 0) {
                        this.$message({
                            message: "请填写材料克重",
                            type: "warning",
                        });
                        return;
                    } else {
                        innerData.push({
                            //---- 部件类型【11，必填】
                            partsType: 11,
                            //---- 部件名称【如，内页一，必填】
                            partsName: "内页" + parseInt(ny + 1),
                            //---- 材料ID【必填】
                            maId: this.form.pagenumberTypeny[ny],
                            //---- 克重ID【必填】
                            weight: this.form.pagenumberGramny[ny],
                            //---- 内页自來料【必填】
                            isCustMa: this.isCustMany[ny] == false ? 0 : 1,
                            //---- 正面印色ID【非必填】
                            color: this.form.coloursny[ny],
                            //---- 反面印色ID【非必填】
                            colors: this.form.coloursny[ny],
                            //---- 内页P数【必填】
                            pNum: this.form.pagenums[ny],
                            //---- 后道工艺列表【非必填】
                            afterIds: afterIdsny[ny],
                        });
                    }
                }
                if (this.feiyearr.length != 0) {
                    for (let ny = 0; ny < this.feiyearr.length; ny++) {
                        if (this.form.pagenumberGramfy[ny] == '' || this.form.pagenumberGramfy.length == 0) {
                            this.$message({
                                message: "请填写材料克重",
                                type: "warning",
                            });
                            return;
                        } else {
                            innerData.push({
                                //---- 部件类型【11，必填】
                                partsType: 12,
                                //---- 部件名称【如，内页一，必填】
                                partsName: "扉页" + parseInt(ny + 1),
                                //---- 材料ID【必填】
                                maId: this.form.pagenumberTypefy[ny],
                                //---- 克重ID【必填】
                                weight: this.form.pagenumberGramfy[ny],
                                //---- 扉页自來料【必填】
                                isCustMa: this.isCustMafy[ny] == false ? 0 : 1,
                                //---- 正面印色ID【非必填】
                                color:
                                    this.form.singlesfy[ny] == "colors"
                                        ? this.form.coloursfy[ny]
                                        : this.form.coloursfy[ny],
                                //---- 反面印色ID【非必填】
                                colors:
                                    this.form.singlesfy[ny] == "colors"
                                        ? this.form.coloursfy[ny]
                                        : "",
                                //---- 内页P数【必填】
                                // 'pNum': this.form.pagenums[ny],
                                //---- 后道工艺列表【非必填】
                                afterIds: afterIdsfy[ny],
                            });
                        }
                    }
                }
                if (this.chayearr.length != 0) {
                    for (let cy = 0; cy < this.chayearr.length; cy++) {
                        if (this.form.pagenumberGramcy[cy] == '' || this.form.pagenumberGramcy.length == 0) {
                            this.$message({
                                message: "请填写材料克重",
                                type: "warning",
                            });
                            return;
                        } else {
                            innerData.push({
                                //---- 部件类型【11，必填】
                                partsType: 13,
                                //---- 部件名称【如，内页一，必填】
                                partsName: "插页" + parseInt(cy + 1),
                                //---- 材料ID【必填】
                                maId: this.form.pagenumberTypecy[cy],
                               // 插页张数
                                cynumber: this.form.cynumber[cy],
                                //---- 克重ID【必填】
                                weight: this.form.pagenumberGramcy[cy],
                                //---- 插页自來料【必填】
                                isCustMa: this.isCustMacy[cy] == false ? 0 : 1,
                                //---- 正面印色ID【非必填】
                                color:
                                    this.form.singlescy[cy] == "colors"
                                        ? this.form.colourscy[cy]
                                        : this.form.colourscy[cy],
                                //---- 反面印色ID【非必填】
                                colors:
                                    this.form.singlescy[cy] == "colors"
                                        ? this.form.colourscy[cy]
                                        : "",
                                //---- 内页P数【必填】
                                // 'pNum': this.form.pagenums[cy],
                                //---- 后道工艺列表【非必填】
                                afterIds: afterIdscy[cy],
                            });
                        }
                    }
                }
                if (this.layearr.length != 0) {
                    for (let ly = 0; ly < this.layearr.length; ly++) {
                        if (this.form.pagenumberGramly[ly] == '' || this.form.pagenumberGramly.length == 0) {
                            this.$message({
                                message: "请填写材料克重",
                                type: "warning",
                            });
                            return;
                        } else {
                            innerData.push({
                                //---- 部件类型【11，必填】
                                partsType: 14,
                                //---- 部件名称【如，内页一，必填】
                                partsName: "拉页" + parseInt(ly + 1),
                                //---- 材料ID【必填】
                                maId: this.form.pagenumberTypely[ly],
                                //---- 克重ID【必填】
                                weight: this.form.pagenumberGramly[ly],
                                //---- 拉页自來料【必填】
                                isCustMa: this.isCustMaly[ly] == false ? 0 : 1,
                                //---- 正面印色ID【非必填】
                                color:
                                    this.form.singlesly[ly] == "colors"
                                        ? this.form.coloursly[ly]
                                        : this.form.coloursly[ly],
                                //---- 反面印色ID【非必填】
                                colors:
                                    this.form.singlesly[ly] == "colors"
                                        ? this.form.coloursly[ly]
                                        : "",
                                //---- 内页P数【必填】
                                pNum: this.form.pagenumberarr[ly],
                                //---- 后道工艺列表【非必填】
                                afterIds: afterIdsly[ly],
                            });
                        }
                    }
                }

                //其他设置
                console.log(this.form.other);
                // console.log(this.technologyhf);
                // console.log(this.technologyyd);
                // console.log(this.technologyzdy);
                let otherData = [];
                let afterIdshf = this.checkedCheckboxhf;
                let afterIdsyd = this.checkedCheckboxyd;
                let afterIdszdy = this.checkedCheckboxzdy;
                if (this.HuaceDustJacket.length != 0) {
                    for (let hf = 0; hf < this.HuaceDustJacket.length; hf++) {
                        if (this.form.pagenumberGramhf[hf] == '' || this.form.pagenumberGramhf.length == 0) {
                            this.$message({
                                message: "请填写材料克重",
                                type: "warning",
                            });
                            return;
                        } else {
                            otherData.push({
                                //---- 部件类型【23，必填】
                                partsType: 21,
                                //---- 部件名称【如，自定义，必填】
                                partsName: "画册加护封",
                                //---- 材料ID【必填】
                                maId: this.form.pagenumberTypehf[hf],
                                //---- 克重ID【必填】
                                weight: this.form.pagenumberGramhf[hf],
                                //---- 护封自來料【必填】
                                isCustMa: this.isCustMahf == false ? 0 : 1,
                                //---- 正面印色ID【非必填】
                                color:
                                    this.form.singlesDustJacket[hf] == "colors"
                                        ? this.form.coloursDustJacket[hf]
                                        : this.form.coloursDustJacket[hf],
                                //---- 反面印色ID【非必填】
                                colors:
                                    this.form.singlesDustJacket[hf] == "colors"
                                        ? this.form.coloursDustJacket[hf]
                                        : "",
                                length: this.form.HuaceDustJacket_height,
                                width: this.form.HuaceDustJacket_width,
                                //---- P数【必填】
                                // 'pNum': '',
                                //---- 后道工艺列表【非必填】
                                afterIds: afterIdshf,
                            });
                        }
                    }
                }
                if (this.HuaceBelt.length != 0) {
                    for (let yd = 0; yd < this.HuaceBelt.length; yd++) {
                        if (this.form.pagenumberGramyd[yd] == '' || this.form.pagenumberGramyd.length == 0) {
                            this.$message({
                                message: "请填写材料克重",
                                type: "warning",
                            });
                            return;
                        } else {
                            otherData.push({
                                //---- 部件类型【23，必填】
                                partsType: 22,
                                //---- 部件名称【如，自定义，必填】
                                partsName: "画册加腰带",
                                //---- 材料ID【必填】
                                maId: this.form.pagenumberTypeyd[yd],
                                //---- 克重ID【必填】
                                weight: this.form.pagenumberGramyd[yd],
                                //---- 腰带自來料【必填】
                                isCustMa: this.isCustMayd == false ? 0 : 1,
                                //---- 正面印色ID【非必填】
                                color:
                                    this.form.singlesBelt[yd] == "colors"
                                        ? this.form.coloursBelt[yd]
                                        : this.form.coloursBelt[yd],
                                //---- 反面印色ID【非必填】
                                colors:
                                    this.form.singlesBelt[yd] == "colors"
                                        ? this.form.coloursBelt[yd]
                                        : "",
                                length: this.form.HuaceBelt_height,
                                width: this.form.HuaceBelt_width,
                                //---- P数【必填】
                                // 'pNum': '',
                                //---- 后道工艺列表【非必填】
                                afterIds: afterIdsyd,
                            });
                        }
                    }
                }
                if (this.CustomParts.length != 0) {
                    for (let zdy = 0; zdy < this.CustomParts.length; zdy++) {
                        if (this.form.pagenumberGramzdy[zdy] == '' || this.form.pagenumberGramzdy.length == 0) {
                            this.$message({
                                message: "请填写材料克重",
                                type: "warning",
                            });
                            return;
                        } else {
                            otherData.push({
                                //---- 部件类型【23，必填】
                                partsType: 23,
                                //---- 部件名称【如，自定义，必填】
                                partsName: this.form.input3,
                                //---- 材料ID【必填】
                                maId: this.form.pagenumberTypezdy[zdy],
                                //---- 克重ID【必填】
                                weight: this.form.pagenumberGramzdy[zdy],
                                //---- 自定义自來料【必填】
                                isCustMa: this.isCustMazdy == false ? 0 : 1,
                                //---- 正面印色ID【非必填】
                                color:
                                    this.form.singleszdy[zdy] == "colors"
                                        ? this.form.colourszdy[zdy]
                                        : this.form.colourszdy[zdy],
                                //---- 反面印色ID【非必填】
                                colors:
                                    this.form.singleszdy[zdy] == "colors"
                                        ? this.form.colourszdy[zdy]
                                        : "",
                                length: this.form.CustomParts_height,
                                width: this.form.CustomParts_width,
                                //---- P数【必填】
                                // 'pNum': '',
                                //---- 后道工艺列表【非必填】
                                afterIds: afterIdszdy,
                            });
                        }
                    }
                }
                if (this.Drilling.length != 0) {
                    for (let dk = 0; dk < this.Drilling.length; dk++) {
                        otherData.push({
                            //---- 部件类型【23，必填】
                            partsType: 24,
                            //---- 部件名称【如，自定义，必填】
                            partsName: "书本打孔",
                            //---- 打孔数【必填】
                            holesNum: this.form.input4,
                        });
                    }
                }
                if (this.Plastic.length != 0) {
                    for (let sf = 0; sf < this.Plastic.length; sf++) {
                        otherData.push({
                            //---- 部件类型【23，必填】
                            partsType: 25,
                            //---- 部件名称【如，自定义，必填】
                            partsName: "画册塑封",
                        });
                    }
                }
                if (this.SelfSealingBag.length != 0) {
                    for (let zfd = 0; zfd < this.SelfSealingBag.length; zfd++) {
                        otherData.push({
                            //---- 部件类型【23，必填】
                            partsType: 26,
                            //---- 部件名称【如，自定义，必填】
                            partsName: "画册套自封袋",
                        });
                    }
                }

                let userInfo = JSON.parse(window.sessionStorage.getItem('currentInfo'))
                console.log(userInfo)

                let datas = this.form;
                if (datas.binding == "") {
                    this.$message.error("请选择装订方式");
                } else if (datas.states == "") {
                    this.$message.error("请填写完整印刷本数");
                } else if (datas.packing == "") {
                    this.$message.error("请填写完整包装方式");
                } else if (datas.distribution == "") {
                    this.$message.error("请填写完整配送方式");
                } else if (datas.distribution_mustaddr != 1 && (datas.provinceId == "" || datas.cityId == "" || datas.areaId == "")) {
                    this.$message.error("请填写完整省市区目的地");
                }
                // else if (this.userType == 1 && (this.servicetext == '' || this.user_ID == '')) {
                //     this.$message.error("请选择服务客户")
                // }
                else if ( this.form.level == "") {
                  this.$message.error("请选择品质要求")
                }else if(datas.needDesign === '1' && !datas.pNums){
                  this.$message.error("设计需要输入p数")
                }  else {
                    let data = {
                        //---- 印厂标识【必填】
                        userfatherid: datas.userfatherid,
                        // ---- 产品类型ID【必填，1028】
                        productId: datas.productId,
                        //---- 用户唯一性标识
                        userId: this.userType == 1 ? this.user_ID : userInfo.user_ID,
                        // ---- 基础数据【必填】
                        baseData: {
                            //---- 是否是自定义【1：是；0：否】
                            isDefind: datas.size == 1 ? "1" : "0",
                            //---- 成品尺寸宽【isDefind = 1，必填；isDefind = 0，非必填】
                            width: datas.size == 1 ? this.cpsize.width : "",
                            //---- 成品尺寸长【isDefind = 1，必填；isDefind = 0，非必填】
                            length: datas.size == 1 ? this.cpsize.height : "",
                            //---- 尺寸ID【isDefind = 0，必填；isDefind = 1，非必填】
                            sizeId: datas.size == 1 ? "" : datas.size,
                            //---- 订单数量【必填】
                            productNum: datas.states,
                            //---- 装订方式【必填】
                            bindingTypeId: datas.binding,
                        },
                        // ---- 封面数据【必填】
                        faceData: faceData,

                        // ---- 内页数据【必填】
                        innerData: innerData,

                        // ---- 其他信息【非必填】
                        otherData: otherData,

                        //---- 包装方式ID【必填】
                        packageTypeId: datas.packing,
                        //---- 配送方式ID【必填】
                        postTypeId: datas.distribution,
                        //---- 省市区
                        dest: datas.dest,
                        //---- 省ID
                        provinceId: datas.provinceId,
                        //---- 市ID
                        cityId: datas.cityId,
                        //---- 区ID
                        areaId: datas.areaId,
                        invoice:  this.form.invoice,
                        level: this.form.level,
                      needDesign:  this.form.needDesign,
                      pNums: this.form.pNums

                    };
                    console.log(data);
                    console.log(JSON.stringify(data));

                    //立即报价
                    this.fullscreenLoading = true;
                   this.$http.post("/cpshttp/getDigit4album", data).then((res) => {
                            console.log(res);
                            console.log(data);
                            console.log(JSON.stringify(data));
                            // this.centerDialogVisible2 = true

                            this.fullscreenLoading = false;
                          if (res.data != '') {
                                this.$message({
                                    message: "报价成功",
                                    type: "success",
                                });

                                this.centerDialogVisible2 = true;
                                //报价信息
                                //---- 弹出框展示数据
                                this.info = res.data.info
                                //---- 部件产品信息
                                this.orderProduct = res.data.orderProduct
                                this.quoteList =  res.data.quoteList ? res.data.quoteList: []
                                this.btnType =  res.data.btnType
                                console.log(this.info)
                            } else {
                              this.$message.error("报价失败",res.message);
                            }
                        }).catch((res) => {
                        this.fullscreenLoading = false;
                        this.$message.error("报价失败",res.message);
                        });
                }
                //     }
                // }
            },

            //发货日期
            //时间转换
            //中国标准时间字符串，转换成y-m-d:h-m-s格式
            time(date) {
                var y = date.getFullYear()
                var m = date.getMonth() + 1
                m = m < 10 ? '0' + m : m
                var d = date.getDate()
                d = d < 10 ? '0' + d : d
                var h = date.getHours()
                h = h < 10 ? '0' + h : h
                var minute = date.getMinutes()
                minute = minute < 10 ? '0' + minute : minute
                var second = date.getSeconds()
                second = second < 10 ? '0' + second : second
                // return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second
                return y + '-' + m + '-' + d
            },


            //立即下单
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        // alert('submit!');
                        console.log(this.ruleForm);
                        // this.$emit('transferUser ',this.ruleForm)
                        // this.$router.push({ name: this.name, params: { xinxi: this.ruleForm } });

                        let userInfo = JSON.parse(window.sessionStorage.getItem('currentInfo'))
                        console.log(userInfo)

                        let ruleForm = this.ruleForm

                        this.centerDialogVisible2 = false
                        let data = {
                            //---- 产品信息【点击（产品报价）返回信息】
                            orderProduct: this.orderProduct,
                            //---- 印件名称
                            orderName: ruleForm.name,
                            //---- 色彩要求【暂无】
                            printType: ruleForm.color,
                            //---- 发货日期
                            completiontime: this.time(new Date(ruleForm.date)),
                            //---- 收货人
                            contacts: ruleForm.consignee,
                            //---- 印厂标识【相当于前面的userfatherid】
                            enterpriseId: this.form.userfatherid,
                            //---- 客户其他备注
                            customSupply: ruleForm.desc,
                            //---- 联系方式
                            telephone: ruleForm.tel,
                            //---- 订单费用
                            orderFee: this.info.totalFee,
                            //---- 送货地址【报价当中的dest + 详情】
                            sendAddrs: ruleForm.address,
                            //---- 配送方式ID
                            // postTypeId:'',
                            //---- 预付款金额
                            preFee: this.info.totalFee * 0.3,
                            //---- 付款方式
                            // paytype: ruleForm.paymentMethod,
                            //---- 用户唯一性标识
                            userid: this.userType == 1 ? this.user_ID : userInfo.user_ID,
                            //---- 代客下单中客户user_id
                            customerId:this.user_ID==''?userInfo.user_ID:this.user_ID,
                        }
                        console.log(data)
                       if(!data.userid){
                          this.$message.error("请选择服务客户");
                          return
                        }
                       this.fullscreenLoading2 = true;
                        setTimeout(() => {
                            this.$http.post('/cpshttp/createMallOrder', data).then((res) => {
                                console.log(res)
                                this.fullscreenLoading2 = false;
                                if (res.data.code == 200) {
                                    this.$message({
                                        message: "下单成功",
                                        type: "success",
                                    });
                                    this.$router.push({name: "Personal", params: {chiecked: 2}});
                                } else {
                                    this.$message.error(res.data.message);
                                }
                            })
                        }, 2000)

                        // }

                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },

            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
        },
        mounted() {

        },
    };
</script>

<style lang="scss" scoped>
    .title {
        color: #058af2;
        padding-left: 20px;
        border-left: 5px solid #058af2;
        /*border-bottom: 1px dashed #ccc;*/
    }

    hr {
        border: none;
        border-bottom: 1px solid #ededed;
        margin: 8px 0;
    }

    .body {
        display: flex;
        margin-top: 20px;
        /*左侧图片*/
        .pic {
            width: 350px;
            display: grid;
            height: 690px;

            img:first-of-type {
                width: 325px;
                height: 325px;
                border: 1px solid #d9d9d9;
            }

            img:last-of-type {
                margin: 10px auto 0 auto;
                width: 150px;
                height: 150px;
                border: 1px solid red;
            }
        }

        /*内容主体*/
        .neirong {
            width: 100%;
            padding-left: 30px;
            /*模块*/
            .modular:first-child {
                h4 {
                    margin-top: 0;
                }
            }

            .modular {
                /*margin-bottom: 20px;*/
                margin-bottom: 0px;

                h4 {
                    margin-top: 8px;
                }

                hr {
                    border-bottom: 1px dashed #ccc;
                }

                .titleh4 {
                    display: flex;
                    justify-content: space-between;
                }

                /*成品尺寸*/
                .chengpin {
                    .class {
                        text-align: right;
                        vertical-align: middle;
                        /*float: right;*/
                        font-size: 14px;
                        color: #606266;
                        line-height: 40px;
                        padding: 0 12px 0 12px;
                        box-sizing: border-box;
                    }

                    .el-form-item {
                        margin-bottom: 0px;
                    }
                }

                .houdao {
                    .el-form-item {
                        margin-bottom: 5px;
                        white-space: nowrap;
                    }

                    .setacreage3_desc {
                        margin-left: 20px;
                    }
                }
            }
        }
    }

    /*立即下单详细信息*/
    .ordernowList {
        margin: 20px auto 0px;
        /*border: 1px solid #EBEEF5;*/

        /*请输入印刷品最显著的标题*/
        .grey {
            line-height: 43px;
            font-size: 14px;
            color: #999;
            font-weight: normal;
            margin-left: 15px;
        }

        /*地址*/
        .black {
            line-height: 43px;
            font-size: 14px;
            color: #333;
            font-weight: normal;
            margin-right: 15px;
        }
    }

    .el-checkbox-group {
        ::v-deep .el-checkbox {
            width: 48% !important;
        }
    }

    .el-form-item__content {
        ::v-deep .el-input {
            margin-right: 0 !important;
        }
    }

</style>

<style lang="scss">
    .el-form-item__label {
        text-align: justify;
        text-align-last: justify;
    }

    /*成品尺寸自定义按钮  */
    .el-select-dropdown {
        .el-select-dropdown__item {
            .el-button {
                width: 100%;
                text-align: left;
                color: #606266;
            }
        }
    }

    /*成品尺寸自定义对话框*/
    .zidingyi {
        .el-dialog {
            .el-form {
                .el-form-item {
                    .el-form-item__content {
                        display: flex;

                        .el-input {
                            width: 20%;
                        }

                        span {
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
    }

    /*标题右侧弹出层*/
    .canshu {
        .titleh4 {
            .el-button {
                padding: 8px 0 0px 0;
            }
        }
    }

    /*后道设置多选框*/
    .el-checkbox-group {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-content: stretch;
        flex-wrap: wrap;
        align-items: flex-start;

        .el-checkbox {
            /*width: 48%;*/
            margin: 5px 10px 5px 0;
            display: flex;
            /*position: relative;*/
            /*display: inline-flex;*/
            .el-checkbox__label {
                /*position: absolute;*/
                /*position: relative;*/
                /*bottom: 0;*/
                .el-form {
                    display: block;
                }

                /*.el-form:last-of-type{*/
                /*    display: block;*/
                /*}*/
            }
        }
    }

    /*后道设置*/
    .houdao {
        .el-form {
            width: 100%;
            display: flex;
            margin: 0 40px 0 0 !important;

            .el-form-item__content {
                margin-left: 0 !important;
            }

            /*文本框*/
            .el-textarea__inner {
                height: 40px;
                width: 100px;
            }

            /*输入框*/
            .input {
                /*display: flex;*/
                margin-bottom: 5px;

                .el-form-item__content {
                    display: flex;
                    margin: 0 !important;
                    /*文本框*/
                    .el-textarea__inner {
                        height: 40px;
                        width: 100px;
                    }

                    .el-input {
                        width: 50px;
                    }

                    /*X:符号*/
                    span {
                        margin: 0 5px;
                    }

                    /*新增按钮*/
                    .newlyAdded {
                      margin-left: 0px;
                        text-align: center;
                        line-height: 25px;
                    }
                }
            }
        }
    }

    /*报价按钮*/
    .baojia {
        width: 100%;

        .el-form-item__content {
            display: flex;
            justify-content: center;
        }
    }

    /*包装方式*/
    .table {
        .el-table__header-wrapper {
            width: 180px;
        }

        .el-table__body-wrapper {
            .el-table__body {
                tbody {
                    .el-table__row {
                        .el-table_2_column_6 {
                            border-right: none;
                        }
                    }
                }
            }
        }
    }

    .el-table th > .cell {
        text-align: center;
    }

    .el-table .cell {
        text-align: center;
    }

    /*立即下单对话框*/
    .orderNow {
        table {
            border: 1px solid #ebeef5;
            width: 100%;
            /*tr{*/
            /*    border-bottom: 1px solid #EBEEF5;*/
            /*}*/
            /*tr:last-of-type{*/
            /*    border-bottom: 0;*/
            /*}*/
            tr {
                th {
                    color: #909399;
                    font-size: 14px;
                    font-weight: bold;
                  padding: 9px 6px;
                    text-align: center;
                    border-right: 1px solid #ebeef5;
                    border-bottom: 1px solid #ebeef5;
                }

                th:last-of-type {
                    border-right: 0;
                }

                td {
                    color: #606266;
                    font-size: 14px;
                  padding: 9px 6px;
                    text-align: center;
                    border-right: 1px solid #ebeef5;
                    border-bottom: 1px solid #ebeef5;
                }

                td:last-of-type {
                    border-right: 0;
                }
            }

            tr:last-of-type th {
                border-bottom: 0;
            }

            tr:last-of-type td {
                border-bottom: 0;
            }

            .packType th {
                border-right: 1px solid #ebeef5 !important;
            }

            .packType td {
                border-right: 1px solid #ebeef5 !important;
            }

            .packType td:last-of-type {
                border-right: 0 !important;
            }
        }
    }

    /*选择封面类型*/
    .el-dialog--center {
        .el-radio-group {
            .el-radio {
                display: flex;
                height: 40px;
                line-height: 40px;
                margin-bottom: 15px;

                .el-radio__input {
                    line-height: 40px;
                }
            }

            .el-input {
                width: auto;
                padding: 0;
                text-align: center;

                .el-input__inner {
                    padding: 0;
                    text-align: center;
                    width: 40px;
                    margin: 0 3px;
                    height: 35px;
                    line-height: 35px;
                }
            }
        }
    }

    /*选择封面类型*/
    .fm {
        .el-dialog--center {
            .el-radio-group {
                .el-input {
                    .el-input__inner {
                        padding: 0;
                        text-align: center;
                        width: 40px;
                        margin: 0 3px;
                        height: 35px;
                        line-height: 35px;
                    }
                }
            }
        }
    }

    /*选择内页类型*/
    .ny {
        .el-dialog--center {
            .el-radio-group {
                .el-input {
                    .el-input__inner {
                        padding: 0;
                        text-align: center;
                        width: 80px;
                        margin: 0 3px;
                        height: 35px;
                        line-height: 35px;
                    }
                }
            }
        }
    }

    /*选择封面类型2*/
    .fengmian {
        .el-form-item__content {
            .el-input {
                width: auto;
                padding: 0;
                text-align: center;

                .el-input__inner {
                    padding: 0;
                    text-align: center;
                    width: 40px;
                    margin: 0 3px;
                    height: 35px;
                    line-height: 35px;
                }
            }
        }
    }

    .ordernowList {
        .el-form {
            .el-form-item {
                margin-bottom: 0px;
                /*border-bottom: 1px solid #EBEEF5;*/
                .el-form-item__content {
                    .el-input {
                        width: 41%;
                    }
                }

                .el-form-item__label {
                    text-align: center;
                    /*background-color: #f5f5f5;*/
                    height: 44px;
                    line-height: 44px;
                    width: 12%;
                    text-align: center;
                    font-size: 14px;
                    color: #333;
                    vertical-align: middle;
                }
            }

        }

        .line {
            display: flex;
            width: 100%;
        }

        .line > div {
            width: 50%;

            .el-select {
                width: 100% !important;
            }

            .el-input {
                width: 100% !important;
            }
        }

        .line > div:nth-child(even) {
            margin-left: 24px;
        }

        .paymentMethod {
            .el-input--suffix {
                width: 100%;
            }
        }
    }
</style>
