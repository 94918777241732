import { render, staticRenderFns } from "./DigitalYilapao.vue?vue&type=template&id=62375140&scoped=true&"
import script from "./DigitalYilapao.vue?vue&type=script&lang=js&"
export * from "./DigitalYilapao.vue?vue&type=script&lang=js&"
import style0 from "./DigitalYilapao.vue?vue&type=style&index=0&id=62375140&lang=scss&scoped=true&"
import style1 from "./DigitalYilapao.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62375140",
  null
  
)

export default component.exports