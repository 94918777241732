<template>
    <div>
        <div>
            <div class="title">瓦楞彩箱报价</div>
            <hr/>
        </div>
        <div class="body">

            <div class="neirong">
                <!--报价产品详情选择-->
                <div class="modular canshu">
                    <span style="display: flex">
                        <h4>报价产品详情选择</h4>
                        <span style="padding: 8px 0 0 0;color: #409EFF;font-size: 14px;margin-left: 10px;">(瓦楞彩箱)</span>
                    </span>
                    <hr/>
                    <el-form ref="form" :model="form" label-width="80px">
                        <!--选择盒型图-->
                        <div class="chengpin boxSize">
                            <el-form-item label="选择盒型图" style="display: block">
                                <el-radio-group v-model="form.RadioBox" @change="RadioBox">
                                    <ul class="size">
                                        <li v-for="item in radioBoxList" :key="item.id">
                                            <el-radio :label="item.id" :value="item.text" border>
                                                <img :src="item.img" alt="" style="width:60px;height: 60px">
                                                <span class="text">{{item.text}}</span>
                                            </el-radio>
                                        </li>
                                    </ul>
                                </el-radio-group>
                            </el-form-item>
                        </div>

                        <!--盒型图类型-->
                        <div class="chengpin boxType">
                            <el-form-item label="类型" style="display: block">
                                <el-radio-group v-model="form.RadioType" @change="RadioType">
                                    <ul class="type">
                                        <li v-for="item in typeList" :key="item.id">
                                            <el-radio :label="item.id" :value="item.text" border>
                                                <span class="text">{{item.text}}</span>
                                            </el-radio>
                                        </li>
                                    </ul>
                                </el-radio-group>
                            </el-form-item>
                        </div>

                        <!--示意圖-->
                        <div class="chengpin SketchMap">
                            <el-form-item label="示意图" style="display: block">
                                <ul class="map">
                                    <li v-for="item in SketchMapList" :key="item">
                                        <el-popover placement="top-start" trigger="hover">
                                            <a :href="item" target="_blank" title="查看最大化图片">
                                                <img :src="item.img" style="width: 300px; height: 300px;"/>
                                            </a>
                                            <img slot="reference" class="imgs" :src="item.img" width="300" height="300"
                                                 alt=""/>
                                        </el-popover>
                                        <img :src="item.img" alt="">
                                    </li>
                                </ul>
                            </el-form-item>
                        </div>

                    </el-form>
                </div>

                <!--基本参数-->
                <div class="modular canshu">
                    <h4>基本参数</h4>
                    <hr/>
                    <el-form ref="form" :model="form" label-width="80px">
                        <!--成品尺寸-->
                        <div class="chengpin">
                            <el-form-item label="成品尺寸">
                                <el-select v-model="form.size" placeholder="请选择成品尺寸">
                                    <!--                                    <el-option-->
                                    <!--                                            v-for="item in specList"-->
                                    <!--                                            :key="item.commonspecid"-->
                                    <!--                                            :label="item.name"-->
                                    <!--                                            :value="item.commonspecid"-->
                                    <!--                                    ></el-option>-->
                                    <el-option :label="zdysize" value="1">
                                        <el-button type="text" @click="centerDialogVisible = true">自定义</el-button>
                                    </el-option>
                                </el-select>
                                <span class="class">(长*宽*高)mm</span>
                              <span class="class">是否设计</span>
                              <el-select
                                  v-model="form.needDesign" >
                                <el-option
                                    label="否"
                                    value="0"
                                ></el-option>
                                <el-option
                                    label="是"
                                    value="1"
                                ></el-option>
                              </el-select>

                              <span class="class"  v-if="form.needDesign==='1'" >  <el-input    placeholder="请输入需要设计的p数" v-model="form.pNums" style="width: 180px;" ></el-input> p</span>

                            </el-form-item>
                        </div>

                        <!--印刷数量-->
                        <div class="chengpin">
                            <el-form-item label="印刷数量">


                                <el-select
                                        v-model="form.states"
                                        placeholder="请输入印刷数量"
                                        filterable
                                        allow-create
                                        default-first-option
                                >
                                    <el-option
                                            v-for="item in restaurantspages"
                                            :key="item"
                                            :label="item"
                                            :value="item"
                                    ></el-option>
                                </el-select>

                                <span class="class">张</span>

                              <el-select
                                  v-model="form.state"
                                  placeholder="请输入款数"
                                  filterable
                                  allow-create
                                  default-first-option
                              >
                                <el-option
                                    v-for="item in restaurants"
                                    :key="item"
                                    :label="item"
                                    :value="item"
                                ></el-option>
                              </el-select>

                              <span class="class">款</span>
                            </el-form-item>
                        </div>

                    </el-form>
                </div>

                <!--上盖设置-->
                <div class="modular canshu">
                    <h4>上盖设置</h4>
                    <hr/>
                    <el-form ref="form" :model="form" label-width="80px">

                        <!--上盖箱层-->
                        <div class="chengpin">
                            <el-form-item label="上盖箱层">
                                <el-select v-model="UpperCover.boxLayer" placeholder="请选择上盖箱层">
                                    <el-option label="3层彩箱" value="3"></el-option>
                                    <el-option label="4层彩箱" value="4"></el-option>
                                    <el-option label="5层彩箱" value="5"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>

                        <!--上盖楞型-->
                        <div class="chengpin">
                            <el-form-item label="上盖楞型">
                                <el-select v-model="UpperCover.CorrugatedType" placeholder="请选择上盖楞型">
                                    <el-option label="E楞" value="e"></el-option>
                                    <el-option label="B楞" value="b"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>

                        <!--印刷数量-->
                        <div class="chengpin">
                            <el-form-item label="印刷数量">
                              <el-select
                                  v-model="form.states"
                                  placeholder="请输入印刷数量"
                                  filterable
                                  allow-create
                                  default-first-option
                              >
                                <el-option
                                    v-for="item in restaurantspages"
                                    :key="item"
                                    :label="item"
                                    :value="item"
                                ></el-option>
                              </el-select>

                              <span class="class">张</span>
                                <el-select
                                        v-model="form.state"
                                        placeholder="请输入款数"
                                        filterable
                                        allow-create
                                        default-first-option
                                >
                                    <el-option
                                            v-for="item in restaurants"
                                            :key="item"
                                            :label="item"
                                            :value="item"
                                    ></el-option>
                                </el-select>

                                <span class="class">款</span>


                            </el-form-item>
                        </div>

                        <!--上盖面纸-->
                        <div class="chengpin">
                            <el-form-item label="纸张克重">
                                <el-select v-model="UpperCover.type" placeholder="请选择纸张克重" @change="Changetype">
                                    <el-option
                                            v-for="item in maList"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id"
                                    ></el-option>
                                </el-select>
                                <span class="class"></span>
                                <el-select v-model="UpperCover.gram" placeholder="请选择克">
                                    <el-option
                                            v-for="item in productWeightList"
                                            :key="item.weightid"
                                            :label="item.name"
                                            :value="item.weightid"
                                    ></el-option>
                                </el-select>
                                <span class="class">克</span>
                                <el-checkbox v-model="UpperCover.isCustMa">自来料</el-checkbox>
                            </el-form-item>
                        </div>

                        <!--上盖瓦楞-->
                        <div class="chengpin">
                            <el-form-item label="上盖瓦楞">
                                <el-select v-model="UpperCover.corrugated" placeholder="请选择上盖瓦楞">
                                    <el-option label="优质(瓦120+130里纸)" value="0"></el-option>
                                    <el-option label="普通(130g里纸130g瓦纸130面纸)" value="1"></el-option>
                                    <el-option label="普通(130g里纸110g瓦纸)" value="2"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>

                        <!--上盖里纸-->
                        <div class="chengpin">
                            <el-form-item label="上盖里纸">
                                <el-radio v-model="UpperCover.LiningPaperColour" label="0">黄色</el-radio>
                                <el-radio v-model="UpperCover.LiningPaperColour" label="1">白色</el-radio>
                            </el-form-item>
                        </div>

                        <!--上盖印刷-->
                        <div class="chengpin" style="display: flex">
                            <el-form-item label="上盖印刷">
                                <el-select v-model="UpperCover.single" placeholder="请选择单双面">
                                    <el-option label="单面" value="color"></el-option>
                                    <el-option label="双面" value="colors"></el-option>
                                </el-select>
                                <span class="class"></span>
                                <el-select v-model="UpperCover.colour" placeholder="请选择印刷颜色">
                                    <el-option
                                            v-for="item in colorList"
                                            :key="item.colorid"
                                            :label="item.name"
                                            :value="item.colorid"
                                    ></el-option>
                                    <el-option label="不印刷" value="">不印刷</el-option>
                                </el-select>
                                <el-input v-model="UpperCover.desc" placeholder="颜色描述，例：蓝"
                                          :style="UpperCover.desc_width"></el-input>
                            </el-form-item>
                        </div>

                        <!--上盖工艺-->
                        <div class="houdao">
                            <el-form-item label="上盖工艺" label-width="100px">
                                <el-checkbox v-model="checkedUp[index]"
                                             @change="CheckedChangeUpperCover($event, index)">{{
                                    checkedUptext }}
                                </el-checkbox>
                            </el-form-item>
                            <el-dialog
                                    :visible.sync="centerDialogVisible4"
                                    center
                                    title="设置上盖工艺"
                                    width="50%"
                                    :before-close="handleClose1"
                            >
                                <div style="height: 452px; overflow: auto">
                                    <el-checkbox-group @change="handleCheckedUpChange"
                                                       v-model="UpperCover.checkedUpCheckbox">
                                      <div v-for="(item,index) in processList" :key="item.id" style="width: 50%;">

                                        <el-checkbox :label="item.id" :value="item.id" v-if="item.mustchoose == 1 && item.defaultShow != 1"
                                                     :checked="true"
                                                     disabled>
                                          {{ item.name }}

                                          <div v-if="item.setacreage == 0">
                                            <div v-for="i in setacreageUp0" :key="i.id">
                                              <el-form ref="form" label-width="80px" v-if="item.id == i.id">
                                                <el-form-item label="">
                                                  <el-select
                                                      v-model="i.models"
                                                      @change="selecChangeUp"
                                                      :placeholder="'请选择' + i.name"
                                                      style="width: 150px;"
                                                  >
                                                    <el-option
                                                        v-for="(items, index) in i.processesSetList"
                                                        :key="index"
                                                        :label="items.name"
                                                        :value="items.id"
                                                    ></el-option>
                                                  </el-select>
                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>
                                        </el-checkbox>

                                        <el-checkbox :label="item.id" :value="item.id" v-if="item.mustchoose !== 1"
                                                     :checked="item.defaultShow == 1">
                                          {{ item.name }}
                                        </el-checkbox>


                                        <div v-if="item.setacreage == 0 && item.mustchoose !== 1">
                                          <div v-for="i in setacreageUp0" :key="i.id">
                                            <el-form ref="form" label-width="80px" v-if="item.id == i.id">
                                              <el-form-item label="">
                                                <el-select
                                                    v-model="i.models"
                                                    @change="selecChangeUp"
                                                    :placeholder="'请选择' + i.name"
                                                    style="width: 150px;"
                                                >
                                                  <el-option
                                                      v-for="(items, index) in i.processesSetList"
                                                      :key="index"
                                                      style="width: 150px;"
                                                      :label="items.name"
                                                      :value="items.id"
                                                  ></el-option>
                                                </el-select>
                                              </el-form-item>
                                            </el-form>
                                          </div>
                                        </div>

                                        <div v-if="item.setacreage == 1">
                                          <div v-for="i in setacreageUp0" :key="i.id">
                                            <el-form ref="form" :model="form" label-width="80px" v-if="item.id == i.id">

                                              <el-form-item label="" :label-width="formWidth"
                                                            v-if="(i.num >0)&&item.processesSetList[0].id==i.processesSetList[0].id">

                                                <el-form-item label="" class="input" :id="item.id"
                                                              v-for="(a,index) in +i.num" :key="index">
                                                  <el-form-item label="" :label-width="formWidth">
                                                    <el-input v-model="i.inSize[index].width" autocomplete="off"
                                                              :id="i.id"
                                                              style="width: 50px;"
                                                              @change="UpwidthChange($event,i,index)"></el-input>
                                                    <span>X</span>
                                                  </el-form-item>
                                                  <el-form-item label="" :label-width="formWidth">
                                                    <el-input v-model="i.inSize[index].length" autocomplete="off"
                                                              style="width: 50px;"
                                                              @change="UplengthChange($event,i,index)"></el-input>
                                                    <span>mm</span>
                                                    <el-button v-if="a==1" type="text" class="newlyAdded" size="small"
                                                               @click="UpnewlyAdded(item, i)">增
                                                    </el-button>
                                                    <el-button v-else type="text" class="newlyAdded" size="small"
                                                               @click="Updel(item, i)">删
                                                    </el-button>
                                                  </el-form-item>
                                                </el-form-item>

                                              </el-form-item>
                                            </el-form>
                                          </div>
                                        </div>

                                        <div v-if="item.setacreage == 2" style="margin-bottom: 20px;">
                                          <div v-for="i in setacreageUp0" :key="i.id">
                                            <el-form
                                                ref="form"
                                                :model="form"
                                                label-width="80px"
                                                v-if="item.id == i.id"
                                            >

                                              <div label="" :label-width="formWidth"
                                                   v-if="(i.num >0)&&item.processesSetList[0].id==i.processesSetList[0].id"  style="margin-top: -30px;margin-bottom: 20px;">

                                                <div label="" class="input" :id="item.id"
                                                     v-for="(a,index) in +i.num" :key="index" style="width: 380px; height: 41px;">

                                                  <el-form-item label="" v-if="item.id == i.id"   style="display:inline-flex;">
                                                    <select
                                                        v-model="i.inSize[index].models"
                                                        :placeholder="'请选择' + i.name"
                                                        class="myselect"
                                                        @change="selecChangeUp1(i.inSize[index].models,i,index)"
                                                    >
                                                      <option
                                                          v-for="(items, index) in i.processesSetList"
                                                          :key="index"
                                                          :label="items.name"
                                                          :value="items.id + ',' + items.name"
                                                      ></option>
                                                    </select>
                                                  </el-form-item>
                                                  <el-form-item label=""  style="display:inline-flex;">
                                                    长<el-input-number v-model="i.inSize[index].width" autocomplete="off"
                                                                      placeholder="长度" :controls ="false"
                                                                      :id="i.id" style="width: 60px;"
                                                                      @change="UpwidthChange($event,i,index)"></el-input-number>
                                                    <span >X</span>
                                                  </el-form-item>
                                                  <el-form-item label=""  style="display:inline-flex;">
                                                    宽<el-input-number v-model="i.inSize[index].length"   style="width: 60px;"
                                                                      placeholder="宽度" :controls ="false"
                                                                      @change="UplengthChange($event,i,index)"></el-input-number>
                                                    <span >mm</span>

                                                  </el-form-item>
                                                  <el-form-item label=""  style="display:inline-flex;">

                                                    <el-button v-if="a==1" type="text" class="newlyAdded"  style="margin-top: 20px;"
                                                               @click="UpnewlyAdded(item, i)">增
                                                    </el-button>
                                                    <el-button v-else type="text" class="newlyAdded"  style="margin-top: 20px;"
                                                               @click="Updel(item, i)">删
                                                    </el-button>
                                                  </el-form-item>

                                                </div>

                                              </div>



                                            </el-form>
                                          </div>
                                        </div>

                                        <div v-if="item.setacreage == 3">
                                          <div v-for="i in setacreageUp0" :key="i.id">
                                            <el-form
                                                ref="form"
                                                :model="form"
                                                label-width="80px"
                                                v-if="item.id == i.id"
                                            >
                                              <el-form-item label="">
                                                <el-select
                                                    v-model="i.models"
                                                    @change="selecChangeUp"
                                                    :placeholder="'请选择' + i.name"
                                                    style="width: 150px;"
                                                >
                                                  <el-option
                                                      v-for="items in i.processesSetList"
                                                      :key="items.id"
                                                      :label="items.name"
                                                      :value="items.id"
                                                  ></el-option>
                                                </el-select>
                                              </el-form-item>
                                              <el-form-item label="数量" label-width="75px;" style="margin-left: 2px;" class="setacreage3_desc">
                                                <el-input
                                                    @change="textareaUpChange($event,i,index)"
                                                    style="width: 50px;"
                                                    v-model="i.desc"
                                                ></el-input>
                                              </el-form-item>
                                            </el-form>
                                          </div>
                                        </div>
                                      </div>
                                    </el-checkbox-group>
                                </div>

                                <span class="dialog-footer" slot="footer">
                            <el-button @click="cancel1(index)">取 消</el-button>
                            <el-button @click="centerDialogVisible4 = false;SubmitUp(index);"
                                       type="primary">提 交</el-button>
                        </span>
                            </el-dialog>
                        </div>
                    </el-form>
                </div>

                <!--下盖设置-->
                <div class="modular canshu">
                    <h4>下盖设置</h4>
                    <hr/>
                    <el-form ref="form" :model="form" label-width="80px">

                        <!--下盖箱层-->
                        <div class="chengpin">
                            <el-form-item label="下盖箱层">
                                <el-select v-model="LowerCover.boxLayer" placeholder="请选择下盖箱层">
                                    <el-option label="3层彩箱" value="3"></el-option>
                                    <el-option label="4层彩箱" value="4"></el-option>
                                    <el-option label="5层彩箱" value="5"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>

                        <!--下盖楞型-->
                        <div class="chengpin">
                            <el-form-item label="下盖楞型">
                                <el-select v-model="LowerCover.CorrugatedType" placeholder="请选择下盖楞型">
                                    <el-option label="E楞" value="e"></el-option>
                                    <el-option label="B楞" value="b"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>

                        <!--印刷数量-->
                        <div class="chengpin">
                            <el-form-item label="印刷数量">
                              <el-select
                                  v-model="form.states"
                                  placeholder="请输入印刷数量"
                                  filterable
                                  allow-create
                                  default-first-option
                              >
                                <el-option
                                    v-for="item in restaurantspages"
                                    :key="item"
                                    :label="item"
                                    :value="item"
                                ></el-option>
                              </el-select>

                              <span class="class">张</span>
                                <el-select
                                        v-model="form.state"
                                        placeholder="请输入款数"
                                        filterable
                                        allow-create
                                        default-first-option
                                >
                                    <el-option
                                            v-for="item in restaurants"
                                            :key="item"
                                            :label="item"
                                            :value="item"
                                    ></el-option>
                                </el-select>

                                <span class="class">款</span>


                            </el-form-item>
                        </div>

                        <!--下盖面纸-->
                        <div class="chengpin">
                            <el-form-item label="纸张克重">
                                <el-select v-model="LowerCover.type" placeholder="请选择纸张克重" @change="Changetype">
                                    <el-option
                                            v-for="item in maList"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id"
                                    ></el-option>
                                </el-select>
                                <span class="class"></span>
                                <el-select v-model="LowerCover.gram" placeholder="请选择克">
                                    <el-option
                                            v-for="item in productWeightList"
                                            :key="item.weightid"
                                            :label="item.name"
                                            :value="item.weightid"
                                    ></el-option>
                                </el-select>
                                <span class="class">克</span>
                                <el-checkbox v-model="LowerCover.isCustMa">自来料</el-checkbox>
                            </el-form-item>
                        </div>

                        <!--下盖瓦楞-->
                        <div class="chengpin">
                            <el-form-item label="下盖瓦楞">
                                <el-select v-model="LowerCover.corrugated" placeholder="请选择下盖瓦楞">
                                    <el-option label="优质(瓦120+130里纸)" value="0"></el-option>
                                    <el-option label="普通(130g里纸130g瓦纸130面纸)" value="1"></el-option>
                                    <el-option label="普通(130g里纸110g瓦纸)" value="2"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>

                        <!--下盖里纸-->
                        <div class="chengpin">
                            <el-form-item label="下盖里纸">
                                <el-radio v-model="LowerCover.LiningPaperColour" label="0">黄色</el-radio>
                                <el-radio v-model="LowerCover.LiningPaperColour" label="1">白色</el-radio>
                            </el-form-item>
                        </div>

                        <!--下盖印刷-->
                        <div class="chengpin" style="display: flex">
                            <el-form-item label="下盖印刷">
                                <el-select v-model="LowerCover.single" placeholder="请选择单双面">
                                    <el-option label="单面" value="color"></el-option>
                                    <el-option label="双面" value="colors"></el-option>
                                </el-select>
                                <span class="class"></span>
                                <el-select v-model="LowerCover.colour" placeholder="请选择印刷颜色">
                                    <el-option
                                            v-for="item in colorList"
                                            :key="item.colorid"
                                            :label="item.name"
                                            :value="item.colorid"
                                    ></el-option>
                                    <el-option label="不印刷" value="">不印刷</el-option>
                                </el-select>
                                <el-input v-model="LowerCover.desc" placeholder="颜色描述，例：蓝"
                                          :style="LowerCover.desc_width"></el-input>
                            </el-form-item>
                        </div>

                        <!--下盖工艺-->
                        <div class="houdao">
                            <el-form-item label="下盖工艺" label-width="100px">
                                <el-checkbox v-model="checkedLower[index]"
                                             @change="CheckedChangeLowerCover($event, index)">{{
                                    checkedLowertext }}
                                </el-checkbox>
                            </el-form-item>
                            <el-dialog
                                    :visible.sync="centerDialogVisible5"
                                    center
                                    title="设置下盖工艺"
                                    width="50%"
                                    :before-close="handleClose2"
                            >
                                <div style="height: 300px; overflow: auto">
                                    <el-checkbox-group @change="handleCheckedLowerChange"
                                                       v-model="LowerCover.checkedLowerCheckbox">


                                      <div v-for="(item,index) in processList" :key="item.id" style="width: 50%;">

                                        <el-checkbox :label="item.id" :value="item.id" v-if="item.mustchoose == 1 && item.defaultShow != 1"
                                                     :checked="true"
                                                     disabled>
                                          {{ item.name }}

                                          <div v-if="item.setacreage == 0">
                                            <div v-for="i in setacreageLower0" :key="i.id">
                                              <el-form ref="form" label-width="80px" v-if="item.id == i.id">
                                                <el-form-item label="">
                                                  <el-select
                                                      v-model="i.models"
                                                      @change="selecChangeLower"
                                                      :placeholder="'请选择' + i.name"
                                                      style="width: 150px;"
                                                  >
                                                    <el-option
                                                        v-for="(items, index) in i.processesSetList"
                                                        :key="index"
                                                        :label="items.name"
                                                        :value="items.id"
                                                    ></el-option>
                                                  </el-select>
                                                </el-form-item>
                                              </el-form>
                                            </div>
                                          </div>
                                        </el-checkbox>

                                        <el-checkbox :label="item.id" :value="item.id" v-if="item.mustchoose !== 1"
                                                     :checked="item.defaultShow == 1">
                                          {{ item.name }}
                                        </el-checkbox>


                                        <div v-if="item.setacreage == 0 && item.mustchoose !== 1">
                                          <div v-for="i in setacreageLower0" :key="i.id">
                                            <el-form ref="form" label-width="80px" v-if="item.id == i.id">
                                              <el-form-item label="">
                                                <el-select
                                                    v-model="i.models"
                                                    @change="selecChangeLower"
                                                    :placeholder="'请选择' + i.name"
                                                    style="width: 150px;"
                                                >
                                                  <el-option
                                                      v-for="(items, index) in i.processesSetList"
                                                      :key="index"
                                                      style="width: 150px;"
                                                      :label="items.name"
                                                      :value="items.id"
                                                  ></el-option>
                                                </el-select>
                                              </el-form-item>
                                            </el-form>
                                          </div>
                                        </div>

                                        <div v-if="item.setacreage == 1">
                                          <div v-for="i in setacreageLower0" :key="i.id">
                                            <el-form ref="form" :model="form" label-width="80px" v-if="item.id == i.id">

                                              <el-form-item label="" :label-width="formWidth"
                                                            v-if="(i.num >0)&&item.processesSetList[0].id==i.processesSetList[0].id">

                                                <el-form-item label="" class="input" :id="item.id"
                                                              v-for="(a,index) in +i.num" :key="index">
                                                  <el-form-item label="" :label-width="formWidth">
                                                    <el-input v-model="i.inSize[index].width" autocomplete="off"
                                                              :id="i.id"
                                                              style="width: 50px;"
                                                              @change="LowerwidthChange($event,i,index)"></el-input>
                                                    <span>X</span>
                                                  </el-form-item>
                                                  <el-form-item label="" :label-width="formWidth">
                                                    <el-input v-model="i.inSize[index].length" autocomplete="off"
                                                              style="width: 50px;"
                                                              @change="LowerlengthChange($event,i,index)"></el-input>
                                                    <span>mm</span>
                                                    <el-button v-if="a==1" type="text" class="newlyAdded" size="small"
                                                               @click="LowernewlyAdded(item, i)">增
                                                    </el-button>
                                                    <el-button v-else type="text" class="newlyAdded" size="small"
                                                               @click="Lowerdel(item, i)">删
                                                    </el-button>
                                                  </el-form-item>
                                                </el-form-item>

                                              </el-form-item>
                                            </el-form>
                                          </div>
                                        </div>

                                        <div v-if="item.setacreage == 2" style="margin-bottom: 20px;">
                                          <div v-for="i in setacreageLower0" :key="i.id">
                                            <el-form
                                                ref="form"
                                                :model="form"
                                                label-width="80px"
                                                v-if="item.id == i.id"
                                            >

                                              <div label="" :label-width="formWidth"
                                                   v-if="(i.num >0)&&item.processesSetList[0].id==i.processesSetList[0].id"  style="margin-top: -30px;margin-bottom: 20px;">

                                                <div label="" class="input" :id="item.id"
                                                     v-for="(a,index) in +i.num" :key="index" style="width: 380px; height: 41px;">

                                                  <el-form-item label="" v-if="item.id == i.id"   style="display:inline-flex;">
                                                    <select
                                                        v-model="i.inSize[index].models"
                                                        :placeholder="'请选择' + i.name"
                                                        class="myselect"
                                                        @change="selecChangeLower1(i.inSize[index].models,i,index)"
                                                    >
                                                      <option
                                                          v-for="(items, index) in i.processesSetList"
                                                          :key="index"
                                                          :label="items.name"
                                                          :value="items.id + ',' + items.name"
                                                      ></option>
                                                    </select>
                                                  </el-form-item>
                                                  <el-form-item label=""  style="display:inline-flex;">
                                                    长<el-input-number v-model="i.inSize[index].width" autocomplete="off"
                                                                      placeholder="长度" :controls ="false"
                                                                      :id="i.id" style="width: 60px;"
                                                                      @change="LowerwidthChange($event,i,index)"></el-input-number>
                                                    <span >X</span>
                                                  </el-form-item>
                                                  <el-form-item label=""  style="display:inline-flex;">
                                                    宽<el-input-number v-model="i.inSize[index].length"   style="width: 60px;"
                                                                      placeholder="宽度" :controls ="false"
                                                                      @change="LowerlengthChange($event,i,index)"></el-input-number>
                                                    <span >mm</span>

                                                  </el-form-item>
                                                  <el-form-item label=""  style="display:inline-flex;">

                                                    <el-button v-if="a==1" type="text" class="newlyAdded"  style="margin-top: 20px;"
                                                               @click="LowernewlyAdded(item, i)">增
                                                    </el-button>
                                                    <el-button v-else type="text" class="newlyAdded"  style="margin-top: 20px;"
                                                               @click="Lowerdel(item, i)">删
                                                    </el-button>
                                                  </el-form-item>

                                                </div>

                                              </div>



                                            </el-form>
                                          </div>
                                        </div>

                                        <div v-if="item.setacreage == 3">
                                          <div v-for="i in setacreageLower0" :key="i.id">
                                            <el-form
                                                ref="form"
                                                :model="form"
                                                label-width="80px"
                                                v-if="item.id == i.id"
                                            >
                                              <el-form-item label="">
                                                <el-select
                                                    v-model="i.models"
                                                    @change="selecChangeLower"
                                                    :placeholder="'请选择' + i.name"
                                                    style="width: 150px;"
                                                >
                                                  <el-option
                                                      v-for="items in i.processesSetList"
                                                      :key="items.id"
                                                      :label="items.name"
                                                      :value="items.id"
                                                  ></el-option>
                                                </el-select>
                                              </el-form-item>
                                              <el-form-item label="数量" label-width="75px;" style="margin-left: 2px;" class="setacreage3_desc">
                                                <el-input
                                                    @change="textareaLowerChange($event,i,index)"
                                                    style="width: 50px;"
                                                    v-model="i.desc"
                                                ></el-input>
                                              </el-form-item>
                                            </el-form>
                                          </div>
                                        </div>
                                      </div>
                                    </el-checkbox-group>
                                </div>

                                <span class="dialog-footer" slot="footer">
                            <el-button @click="cancel2(index)">取 消</el-button>
                            <el-button @click="centerDialogVisible5 = false;SubmitLower(index);"
                                       type="primary">提 交</el-button>
                        </span>
                            </el-dialog>
                        </div>
                    </el-form>
                </div>

                <!--包装方式-->
                <div class="modular">
                    <h4>包装方式</h4>
                    <hr/>
                    <el-form ref="form" :model="form" label-width="80px">
                        <!--包装方式-->
                        <div class="chengpin">
                            <el-form-item label="包装方式">
                                <el-select v-model="form.packing" placeholder="请选择包装方式">
                                    <el-option
                                            v-for="item in packageList"
                                            :key="item.id"
                                            :label="item.typename"
                                            :value="item.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </el-form>
                </div>

                <!--配送方式-->
                <div class="modular">
                    <h4>配送方式</h4>
                    <hr/>
                    <el-form ref="form" :model="form" label-width="80px">
                        <!--包装方式-->
                        <div class="chengpin">
                            <el-form-item label="配送方式">
                                <el-select
                                        v-model="form.distribution"
                                        placeholder="请选择配送方式"
                                        @change="selecChangepsfs($event)"
                                >
                                    <el-option
                                            v-for="item in postList"
                                            :key="item.id"
                                            :label="item.typename"
                                            :value="item.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </div>

                        <!--服务客户对话框-->
                        <div class="chengpin" v-if="this.userType !== 0" @click="service = true">
                            <el-form-item label="服务客户">
                                <el-input placeholder="请选择服务客户" v-model="servicetext"></el-input>
                            </el-form-item>
                        </div>

                        <!--目的地-->
                        <div class="chengpin" v-if="this.form.distribution_mustaddr != 1">
                            <el-form-item label="目的地" prop="selectedOptions">
                                <el-cascader
                                        size="large"
                                        :options="options"
                                        v-model="form.selectedOptions"
                                        @change="handleChange"
                                >
                                </el-cascader>
                            </el-form-item>
                        </div>

                      <div class="chengpin">
                        <el-form-item label="发票要求">
                          <el-select v-model="form.invoice"   >
                            <el-option  v-for="i  in invoiceList" :label="i.name" :value="i.num"></el-option>
                          </el-select>
                        </el-form-item>
                      </div>

                    </el-form>
                </div>



                <div class="baojia">
                    <el-form>
                        <el-form-item>
                            <el-button
                                    type="primary"
                                    @click="onSubmit()"
                                    v-loading.fullscreen.lock="fullscreenLoading"
                            >立即报价
                            </el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>

            <!--服务客户-->
            <el-dialog title="服务客户" :visible.sync="service" width="68%" center>
                <customer-service @transferUser="change($event)"></customer-service>
                <span slot="footer" class="dialog-footer">
                  <el-button @click="cancel">取 消</el-button>
                  <el-button type="primary" @click="determine">确 定</el-button>
                </span>
            </el-dialog>

            <!--成品尺寸自定义对话框-->
            <div class="zidingyi">
                <el-dialog
                        title="设置自定义尺寸"
                        :visible.sync="centerDialogVisible"
                        width="30%"
                        center
                >
                    <el-form :model="cpsize">
                        <el-form-item label="长" :label-width="formLabelWidth">
                            <el-input v-model="cpsize.length" autocomplete="off"></el-input>
                            <span>mm</span>
                        </el-form-item>
                        <el-form-item label="宽" :label-width="formLabelWidth">
                            <el-input v-model="cpsize.width" autocomplete="off"></el-input>
                            <span>mm</span>
                        </el-form-item>
                        <el-form-item label="高" :label-width="formLabelWidth">
                            <el-input v-model="cpsize.height" autocomplete="off"></el-input>
                            <span>mm</span>
                        </el-form-item>
                    </el-form>
                    <span slot="footer" class="dialog-footer">
            <!--                  <el-button @click="centerDialogVisible = false">取 消</el-button>-->
            <el-button type="primary" @click="centerDialogVisible = false;tijiao();">提交</el-button>
          </span>
                </el-dialog>
            </div>

            <!--立即下单对话框-->
            <div class="orderNow">
                <el-dialog title="报价详情" :visible.sync="centerDialogVisible2">
                    <table>
                        <tr>
                            <th>成品尺寸(宽*高)</th>
                            <th>印刷数量</th>
                            <th>印刷颜色</th>
                            <th>印刷纸张</th>
                            <th>后道工艺</th>
                        </tr>
                        <tr>
                            <td>{{ info.productSpec }}</td>
                            <td>{{ info.productNum }}</td>
                            <td>{{ info.printColor }}</td>
                            <td>{{ info.paperName }}</td>
                            <td>{{ info.aftDesc }}</td>
                        </tr>
                      <tr class="packType">
                        <th v-if="orderProduct.needDesign === '1'">设计</th>
                        <td v-if="orderProduct.needDesign === '1'">{{orderProduct.pNums}}p</td>
                        <td style="font-weight: bold">包装方式</td>
                        <td :colspan="orderProduct.needDesign === '1'?2:4">{{info.packageType}}</td>
                      </tr>
                    </table>

                    <div class="cost"
                         style="display: flex;margin-top: 10px;justify-content: space-evenly;line-height: 30px;">
                        <div>
                            <span class="text" style="color: #999; font-size: 12px">系统报价：</span>
                            <span style="color: #ed6d00;font-size: 14px;font-weight: 700;font-family: tahoma;">￥{{orderProduct.sysFee}}</span>
                        </div>
                        <div>
                            <span class="text" style="color: #999; font-size: 12px">运费：</span>
                            <span style="color: #ed6d00;font-size: 14px;font-weight: 700;font-family: tahoma;">￥{{info.postFee}}</span>
                        </div>
                      <div>
                        <span class="text" style="color: #999; font-size: 12px">税费：</span>
                        <span style="color: #ed6d00;font-size: 14px;font-weight: 700;font-family: tahoma;">￥{{info.invoiceFee}}</span>
                      </div>
                      <div>
                        <span class="text" style="color: #999; font-size: 12px">成交价：</span>
                        <span style="color: #ed6d00;font-size: 24px;font-weight: 700;font-family: tahoma;">￥{{orderProduct.productFee}}</span>
                      </div>
                      <div >
                        <el-button type="text"  v-if="userType == 1" @click="openFeeDetail1(orderProduct.orderExtendInfoJson)">报价明细</el-button>
                      </div>
                    </div>

                    <!--下单信息-->
                    <div class="ordernowList">
                        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px"
                                 class="demo-ruleForm">
                            <el-form-item label="印件名称" prop="name">
                                <el-input v-model="ruleForm.name" placeholder="请选择印件名称"></el-input>
                                <span class="grey">请输入印刷品最显著的标题</span>
                            </el-form-item>
                            <div class="line">

                                <el-form-item label="色彩要求" prop="color">
                                    <el-select v-model="ruleForm.color" placeholder="请选择色彩要求">
                                        <el-option v-for="(item,index) in colourList" :key="index" :label="item"
                                                   :value="item"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="发货日期" required>
                                    <el-form-item prop="date">
                                        <el-date-picker type="date" placeholder="选择日期" v-model="ruleForm.date"
                                                        :picker-options="pickerOptions0"
                                                        style="width: 100%;"></el-date-picker>
                                    </el-form-item>
                                </el-form-item>

                            </div>

                            <div class="line" v-if="this.form.distribution_mustaddr!=1">

                                <el-form-item label="收货人" prop="consignee">
                                    <el-input v-model="ruleForm.consignee" placeholder="请选择收货人"></el-input>
                                </el-form-item>
                                <el-form-item label="联系电话" prop="tel">
                                    <el-input v-model="ruleForm.tel" placeholder="请选择联系电话"></el-input>
                                </el-form-item>

                            </div>

                            <el-form-item label="收货地址" v-if="this.form.distribution_mustaddr!=1">
                                <span class="black">{{orderProduct.dest}}</span>
                                <el-input v-model="ruleForm.address"></el-input>
                                <span class="grey">如需配送多个地址，请下单后在订单管理页面修改</span>
                            </el-form-item>

                            <el-form-item label="其他备注">
                                <el-input type="textarea" v-model="ruleForm.desc"></el-input>
                            </el-form-item>

                            <!--                            <el-form-item class="paymentMethod" label="付款方式">-->
                            <!--                                <el-select v-model="ruleForm.paymentMethod" placeholder="请选择付款方式">-->
                            <!--                                    <el-option v-for="(item,index) in paymentMethodList" :key="index" :label="item"-->
                            <!--                                               :value="item"></el-option>-->
                            <!--                                </el-select>-->
                            <!--                            </el-form-item>-->

                          <el-form-item style="margin-top: 10px;">
                                <el-button type="primary" @click="centerDialogVisible2; submitForm('ruleForm')"
                                           v-loading.fullscreen.lock="fullscreenLoading2">立即下单
                                </el-button>
                                <el-button @click="resetForm('ruleForm')">重置</el-button>
                            </el-form-item>
                        </el-form>
                    </div>

                </el-dialog>
              <el-dialog title="报价明细" :visible.sync="feeDialogVisible"       :append-to-body="true">
                <el-table :data="feeList" border>
                  <el-table-column prop="broadHeading" label="大类"></el-table-column>
                  <el-table-column prop="pronname" label="小类"></el-table-column>
                  <el-table-column prop="provalue" label="公式"></el-table-column>
                  <el-table-column prop="value" label="价格"></el-table-column>
                </el-table>
              </el-dialog>
            </div>
        </div>

        <!--纸张材质&贴心服务&下单流程-->
        <!--        <goods-list></goods-list>-->
    </div>
</template>

<script>
    import {regionData, CodeToText} from "element-china-area-data";
    import {getusername} from "../../api/myAuth";
    // import goodsList from "../goodsList/goodslist";
    import CustomerService from "../CustomerService/CustomerService";

    // import {username} from "../../api/myAuth";

    export default {
        name: "CorrugatedBox",
        components: {
            // goodsList,
            CustomerService
        },
        data() {
            // 手机验证
            var validateMobilePhone = (rule, value, callback) => {
                if (value === "") {
                    callback(new Error("手机号不可为空"));
                } else {
                    if (value !== "") {
                        var reg = /^1[3456789]\d{9}$/;
                        if (!reg.test(value)) {
                            callback(new Error("请输入有效的手机号码"));
                        }
                    }
                    callback();
                }
            };
            return {
              feeList: [],
              feeDialogVisible: false,
                // 设置只能选择当前日期及之后的日期
                pickerOptions0: {
                    disabledDate(time) {
                        //如果没有后面的-8.64e7就是不可以选择今天的
                        return time.getTime() < Date.now() - 8.64e7;
                    }
                },

                isCustMa: false,//自来料

                specList: [], //产品规格列表(基本参数)
                restaurants: [], //印刷数量（款）
                restaurantspages: [
                    500,
                    1000,
                    2000,
                    3000,
                    4000,
                    5000,
                    6000,
                    7000,
                    8000,
                    9000,
                    10000,
                    15000,
                    20000,
                    25000,
                    30000,
                    35000,
                    40000,
                    45000,
                    50000,
                    55000,
                    60000,
                    65000,
                    70000,
                    75000,
                    80000,
                    85000,
                    90000,
                    95000,
                    100000,
                ], //印刷数量（张/款）
                maList: [], //纸张克重（类型）
                productWeightList: [], //纸张克重（克）
                colorList: [], //印刷颜色
                processList: [], //后道设置
                processesSetList: [], //后道设置（子类）
                packageList: [], //包装方式
                postList: [], //配送方式

                //基本参数
                form: {
                    userfatherid: "", //印厂标识【必填】
                    productId: "", //产品类型ID【必填，1028】
                    size: "", //成品尺寸
                    state: "", //印刷数量（款）
                    states: "", //印刷数量（张/款）
                    type: "", //纸张克重（类型）
                    gram: "", //纸张克重（克）
                    single: "", //印刷颜色（单双面）
                    colour: "", //印刷颜色（颜色）

                    RadioBox: 10,//选择盒型图
                    RadioType: 11,//盒型图类型
                    // SketchMap: 0,//示意图
                    bagbox: "",//包盒方式
                    inputcg: "",//插盖
                    inputgk: "",//盖宽
                    inputsd: "",//锁底插入

                    packing: "", //包装方式
                    distribution: "", //配送方式
                    distribution_mustaddr: "", //配送方式_mustaddr(mustaddr:1,不填写目的地)
                    selectedOptions: [], //目的地
                    dest: "", //省市区
                    provinceId: "", //省ID
                    cityId: "", //市ID
                    areaId: "", //区ID
                  needDesign: '0',
                  pNums: '1',
                    invoice: ""
                },
              invoiceList: [],

                //上盖设置
                UpperCover: {
                    boxLayer: '3',//上盖箱层
                    CorrugatedType: 'e',//上盖楞型
                    type: "", //上盖面纸_纸张克重（类型）
                    gram: "", //上盖面纸_纸张克重（克）
                    isCustMa: false,//上盖自来料
                    corrugated: '0',//上盖瓦楞
                    LiningPaperColour: '0',//上盖里纸颜色
                    single: "colors", //上盖印刷颜色（单双面）
                    colour: "", //上盖印刷颜色（颜色）
                    desc: "",//上盖颜色描述
                    desc_width: "width:155px;margin-left:24px",//上盖颜色描述_文本框长度
                    checkedUpCheckbox: [], //上盖后道设置
                },

                //下盖设置
                LowerCover: {
                    boxLayer: '3',//下盖箱层
                    CorrugatedType: 'e',//下盖楞型
                    type: "", //下盖面纸_纸张克重（类型）
                    gram: "", //下盖面纸_纸张克重（克）
                    isCustMa: false,//下盖自来料
                    corrugated: '0',//下盖瓦楞
                    LiningPaperColour: '0',//下盖里纸颜色
                    single: "colors", //下盖印刷颜色（单双面）
                    colour: "", //下盖印刷颜色（颜色）
                    desc: "",//下盖颜色描述
                    desc_width: "width:155px;margin-left:24px",//下盖颜色描述_文本框长度
                    checkedLowerCheckbox: [], //下盖后道设置
                },

                centerDialogVisible4: false, //设置上盖工艺
                centerDialogVisible5: false, //设置下盖工艺
                checkedUp: [false], //上盖工艺
                checkedUptext: '', //上盖工艺_内容
                checkedLower: [false], //下盖工艺
                checkedLowertext: '', //下盖工艺_内容
                indexUp: 0,//上盖工艺下标
                indexLower: 0,//下盖工艺下标

                checkedUpCheckbox: [], //上盖后道设置
                checkedUpCheckbox1: [], //上盖后道设置
                checkedLowerCheckbox: [], //下盖后道设置
                checkedLowerCheckbox1: [], //下盖后道设置


                selectedOptions: [], //目的地
                //成品尺寸自定义长宽高
                cpsize: {
                    width: "",
                    height: "",
                    length: ""
                },
                formLabelWidth: "50px", //成品尺寸自定义宽高输入框宽度

                formWidth: "20px", //后道设置新增宽高输入框宽度

                //自定义尺寸
                zdysize: "自定义",

                setacreageUp0: [], //上盖下拉框
                setacreageLower0: [], //下盖下拉框

                state: "",

                centerDialogVisible: false, //成品尺寸自定义对话框

                centerDialogVisible2: false, //立即下单对话框

                addressList: [], // 地址列表
                options: regionData, //省市区三级联动数据

                //立即报价_指令加载方式
                fullscreenLoading: false,

                //报价信息
                //---- 弹出框展示数据
                info: [],
                //---- 部件产品信息
                orderProduct: [],

                colourList: ['正常印刷，无特别要求', '显示屏，鲜艳饱和', '有印样，严格追样（不含数码样）', '有样稿，仅供参考', '样稿未到，到样时间：', '看兰纸', '预约上机看样', '其他'],//色彩要求列表
                paymentMethodList: ['全款下单', '预付30%下单', '货到付款', '带款提货', '账期客户'],//付款方式列表
                ruleForm: {
                    name: '',//印件名称
                    color: '',//色彩要求
                    date: '',//发货日期
                    consignee: '',//收货人
                    tel: '',//联系电话
                    address: '',//收货地址
                    paymentMethod: '',//付款方式
                    desc: ''//备注
                },
                rules: {
                    name: [
                        {required: true, message: '请输入印件名称', trigger: 'blur'},
                        {min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur'}
                    ],
                    // color: [
                    //     {required: true, message: '请选择色彩要求', trigger: 'change'}
                    // ],
                    date: [
                        {type: 'date', required: true, message: '请选择发货日期', trigger: 'change'}
                    ],
                    consignee: [
                        {required: true, message: '请输入收货人', trigger: 'blur'},
                        // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
                    ],
                    tel: [
                        {required: true, message: '请输入联系电话', validator: validateMobilePhone, trigger: 'blur'},
                        // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
                    ],
                    // address: [
                    //     {required: true, message: '请输入收货地址', trigger: 'blur'},
                    // ]
                },

                //报价详情_指令加载方式
                fullscreenLoading2: false,

                //是否是管理员账号
                userType: '',
                //服务客户
                service: false,
                //服务客户_用户名称
                servicetext: '',
                //服务客户_user_ID
                user_ID: '',
                //服务客户_信息
                userList: '',


                //盒型图
                radioBoxList: [
                    {
                        id: 10,
                        text: '天地盒',
                        img: 'heaven_and_earth_box.png',
                        children: [
                            {
                                id: 11,
                                text: "普通天地盒",
                                pic: [
                                    {
                                        img: 'heaven_and_earth_box1.png'
                                    },
                                    {
                                        img: 'heaven_and_earth_box2.png'
                                    }
                                ]
                            },
                        ],
                    },
                    {
                        id: 20,
                        text: '双插盒',
                        img: 'double_plug_box.png',
                        children: [
                            {
                                id: 21,
                                text: "普通双插盒",
                                pic: [
                                    {
                                        img: 'double_plug_box1.png'
                                    },
                                    {
                                        img: 'double_plug_box2.png'
                                    }
                                ]
                            },
                            {
                                id: 22,
                                text: "带保险扣双插盒",
                                pic: [
                                    {
                                        img: 'automatic_bottom_lock_box_safety1.png'
                                    },
                                    {
                                        img: 'automatic_bottom_lock_box_safety2.png'
                                    }
                                ]
                            },
                            {
                                id: 23,
                                text: "带挂钩双插盒",
                                pic: [
                                    {
                                        img: 'double_plug_hook_box1.png'
                                    },
                                    {
                                        img: 'double_plug_hook_box2.png'
                                    }
                                ]
                            },
                            {
                                id: 24,
                                text: "同边插双插盒",
                                pic: [
                                    {
                                        img: 'double_plug_the_same_side_box1.png'
                                    },
                                    {
                                        img: 'double_plug_the_same_side_box2.png'
                                    }
                                ]
                            },
                        ],
                    },
                    {
                        id: 30,
                        text: '锁底盒',
                        img: 'lock_bottom_box.png',
                        children: [
                            {
                                id: 31,
                                text: "普通锁底盒",
                                pic: [
                                    {
                                        img: 'lock_bottom_box1.png'
                                    },
                                    {
                                        img: 'lock_bottom_box2.png'
                                    }
                                ]
                            },
                            {
                                id: 32,
                                text: "带保险锁底盒",
                                pic: [
                                    {
                                        img: 'safety_lock_bottom_box1.png'
                                    },
                                    {
                                        img: 'safety_lock_bottom_box2.png'
                                    }
                                ]
                            },
                            {
                                id: 33,
                                text: "带挂钩锁底盒",
                                pic: [
                                    {
                                        img: 'lock_bottom_hook_box1.png'
                                    },
                                    {
                                        img: 'lock_bottom_hook_box2.png'
                                    }
                                ]
                            },
                        ],
                    }
                ],

                //盒型图类型
                typeList: [],
                //示意图
                SketchMapList: []
            };
        },
        created() {
            this.getImg(this.radioBoxList);

            // console.log(getusername().userfatherid);
            // this.form.userfatherid = getusername().userfatherid;
            let obj = JSON.parse(window.sessionStorage.getItem("shoppingInfo"))
            console.log(obj.userfatherid)
            this.form.userfatherid = obj.userfatherid;

            //默认盒型图类型为盒型图列表下第一组盒型图的类型
            this.typeList = this.radioBoxList[0].children
            //默认示意图为盒型图列表下第一组盒型图的类型的pic
            this.SketchMapList = this.radioBoxList[0].children[0].pic
            console.log(this.typeList)
            // this.getImg(this.radioBoxList);


            var id = this.$route.params.id;
            if (id != 1028) {
                id = 1028
            }
            this.form.productId = id;
            console.log(id);

            //获取默认地址
            this.GetAlladdress()

            //是否是管理员账号
            this.getuserType()

            this.$http.get("/cpshttp/getQuoteData?productId=" + id).then((res) => {
                console.log(res);
                if (res.status == 200) {
                    // this.$message({
                    //     message: "报价页面加载成功",
                    //     type: "success",
                    // });

                    //产品规格列表(基本参数)
                    this.specList = res.data.specList;
                    //默认成品尺寸
                    // this.form.size = res.data.specList[0].commonspecid;

                    //纸张克重（类型）
                    this.maList = res.data.maList;
                    //默认纸张克重（类型）
                    this.form.type = res.data.maList[0].id;
                    //默认纸张克重列表（克）
                    this.productWeightList = res.data.maList[0].productWeightList;

                    //印刷颜色
                    this.colorList = res.data.colorList;
                    this.invoiceList = res.data.invoiceList;
                  this.form.invoice = this.invoiceList[0].num

                    //默认印刷颜色
                    // this.form.colour = res.data.colorList[0].colorid;
                    let colorId = ''
                    for (let a = 0; a < res.data.colorList.length; a++) {
                        if (res.data.colorList[a].setDefault) {
                            colorId = res.data.colorList[a].colorid;
                        }
                    }
                    this.form.colour = colorId != '' ? colorId : res.data.colorList[0].colorid;

                    //后道设置列表
                    // this.processList = res.data.processList;
                    for (let a = 0; a < res.data.processList.length; a++) {
                        if (res.data.processList[a].dispaly == 1) {
                            this.processList.push(res.data.processList[a])
                        }
                    }

                    //包装方式列表
                    this.packageList = res.data.packageList;
                    this.form.packing = res.data.packageList[0].id;

                    //配送方式列表
                    this.postList = res.data.postList;
                    this.form.distribution = res.data.postList[0].id;
                  this.form.distribution_mustaddr = res.data.postList[0].mustaddr;
                  if (this.form.distribution_mustaddr == 1) {
                    this.form.dest = "";
                    this.form.provinceId = "";
                    this.form.cityId = "";
                    this.form.areaId = "";
                  }
                    for (var i = 0; i < res.data.maList[0].productWeightList.length; i++) {
                        if (res.data.maList[0].defaultweight == res.data.maList[0].productWeightList[i].name) {
                            // console.log(res.data.maList[0].productWeightList[i].id)
                            //默认纸张克重（克）
                            this.form.gram = res.data.maList[0].productWeightList[i].weightid;
                            return;
                        } else if (res.data.maList[0].defaultweight != res.data.maList[0].productWeightList[i].name) {
                            //默认纸张克重（克）
                            this.form.gram = res.data.maList[0].productWeightList[0].weightid;
                        }
                    }
                } else {
                    this.$message.error("报价页面加载失败");
                }
            }).catch((error) => {
                console.error("Error", error);
            });

            //定义印刷数量
            for (var i = 1; i <= 5; i++) {
                this.restaurants.push(i);
            }
            //默认印刷数量
            this.form.state = 1;

            //默认印刷颜色（单双面）
            this.form.single = "colors";

            //默认印刷数量（张/款）
            this.form.states = 1000;

            // let year = new Date().getFullYear(); //得到年份
            // let month = new Date().getMonth();//得到月份
            // let date = new Date().getDate();//得到日期
            // // let hour = new Date().getHours();//得到小时
            // // let minu = new Date().getMinutes();//得到分钟
            // let hour = 17;//得到小时
            // let minu = 0;//得到分钟
            //
            // month = month + 1;
            // if (month < 10) month = "0" + month;
            // if (date < 10) date = "0" + date;
            // if (hour < 10) hour = "0" + hour;
            // if (minu < 10) minu = "0" + minu;
            //色彩要求
            this.ruleForm.color = this.colourList[0]
            //付款方式
            this.ruleForm.paymentMethod = this.paymentMethodList[0]
            //发货日期
            // this.ruleForm.date = year + '-' + month + '-' + date

        },

        methods: {

            // 递归动态加载本地图片
            getImg(arr) {
                arr.forEach((el) => {
                    el.img = require(`@/static/imgs/corrugatedBox/${el.img}`);
                    let newArr = el.children;
                    if (newArr) {
                        this.getImg1(newArr);
                    } else {
                        return;
                    }
                });
            },

            // 递归动态加载本地图片
            getImg1(arr) {
                arr.forEach((el) => {
                    this.getImg2(el.pic);
                })
            },

            // 递归动态加载本地图片
            getImg2(arr) {
                arr.forEach((el) => {
                    console.log(el)
                    el.img = require(`@/static/imgs/corrugatedBox/${el.img}`);
                    console.log(el)
                    console.log(this.radioBoxList)
                    let newArr = el.children;
                    if (newArr) {
                    } else {
                        return;
                    }
                });
            },

            //服务客户_信息_子组件传值
            change(data) {
                // this.user=msg
                console.log(data)
                this.userList = data
                // this.xiadanList = data
            },

            //服务客户_取消
            cancel() {
                this.service = false
                this.servicetext = ''
                this.user_ID = ''
            },

            //服务客户_确定
            determine() {
                this.service = false
              if(this.userList.newTel != '' && this.userList.newTel != null){
                this.servicetext = this.userList.custName + '(' + this.userList.newTel + ')'
              } else {
                this.servicetext = this.userList.custName
              }
                this.user_ID = this.userList.user_ID
                this.GetAlladdress()
            },

            //是否是管理员账号
            getuserType() {
                let userInfo = JSON.parse(window.sessionStorage.getItem('currentInfo'))
                console.log(userInfo)
                if (userInfo.userType == 1) {
                    this.userType = userInfo.userType
                    console.log('管理员账号')
                } else {
                    this.userType = userInfo.userType
                    console.log('游客账号')
                }
            },

          openFeeDetail1(feeJson){
            this.feeList = JSON.parse(feeJson);
            this.feeDialogVisible = true
          },
            //提交自定义尺寸
            tijiao() {
                console.log(this.cpsize);
                this.zdysize = this.cpsize.length + "*" + this.cpsize.width + "*" + this.cpsize.height + "mm";
            },

            //纸张克重（类型）发生改变
            //返回该类型ID
            Changetype(item) {
                console.log(item);
                //循环材料列表，判断当前材料ID是否与材料列表中某一组材料的ID相同
                //赋值当前材料的默认克重ID
                //循环当前材料的克重
                for (var i = 0; i < this.maList.length; i++) {
                    if (item == this.maList[i].id) {
                        console.log(this.maList[i]);
                        // this.form.gram=this.maList[i].defaultweight
                        this.productWeightList = this.maList[i].productWeightList;
                        //循环材料列表中克重列表，判断当前默认克重是否与克重列表中某一组克重的相同
                        //取出克重ID
                        //否则默认纸张克重（克）为克重列表第一组数据ID
                        for (var a = 0; a < this.maList[i].productWeightList.length; a++) {
                            if (this.maList[i].defaultweight == this.maList[i].productWeightList[a].name) {
                                console.log(this.maList[i].productWeightList[a].weightid);
                                //默认纸张克重（克）
                                this.form.gram = this.maList[i].productWeightList[a].weightid;
                                return;
                            } else {
                                console.log(this.maList[i].productWeightList[0].weightid);
                                //默认纸张克重（克）
                                this.form.gram = this.maList[i].productWeightList[0].weightid;
                            }
                        }
                    }
                }
            },

            // 地址级联选择器
            handleChange(value) {
                console.log(value);
                this.addressCode = [...value];
                // 地址反显
                this.showAddress = `${CodeToText[value[0]]}${CodeToText[value[1]]}${
                    CodeToText[value[2]]
                }`;
                console.log(this.showAddress);
                console.log(this.addressCode);
                if (this.form.dest != this.showAddress) {
                    //详情收货地址
                    this.ruleForm.address = ''
                }
                //省市区
                this.form.dest = this.showAddress;
                //省ID
                this.form.provinceId = this.addressCode[0];
                //市ID
                this.form.cityId = this.addressCode[1];
                //区ID
                this.form.areaId = this.addressCode[2];

                // let id = uuid.v4();
                // console.log(id);
            },

            //获取默认地址
          GetAlladdress() {
            let userInfo = JSON.parse(window.sessionStorage.getItem('currentInfo'))
            console.log(this.user_ID)
            console.log(userInfo.user_ID)

            let userid = ''
            if (this.user_ID == '') {
              userid = userInfo.user_ID
            } else {
              userid = this.user_ID
            }
            console.log(userid)
            let array = []

            //非普通用户 并且 未选择服务客户
            console.log(userInfo.userType)
            console.log(this.user_ID)
            if (userInfo.userType != 0 && userid == ''){
              this.form.selectedOptions=[];//目的地
              this.form.dest= ''; //省市区
              this.form.provinceId=''; //省ID
              this.form.cityId=''; //市ID
              this.form.areaId='';//区ID
            }else {
              this.$http.get("/receiveadderss/listAll?operatorId=" + userid).then((res) => {
                console.log('获取默认地址', res)
                if (res.data.code == 200) {
                  if (res.data.data.length != 0) {
                    array.push(res.data.data[0].provinceCode, res.data.data[0].cityCode, res.data.data[0].areaCode)
                    //省市区
                    this.form.dest = res.data.data[0].provinceZh + res.data.data[0].cityZh + res.data.data[0].areaZh
                    //收货人姓名
                    this.ruleForm.consignee = res.data.data[0].receiveName
                    //收货人电话
                    this.ruleForm.tel = res.data.data[0].receivePhone
                    //详情收货地址
                    this.ruleForm.address = res.data.data[0].detailAddress
                  }
                }
                this.form.selectedOptions = array
                console.log(array)
                console.log(this.form.dest)
                if (array.length != 0) {
                  //省ID
                  this.form.provinceId = array[0]
                  //市ID
                  this.form.cityId = array[1]
                  //区ID
                  this.form.areaId = array[2]
                }
              })
            }
          },

            //上盖工艺
            CheckedChangeUpperCover(value, index) {
                console.log(index);
                console.log(value);
                console.log(this.checkedUptext);
                this.indexUp = index;
                this.centerDialogVisible4 = true;
                console.log(this.centerDialogVisible4);
                if (value == false && this.checkedUptext[index] != "") {
                    value = true;
                }
            },

            //上盖工艺_取消
            cancel1(index) {
                console.log(index)
                console.log(this.indexUp)
                console.log(this.checkedUp)
                console.log(this.checkedUptext)
                this.centerDialogVisible4 = false
                this.checkedUptext = ''
                this.checkedUpCheckbox = ''
                this.checkedUp[this.indexUp] = false
            },

            //上盖工艺_右上角关闭
            handleClose1() {
                this.centerDialogVisible4 = false
                this.checkedUptext = ''
                this.checkedUpCheckbox = ''
                this.checkedUp[this.indexUp] = false
            },

            //上盖工艺_提交
            SubmitUp(index) {
                console.log(index);
                console.log(this.checkedUpCheckbox1);
                this.checkedUp[this.indexUp] = true
                for (let c = 0; c < this.checkedUpCheckbox.length; c++) {
                    for (let a = 0; a < this.setacreageUp0.length; a++) {
                        if (this.setacreageUp0[a].setacreage == 1) {
                            if (this.checkedUpCheckbox[c].id == this.setacreageUp0[a].processesSetList[0].id) {
                                console.log(this.setacreageUp0[a].inSize)
                                this.checkedUpCheckbox[c].inSize = this.setacreageUp0[a].inSize
                            }
                        }
                    }
                }

                // this.technologyfm[index] = this.checkedUpCheckbox;
                // console.log(this.technologyfm);
                console.log(this.checkedUpCheckbox);
                console.log(this.checkedUpCheckbox1);

                this.checkedUpCheckbox = this.checkedUpCheckbox1

                let text = [];
                console.log(this.setacreageUp0);
                console.log(this.checkedUpCheckbox);
                for (let a = 0; a < this.checkedUpCheckbox.length; a++) {
                    for (let b = 0; b < this.setacreageUp0.length; b++) {
                        for (let c = 0; c < this.setacreageUp0[b].processesSetList.length; c++) {
                            if (this.setacreageUp0[b].setacreage == 0) {
                                if (this.checkedUpCheckbox[a].id == this.setacreageUp0[b].processesSetList[c].id) {
                                    console.log(this.setacreageUp0[b].processesSetList[c].name);
                                    text.push(this.setacreageUp0[b].processesSetList[c].name);
                                }
                            } else if (this.setacreageUp0[b].setacreage == 1) {
                                if (this.checkedUpCheckbox[a].id == this.setacreageUp0[b].processesSetList[0].id) {

                                    let size = []

                                    for (let d = 0; d < this.checkedUpCheckbox[b].inSize.length; d++) {
                                        console.log(this.setacreageUp0[b].processesSetList[c].name);
                                        size.push(
                                            this.checkedUpCheckbox[a].inSize[d].width + "*" + this.checkedUpCheckbox[a].inSize[d].length
                                        );
                                    }
                                    text.push(
                                        this.setacreageUp0[b].processesSetList[c].name + "(" + size + ")"
                                    );
                                }
                            }   else if (this.setacreageUp0[b].setacreage == 3) {
                                if (this.checkedUpCheckbox[a].id == this.setacreageUp0[b].processesSetList[c].id) {
                                    text.push(
                                        this.setacreageUp0[b].processesSetList[c].name + "(" + this.setacreageUp0[b].desc + ")"
                                    )
                                }
                            }
                        }
                    }
                }

              for (let b = 0; b < this.setacreageUp0.length; b++) {

                if (this.setacreageUp0[b].setacreage == 2) {
                  for (let i = 0; i < Number(this.setacreageUp0[b].num); i++) {
                    text.push(
                        this.setacreageUp0[b].inSize[i].modelsName + "(" +
                        this.setacreageUp0[b].inSize[i].width + "*" + this.setacreageUp0[b].inSize[i].length + ")"
                    )
                  }
                }
              }
                console.log(text);
                this.checkedUptext = text;
            },

            //上盖多选框发送改变
            handleCheckedUpChange(value) {

              let arr = [];
              console.log('1:' , value)
              //this.chooseValue = value
              console.log('2:' ,this.arrcheckedCheckbox)

              if (value.length != 0) {

                for (let v = 0; v < value.length; v++) {
                  let flag = false
                  for (let i = 0; i < this.setacreageUp0.length; i++) {
                    if (value[v] == this.setacreageUp0[i].id) {
                      flag = true  // 以前存在
                      arr.push(this.setacreageUp0[i])
                      break
                    }
                  }
                  if (!flag) { // 以前不存在的
                    for (let i = 0; i < this.processList.length; i++) {
                      if (value[v] == this.processList[i].id) {   //取最后加入的那个
                        arr.push({
                          name: this.processList[i].name,
                          id: this.processList[i].id,
                          setacreage: this.processList[i].setacreage,
                          processesSetList: this.processList[i].processesSetList,
                          num: 1,
                          desc: "",
                          models: this.processList[i].setacreage == '2' ? this.processList[i].id:  this.processList[i].processesSetList[0].id,
                          inSize: this.processList[i].setacreage == '2' ? [{
                            'id': this.processList[i].processesSetList[0].id,
                            'models': this.processList[i].processesSetList[0].id + ','+ this.processList[i].processesSetList[0].name,
                            'modelsName': this.processList[i].processesSetList[0].name,
                            'width': '100',
                            'length': '100'
                          }] : [{'width': '', 'length': ''}],
                        });
                      }
                    }
                  }
                }
                this.setacreageUp0 = arr
                console.log('1:' , value)


              } else {
                this.setacreageUp0 = [];
              }


                //setacreage:0  默认下拉值
                let arrcheckedCheckbox = [];
                // this.models = [];
                for (let b = 0; b < this.setacreageUp0.length; b++) {
                    if (this.setacreageUp0[b].setacreage == 0) {

                        arrcheckedCheckbox.push({
                            id: this.setacreageUp0[b].models,
                        });
                    } else if (this.setacreageUp0[b].setacreage == 1) {

                        arrcheckedCheckbox.push({
                            id: this.setacreageUp0[b].models,
                            inSize: this.setacreageUp0[b].inSize,
                        });
                        // arrcheckedCheckbox.inSize.push(this.setacreageUp0[b].inSize)
                    } else if (this.setacreageUp0[b].setacreage == 2) {
                        arrcheckedCheckbox.push({
                            id: this.setacreageUp0[b].id,
                            inSize: this.setacreageUp0[b].inSize,
                        });
                    } else if (this.setacreageUp0[b].setacreage == 3) {
                        console.log(this.setacreageUp0[b].processesSetList[0].id);
                        arrcheckedCheckbox.push({
                            id: this.setacreageUp0[b].models,
                            desc: this.setacreageUp0[b].desc,
                        });
                    }
                }
              for (let b = 0; b < this.checkedUpCheckbox.length; b++) {
                for (let a = 0; a < arrcheckedCheckbox.length; a++) {
                  if (arrcheckedCheckbox[a].id == this.checkedUpCheckbox[b].id) {
                    arrcheckedCheckbox[a].inSize = this.checkedUpCheckbox[b].inSize
                  }
                }
                for (let c = 0; c < this.setacreageUp0.length; c++) {
                  if (this.setacreageUp0[c].models == this.checkedUpCheckbox[b].id) {
                    this.setacreageUp0[c].inSize = this.checkedUpCheckbox[b].inSize
                  }
                }
              }
                this.checkedUpCheckbox = arrcheckedCheckbox;
                this.checkedUpCheckbox1 = arrcheckedCheckbox;
                console.log(arrcheckedCheckbox);
            },

            //下盖工艺
            CheckedChangeLowerCover(value, index) {
                console.log(index);
                console.log(value);
                console.log(this.checkedLowertext);
                this.indexLower = index;
                this.centerDialogVisible5 = true;
                console.log(this.LowerCover.checkedLowerCheckbox);
                console.log(this.centerDialogVisible5);
                if (value == false && this.checkedLowertext[index] != "") {
                    value = true;
                }
            },

            //下盖工艺_取消
            cancel2(index) {
                console.log(index)
                console.log(this.indexLower)
                console.log(this.checkedLower)
                console.log(this.checkedLowertext)
                this.centerDialogVisible5 = false
                this.checkedLowertext = ''
                this.checkedLowerCheckbox = ''
                this.checkedLower[this.indexLower] = false
            },

            //下盖工艺_右上角关闭
            handleClose2() {
                this.centerDialogVisible5 = false
                this.checkedLowertext = ''
                this.checkedLowerCheckbox = ''
                this.checkedLower[this.indexLower] = false
            },

            //下盖工艺_提交
            SubmitLower(index) {
                console.log(index);
                console.log(this.checkedLowerCheckbox1);
                this.checkedLower[this.indexLower] = true
                for (let c = 0; c < this.checkedLowerCheckbox.length; c++) {
                    for (let a = 0; a < this.setacreageLower0.length; a++) {
                        if (this.setacreageLower0[a].setacreage == 1) {
                            if (this.checkedLowerCheckbox[c].id == this.setacreageLower0[a].processesSetList[0].id) {
                                console.log(this.setacreageLower0[a].inSize)
                                this.checkedLowerCheckbox[c].inSize = this.setacreageLower0[a].inSize
                            }
                        }
                    }
                }

                // this.technologyfm[index] = this.checkedLowerCheckbox;
                // console.log(this.technologyfm);
                console.log(this.checkedLowerCheckbox);
                console.log(this.checkedLowerCheckbox1);

                this.checkedLowerCheckbox = this.checkedLowerCheckbox1

                let text = [];
                console.log(this.setacreageLower0);
                console.log(this.checkedLowerCheckbox);
                for (let a = 0; a < this.checkedLowerCheckbox.length; a++) {
                    for (let b = 0; b < this.setacreageLower0.length; b++) {
                        for (let c = 0; c < this.setacreageLower0[b].processesSetList.length; c++) {
                            if (this.setacreageLower0[b].setacreage == 0) {
                                if (this.checkedLowerCheckbox[a].id == this.setacreageLower0[b].processesSetList[c].id) {
                                    console.log(this.setacreageLower0[b].processesSetList[c].name);
                                    text.push(this.setacreageLower0[b].processesSetList[c].name);
                                }
                            } else if (this.setacreageLower0[b].setacreage == 1) {
                                if (this.checkedLowerCheckbox[a].id == this.setacreageLower0[b].processesSetList[0].id) {

                                    let size = []

                                    for (let d = 0; d < this.checkedLowerCheckbox[b].inSize.length; d++) {
                                        console.log(this.setacreageLower0[b].processesSetList[c].name);
                                        size.push(
                                            this.checkedLowerCheckbox[a].inSize[d].width + "*" + this.checkedLowerCheckbox[a].inSize[d].length
                                        );
                                    }
                                    text.push(
                                        this.setacreageLower0[b].processesSetList[c].name + "(" + size + ")"
                                    );
                                }
                            }   else if (this.setacreageLower0[b].setacreage == 3) {
                                if (this.checkedLowerCheckbox[a].id == this.setacreageLower0[b].processesSetList[c].id) {
                                    text.push(
                                        this.setacreageLower0[b].processesSetList[c].name + "(" + this.setacreageLower0[b].desc + ")"
                                    )
                                }
                            }
                        }
                    }
                }

              for (let b = 0; b < this.setacreageLower0.length; b++) {

                if (this.setacreageLower0[b].setacreage == 2) {
                  for (let i = 0; i < Number(this.setacreageLower0[b].num); i++) {
                    text.push(
                        this.setacreageLower0[b].inSize[i].modelsName + "(" +
                        this.setacreageLower0[b].inSize[i].width + "*" + this.setacreageLower0[b].inSize[i].length+")"
                    )
                  }
                }

              }

                console.log(text);
                this.checkedLowertext = text;
            },

            //下盖多选框发送改变
            handleCheckedLowerChange(value) {
                let arr = [];
                console.log(value)
                console.log(this.checkedLowerCheckbox1)
              if (value.length != 0) {

                for (let v = 0; v < value.length; v++) {
                  let flag = false
                  for (let i = 0; i < this.setacreageLower0.length; i++) {
                    if (value[v] == this.setacreageLower0[i].id) {
                      flag = true  // 以前存在
                      arr.push(this.setacreageLower0[i])
                      break
                    }
                  }
                  if (!flag) { // 以前不存在的
                    for (let i = 0; i < this.processList.length; i++) {
                      if (value[v] == this.processList[i].id) {   //取最后加入的那个
                        arr.push({
                          name: this.processList[i].name,
                          id: this.processList[i].id,
                          setacreage: this.processList[i].setacreage,
                          processesSetList: this.processList[i].processesSetList,
                          num: 1,
                          desc: "",
                          models: this.processList[i].setacreage == '2' ? this.processList[i].id:  this.processList[i].processesSetList[0].id,
                          inSize: this.processList[i].setacreage == '2' ? [{
                            'id': this.processList[i].processesSetList[0].id,
                            'models': this.processList[i].processesSetList[0].id + ','+ this.processList[i].processesSetList[0].name,
                            'modelsName': this.processList[i].processesSetList[0].name,
                            'width': '100',
                            'length': '100'
                          }] : [{'width': '', 'length': ''}],
                        });
                      }
                    }
                  }
                }
                this.setacreageLower0 = arr
                console.log('1:' , value)


              } else {
                this.setacreageLower0 = [];
              }

                //setacreage:0  默认下拉值
                let arrcheckedCheckbox = [];
                // this.models = [];
                for (let b = 0; b < this.setacreageLower0.length; b++) {
                    if (this.setacreageLower0[b].setacreage == 0) {

                        arrcheckedCheckbox.push({
                            id: this.setacreageLower0[b].models,
                        });
                    } else if (this.setacreageLower0[b].setacreage == 1) {

                        arrcheckedCheckbox.push({
                            id: this.setacreageLower0[b].models,
                            inSize: this.setacreageLower0[b].inSize,
                        });
                        // arrcheckedCheckbox.inSize.push(this.setacreageLower0[b].inSize)
                    } else if (this.setacreageLower0[b].setacreage == 2) {

                        arrcheckedCheckbox.push({
                            id: this.setacreageLower0[b].id,
                            inSize: this.setacreageLower0[b].inSize,
                        });
                    } else if (this.setacreageLower0[b].setacreage == 3) {
                        arrcheckedCheckbox.push({
                            id: this.setacreageLower0[b].models,
                            desc: this.setacreageLower0[b].desc,
                        });
                    }
                }
              for (let b = 0; b < this.checkedLowerCheckbox.length; b++) {
                for (let a = 0; a < arrcheckedCheckbox.length; a++) {
                  if (arrcheckedCheckbox[a].id == this.checkedLowerCheckbox[b].id) {
                    arrcheckedCheckbox[a].inSize = this.checkedLowerCheckbox[b].inSize
                  }
                }
                for (let c = 0; c < this.setacreageLower0.length; c++) {
                  if (this.setacreageLower0[c].models == this.checkedLowerCheckbox[b].id) {
                    this.setacreageLower0[c].inSize = this.checkedLowerCheckbox[b].inSize
                  }
                }
              }
                this.checkedLowerCheckbox = arrcheckedCheckbox;
                this.checkedLowerCheckbox1 = arrcheckedCheckbox;
                console.log(arrcheckedCheckbox);
            },

            //上盖后道设置_新增
            UpnewlyAdded(item, i) {
                console.log(i);
                console.log(item);
                console.log(this.setacreageUp0);

                if (i.processesSetList[0].id === item.processesSetList[0].id) {
                    for (let a = 0; a < this.setacreageUp0.length; a++) {
                        if (this.setacreageUp0[a].processesSetList[0].id === i.processesSetList[0].id) {
                            this.setacreageUp0[a].num = ++this.setacreageUp0[a].num;
                          if (i.setacreage == 2) {
                            this.setacreageUp0[a].inSize.push({'width': '100', 'length': '100', 'desc': ''});
                          } else {
                            this.setacreageUp0[a].inSize.push({'width': '', 'length': ''});
                          }
                        }
                    }
                }

                for (let a = 0; a < this.checkedUpCheckbox.length; a++) {
                    if (i.processesSetList[0].id === this.checkedUpCheckbox[a].id) {
                        // this.checkedCheckbox[a].inSize.push({'width': '', 'length': ''})
                        console.log(this.checkedUpCheckbox[a].inSize);
                    }
                }
                console.log(this.setacreageUp0);
            },

            //上盖后道设置_删除
            Updel(item, i) {
                console.log(i);
                console.log(item);
                if (i.processesSetList[0].id == item.processesSetList[0].id) {
                    for (let a = 0; a < this.setacreageUp0.length; a++) {
                        if (this.setacreageUp0[a].processesSetList[0].id == i.processesSetList[0].id) {
                            this.setacreageUp0[a].num = --this.setacreageUp0[a].num;
                            this.setacreageUp0[a].inSize.pop();
                            console.log(this.setacreageUp0);
                            console.log(this.setacreageUp0[a]);
                            console.log(this.setacreageUp0[a].num);
                        }
                    }
                }
            },

            //下盖后道设置_新增
            LowernewlyAdded(item, i) {
                console.log(i);
                console.log(item);
                console.log(this.setacreageLower0);

                if (i.processesSetList[0].id === item.processesSetList[0].id) {
                    for (let a = 0; a < this.setacreageLower0.length; a++) {
                        if (this.setacreageLower0[a].processesSetList[0].id === i.processesSetList[0].id) {
                            this.setacreageLower0[a].num = ++this.setacreageLower0[a].num;
                          if (i.setacreage == 2) {
                            this.setacreageLower0[a].inSize.push({'width': '100', 'length': '100', 'desc': ''});
                          } else {
                            this.setacreageLower0[a].inSize.push({'width': '', 'length': ''});
                          }
                        }
                    }
                }

                for (let a = 0; a < this.checkedLowerCheckbox.length; a++) {
                    if (i.processesSetList[0].id === this.checkedLowerCheckbox[a].id) {
                        // this.checkedLowerCheckbox[a].inSize.push({'width': '', 'length': ''})
                        console.log(this.checkedLowerCheckbox[a].inSize);
                    }
                }
                console.log(this.setacreageLower0);
            },

            //下盖后道设置_删除
            Lowerdel(item, i) {
                console.log(i);
                console.log(item);
                if (i.processesSetList[0].id == item.processesSetList[0].id) {
                    for (let a = 0; a < this.setacreageLower0.length; a++) {
                        if (this.setacreageLower0[a].processesSetList[0].id == i.processesSetList[0].id) {
                            this.setacreageLower0[a].num = --this.setacreageLower0[a].num;
                            this.setacreageLower0[a].inSize.pop();
                            console.log(this.setacreageLower0);
                            console.log(this.setacreageLower0[a]);
                            console.log(this.setacreageLower0[a].num);
                        }
                    }
                }
            },

            //setacreage:0  上盖下拉值发生改变
          selecChangeUp1(value, i, index) {
            for (let a = 0; a < this.setacreageUp0.length; a++) {
              if (i.processesSetList[0].id == this.setacreageUp0[a].processesSetList[0].id) {
                this.setacreageUp0[a].inSize[index].models = value
                this.setacreageUp0[a].inSize[index].id = value.split(',')[0]
                this.setacreageUp0[a].inSize[index].modelsName = value.split(',')[1]
              }

            }

            console.log(this.setacreageUp0)
          },
            selecChangeUp(value) {
                console.log(value);
                console.log(this.checkedUpCheckbox);

                for (let b = 0; b < this.setacreageUp0.length; b++) {
                    for (let c = 0; c < this.setacreageUp0[b].processesSetList.length; c++) {
                        if (value == this.setacreageUp0[b].processesSetList[c].id) {
                                this.checkedUpCheckbox[b].id =  value

                        }
                    }
               }
                console.log(this.checkedUpCheckbox);
            },

          selecChangeLower1(value, i, index) {
            for (let a = 0; a < this.setacreageLower0.length; a++) {
              if (i.processesSetList[0].id == this.setacreageLower0[a].processesSetList[0].id) {
                this.setacreageLower0[a].inSize[index].models = value
                this.setacreageLower0[a].inSize[index].id = value.split(',')[0]
                this.setacreageLower0[a].inSize[index].modelsName = value.split(',')[1]
              }

            }

            console.log(this.setacreageLower0)
          },
            //setacreage:0  下盖下拉值发生改变
            selecChangeLower(value) {

                for (let b = 0; b < this.setacreageLower0.length; b++) {
                    for (let c = 0; c < this.setacreageLower0[b].processesSetList.length; c++) {
                        if (value == this.setacreageLower0[b].processesSetList[c].id) {
                                this.checkedLowerCheckbox[b].id = value
                            }

                    }

                }
                console.log(this.checkedLowerCheckbox);
            },

            //配送方式下拉改变
            selecChangepsfs(value) {
                console.log(value);
                for (let a = 0; a < this.postList.length; a++) {
                    if (this.postList[a].id == value) {
                        this.form.distribution_mustaddr = this.postList[a].mustaddr;
                    }
                    //如选中的送货方式mustaddr:1，目的地就为空
                    if (this.form.distribution_mustaddr == 1) {
                        this.form.dest = "";
                        this.form.provinceId = "";
                        this.form.cityId = "";
                        this.form.areaId = "";
                    }
                }
            },

            //上盖宽发生改变
            UpwidthChange(value, i, index) {
                console.log(value)
                console.log(i)
                console.log(index)
                console.log(this.checkedUpCheckbox)
                console.log(this.UpperCover.checkedUpCheckbox)

                for (let a = 0; a < this.checkedUpCheckbox.length; a++) {
                    if (i.processesSetList[0].id == this.checkedUpCheckbox[a].id) {
                        this.checkedUpCheckbox[a].inSize[index].width = value
                    }
                }

                for (let a = 0; a < this.setacreageUp0.length; a++) {
                    if (i.processesSetList[0].id == this.setacreageUp0[a].processesSetList[0].id) {
                        this.setacreageUp0[a].inSize[index].width = value
                    }
                }
                console.log(this.checkedUpCheckbox)
                console.log(this.setacreageUp0)
            },

            //上盖高发生改变
            UplengthChange(value, i, index) {
                console.log(value)
                console.log(i)
                console.log(index)
                console.log(this.checkedUpCheckbox)
                console.log(this.UpperCover.checkedUpCheckbox)

                for (let a = 0; a < this.checkedUpCheckbox.length; a++) {
                    if (i.processesSetList[0].id == this.checkedUpCheckbox[a].id) {
                        this.checkedUpCheckbox[a].inSize[index].length = value
                        console.log(this.checkedUpCheckbox[a].inSize[index]);
                    }
                }

                for (let a = 0; a < this.setacreageUp0.length; a++) {
                    if (i.processesSetList[0].id == this.setacreageUp0[a].processesSetList[0].id) {
                        this.setacreageUp0[a].inSize[index].length = value
                    }
                }

                console.log(this.checkedUpCheckbox)
                console.log(this.setacreageUp0)
            },

            //下盖宽发生改变
            LowerwidthChange(value, i, index) {
                console.log(value)
                console.log(i)
                console.log(index)
                console.log(this.checkedLowerCheckbox)
                console.log(this.UpperCover.checkedLowerCheckbox)

                for (let a = 0; a < this.checkedLowerCheckbox.length; a++) {
                    if (i.processesSetList[0].id == this.checkedLowerCheckbox[a].id) {
                        this.checkedLowerCheckbox[a].inSize[index].width = value
                    }
                }

                for (let a = 0; a < this.setacreageLower0.length; a++) {
                    if (i.processesSetList[0].id == this.setacreageLower0[a].processesSetList[0].id) {
                        this.setacreageLower0[a].inSize[index].width = value
                    }
                }
                console.log(this.checkedLowerCheckbox)
                console.log(this.setacreageLower0)
            },

            //下盖高发生改变
            LowerlengthChange(value, i, index) {
                console.log(value)
                console.log(i)
                console.log(index)
                console.log(this.checkedLowerCheckbox)
                console.log(this.UpperCover.checkedLowerCheckbox)

                for (let a = 0; a < this.checkedLowerCheckbox.length; a++) {
                    if (i.processesSetList[0].id == this.checkedLowerCheckbox[a].id) {
                        this.checkedLowerCheckbox[a].inSize[index].length = value
                        console.log(this.checkedLowerCheckbox[a].inSize[index]);
                    }
                }

                for (let a = 0; a < this.setacreageLower0.length; a++) {
                    if (i.processesSetList[0].id == this.setacreageLower0[a].processesSetList[0].id) {
                        this.setacreageLower0[a].inSize[index].length = value
                    }
                }

                console.log(this.checkedLowerCheckbox)
                console.log(this.setacreageLower0)
            },

            //上盖文本框发生改变
            textareaUpChange(value, i, index) {
                console.log(value);
                console.log(i);
                console.log(index);
                console.log(this.checkedUpCheckbox);
                for (let a = 0; a < this.checkedUpCheckbox.length; a++) {
                    if (i.processesSetList[0].id == this.checkedUpCheckbox[a].id) {
                        this.checkedUpCheckbox[a].desc = value
                    }
                }

                for (let a = 0; a < this.setacreageUp0.length; a++) {
                    if (i.processesSetList[0].id == this.setacreageUp0[a].processesSetList[0].id) {
                        this.setacreageUp0[a].desc = value
                    }
                }
                console.log(this.checkedUpCheckbox)
                console.log(this.setacreageUp0)
                // this.handleCheckedChange(value);
            },

            //下盖文本框发生改变
            textareaLowerChange(value, i, index) {
                console.log(value);
                console.log(i);
                console.log(index);
                console.log(this.checkedLowerCheckbox);
                for (let a = 0; a < this.checkedLowerCheckbox.length; a++) {
                    if (i.processesSetList[0].id == this.checkedLowerCheckbox[a].id) {
                        this.checkedLowerCheckbox[a].desc = value
                    }
                }

                for (let a = 0; a < this.setacreageLower0.length; a++) {
                    if (i.processesSetList[0].id == this.setacreageLower0[a].processesSetList[0].id) {
                        this.setacreageLower0[a].desc = value
                    }
                }
                console.log(this.checkedLowerCheckbox)
                console.log(this.setacreageLower0)
                // this.handleCheckedChange(value);
            },

            //盒型图发生改变
            RadioBox(value) {
                console.log(value)
                this.form.RadioBox = value
                // for (let a = 0; a < this.radioBoxList.length; a++) {
                //     if (this.form.RadioBox == this.radioBoxList[a].id) {
                //         this.typeList = this.radioBoxList[a].children
                //     }
                // }

                for (let a = 0; a < this.radioBoxList.length; a++) {

                    for (let b = 0; b < this.radioBoxList[a].children.length; b++) {
                        if (this.form.RadioBox == this.radioBoxList[a].id) {
                            this.typeList = this.radioBoxList[a].children
                            this.SketchMapList = this.radioBoxList[a].children[0].pic
                        }

                        if (this.form.RadioType == this.radioBoxList[a].children[b].id) {
                            this.SketchMapList = this.radioBoxList[a].children[b].pic
                        }
                    }
                }
            },

            //盒型图类型发生改变
            RadioType(value) {
                console.log(value)
                this.form.RadioType = value
                for (let a = 0; a < this.radioBoxList.length; a++) {
                    for (let b = 0; b < this.radioBoxList[a].children.length; b++) {
                        if (this.form.RadioType == this.radioBoxList[a].children[b].id) {
                            this.SketchMapList = this.radioBoxList[a].children[b].pic
                        }
                    }
                }
            },

            //提交报价
            onSubmit() {
                // let len = this.userList.length;
                // if(len <= 0){
                //   this.$message.error('请选择代理的客户！');
                //   return;
                // }
                console.log("submit!");
                console.log(this.form);
                console.log(this.setacreageUp0);
                console.log(this.setacreageLower0);
                console.log(this.checkedUpCheckbox);
                console.log(this.checkedLowerCheckbox);
                // for (let c = 0; c < this.checkedCheckbox.length; c++) {
                //     for (let a = 0; a < this.setacreage0.length; a++) {
                //         if (this.setacreage0[a].setacreage == 1) {
                //             if (this.checkedCheckbox[c].id == this.setacreage0[a].processesSetList[0].id) {
                //                 console.log(this.setacreage0[a].inSize)
                //                 this.checkedCheckbox[c].inSize = this.setacreage0[a].inSize
                //             }
                //         }
                //     }
                // }
                // console.log(this.widths);

                let userInfo = JSON.parse(window.sessionStorage.getItem('currentInfo'))
                console.log(userInfo)


                if (this.form.productId == 1028) {
                    this.$alert('页面正在开发中', '不可以', {
                        confirmButtonText: '确定',
                        callback: action => {
                            this.$message({
                                type: 'info',
                                message: `action: ${action}`
                            });
                        }
                    });
                } else {

                    let datas = this.form;
                    if (datas.state == "" || datas.states == "") {
                        this.$message.error("请填写完整印刷数量");
                    } else if (datas.packing == "") {
                        this.$message.error("请填写完整包装方式");
                    } else if (datas.distribution == "") {
                        this.$message.error("请填写完整配送方式");
                    } else if (datas.distribution_mustaddr != 1 && (datas.provinceId == "" || datas.cityId == "" || datas.areaId == "")) {
                        this.$message.error("请填写完整省市区目的地");
                    }
                    // else if (this.userType == 1 && (this.servicetext == '' || this.user_ID == '')) {
                    //     this.$message.error("请选择服务客户")
                    // }
                    else if(datas.needDesign === '1' && !datas.pNums){
                      this.$message.error("设计需要输入p数")
                    }else {
                        let data = {
                            //---- 印厂标识【必填】
                            userfatherid: datas.userfatherid,
                            // ---- 产品类型ID【必填，1028】
                            productId: datas.productId,
                            //---- 用户唯一性标识
                            userId: this.userType == 1 ? this.user_ID : userInfo.user_ID,
                            // ---- 基础数据【必填】
                            baseData: {
                                //---- 是否是自定义【1：是；0：否】
                                isDefind: datas.size == 1 ? "1" : "0",
                                //---- 成品尺寸宽【isDefind = 1，必填；isDefind = 0，非必填】
                                width: this.cpsize.width,
                                //---- 成品尺寸长【isDefind = 1，必填；isDefind = 0，非必填】
                                length: this.cpsize.height,
                                //---- 尺寸ID【isDefind = 0，必填；isDefind = 1，非必填】
                                sizeId: datas.size,
                                //---- 款数【必填】
                                styleNum: datas.state,
                                //---- 每款数量【必填】
                                perNum: datas.states,
                            },
                            // ---- 部件信息【必填】
                            otherData: [
                                {
                                    // ---- 材料ID【必填】
                                    maId: datas.type,
                                    //---- 克重ID【必填】
                                    weight: datas.gram,
                                    //---- 自來料【必填】
                                    isCustMa: this.isCustMa == false ? 0 : 1,
                                    //---- 正面印色ID【非必填】
                                    color:
                                        datas.single == "colors"
                                            ? this.form.colour
                                            : this.form.colour,
                                    //---- 反面印色ID【非必填】
                                    colors: datas.single == "colors" ? this.form.colour : "",
                                    //---- 后道工艺列表【非必填】
                                    afterIds: this.checkedCheckbox,
                                },
                            ],
                            //---- 包装方式ID【必填】
                            packageTypeId: datas.packing,
                            //---- 配送方式ID【必填】
                            postTypeId: datas.distribution,
                            //---- 省市区
                            dest: datas.dest,
                            //---- 省ID
                            provinceId: datas.provinceId,
                            //---- 市ID
                            cityId: datas.cityId,
                            //---- 区ID
                            areaId: datas.areaId,
                          invoice:  this.form.invoice,

                          needDesign:  this.form.needDesign,
                          pNums: this.form.pNums
                            // remark: "轻拿轻放"
                        };
                        //立即报价
                        this.fullscreenLoading = true;
                        setTimeout(() => {
                            this.$http
                                .post("/cpshttp/getSinglePageQuotation", data)
                                .then((res) => {
                                    console.log(res);
                                    console.log(data);
                                    console.log(JSON.stringify(data));
                                    // this.centerDialogVisible2 = true

                                    this.fullscreenLoading = false;
                                    if (res.data != "") {
                                        this.$message({
                                            message: "报价成功",
                                            type: "success",
                                        });

                                        this.centerDialogVisible2 = true;
                                        //报价信息
                                        //---- 弹出框展示数据
                                        this.info = res.data.info
                                        //---- 部件产品信息
                                        this.orderProduct = res.data.orderProduct
                                        console.log(this.info)
                                    } else {
                                        this.$message.error("报价失败");
                                    }
                                });
                        }, 2000);
                    }

                }

            },

            //发货日期
            //时间转换
            //中国标准时间字符串，转换成y-m-d:h-m-s格式
            time(date) {
                var y = date.getFullYear()
                var m = date.getMonth() + 1
                m = m < 10 ? '0' + m : m
                var d = date.getDate()
                d = d < 10 ? '0' + d : d
                var h = date.getHours()
                h = h < 10 ? '0' + h : h
                var minute = date.getMinutes()
                minute = minute < 10 ? '0' + minute : minute
                var second = date.getSeconds()
                second = second < 10 ? '0' + second : second
                // return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second
                return y + '-' + m + '-' + d
            },

            //立即下单
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        // alert('submit!');
                        console.log(this.ruleForm);
                        // this.$emit('transferUser ',this.ruleForm)
                        // this.$router.push({ name: this.name, params: { xinxi: this.ruleForm } });

                        let userInfo = JSON.parse(window.sessionStorage.getItem('currentInfo'))
                        console.log(userInfo)

                        let ruleForm = this.ruleForm

                        this.centerDialogVisible2 = false
                        let data = {
                            //---- 产品信息【点击（产品报价）返回信息】
                            orderProduct: this.orderProduct,
                            //---- 印件名称
                            orderName: ruleForm.name,
                            //---- 色彩要求【暂无】
                            printType: ruleForm.color,
                            //---- 发货日期
                            completiontime: this.time(new Date(ruleForm.date)),
                            //---- 收货人
                            contacts: ruleForm.consignee,
                            //---- 印厂标识【相当于前面的userfatherid】
                            enterpriseId: this.form.userfatherid,
                            //---- 客户其他备注
                            customSupply: ruleForm.desc,
                            //---- 联系方式
                            telephone: ruleForm.tel,
                            //---- 订单费用
                            orderFee: this.info.totalFee,
                            //---- 送货地址【报价当中的dest + 详情】
                            sendAddrs: ruleForm.address,
                            //---- 配送方式ID
                            // postTypeId:'',
                            //---- 预付款金额
                            preFee: this.info.totalFee * 0.3,
                            //---- 付款方式
                            // paytype: ruleForm.paymentMethod,
                            //---- 用户唯一性标识
                          userid: this.userType == 1 ? this.user_ID : userInfo.user_ID,
                            //---- 代客下单中客户user_id
                            customerId: this.user_ID == '' ? userInfo.user_ID : this.user_ID,
                        }
                        console.log(data)

                      if(!data.userid){
                        this.$message.error("请选择服务客户");
                        return
                      }

                        this.fullscreenLoading2 = true;
                        setTimeout(() => {
                            this.$http.post('/cpshttp/createMallOrder', data).then((res) => {
                                console.log(res)
                                this.fullscreenLoading2 = false;
                                if (res.data.code == 200) {
                                    this.$message({
                                        message: "下单成功",
                                        type: "success",
                                    });
                                    this.$router.push({name: "Personal", params: {chiecked: 2}});
                                } else {
                                    this.$message.error(res.data.message);
                                }
                            })
                        }, 2000)

                        // }

                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },

            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
        },
        mounted() {

        },
    };
</script>

<style lang="scss" scoped>
    .title {
        color: #058af2;
        padding-left: 20px;
        border-left: 5px solid #058af2;
        /*border-bottom: 1px dashed #ccc;*/
    }

    hr {
        border: none;
        border-bottom: 1px solid #ededed;
        margin: 8px 0;
    }

    .body {
        display: flex;
        margin-top: 20px;
        /*左侧图片*/
        .pic {
            width: 350px;
            display: grid;
            height: 690px;

            img:first-of-type {
                width: 325px;
                height: 325px;
                border: 1px solid #d9d9d9;
            }

            img:last-of-type {
                margin: 10px auto 0 auto;
                width: 150px;
                height: 150px;
                border: 1px solid red;
            }
        }

        /*内容主体*/
        .neirong {
            width: 100%;
            padding-left: 30px;
            /*模块*/
            .modular:first-child {
                h4 {
                    margin-top: 0;
                }
            }

            .modular {
                /*margin-bottom: 20px;*/
                margin-bottom: 0px;

                h4 {
                    margin-top: 8px;
                }

                hr {
                    border-bottom: 1px dashed #ccc;
                }

                /*成品尺寸*/
                .chengpin {
                    .class {
                        text-align: right;
                        vertical-align: middle;
                        /*float: right;*/
                        font-size: 14px;
                        color: #606266;
                        line-height: 40px;
                        padding: 0 12px 0 12px;
                        box-sizing: border-box;
                    }

                    .el-form-item {
                        margin-bottom: 0px;
                        white-space: nowrap;
                    }

                    ::v-deep .el-form-item__content {
                        width: 217px !important;
                    }
                }

                /*选择盒型图*/
                .boxSize {
                    .el-form-item {
                        display: flex !important;
                        align-content: flex-start !important;
                        flex-direction: column !important;

                        ::v-deep .el-form-item__label {
                            display: block !important;
                        }

                        ::v-deep .el-form-item__content {
                            display: block !important;
                            margin-left: 0 !important;
                        }
                    }

                    /*选择盒型图*/
                    ul.size {
                        li {
                            width: 87px;
                            height: 87px;
                            overflow: hidden;
                            text-align: center;
                            margin-right: 5px;
                            display: inline-block;

                            /*图片*/
                            img {
                                width: 60px;
                                height: 60px;
                                display: block;
                                margin: auto;
                                margin-top: 3px;
                            }

                            /*图片描述*/
                            .text {
                                font-size: 14px;
                                color: #676767;
                                margin-top: 3px;
                                display: block;
                            }

                            /*单选按钮隐藏*/
                            ::v-deep .el-radio__input {
                                display: none;
                            }

                            ::v-deep .el-radio__input + .el-radio__label {
                                display: block;
                                width: 100%;
                                /*height: 100%;*/
                                padding: 0;
                            }

                            /*边框样式*/
                            ::v-deep .el-radio.is-bordered {
                                /*border-color: #409EFF;*/
                                width: 87px;
                                height: 87px;
                                overflow: hidden;
                                border-radius: 3px;
                                text-align: center;
                                border: 1px solid #ccc;
                                display: inline-block;
                                padding: 0;
                            }

                            /*边框样式_鼠标浮动样式*/
                            ::v-deep .el-radio.is-bordered:hover {
                                border-color: #409EFF;
                                width: 87px;
                                height: 87px;
                                overflow: hidden;
                                border-radius: 3px;
                                text-align: center;
                                /*border: 1px solid #ccc;*/
                                display: inline-block;
                                padding: 0;
                            }

                            /*单选时边框样式*/
                            ::v-deep .el-radio.is-bordered.is-checked {
                                border-color: #409EFF;
                                width: 87px;
                                height: 87px;
                                overflow: hidden;
                                border-radius: 3px;
                                text-align: center;
                                /*border: 1px solid #ccc;*/
                                display: inline-block;
                            }
                        }
                    }
                }

                /*盒型图类型*/
                .boxType {
                    .el-form-item {
                        display: flex !important;
                        align-content: flex-start !important;
                        flex-direction: column !important;

                        ::v-deep .el-form-item__label {
                            display: block !important;
                        }

                        ::v-deep .el-form-item__content {
                            display: block !important;
                            margin-left: 0 !important;
                            width: auto !important;
                        }
                    }

                    /*盒型图类型*/
                    ul.type {
                        padding: 0;

                        li {
                            /*padding: 5px 20px;*/
                            /*border-radius: 3px;*/
                            /*border: 1px solid #ccc;*/
                            display: inline-block;
                            text-align: center;
                            margin-right: 5px;

                            /*描述*/
                            .text {
                                font-size: 14px;
                                color: #676767;
                                line-height: 28px;
                            }

                            /*单选按钮隐藏*/
                            ::v-deep .el-radio__input {
                                display: none;
                            }

                            ::v-deep .el-radio__input + .el-radio__label {
                                display: block;
                                width: 100%;
                                /*height: 100%;*/
                                padding: 0;
                            }

                            /*边框样式*/
                            ::v-deep .el-radio.is-bordered {
                                /*border-color: #409EFF;*/
                                /*width: 87px;*/
                                /*height: 87px;*/
                                padding: 5px 20px;
                                overflow: hidden;
                                border-radius: 3px;
                                text-align: center;
                                border: 1px solid #ccc;
                                display: inline-block;
                            }

                            /*边框样式*/
                            ::v-deep .el-radio.is-bordered:hover {
                                border-color: #409EFF;
                                /*width: 87px;*/
                                /*height: 87px;*/
                                padding: 5px 20px;
                                overflow: hidden;
                                border-radius: 3px;
                                text-align: center;
                                /*border: 1px solid #ccc;*/
                                display: inline-block;
                            }

                            /*边框样式_鼠标浮动样式*/
                            ::v-deep .el-radio.is-bordered:hover {
                                border-color: #409EFF !important;
                                /*width: 87px;*/
                                /*height: 87px;*/
                                padding: 5px 20px;
                                overflow: hidden;
                                border-radius: 3px;
                                text-align: center;
                                /*border: 1px solid #ccc;*/
                                display: inline-block;
                            }

                            /*单选时边框样式*/
                            ::v-deep .el-radio.is-bordered.is-checked {
                                border-color: #409EFF;
                                /*width: 87px;*/
                                /*height: 87px;*/
                                padding: 5px 20px;
                                overflow: hidden;
                                border-radius: 3px;
                                text-align: center;
                                /*border: 1px solid #ccc;*/
                                display: inline-block;
                            }
                        }
                    }
                }

                /*示意图*/
                .SketchMap {
                    .el-form-item {
                        display: flex !important;
                        align-content: flex-start !important;
                        flex-direction: column !important;

                        ::v-deep .el-form-item__label {
                            display: block !important;
                        }

                        ::v-deep .el-form-item__content {
                            display: block !important;
                            margin-left: 0 !important;
                        }
                    }

                    /*示意图*/
                    ul.map {
                        li:hover {
                            border-color: #409EFF;
                            border-radius: 3px;
                        }

                        li {
                            width: 80px;
                            height: 80px;
                            overflow: hidden;
                            border-radius: 3px;
                            text-align: center;
                            border: 1px solid #ccc;
                            display: inline-block;
                            margin-right: 5px;

                            /*图片*/
                            img {
                                width: 60px;
                                height: 60px;
                                display: block;
                                margin: auto;
                                margin-top: 10px;
                            }
                        }
                    }
                }
            }
        }
    }

    /*立即下单详细信息*/
    .ordernowList {
        margin: 20px auto 0px;
        /*border: 1px solid #EBEEF5;*/

        /*请输入印刷品最显著的标题*/
        .grey {
            line-height: 43px;
            font-size: 14px;
            color: #999;
            font-weight: normal;
            margin-left: 15px;
        }

        /*地址*/
        .black {
            line-height: 43px;
            font-size: 14px;
            color: #333;
            font-weight: normal;
            margin-right: 15px;
        }
    }

    .el-checkbox-group {
        ::v-deep .el-checkbox {
            width: auto !important;
        }
    }
</style>

<style lang="scss">
    /*成品尺寸自定义按钮  */
    .el-select-dropdown {
        .el-select-dropdown__item {
            .el-button {
                width: 100%;
                text-align: left;
                color: #606266;
            }
        }
    }

    /*成品尺寸自定义对话框*/
    .zidingyi {
        .el-dialog {
            .el-form {
                .el-form-item {
                    .el-form-item__content {
                        display: flex;

                        .el-input {
                            width: 20%;
                        }

                        span {
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
    }

    /*后道设置多选框*/
    .danye_houddao {

        .el-checkbox-group {
            .is-checked {
                width: auto !important;
            }
        }
    }

    .el-checkbox-group {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-content: stretch;
        flex-wrap: wrap;
        align-items: flex-start;

        .el-checkbox {
            /*width: 48%;*/
            margin: 5px 10px 5px 0;
            display: flex;
            /*position: relative;*/
            /*display: inline-flex;*/
            .el-checkbox__label {
                /*position: absolute;*/
                /*position: relative;*/
                /*bottom: 0;*/
                .el-form {
                    display: block;
                }

                /*.el-form:last-of-type{*/
                /*    display: block;*/
                /*}*/
            }
        }
    }


    /*后道设置*/
    .houdao {
        .el-form {
            width: 100%;
            display: flex;
            margin: 0 40px 0 0 !important;

            .el-form-item__content {
                margin-left: 0 !important;
            }

            /*文本框*/
            .el-textarea__inner {
                height: 40px;
                width: 100px;
            }

            /*输入框*/
            .input {
                /*display: flex;*/
                margin-bottom: 5px;

                .el-form-item__content {
                    display: flex;
                    margin: 0 !important;
                    /*文本框*/
                    .el-textarea__inner {
                        height: 40px;
                        width: 100px;
                    }

                    .el-input {
                        width: 50px;
                    }

                    /*X:符号*/
                    span {
                        margin: 0 5px;
                    }

                    /*新增按钮*/
                    .newlyAdded {
                      margin-left: 0px;
                        text-align: center;
                        line-height: 25px;
                    }
                }
            }
        }

        .setacreage3_desc {
            margin-left: 20px;
        }
    }

    /*报价按钮*/
    .baojia {
        width: 100%;

        .el-form-item__content {
            display: flex;
            justify-content: center;
        }
    }

    /*包装方式*/
    .table {
        .el-table__header-wrapper {
            width: 180px;
        }

        .el-table__body-wrapper {
            .el-table__body {
                tbody {
                    .el-table__row {
                        .el-table_2_column_6 {
                            border-right: none;
                        }
                    }
                }
            }
        }
    }

    .el-table th > .cell {
        text-align: center;
    }

    .el-table .cell {
        text-align: center;
    }

    /*立即下单对话框*/
    .orderNow {
        table {
            border: 1px solid #ebeef5;
            width: 100%;

            tr {
                th {
                    color: #909399;
                    font-size: 14px;
                    font-weight: bold;
                  padding: 9px 6px;
                    text-align: center;
                    border-right: 1px solid #ebeef5;
                    border-bottom: 1px solid #ebeef5;
                }

                th:last-of-type {
                    border-right: 0;
                }

                td {
                    color: #606266;
                    font-size: 14px;
                  padding: 9px 6px;
                    text-align: center;
                    border-right: 1px solid #ebeef5;
                    border-bottom: 1px solid #ebeef5;
                }

                td:last-of-type {
                    border-right: 0;
                }
            }

            tr:last-of-type th {
                border-bottom: 0;
            }

            tr:last-of-type td {
                border-bottom: 0;
            }

            .packType th {
                border-right: 1px solid #ebeef5 !important;
            }

            .packType td {
                border-right: 1px solid #ebeef5 !important;
            }

            .packType td:last-of-type {
                border-right: 0 !important;
            }
        }
    }

    .ordernowList {
        .el-form {
            .el-form-item {
                margin-bottom: 0px;
                /*border-bottom: 1px solid #EBEEF5;*/
                .el-form-item__content {
                    .el-input {
                        width: 41%;
                    }
                }

                .el-form-item__label {
                    text-align: center;
                    /*background-color: #f5f5f5;*/
                    height: 44px;
                    line-height: 44px;
                    width: 12%;
                    text-align: center;
                    font-size: 14px;
                    color: #333;
                    vertical-align: middle;
                }
            }

        }

        .line {
            display: flex;
            width: 100%;
        }

        .line > div {
            width: 50%;

            .el-select {
                width: 100% !important;
            }

            .el-input {
                width: 100% !important;
            }
        }

        .line > div:nth-child(even) {
            margin-left: 24px;
        }

        .paymentMethod {
            .el-input--suffix {
                width: 100%;
            }
        }
    }
</style>
