<template>
    <div>
        <div>
            <div class="title">条幅锦旗绶带报价</div>
            <hr/>
        </div>
        <div class="body">

            <div class="neirong">
                <el-alert
                        title="1. 喷画自动处理，无人工干预；2. 此报价的出货时间为单个订单要求的正常出货时间，若分开下多个订单，全部订单出货需按照累计的出货时间计算"
                        type="warning"
                        :closable="false"
                >
                </el-alert>
                <!--基本参数-->
                <div class="modular canshu">
                    <h4>基本参数</h4>
                    <hr/>
                    <el-form :model="form" label-width="80px" ref="form">
                        <!--单款数量(面)-->
                        <div class="chengpin" v-if="BannerRibbon.cover == 2">
                            <el-form-item label="单款数量">
                                <el-select
                                        allow-create
                                        default-first-option
                                        filterable
                                        placeholder="请输入单款数量"
                                        v-model="form.numbers"
                                >
                                    <el-option
                                            :key="item"
                                            :label="item"
                                            :value="item"
                                            v-for="item in NumberOfPrintedSheets"
                                    ></el-option>
                                </el-select>
                                <span class="class">面</span>
                            </el-form-item>
                        </div>

                        <!--单款数量(条)-->
                        <div class="chengpin" v-else-if="BannerRibbon.cover == 3">
                            <el-form-item label="单款数量">
                                <el-select
                                        allow-create
                                        default-first-option
                                        filterable
                                        placeholder="请输入单款数量"
                                        v-model="form.numbers"
                                >
                                    <el-option
                                            :key="item"
                                            :label="item"
                                            :value="item"
                                            v-for="item in NumberOfPrintedSheets"
                                    ></el-option>
                                </el-select>
                                <span class="class">条</span>
                            </el-form-item>
                        </div>

                        <div v-else>
                            <!--款数(种类)-->
                            <div class="chengpin">
                                <el-form-item label="款数(种类)">
                                    <el-input
                                            v-model="form.types"
                                            placeholder="请输入款数(种类)"
                                            :style="types_width"
                                    ></el-input>
                                    <span class="class">款</span>
                                </el-form-item>
                            </div>

                            <!--单款数量(条)-->
                            <div class="chengpin">
                                <el-form-item label="单款数量">
                                    <el-select
                                            allow-create
                                            default-first-option
                                            filterable
                                            placeholder="请输入单款数量"
                                            v-model="form.numbers"
                                    >
                                        <el-option
                                                :key="item"
                                                :label="item"
                                                :value="item"
                                                v-for="item in NumberOfPrintedSheets"
                                        ></el-option>
                                    </el-select>
                                    <span class="class">条</span>
                                </el-form-item>
                            </div>
                        </div>

                      <div class="chengpin"  >
                        <el-form-item label="是否设计">
                          <el-select
                              v-model="form.needDesign" >
                            <el-option
                                label="否"
                                value="0"
                            ></el-option>
                            <el-option
                                label="是"
                                value="1"
                            ></el-option>
                          </el-select>

                          <span class="class"  v-if="form.needDesign==='1'" >  <el-input    placeholder="请输入需要设计的p数" v-model="form.pNums" style="width: 180px;" ></el-input> p</span>

                        </el-form-item>
                      </div>
                    </el-form>
                </div>

                <!--条幅锦旗绶带选择-->
                <div class="modular canshu fm">
          <span
                  class="titleh4"
                  style="display: flex; justify-content: space-between"
          >
            <h4>
              {{
                BannerRibbon.cover == 0
                  ? "彩色条幅"
                  : BannerRibbon.cover == 1
                  ? "红底条幅"
                  : BannerRibbon.cover == 2
                  ? "锦旗"
                  : "绶带"
              }}
            </h4>
            <el-button @click="coverVisible = true" type="text"
            >条幅锦旗绶带选择</el-button
            >
          </span>

                    <el-dialog
                            :visible.sync="coverVisible"
                            center
                            title="选择条幅锦旗绶带"
                            width="30%"
                    >
                        <el-form :model="BannerRibbon">
                            <el-form-item label="">
                                <el-radio-group v-model="BannerRibbon.cover">
                                    <el-radio label="0" v-model="BannerRibbon.cover"
                                    >彩色条幅
                                    </el-radio
                                    >
                                    <el-radio label="1" v-model="BannerRibbon.cover"
                                    >红底条幅
                                    </el-radio
                                    >
                                    <el-radio label="2" v-model="BannerRibbon.cover"
                                    >锦旗
                                    </el-radio
                                    >
                                    <el-radio label="3" v-model="BannerRibbon.cover"
                                    >绶带
                                    </el-radio
                                    >
                                </el-radio-group>
                            </el-form-item>
                        </el-form>

                        <span class="dialog-footer" slot="footer">
              <!--                          <el-button @click="coverVisible = false">取 消</el-button>-->
              <el-button
                      @click="
                  coverVisible = false;
                  coverdetermine();
                "
                      type="primary"
              >提 交</el-button
              >
            </span>
                    </el-dialog>

                    <hr/>

                    <!--彩色条幅-->
                    <el-form
                            :model="form"
                            label-width="80px"
                            ref="form"
                            v-if="BannerRibbon.cover == 0"
                    >
                        <!--成品尺寸-->
                        <div class="chengpin">
                            <el-form-item label="成品尺寸">
                                <el-select
                                        placeholder="请选择成品尺寸"
                                        v-model="BannerRibbon.size"
                                >
                                    <el-option label="90*54MM" value="0"></el-option>
                                    <el-option label="100*140MM" value="1"></el-option>
                                    <el-option label="210*140MM" value="2"></el-option>
                                    <el-option label="210*285MM" value="3"></el-option>
                                    <el-option label="420*285MM" value="4"></el-option>
                                    <el-option label="420*570MM" value="5"></el-option>
                                    <el-option :label="zdysize" value="1">
                                        <el-button @click="centerDialogVisible = true" type="text"
                                        >自定义
                                        </el-button
                                        >
                                    </el-option>
                                </el-select>
                                <span class="class">(宽*高)m</span>
                            </el-form-item>
                        </div>

                        <!--成品尺寸自定义对话框-->
                        <div class="zidingyi">
                            <el-dialog
                                    :visible.sync="centerDialogVisible"
                                    center
                                    title="设置自定义尺寸"
                                    width="30%"
                            >
                                <el-form :model="cpsize">
                                    <el-form-item :label-width="formLabelWidth" label="宽">
                                        <el-input
                                                autocomplete="off"
                                                v-model="cpsize.width"
                                        ></el-input>
                                        <span>m</span>
                                    </el-form-item>
                                    <el-form-item :label-width="formLabelWidth" label="高">
                                        <el-input
                                                autocomplete="off"
                                                v-model="cpsize.height"
                                        ></el-input>
                                        <span>m</span>
                                    </el-form-item>
                                </el-form>
                                <span class="dialog-footer" slot="footer">
                  <!--<el-button @click="centerDialogVisible = false">取 消</el-button>-->
                  <el-button
                          @click="
                      centerDialogVisible = false;
                      tijiao();
                    "
                          type="primary"
                  >提交</el-button
                  >
                </span>
                            </el-dialog>
                        </div>

                        <!--材料克重-->
                        <div class="chengpin">
                            <el-form-item label="材料克重">
                                <el-select
                                        v-model="BannerRibbon.material"
                                        placeholder="请选择彩色条幅材料"
                                >
                                    <el-option label="50CM宽彩色条幅" value="0"></el-option>
                                    <el-option label="60CM宽彩色条幅" value="1"></el-option>
                                    <el-option label="70CM宽彩色条幅" value="2"></el-option>
                                    <el-option label="80CM宽彩色条幅" value="3"></el-option>
                                    <el-option label="90CM宽彩色条幅" value="4"></el-option>
                                </el-select>
                                <span class="class">
                  <el-checkbox v-model="isCustMafm">自来料</el-checkbox>
                </span>
                                <!--                                <el-select placeholder="请选择克" v-model="BannerRibbon.GramWeight">-->
                                <!--                                    <el-option label="250" value="0"></el-option>-->
                                <!--                                </el-select>-->
                                <!--                                <span class="class">克</span>-->
                            </el-form-item>
                        </div>
                    </el-form>

                    <!--红底条幅-->
                    <el-form
                            :model="form"
                            label-width="80px"
                            ref="form"
                            v-if="BannerRibbon.cover == 1"
                    >
                        <!--成品尺寸-->
                        <div class="chengpin">
                            <el-form-item label="成品尺寸">
                                <el-select
                                        placeholder="请选择成品尺寸"
                                        v-model="BannerRibbon.size"
                                >
                                    <el-option label="大度16开210*285" value="0"></el-option>
                                    <el-option label="大度32开140*210" value="1"></el-option>
                                    <el-option label="正度16开185*260" value="2"></el-option>
                                    <el-option label="正度32开130*185" value="3"></el-option>
                                    <el-option :label="zdysize" value="1">
                                        <el-button @click="centerDialogVisible = true" type="text"
                                        >自定义
                                        </el-button
                                        >
                                    </el-option>
                                </el-select>
                                <span class="class">(宽*高)m</span>
                            </el-form-item>
                        </div>

                        <!--成品尺寸自定义对话框-->
                        <div class="zidingyi">
                            <el-dialog
                                    :visible.sync="centerDialogVisible"
                                    center
                                    title="设置自定义尺寸"
                                    width="30%"
                            >
                                <el-form :model="cpsize">
                                    <el-form-item :label-width="formLabelWidth" label="宽">
                                        <el-input
                                                autocomplete="off"
                                                v-model="cpsize.width"
                                        ></el-input>
                                        <span>m</span>
                                    </el-form-item>
                                    <el-form-item :label-width="formLabelWidth" label="高">
                                        <el-input
                                                autocomplete="off"
                                                v-model="cpsize.height"
                                        ></el-input>
                                        <span>m</span>
                                    </el-form-item>
                                </el-form>
                                <span class="dialog-footer" slot="footer">
                  <!--<el-button @click="centerDialogVisible = false">取 消</el-button>-->
                  <el-button
                          @click="
                      centerDialogVisible = false;
                      tijiao();
                    "
                          type="primary"
                  >提交</el-button
                  >
                </span>
                            </el-dialog>
                        </div>

                        <!--材料克重-->
                        <div class="chengpin">
                            <el-form-item label="材料克重">
                                <el-select
                                        v-model="BannerRibbon.material"
                                        placeholder="请选择红底条幅材料"
                                >
                                    <el-option
                                            label="50CM宽红底条幅【实际宽度44.5CM】"
                                            value="0"
                                    ></el-option>
                                    <el-option
                                            label="60CM宽红底条幅【实际宽度54CM】"
                                            value="1"
                                    ></el-option>
                                    <el-option
                                            label="70CM宽红底条幅【实际宽度66CM】"
                                            value="2"
                                    ></el-option>
                                    <el-option
                                            label="90CM宽红底条幅【实际宽度80.5CM】"
                                            value="3"
                                    ></el-option>
                                </el-select>
                                <!--                                <span class="class"></span>-->
                                <!--                                <el-select placeholder="请选择克" v-model="BannerRibbon.GramWeight">-->
                                <!--                                    <el-option label="300" value="0"></el-option>-->
                                <!--                                </el-select>-->
                                <span class="class">
                  <el-checkbox v-model="isCustMafm">自来料</el-checkbox>
                </span>
                            </el-form-item>
                        </div>

                        <!--印色-->
                        <div class="chengpin">
                            <el-form-item label="印色">
                                <el-radio v-model="BannerRibbon.color" label="0">白字</el-radio>
                                <el-radio v-model="BannerRibbon.color" label="1">黄字</el-radio>
                            </el-form-item>
                        </div>
                    </el-form>

                    <!--锦旗-->
                    <el-form
                            :model="form"
                            label-width="80px"
                            ref="form"
                            v-if="BannerRibbon.cover == 2"
                    >
                        <!--成品尺寸-->
                        <div class="chengpin">
                            <el-form-item label="成品尺寸">
                                <el-select
                                        placeholder="请选择成品尺寸"
                                        v-model="BannerRibbon.size"
                                >
                                    <el-option label="0.4*0.6米" value="0"></el-option>
                                    <el-option label="0.6*0.9米" value="1"></el-option>
                                    <el-option label="0.7*1.1米" value="2"></el-option>
                                    <el-option label="0.8*1.2米" value="3"></el-option>
                                    <el-option label="0.9*1.5米" value="4"></el-option>
                                    <!--                                    <el-option :label="zdysize" value="1">-->
                                    <!--                                        <el-button @click="centerDialogVisible = true" type="text">自定义</el-button>-->
                                    <!--                                    </el-option>-->
                                </el-select>
                                <span class="class">(宽*高)m</span>
                            </el-form-item>
                        </div>

                        <!--成品尺寸自定义对话框-->
                        <div class="zidingyi">
                            <el-dialog
                                    :visible.sync="centerDialogVisible"
                                    center
                                    title="设置自定义尺寸"
                                    width="30%"
                            >
                                <el-form :model="cpsize">
                                    <el-form-item :label-width="formLabelWidth" label="宽">
                                        <el-input
                                                autocomplete="off"
                                                v-model="cpsize.width"
                                        ></el-input>
                                        <span>m</span>
                                    </el-form-item>
                                    <el-form-item :label-width="formLabelWidth" label="高">
                                        <el-input
                                                autocomplete="off"
                                                v-model="cpsize.height"
                                        ></el-input>
                                        <span>m</span>
                                    </el-form-item>
                                </el-form>
                                <span class="dialog-footer" slot="footer">
                  <!--<el-button @click="centerDialogVisible = false">取 消</el-button>-->
                  <el-button
                          @click="
                      centerDialogVisible = false;
                      tijiao();
                    "
                          type="primary"
                  >提交</el-button
                  >
                </span>
                            </el-dialog>
                        </div>

                        <!--材料克重-->
                        <div class="chengpin">
                            <el-form-item label="材料克重">
                                <el-select
                                        v-model="BannerRibbon.material"
                                        placeholder="请选择锦旗材料"
                                >
                                    <el-option label="普通发泡锦旗" value="0"></el-option>
                                    <el-option label="普通镀金锦旗" value="1"></el-option>
                                    <el-option label="高档发泡锦旗" value="2"></el-option>
                                    <el-option label="高档镀金锦旗" value="3"></el-option>
                                </el-select>
                                <!--                                <span class="class"></span>-->
                                <!--                                <el-select placeholder="请选择克" v-model="BannerRibbon.GramWeight">-->
                                <!--                                    <el-option label="300" value="0"></el-option>-->
                                <!--                                </el-select>-->
                                <span class="class">
                  <el-checkbox v-model="isCustMafm">自来料</el-checkbox>
                </span>
                            </el-form-item>
                        </div>
                    </el-form>

                    <!--绶带-->
                    <el-form
                            :model="form"
                            label-width="80px"
                            ref="form"
                            v-if="BannerRibbon.cover == 3"
                    >
                        <!--成品尺寸-->
                        <div class="chengpin">
                            <el-form-item label="成品尺寸">
                                <el-select
                                        placeholder="请选择成品尺寸"
                                        v-model="BannerRibbon.size"
                                >
                                    <el-option label="1.8*0.13米" value="0"></el-option>
                                    <!--                                    <el-option :label="zdysize" value="1">-->
                                    <!--                                        <el-button @click="centerDialogVisible = true" type="text">自定义</el-button>-->
                                    <!--                                    </el-option>-->
                                </el-select>
                                <span class="class">(宽*高)m</span>
                            </el-form-item>
                        </div>

                        <!--成品尺寸自定义对话框-->
                        <div class="zidingyi">
                            <el-dialog
                                    :visible.sync="centerDialogVisible"
                                    center
                                    title="设置自定义尺寸"
                                    width="30%"
                            >
                                <el-form :model="cpsize">
                                    <el-form-item :label-width="formLabelWidth" label="宽">
                                        <el-input
                                                autocomplete="off"
                                                v-model="cpsize.width"
                                        ></el-input>
                                        <span>m</span>
                                    </el-form-item>
                                    <el-form-item :label-width="formLabelWidth" label="高">
                                        <el-input
                                                autocomplete="off"
                                                v-model="cpsize.height"
                                        ></el-input>
                                        <span>m</span>
                                    </el-form-item>
                                </el-form>
                                <span class="dialog-footer" slot="footer">
                  <!--<el-button @click="centerDialogVisible = false">取 消</el-button>-->
                  <el-button
                          @click="
                      centerDialogVisible = false;
                      tijiao();
                    "
                          type="primary"
                  >提交</el-button
                  >
                </span>
                            </el-dialog>
                        </div>

                        <!--材料克重-->
                        <div class="chengpin">
                            <el-form-item label="材料克重">
                                <el-select
                                        v-model="BannerRibbon.material"
                                        placeholder="请选择绶带材料"
                                >
                                    <el-option label="缎面绶带" value="0"></el-option>
                                    <el-option label="发泡绶带" value="1"></el-option>
                                </el-select>
                                <span class="class">
                  <el-checkbox v-model="isCustMafm">自来料</el-checkbox>
                </span>
                            </el-form-item>
                        </div>
                    </el-form>
                </div>

                <!--后道设置-->
                <div class="modular houdao">
                    <h4>后道设置</h4>
                    <hr/>
                  <el-checkbox-group v-model="form.checkedCheckbox" @change="handleCheckedChange">

                    <div v-for="item in processList" :key="item.id" style="width: 25%;">

                      <el-checkbox :label="item.id" :value="item.id" v-if="item.mustchoose == 1" :checked="true"
                                   disabled>
                        {{ item.name }}

                        <div v-if="item.setacreage == 0">
                          <div v-for="i in setacreage0" :key="i.id">
                            <el-form ref="form" label-width="80px" v-if="item.id == i.id">
                              <el-form-item label="">
                                <el-select
                                    v-model="i.models"
                                    @change="selecChange"
                                    :placeholder="'请选择' + i.name"
                                    style="width: 80px;"
                                >
                                  <el-option
                                      v-for="(items, index) in i.processesSetList"
                                      :key="index"
                                      :label="items.name"
                                      :value="items.id"
                                  ></el-option>
                                </el-select>
                              </el-form-item>
                            </el-form>
                          </div>
                        </div>
                      </el-checkbox>

                      <el-checkbox :label="item.id" :value="item.id" v-else>
                        {{ item.name }}
                      </el-checkbox>

                      <div v-if="item.setacreage == 0 && item.mustchoose !== 1 ">
                        <div v-for="i in setacreage0" :key="i.id">
                          <el-form ref="form" label-width="80px" v-if="item.id == i.id">
                            <el-form-item label="">
                              <el-select
                                  v-model="i.models"
                                  @change="selecChange"
                                  :placeholder="'请选择' + i.name"
                                  style="width: 100px;"
                              >
                                <el-option
                                    v-for="(items, index) in i.processesSetList"
                                    :key="index"
                                    :label="items.name"
                                    :value="items.id"
                                ></el-option>
                              </el-select>
                            </el-form-item>
                          </el-form>
                        </div>
                      </div>

                      <div v-if="item.setacreage == 1">
                        <div v-for="i in setacreage0" :key="i.id">
                          <el-form ref="form" :model="form" label-width="80px" v-if="item.id == i.id">

                            <el-form-item label="" :label-width="formWidth"
                                          v-if="(i.num >0)&&item.processesSetList[0].id==i.processesSetList[0].id">

                              <el-form-item label="" class="input" :id="item.id"
                                            v-for="(a,index) in +i.num" :key="index">
                                <el-form-item label="" :label-width="formWidth">
                                  <el-input v-model="i.inSize[index].width" autocomplete="off"
                                            :id="i.id"
                                            style="width: 50px;"
                                            @change="widthChange($event,i,index)"></el-input>
                                  <span>X</span>
                                </el-form-item>
                                <el-form-item label="" :label-width="formWidth">
                                  <el-input v-model="i.inSize[index].length" autocomplete="off"
                                            style="width: 50px;"
                                            @change="lengthChange($event,i,index)"></el-input>
                                  <span>mm</span>
                                  <el-button v-if="a==1" type="text" class="newlyAdded" size="small"
                                             @click="newlyAdded(item, i)">增
                                  </el-button>
                                  <el-button v-else type="text" class="newlyAdded"  size="small"
                                             @click="del(item, i)">删
                                  </el-button>
                                </el-form-item>
                              </el-form-item>

                            </el-form-item>
                          </el-form>
                        </div>
                      </div>

                      <div v-if="item.setacreage == 2">
                        <div v-for="i in setacreage0" :key="i.id">
                          <el-form ref="form" label-width="80px" v-if="item.id == i.id">
                            <el-form-item label="">
                              <el-select
                                  v-model="i.models"
                                  @change="selecChange"
                                  :placeholder="'请选择' + i.name"
                                  style="width: 100px;"
                              >
                                <el-option
                                    v-for="(items, index) in i.processesSetList"
                                    :key="index"
                                    :label="items.name"
                                    :value="items.id"
                                ></el-option>
                              </el-select>
                            </el-form-item>
                          </el-form>

                          <el-form ref="form" :model="form" label-width="80px" v-if="item.id == i.id">

                            <el-form-item label="" :label-width="formWidth"
                                          v-if="(i.num >0)&&item.processesSetList[0].id==i.processesSetList[0].id">

                              <el-form-item label="" class="input" :id="item.id"
                                            v-for="(a,index) in +i.num" :key="index">
                                <el-form-item label="" :label-width="formWidth">
                                  <el-input v-model="i.inSize[index].width" autocomplete="off"
                                            :id="i.id"    style="width: 50px;"
                                            @change="widthChange($event,i,index)"></el-input>
                                  <span>X</span>
                                </el-form-item>
                                <el-form-item label="" :label-width="formWidth">
                                  <el-input v-model="i.inSize[index].length" autocomplete="off"   style="width: 50px;"
                                            @change="lengthChange($event,i,index)"></el-input>
                                  <span>mm</span>
                                  <el-button v-if="a==1" type="text" class="newlyAdded"
                                             @click="newlyAdded(item, i)">增
                                  </el-button>
                                  <el-button v-else type="text" class="newlyAdded"
                                             @click="del(item, i)">删
                                  </el-button>
                                </el-form-item>
                                <el-form-item label="数量" label-width="67px;" style="margin-left: 2px;"  v-if="index==0">
                                  <el-input
                                      @change="textareaChange($event,i,index)"
                                      style="width: 100px;"
                                      v-model="i.desc"
                                  ></el-input>
                                </el-form-item>
                              </el-form-item>

                            </el-form-item>
                          </el-form>
                        </div>
                      </div>

                      <div v-if="item.setacreage == 3">
                        <div v-for="i in setacreage0" :key="i.id">
                          <el-form
                              ref="form"
                              :model="form"
                              label-width="80px"
                              v-if="item.id == i.id"
                          >
                            <el-form-item label="">
                              <el-select
                                  v-model="i.models"
                                  @change="selecChange"
                                  :placeholder="'请选择' + i.name"
                                  style="width: 100px;"
                              >
                                <el-option
                                    v-for="items in i.processesSetList"
                                    :key="items.id"
                                    :label="items.name"
                                    :value="items.id"
                                ></el-option>
                              </el-select>
                            </el-form-item>
                            <el-form-item label="数量"  label-width="75px;" style="margin-left: 2px;" class="setacreage3_desc">
                              <el-input
                                  @change="textareaChange($event,i,index)"
                                  style="width: 50px;"
                                  v-model="i.desc"
                              ></el-input>
                            </el-form-item>
                          </el-form>
                        </div>
                      </div>
                    </div>
                  </el-checkbox-group>

                </div>

                <!--包装方式-->
                <div class="modular">
                    <h4>包装方式</h4>
                    <hr/>
                    <el-form :model="form" label-width="80px" ref="form">
                        <!--包装方式-->
                        <div class="chengpin">
                            <el-form-item label="包装方式">
                                <el-select placeholder="请选择包装方式" v-model="form.packing">
                                    <el-option
                                            :key="item.id"
                                            :label="item.typename"
                                            :value="item.id"
                                            v-for="item in packageList"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </el-form>
                </div>

                <!--配送方式-->
                <div class="modular">
                    <h4>配送方式</h4>
                    <hr/>
                    <el-form :model="form" label-width="80px" ref="form">
                        <!--包装方式-->
                        <div class="chengpin">
                            <el-form-item label="配送方式">
                                <el-select
                                        placeholder="请选择配送方式"
                                        v-model="form.distribution"
                                        @change="selecChangepsfs($event)"
                                >
                                    <el-option
                                            :key="item.id"
                                            :label="item.typename"
                                            :value="item.id"
                                            v-for="item in postList"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </div>

                        <!--服务客户-->
                        <div
                                class="chengpin"
                                v-if="this.userType !== 0"
                                @click="service = true"
                        >
                            <el-form-item label="服务客户">
                                <el-input
                                        placeholder="请选择服务客户"
                                        v-model="servicetext"
                                        style="width: 25%"
                                ></el-input>
                            </el-form-item>
                        </div>

                        <!--目的地-->
                        <div class="chengpin" v-if="this.form.distribution_mustaddr != 1">
                            <el-form-item label="目的地" prop="selectedOptions">
                                <el-cascader
                                        :options="options"
                                        @change="handleChange"
                                        size="large"
                                        v-model="form.selectedOptions"
                                >
                                </el-cascader>
                            </el-form-item>
                        </div>
                      <div class="chengpin">
                        <el-form-item label="发票要求" prop="invoice">
                          <el-select v-model="form.invoice" >
                            <el-option v-for="i in invoiceList" :label="i.name" :value="i.num"></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                    </el-form>
                </div>

                <!--立即报价-->
                <div class="baojia">
                    <el-form>
                        <el-form-item>
                            <el-button
                                    @click="onSubmit()"
                                    type="primary"
                                    v-loading.fullscreen.lock="fullscreenLoading"
                            >立即报价
                            </el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>

            <!--服务客户对话框-->
            <el-dialog title="服务客户" :visible.sync="service" width="68%" center>
                <customer-service @transferUser="change($event)"></customer-service>
                <span slot="footer" class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="determine">确 定</el-button>
        </span>
            </el-dialog>

            <!--立即下单对话框-->
            <div class="orderNow">
                <el-dialog :visible.sync="centerDialogVisible2" title="报价详情">
                    <table>
                        <tr>
                            <th>成品尺寸(宽*高)</th>
                            <th>印刷数量</th>
                            <th>装订方式</th>
                        </tr>
                        <tr>
                            <td>{{ info.productSpec }}</td>
                            <td>{{ info.productNum }}</td>
                            <td>{{ info.bindingType }}</td>
                        </tr>
                    </table>
                    <table>
                        <tr>
                            <th>部件名称</th>
                            <th>P数/尺寸</th>
                            <th>印刷颜色</th>
                            <th>印刷纸张</th>
                            <th>后道工艺</th>
                        </tr>
                        <tr v-for="(item, index) in info.partsList" :key="index">
                            <td style="font-weight: bold">{{ item.partsName }}</td>
                            <td>{{ item.pNum }}</td>
                            <td>{{ item.printColor }}</td>
                            <td>{{ item.paperName }}</td>
                            <td>{{ item.aftDesc }}</td>
                        </tr>
                        <tr class="packType">
                            <td style="font-weight: bold">包装方式</td>
                            <td colspan="4">{{ info.packageType }}</td>
                        </tr>
                    </table>

                    <div
                            class="cost"
                            style="
              display: flex;
              margin-top: 10px;
              justify-content: space-evenly;
              line-height: 30px;
            "
                    >
                        <div>
              <span class="text" style="color: #999; font-size: 12px"
              >系统报价：</span
              >
                            <span
                                    style="
                  color: #ed6d00;
                  font-size: 14px;
                  font-weight: 700;
                  font-family: tahoma;
                "
                            >￥{{ orderProduct.sysFee }}</span
                            >
                        </div>
                        <div>
              <span class="text" style="color: #999; font-size: 12px"
              >运费：</span
              >
                            <span
                                    style="
                  color: #ed6d00;
                  font-size: 14px;
                  font-weight: 700;
                  font-family: tahoma;
                "
                            >￥{{ info.postFee }}</span
                            >
                        </div>
                        <div>
              <span class="text" style="color: #999; font-size: 12px"
              >成交价：</span
              >
                            <span
                                    style="
                  color: #ed6d00;
                  font-size: 24px;
                  font-weight: 700;
                  font-family: tahoma;
                "
                            >￥{{ orderProduct.productFee }}</span
                            >
                        </div>
                    </div>

                    <!--下单信息-->
                    <!--                    <order-now @transferUser="change($event)"></order-now>-->
                    <div class="ordernowList">
                        <el-form
                                :model="ruleForm"
                                :rules="rules"
                                ref="ruleForm"
                                label-width="100px"
                                class="demo-ruleForm"
                        >
                            <el-form-item label="印件名称" prop="name">
                                <el-input
                                        v-model="ruleForm.name"
                                        placeholder="请选择印件名称"
                                ></el-input>
                                <span class="grey">请输入印刷品最显著的标题</span>
                            </el-form-item>
                            <div class="line">
                                <el-form-item label="色彩要求" prop="color">
                                    <el-select
                                            v-model="ruleForm.color"
                                            placeholder="请选择色彩要求"
                                    >
                                        <el-option
                                                v-for="(item, index) in colourList"
                                                :key="index"
                                                :label="item"
                                                :value="item"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="发货日期" required>
                                    <el-form-item prop="date">
                                        <el-date-picker
                                                type="date"
                                                placeholder="选择日期"
                                                v-model="ruleForm.date"
                                                :picker-options="pickerOptions0"
                                                style="width: 100%"
                                        ></el-date-picker>
                                    </el-form-item>
                                </el-form-item>
                            </div>

                            <div class="line" v-if="this.form.distribution_mustaddr != 1">
                                <el-form-item label="收货人" prop="consignee">
                                    <el-input
                                            v-model="ruleForm.consignee"
                                            placeholder="请选择收货人"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item label="联系电话" prop="tel">
                                    <el-input
                                            v-model="ruleForm.tel"
                                            placeholder="请选择联系电话"
                                    ></el-input>
                                </el-form-item>
                            </div>

                            <el-form-item
                                    label="收货地址"
                                    v-if="this.form.distribution_mustaddr != 1"
                            >
                                <span class="black">{{ orderProduct.dest }}</span>
                                <el-input v-model="ruleForm.address"></el-input>
                                <span class="grey"
                                >如需配送多个地址，请下单后在订单管理页面修改</span
                                >
                            </el-form-item>

                            <el-form-item label="其他备注">
                                <el-input type="textarea" v-model="ruleForm.desc"></el-input>
                            </el-form-item>

                            <!--                            <el-form-item class="paymentMethod" label="付款方式">-->
                            <!--                                <el-select v-model="ruleForm.paymentMethod" placeholder="请选择付款方式">-->
                            <!--                                    <el-option v-for="(item,index) in paymentMethodList" :key="index" :label="item"-->
                            <!--                                               :value="item"></el-option>-->
                            <!--                                </el-select>-->
                            <!--                            </el-form-item>-->

                          <el-form-item style="margin-top: 10px;">
                                <el-button
                                        type="primary"
                                        @click="
                    centerDialogVisible2;
                    submitForm('ruleForm');
                  "
                                        v-loading.fullscreen.lock="fullscreenLoading2"
                                >立即下单
                                </el-button>
                                <el-button @click="resetForm('ruleForm')">重置</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </el-dialog>
            </div>
        </div>

        <!--纸张材质&贴心服务&下单流程-->
        <!--        <goods-list></goods-list>-->
    </div>
</template>

<script>
    import {regionData, CodeToText} from "element-china-area-data";
    import {getusername} from "../../api/myAuth";
    // import goodsList from "../goodsList/goodslist";
    import CustomerService from "../CustomerService/CustomerService";
    // import {username} from "../../api/myAuth";

    export default {
        name: "Banner",
        components: {
            // goodsList,
            CustomerService,
        },
        data() {
            // 手机验证
            var validateMobilePhone = (rule, value, callback) => {
                if (value === "") {
                    callback(new Error("手机号不可为空"));
                } else {
                    if (value !== "") {
                        var reg = /^1[3456789]\d{9}$/;
                        if (!reg.test(value)) {
                            callback(new Error("请输入有效的手机号码"));
                        }
                    }
                    callback();
                }
            };
            return {
                // 设置只能选择当前日期及之后的日期
                pickerOptions0: {
                    disabledDate(time) {
                        //如果没有后面的-8.64e7就是不可以选择今天的
                        return time.getTime() < Date.now() - 8.64e7;
                    },
                },

                isCustMafm: false, //自来料

                specList: [], //产品规格列表(基本参数)

                restaurants: [10, 20, 50, 100, 500], //印刷本数（本）
                NumberOfPrintedSheets: [1000, 2000, 5000, 100000], //印刷张数（张）
                restaurantspages: [90, 100], //每本张数（张/本）

                bindingList: [], //装订方式
                maList: [], //纸张克重（类型）
                covermaList: [], //parts=='封面'的封面纸张

                productWeightList: [], //纸张克重（克）

                colorList: [], //印刷颜色
                processList: [], //后道设置

                processesSetList: [], //后道设置（子类）
                packageList: [], //包装方式
                postList: [], //配送方式
              invoiceList: [],
                //基本参数
                form: {
                    userfatherid: "", //印厂标识【必填】
                    productId: "", //产品类型ID【必填，1028】

                    types: "", //款数(种类)
                    // pages: "",//每本张数（张/本）
                    numbers: "", //单款数量（条）
                    Conjunctions: "1", //联数

                    packing: "", //包装方式
                    distribution: "", //配送方式
                    distribution_mustaddr: "", //配送方式_mustaddr(mustaddr:1,不填写目的地)
                    selectedOptions: [], //目的地
                    dest: "", //省市区
                    provinceId: "", //省ID
                    cityId: "", //市ID
                    areaId: "", //区ID
                    needDesign: '0',
                    pNums: '1',
                    checkedCheckbox: [], //后道设置
                  invoice: "",
                },

                types_width: "width:217px", //款数(种类)

                //条幅锦旗绶带_材料
                BannerRibbon: {
                    cover: "0", //条幅锦旗绶带选择（默认条幅锦旗绶带选择）
                    material: "0", //材料
                    GramWeight: "0", //克重
                    size: "0", //成品尺寸
                    pic: "0", //材料_图片
                    color: "", //印色
                    // PrintingSurface: '0',//印面
                },

                checkedCheckbox: [], //后道设置

                selectedOptions: [], //目的地

                //成品尺寸自定义宽高
                cpsize: [],

                formLabelWidth: "50px", //成品尺寸自定义宽高输入框宽度

                formWidth: "20px", //后道设置新增宽高输入框宽度

                //自定义尺寸
                zdysize: "自定义",

                setacreage0: [], //封面下拉框

                centerDialogVisible: false, //成品尺寸自定义对话框

                addressList: [], // 地址列表
                options: regionData, //省市区三级联动数据

                coverVisible: false, //条幅锦旗绶带选择弹出层

                centerDialogVisible2: false, //立即下单对话框

                //立即报价_指令加载方式
                fullscreenLoading: false,

                //报价信息
                //---- 弹出框展示数据
                info: [],
                //---- 部件产品信息
                orderProduct: [],

                colourList: [
                    "正常印刷，无特别要求",
                    "显示屏，鲜艳饱和",
                    "有印样，严格追样（不含数码样）",
                    "有样稿，仅供参考",
                    "样稿未到，到样时间：",
                    "看兰纸",
                    "预约上机看样",
                    "其他",
                ], //色彩要求列表
                paymentMethodList: [
                    "全款下单",
                    "预付30%下单",
                    "货到付款",
                    "带款提货",
                    "账期客户",
                ], //付款方式列表
                ruleForm: {
                    name: "", //印件名称
                    color: "", //色彩要求
                    date: "", //发货日期
                    consignee: "", //收货人
                    tel: "", //联系电话
                    address: "", //收货地址
                    paymentMethod: "", //付款方式
                    desc: "", //备注
                },
                rules: {
                    name: [
                        {required: true, message: "请输入印件名称", trigger: "blur"},
                        {
                            min: 2,
                            max: 20,
                            message: "长度在 2 到 20 个字符",
                            trigger: "blur",
                        },
                    ],
                    // color: [
                    //     {required: true, message: '请选择色彩要求', trigger: 'change'}
                    // ],
                    date: [
                        {
                            type: "date",
                            required: true,
                            message: "请选择发货日期",
                            trigger: "change",
                        },
                    ],
                    consignee: [
                        {required: true, message: "请输入收货人", trigger: "blur"},
                        // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
                    ],
                    tel: [
                        {
                            required: true,
                            message: "请输入联系电话",
                            validator: validateMobilePhone,
                            trigger: "blur",
                        },
                        // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
                    ],
                    // address: [
                    //     {required: true, message: '请输入收货地址', trigger: 'blur'},
                    // ]
                },

                //报价详情_指令加载方式
                fullscreenLoading2: false,

                //是否是管理员账号
                userType: "",
                //服务客户
                service: false,
                //服务客户_用户名称
                servicetext: "",
                //服务客户_user_ID
                user_ID: "",
                //服务客户_信息
                userList: "",

                //拉页P数输入框宽度
                pNum_width: "width:40px;text-align: center;",
            };
        },
        created() {
            // console.log(getusername().userfatherid);
            // this.form.userfatherid = getusername().userfatherid;
            let obj=JSON.parse(window.sessionStorage.getItem("shoppingInfo"))
            console.log(obj.userfatherid)
            this.form.userfatherid = obj.userfatherid;

            var id = this.$route.params.id;
            if (id != 1015) {
                id = 1015;
            }
            this.form.productId = id;
            console.log(id);

            //获取默认地址
            this.GetAlladdress();

            //是否是管理员账号
            this.getuserType();

            this.$http
                .get("/cpshttp/getQuoteData?productId=" + id)
                .then((res) => {
                    console.log(res);
                    if (res.status == 200) {
                        // this.$message({
                        //     message: "报价页面加载成功",
                        //     type: "success",
                        // });

                        //包装方式列表
                        this.packageList = res.data.packageList;
                        this.form.packing = res.data.packageList[0].id;
                      this.invoiceList = res.data.invoiceList;

                        //配送方式列表
                        this.postList = res.data.postList;
                        this.form.distribution = res.data.postList[0].id;
                      this.form.distribution_mustaddr = res.data.postList[0].mustaddr;
                      if (this.form.distribution_mustaddr == 1) {
                        this.form.dest = "";
                        this.form.provinceId = "";
                        this.form.cityId = "";
                        this.form.areaId = "";
                      }
                        //后道设置列表
                        // this.processList = res.data.processList;
                        //循环后道设置
                        for (let a = 0; a < res.data.processList.length; a++) {
                            if (res.data.processList[a].dispaly == 1) {
                                this.processList.push(res.data.processList[a]);
                            }
                        }

                        //必选工艺
                        this.Mandatory();
                    } else {
                        this.$message.error("报价页面加载失败");
                    }
                })
                .catch((error) => {
                    console.error("Error", error);
                });

            //默认款数(种类)
            this.form.types = 1;
            //默认每本张数（张/本）
            this.form.pages = 100;
            //默认单款数量（张）
            this.form.numbers = 1000;

            // let year = new Date().getFullYear(); //得到年份
            // let month = new Date().getMonth();//得到月份
            // let date = new Date().getDate();//得到日期
            // // let hour = new Date().getHours();//得到小时
            // // let minu = new Date().getMinutes();//得到分钟
            // let hour = 17;//得到小时
            // let minu = 0;//得到分钟
            //
            // month = month + 1;
            // if (month < 10) month = "0" + month;
            // if (date < 10) date = "0" + date;
            // if (hour < 10) hour = "0" + hour;
            // if (minu < 10) minu = "0" + minu;
            //色彩要求
            this.ruleForm.color = this.colourList[0];
            //付款方式
            this.ruleForm.paymentMethod = this.paymentMethodList[0];
            //发货日期
            // this.ruleForm.date = year + '-' + month + '-' + date
        },
        methods: {
            //服务客户_信息_子组件传值
            change(data) {
                // this.user=msg
                console.log(data);
                this.userList = data;
                // this.xiadanList = data
            },

            //服务客户_取消
            cancel() {
                this.service = false;
                this.servicetext = "";
                this.user_ID = "";
            },

            //服务客户_确定
            determine() {
                this.service = false;
              if(this.userList.newTel != '' && this.userList.newTel != null){
                this.servicetext = this.userList.custName + '(' + this.userList.newTel + ')'
              } else {
                this.servicetext = this.userList.custName
              }
                this.user_ID = this.userList.user_ID;
                this.GetAlladdress();
            },

            //是否是管理员账号
            getuserType() {
                let userInfo = JSON.parse(window.sessionStorage.getItem("currentInfo"));
                console.log(userInfo);
                if (userInfo.userType == 1) {
                    this.userType = userInfo.userType;
                    console.log("管理员账号");
                } else {
                    this.userType = userInfo.userType;
                    console.log("游客账号");
                }
            },

            //必选工艺
            Mandatory() {
                let text = [];
                let arr = [];
                console.log(this.processList);
                for (let i = 0; i < this.processList.length; i++) {
                  if (this.processList[i].setacreage == 0 && (this.processList[i].mustchoose == 1 || this.processList[i].defaultShow == 1)) {
                        console.log(this.processList[i]);
                        this.checkedCheckbox.push({
                            id: this.processList[i].processesSetList[0].id,
                        });
                        arr.push({
                            name: this.processList[i].name,
                            id: this.processList[i].id,
                            setacreage: this.processList[i].setacreage,
                            processesSetList: this.processList[i].processesSetList,
                            num: 1,
                            desc: "",
                            models: this.processList[i].processesSetList[0].id,
                            inSize: [{width: "", length: ""}],
                        });
                        this.setacreage0 = arr;
                    }
                    for (let a = 0; a < this.checkedCheckbox.length; a++) {
                        if (
                            this.checkedCheckbox[a].id ==
                            this.processList[i].processesSetList[0].id
                        ) {
                            console.log(this.processList[i].processesSetList[0].name);
                            text.push(this.processList[i].processesSetList[0].name);
                        }
                    }
                }
                console.log(text);
                console.log(this.checkedCheckbox);
            },

            //提交自定义尺寸
            tijiao() {
                console.log(this.cpsize);
                this.zdysize = this.cpsize.width + "*" + this.cpsize.height + "m";
                console.log(this.zdysize);
            },

            //重定义
            redefinition() {
                //默认款数(种类)
                this.form.types = 1;
                //默认每本张数（张/本）
                this.form.pages = 100;
                //默认单款数量（张）
                this.form.numbers = 1000;
                this.BannerRibbon.material = "0"; //材料
                this.BannerRibbon.GramWeight = "0"; //克重
                this.BannerRibbon.size = "0"; //成品尺寸
                this.BannerRibbon.color = ""; //印色
                // this.BannerRibbon.PrintingSurface = '0';//印面
            },

            //确定条幅锦旗绶带选择
            coverdetermine() {
                //重置自来料
                this.isCustMafm = false;
                this.redefinition();
                if (this.BannerRibbon.cover == 0) {
                    console.log(this.BannerRibbon.cover);
                } else if (this.BannerRibbon.cover == 1) {
                    console.log(this.BannerRibbon.cover);
                } else if (this.BannerRibbon.cover == 2) {
                    console.log(this.BannerRibbon.cover);
                } else {
                    //cover:    条幅锦旗绶带选择
                    console.log(this.BannerRibbon.cover);
                }
            },

            // 地址级联选择器
            handleChange(value) {
                console.log(value);
                this.addressCode = [...value];
                // 地址反显
                this.showAddress = `${CodeToText[value[0]]}${CodeToText[value[1]]}${
                    CodeToText[value[2]]
                }`;
                console.log(this.showAddress);
                console.log(this.addressCode);
                if (this.form.dest != this.showAddress) {
                    //详情收货地址
                    this.ruleForm.address = "";
                }
                //省市区
                this.form.dest = this.showAddress;
                //省ID
                this.form.provinceId = this.addressCode[0];
                //市ID
                this.form.cityId = this.addressCode[1];
                //区ID
                this.form.areaId = this.addressCode[2];

                // let id = uuid.v4();
                // console.log(id);
            },

            //获取默认地址
            GetAlladdress() {
                let userInfo = JSON.parse(window.sessionStorage.getItem("currentInfo"));
                console.log(this.user_ID);
                console.log(userInfo.user_ID);

                let userid = "";
                if (this.user_ID == "") {
                    userid = userInfo.user_ID;
                } else {
                    userid = this.user_ID;
                }
                console.log(userid);
                let array = [];

                //非普通用户 并且 未选择服务客户
                console.log(userInfo.userType)
                console.log(this.user_ID)
                if (userInfo.userType != 0 && userid == ''){
                    this.form.selectedOptions=[];//目的地
                    this.form.dest= ''; //省市区
                    this.form.provinceId=''; //省ID
                    this.form.cityId=''; //市ID
                    this.form.areaId='';//区ID
                }else {
                    this.$http.get("/receiveadderss/listAll?operatorId=" + userid).then((res) => {
                        console.log("获取默认地址", res);
                        if (res.data.code == 200) {
                            if (res.data.data.length != 0) {
                                array.push(
                                    res.data.data[0].provinceCode,
                                    res.data.data[0].cityCode,
                                    res.data.data[0].areaCode
                                );
                                //省市区
                                this.form.dest =
                                    res.data.data[0].provinceZh +
                                    res.data.data[0].cityZh +
                                    res.data.data[0].areaZh;
                                //收货人姓名
                                this.ruleForm.consignee = res.data.data[0].receiveName;
                                //收货人电话
                                this.ruleForm.tel = res.data.data[0].receivePhone;
                                //详情收货地址
                                this.ruleForm.address = res.data.data[0].detailAddress;
                            }
                        }
                        this.form.selectedOptions = array;
                        console.log(array);
                        console.log(this.form.dest);
                        if (array.length != 0) {
                            //省ID
                            this.form.provinceId = array[0];
                            //市ID
                            this.form.cityId = array[1];
                            //区ID
                            this.form.areaId = array[2];
                        }
                    });
                }
            },

            //多选框发送改变
            handleCheckedChange(value) {
                let arr = [];
                console.log(value);
                console.log(this.checkedCheckbox);
                if (value.length != 0) {
                    if (this.checkedCheckbox.length != 0) {
                        for (let i = 0; i < this.processList.length; i++) {
                            // if (this.checkedCheckbox[a].id==this.processList[i].processesSetList[0].id){
                            if (value[value.length - 1] == this.processList[i].id) {
                                console.log(this.checkedCheckbox);
                                arr.push({
                                    name: this.processList[i].name,
                                    id: this.processList[i].id,
                                    setacreage: this.processList[i].setacreage,
                                    processesSetList: this.processList[i].processesSetList,
                                    num: 1,
                                    desc: "",
                                    models: this.processList[i].processesSetList[0].id,
                                    inSize: [{width: "", length: ""}],
                                });
                                console.log(arr);

                                this.setacreage0 = arr;
                            } else {
                                for (let a = 0; a < this.checkedCheckbox.length; a++) {
                                    for (let c = 0; c < value.length - 1; c++) {
                                        if (value[c] == this.processList[i].id) {
                                            for (
                                                let v = 0;
                                                v < this.processList[i].processesSetList.length;
                                                v++
                                            ) {
                                                if (
                                                    this.checkedCheckbox[a].id ==
                                                    this.processList[i].processesSetList[v].id
                                                ) {
                                                    console.log(this.checkedCheckbox[a]);
                                                    arr.push({
                                                        name: this.processList[i].name,
                                                        id: this.processList[i].id,
                                                        setacreage: this.processList[i].setacreage,
                                                        processesSetList: this.processList[i]
                                                            .processesSetList,
                                                        num:
                                                            this.processList[i].setacreage == 0
                                                                ? 1
                                                                : this.processList[i].setacreage == 1
                                                                ? this.checkedCheckbox[a].inSize.length == 0
                                                                    ? 1
                                                                    : this.checkedCheckbox[a].inSize.length
                                                                : this.processList[i].setacreage == 2
                                                                    ? this.checkedCheckbox[a].inSize.length == 0
                                                                        ? 1
                                                                        : this.checkedCheckbox[a].inSize.length
                                                                    : 0,
                                                        desc: this.checkedCheckbox[a].desc,
                                                        models: this.checkedCheckbox[a].id,
                                                        inSize:
                                                            this.processList[i].setacreage == 0
                                                                ? []
                                                                : this.processList[i].setacreage == 1
                                                                ? this.checkedCheckbox[a].inSize.length == 0
                                                                    ? [
                                                                        {
                                                                            width: "",
                                                                            length: "",
                                                                        },
                                                                    ]
                                                                    : this.checkedCheckbox[a].inSize
                                                                : this.processList[i].setacreage == 2
                                                                    ? this.checkedCheckbox[a].inSize.length == 0
                                                                        ? [
                                                                            {
                                                                                width: "",
                                                                                length: "",
                                                                            },
                                                                        ]
                                                                        : this.checkedCheckbox[a].inSize
                                                                    : [],
                                                    });
                                                    console.log(arr);

                                                    this.setacreage0 = arr;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        for (let a = 0; a < value.length; a++) {
                            for (let i = 0; i < this.processList.length; i++) {
                                //mustchoose:1         必选
                                //mustchoose:0或其他   非必选
                                if (value[a] == this.processList[i].id) {
                                    arr.push({
                                        name: this.processList[i].name,
                                        id: this.processList[i].id,
                                        setacreage: this.processList[i].setacreage,
                                        processesSetList: this.processList[i].processesSetList,
                                        num: 1,
                                        desc: "",
                                        models: this.processList[i].processesSetList[0].id,
                                        inSize: [{width: "", length: ""}],
                                    });

                                    console.log(arr);

                                    this.setacreage0 = arr;
                                }
                            }
                        }
                    }
                } else {
                    this.setacreage0 = [];
                }

                //setacreage:0  默认下拉值
                let arrcheckedCheckbox = [];
                for (let b = 0; b < this.setacreage0.length; b++) {
                    if (this.setacreage0[b].setacreage == 0) {
                        console.log(this.setacreage0[b].processesSetList[0].id);
                        this.setacreage0[b].models = this.setacreage0[
                            b
                            ].processesSetList[0].id;
                        arrcheckedCheckbox.push({
                            id: this.setacreage0[b].processesSetList[0].id,
                        });
                    } else if (this.setacreage0[b].setacreage == 1) {
                        // console.log(this.setacreage0[b].inSize)
                        console.log(this.setacreage0[b].processesSetList[0].id);
                        console.log(this.checkedCheckbox);
                        arrcheckedCheckbox.push({
                            id: this.setacreage0[b].processesSetList[0].id,
                            inSize: this.setacreage0[b].inSize,
                        });
                        // arrcheckedCheckbox.inSize.push(this.setacreage0[b].inSize)
                    } else if (this.setacreage0[b].setacreage == 2) {
                        console.log(this.setacreage0[b].processesSetList[0].id);
                        arrcheckedCheckbox.push({
                            id: this.setacreage0[b].processesSetList[0].id,
                            desc: this.setacreage0[b].desc,
                            inSize: this.setacreage0[b].inSize,
                        });
                    } else if (this.setacreage0[b].setacreage == 3) {
                        console.log(this.setacreage0[b].processesSetList[0].id);
                        arrcheckedCheckbox.push({
                            id: this.setacreage0[b].processesSetList[0].id,
                            desc: this.setacreage0[b].desc,
                        });
                    }
                }
                this.checkedCheckbox = arrcheckedCheckbox;
                console.log(arrcheckedCheckbox);
            },

            //后道设置_新增
            newlyAdded(item, i) {
                console.log(i);
                console.log(item);

                if (i.processesSetList[0].id == item.processesSetList[0].id) {
                    for (let a = 0; a < this.setacreage0.length; a++) {
                        if (
                            this.setacreage0[a].processesSetList[0].id ==
                            i.processesSetList[0].id
                        ) {
                            this.setacreage0[a].num = ++this.setacreage0[a].num;
                            this.setacreage0[a].inSize.push({width: "", length: ""});
                            console.log(this.setacreage0[a].num);
                        }
                    }
                }

                for (let a = 0; a < this.checkedCheckbox.length; a++) {
                    if (i.processesSetList[0].id == this.checkedCheckbox[a].id) {
                        // this.checkedCheckbox[a].inSize.push({'width': '', 'length': ''})
                    }
                }
                console.log(this.setacreage0);
            },

            //后道设置_删除
            del(item, i) {
                console.log(i);
                console.log(item);
                if (i.processesSetList[0].id == item.processesSetList[0].id) {
                    for (let a = 0; a < this.setacreage0.length; a++) {
                        if (
                            this.setacreage0[a].processesSetList[0].id ==
                            i.processesSetList[0].id
                        ) {
                            this.setacreage0[a].num = --this.setacreage0[a].num;
                            this.setacreage0[a].inSize.pop();
                            console.log(this.setacreage0[a].num);
                        }
                    }
                }
            },

            //setacreage:0  下拉值发生改变
            selecChange(value) {
                console.log(value);
                console.log(this.checkedCheckbox);

                for (let b = 0; b < this.setacreage0.length; b++) {
                    for (let c = 0; c < this.setacreage0[b].processesSetList.length; c++) {
                        if (value == this.setacreage0[b].processesSetList[c].id) {
                            // console.log(this.setacreageny0[b].processesSetList[0].id)
                            if (
                                this.checkedCheckbox[b].id ==
                                this.setacreage0[b].processesSetList[0].id
                            ) {
                                this.checkedCheckbox[b].id = this.setacreage0[b].processesSetList[
                                    c
                                    ].id;
                            }
                        }
                    }

                    // this.checkedCheckboxny = arrcheckedCheckbox
                }
                // console.log(this.models);
                console.log(this.checkedCheckbox);
            },

            //配送方式下拉改变
            selecChangepsfs(value) {
                console.log(value);
                for (let a = 0; a < this.postList.length; a++) {
                    if (this.postList[a].id == value) {
                        this.form.distribution_mustaddr = this.postList[a].mustaddr;
                    }
                    //如选中的送货方式mustaddr:1，目的地就为空
                    if (this.form.distribution_mustaddr == 1) {
                        this.form.dest = "";
                        this.form.provinceId = "";
                        this.form.cityId = "";
                        this.form.areaId = "";
                    }
                }
            },

            //宽发生改变
            widthChange(value, i, index) {
                console.log(value);
                console.log(i);
                console.log(index);
                console.log(this.checkedCheckbox);
                console.log(this.form.checkedCheckbox);

                for (let a = 0; a < this.checkedCheckbox.length; a++) {
                    if (i.processesSetList[0].id == this.checkedCheckbox[a].id) {
                        this.checkedCheckbox[a].inSize[index].width = value;
                    }
                }

                for (let a = 0; a < this.setacreage0.length; a++) {
                    if (
                        i.processesSetList[0].id == this.setacreage0[a].processesSetList[0].id
                    ) {
                        this.setacreage0[a].inSize[index].width = value;
                    }
                }
                console.log(this.checkedCheckbox);
                console.log(this.setacreage0);
            },

            //高发生改变
            lengthChange(value, i, index) {
                console.log(value);
                console.log(i);
                console.log(index);
                console.log(this.checkedCheckbox);
                console.log(this.form.checkedCheckbox);

                for (let a = 0; a < this.checkedCheckbox.length; a++) {
                    if (i.processesSetList[0].id == this.checkedCheckbox[a].id) {
                        this.checkedCheckbox[a].inSize[index].length = value;
                        console.log(this.checkedCheckbox[a].inSize[index]);
                    }
                }

                for (let a = 0; a < this.setacreage0.length; a++) {
                    if (
                        i.processesSetList[0].id == this.setacreage0[a].processesSetList[0].id
                    ) {
                        this.setacreage0[a].inSize[index].length = value;
                    }
                }

                console.log(this.checkedCheckbox);
                console.log(this.setacreage0);
            },

            //文本框发生改变
            textareaChange(value, i, index) {
                console.log(value);
                console.log(i);
                console.log(index);
                console.log(this.checkedCheckbox);
                for (let a = 0; a < this.checkedCheckbox.length; a++) {
                    if (i.processesSetList[0].id == this.checkedCheckbox[a].id) {
                        this.checkedCheckbox[a].desc = value;
                    }
                }

                for (let a = 0; a < this.setacreage0.length; a++) {
                    if (
                        i.processesSetList[0].id == this.setacreage0[a].processesSetList[0].id
                    ) {
                        this.setacreage0[a].desc = value;
                    }
                }
                console.log(this.checkedCheckbox);
                console.log(this.setacreage0);
                // this.handleCheckedChange(value);
            },

            //提交报价
            onSubmit() {
                // let len = this.userList.length;
                // if(len <= 0){
                //   this.$message.error('请选择代理的客户！');
                //   return;
                // }
                console.log(this.form.pagenums);
                console.log("submit!");
                console.log(this.form);
                //内页必选项id
                console.log(this.form.nybx);
                console.log(this.setacreage0);
                console.log(this.checkedCheckbox);

                if (this.form.productId == 1015) {
                    this.$alert("页面正在开发中", "不可以", {
                        confirmButtonText: "确定",
                        callback: (action) => {
                            this.$message({
                                type: "info",
                                message: `action: ${action}`,
                            });
                        },
                    });
                } else {
                    //封面设置
                    console.log(this.form.cover);
                    // console.log(this.technologyfm);

                    let faceData = [];
                    let afterIds = this.checkedCheckbox;
                    if (this.form.cover == 0) {
                        faceData = [];
                    } else if (this.form.cover == 1) {
                        for (let a = 0; a < this.form.input1; a++) {
                            faceData.push({
                                partsType: this.form.cover,
                                partsName: "封面" + parseInt(a + 1),
                                // ---- 材料ID【必填】
                                maId: this.form.coverTypes[a],
                                // ---- 克重ID【必填】
                                weight: this.form.coverGrams[a],
                                //---- 封面自來料【必填】
                                isCustMa: this.isCustMafm == false ? 0 : 1,
                                // ---- 正面印色ID【非必填】
                                color:
                                    this.form.singles[a] == "colors"
                                        ? this.form.colours[a]
                                        : this.form.colours[a],
                                // ---- 反面印色ID【非必填】
                                colors:
                                    this.form.singles[a] == "colors" ? this.form.colours[a] : "",
                                //----  本数（单个本数，多封面时使用）【多封面时，必填；其他非必填】
                                bookNum: this.form.input1 == "1" ? "" : this.benshu[a],
                                // //---- 封面P数【必填】
                                // 'pNum':4,
                                // ---- 后道工艺列表
                                afterIds: afterIds,
                            });
                        }
                    } else if (this.form.cover == 2) {
                        for (let a = 0; a < this.StrangleMouth.length; a++) {
                            faceData.push({
                                partsType: this.form.cover,
                                partsName: "封面封底加勒口",
                                // ---- 材料ID【必填】
                                maId: this.form.coverTypes[a],
                                // ---- 克重ID【必填】
                                weight: this.form.coverGrams[a],
                                //---- 封面自來料【必填】
                                isCustMa: this.isCustMafm == false ? 0 : 1,
                                // ---- 正面印色ID【非必填】
                                color:
                                    this.form.singles[a] == "colors"
                                        ? this.form.colours[a]
                                        : this.form.colours[a],
                                // ---- 反面印色ID【非必填】
                                colors:
                                    this.form.singles[a] == "colors" ? this.form.colours[a] : "",
                                //----  本数（单个本数，多封面时使用）【多封面时，必填；其他非必填】
                                // 'bookNum':this.form.input1=='1'?'':this.benshu[a],
                                //---- 封面P数【必填】
                                // 'pNum':4,
                                //---- 封面勒口【封面勒口类型时，使用】
                                faceSize: this.fm,
                                //---- 封底勒口【封面勒口类型时，使用】
                                backSize: this.fd,
                                // ---- 后道工艺列表
                                afterIds: afterIds,
                            });
                        }
                    } else if (this.form.cover == 3) {
                        for (let a = 0; a < this.PullPage2p.length; a++) {
                            faceData.push({
                                partsType: this.form.cover,
                                partsName: "封面封底加拉页",
                                // ---- 材料ID【必填】
                                maId: this.form.coverTypes[a],
                                // ---- 克重ID【必填】
                                weight: this.form.coverGrams[a],
                                //---- 封面自來料【必填】
                                isCustMa: this.isCustMafm == false ? 0 : 1,
                                // ---- 正面印色ID【非必填】
                                color:
                                    this.form.singles[a] == "colors"
                                        ? this.form.colours[a]
                                        : this.form.colours[a],
                                // ---- 反面印色ID【非必填】
                                colors:
                                    this.form.singles[a] == "colors" ? this.form.colours[a] : "",
                                //----  本数（单个本数，多封面时使用）【多封面时，必填；其他非必填】
                                bookNum: this.form.input1 == "1" ? "" : this.benshu[a],
                                //---- 封面P数【必填】
                                // 'pNum':4,
                                //---- 封面P数【封面封底加拉页时，使用】
                                faceNum: this.fm,
                                //---- 封底P数【封面封底加拉页时，使用】
                                backNum: this.fd,
                                // ---- 后道工艺列表
                                afterIds: afterIds,
                            });
                        }
                    } else if (this.form.cover == 4) {
                        for (let a = 0; a < this.PullPage4p.length; a++) {
                            faceData.push({
                                partsType: this.form.cover,
                                partsName: "封面封底加拉页",
                                // ---- 材料ID【必填】
                                maId: this.form.coverTypes[a],
                                // ---- 克重ID【必填】
                                weight: this.form.coverGrams[a],
                                //---- 封面自來料【必填】
                                isCustMa: this.isCustMafm == false ? 0 : 1,
                                // ---- 正面印色ID【非必填】
                                color:
                                    this.form.singles[a] == "colors"
                                        ? this.form.colours[a]
                                        : this.form.colours[a],
                                // ---- 反面印色ID【非必填】
                                colors:
                                    this.form.singles[a] == "colors" ? this.form.colours[a] : "",
                                //----  本数（单个本数，多封面时使用）【多封面时，必填；其他非必填】
                                bookNum: this.form.input1 == "1" ? "" : this.benshu[a],
                                //---- 封面P数【必填】
                                // 'pNum':4,
                                //---- 封面P数【封面封底加拉页时，使用】
                                faceNum: this.fm,
                                //---- 封底P数【封面封底加拉页时，使用】
                                backNum: this.fd,
                                // ---- 后道工艺列表
                                afterIds: afterIds,
                            });
                        }
                    } else if (this.form.cover == 5) {
                        for (let a = 0; a < this.PullPage22p.length; a++) {
                            faceData.push({
                                partsType: this.form.cover,
                                partsName: "封面封底加拉页",
                                // ---- 材料ID【必填】
                                maId: this.form.coverTypes[a],
                                // ---- 克重ID【必填】
                                weight: this.form.coverGrams[a],
                                //---- 封面自來料【必填】
                                isCustMa: this.isCustMafm == false ? 0 : 1,
                                // ---- 正面印色ID【非必填】
                                color:
                                    this.form.singles[a] == "colors"
                                        ? this.form.colours[a]
                                        : this.form.colours[a],
                                // ---- 反面印色ID【非必填】
                                colors:
                                    this.form.singles[a] == "colors" ? this.form.colours[a] : "",
                                //----  本数（单个本数，多封面时使用）【多封面时，必填；其他非必填】
                                bookNum: this.form.input1 == "1" ? "" : this.benshu[a],
                                //---- 封面P数【必填】
                                // 'pNum':4,
                                //---- 封面P数【封面封底加拉页时，使用】
                                faceNum: this.fm,
                                //---- 封底P数【封面封底加拉页时，使用】
                                backNum: this.fd,
                                // ---- 后道工艺列表
                                afterIds: afterIds,
                            });
                        }
                    }

                    //内页设置
                    console.log(this.form.insidepage);
                    // console.log(this.technologyny);
                    // console.log(this.technologyfy);
                    // console.log(this.technologycy);
                    // console.log(this.technologyly);
                    let innerData = [];
                    let afterIdsny = this.checkedCheckboxny;
                    let afterIdsfy = this.checkedCheckboxfy;
                    let afterIdscy = this.checkedCheckboxcy;
                    let afterIdsly = this.checkedCheckboxly;
                    for (let ny = 0; ny < this.neiyearr.length; ny++) {
                        if (
                            this.form.pagenumberGramny[ny] == "" ||
                            this.form.pagenumberGramny.length == 0
                        ) {
                            this.$message({
                                message: "请填写材料克重",
                                type: "warning",
                            });
                            return;
                        } else {
                            innerData.push({
                                //---- 部件类型【11，必填】
                                partsType: 11,
                                //---- 部件名称【如，内页一，必填】
                                partsName: "内页" + parseInt(ny + 1),
                                //---- 材料ID【必填】
                                maId: this.form.pagenumberTypeny[ny],
                                //---- 克重ID【必填】
                                weight: this.form.pagenumberGramny[ny],
                                //---- 内页自來料【必填】
                                isCustMa: this.isCustMany[ny] == false ? 0 : 1,
                                //---- 正面印色ID【非必填】
                                color: this.form.coloursny[ny],
                                //---- 反面印色ID【非必填】
                                colors: this.form.coloursny[ny],
                                //---- 内页P数【必填】
                                pNum: this.form.pagenums[ny],
                                //---- 后道工艺列表【非必填】
                                afterIds: afterIdsny[ny],
                            });
                        }
                    }
                    if (this.feiyearr.length != 0) {
                        for (let ny = 0; ny < this.feiyearr.length; ny++) {
                            if (
                                this.form.pagenumberGramfy[ny] == "" ||
                                this.form.pagenumberGramfy.length == 0
                            ) {
                                this.$message({
                                    message: "请填写材料克重",
                                    type: "warning",
                                });
                                return;
                            } else {
                                innerData.push({
                                    //---- 部件类型【11，必填】
                                    partsType: 12,
                                    //---- 部件名称【如，内页一，必填】
                                    partsName: "扉页" + parseInt(ny + 1),
                                    //---- 材料ID【必填】
                                    maId: this.form.pagenumberTypefy[ny],
                                    //---- 克重ID【必填】
                                    weight: this.form.pagenumberGramfy[ny],
                                    //---- 扉页自來料【必填】
                                    isCustMa: this.isCustMafy[ny] == false ? 0 : 1,
                                    //---- 正面印色ID【非必填】
                                    color:
                                        this.form.singlesfy[ny] == "colors"
                                            ? this.form.coloursfy[ny]
                                            : this.form.coloursfy[ny],
                                    //---- 反面印色ID【非必填】
                                    colors:
                                        this.form.singlesfy[ny] == "colors"
                                            ? this.form.coloursfy[ny]
                                            : "",
                                    //---- 内页P数【必填】
                                    // 'pNum': this.form.pagenums[ny],
                                    //---- 后道工艺列表【非必填】
                                    afterIds: afterIdsfy[ny],
                                });
                            }
                        }
                    }
                    if (this.chayearr.length != 0) {
                        for (let cy = 0; cy < this.chayearr.length; cy++) {
                            if (
                                this.form.pagenumberGramcy[cy] == "" ||
                                this.form.pagenumberGramcy.length == 0
                            ) {
                                this.$message({
                                    message: "请填写材料克重",
                                    type: "warning",
                                });
                                return;
                            } else {
                                innerData.push({
                                    //---- 部件类型【11，必填】
                                    partsType: 13,
                                    //---- 部件名称【如，内页一，必填】
                                    partsName: "插页" + parseInt(cy + 1),
                                    //---- 材料ID【必填】
                                    maId: this.form.pagenumberTypecy[cy],
                                    //---- 克重ID【必填】
                                    weight: this.form.pagenumberGramcy[cy],
                                    //---- 插页自來料【必填】
                                    isCustMa: this.isCustMacy[cy] == false ? 0 : 1,
                                    //---- 正面印色ID【非必填】
                                    color:
                                        this.form.singlescy[cy] == "colors"
                                            ? this.form.colourscy[cy]
                                            : this.form.colourscy[cy],
                                    //---- 反面印色ID【非必填】
                                    colors:
                                        this.form.singlescy[cy] == "colors"
                                            ? this.form.colourscy[cy]
                                            : "",
                                    //---- 内页P数【必填】
                                    // 'pNum': this.form.pagenums[cy],
                                    //---- 后道工艺列表【非必填】
                                    afterIds: afterIdscy[cy],
                                });
                            }
                        }
                    }
                    if (this.layearr.length != 0) {
                        for (let ly = 0; ly < this.layearr.length; ly++) {
                            if (
                                this.form.pagenumberGramly[ly] == "" ||
                                this.form.pagenumberGramly.length == 0
                            ) {
                                this.$message({
                                    message: "请填写材料克重",
                                    type: "warning",
                                });
                                return;
                            } else {
                                innerData.push({
                                    //---- 部件类型【11，必填】
                                    partsType: 14,
                                    //---- 部件名称【如，内页一，必填】
                                    partsName: "拉页" + parseInt(ly + 1),
                                    //---- 材料ID【必填】
                                    maId: this.form.pagenumberTypely[ly],
                                    //---- 克重ID【必填】
                                    weight: this.form.pagenumberGramly[ly],
                                    //---- 拉页自來料【必填】
                                    isCustMa: this.isCustMaly[ly] == false ? 0 : 1,
                                    //---- 正面印色ID【非必填】
                                    color:
                                        this.form.singlesly[ly] == "colors"
                                            ? this.form.coloursly[ly]
                                            : this.form.coloursly[ly],
                                    //---- 反面印色ID【非必填】
                                    colors:
                                        this.form.singlesly[ly] == "colors"
                                            ? this.form.coloursly[ly]
                                            : "",
                                    //---- 内页P数【必填】
                                    pNum: this.form.pagenumberarr[ly],
                                    //---- 后道工艺列表【非必填】
                                    afterIds: afterIdsly[ly],
                                });
                            }
                        }
                    }

                    //其他设置
                    console.log(this.form.other);
                    // console.log(this.technologyhf);
                    // console.log(this.technologyyd);
                    // console.log(this.technologyzdy);
                    let otherData = [];
                    let afterIdshf = this.checkedCheckboxhf;
                    let afterIdsyd = this.checkedCheckboxyd;
                    let afterIdszdy = this.checkedCheckboxzdy;
                    if (this.HuaceDustJacket.length != 0) {
                        for (let hf = 0; hf < this.HuaceDustJacket.length; hf++) {
                            if (
                                this.form.pagenumberGramhf[hf] == "" ||
                                this.form.pagenumberGramhf.length == 0
                            ) {
                                this.$message({
                                    message: "请填写材料克重",
                                    type: "warning",
                                });
                                return;
                            } else {
                                otherData.push({
                                    //---- 部件类型【23，必填】
                                    partsType: 21,
                                    //---- 部件名称【如，自定义，必填】
                                    partsName: "画册加护封",
                                    //---- 材料ID【必填】
                                    maId: this.form.pagenumberTypehf[hf],
                                    //---- 克重ID【必填】
                                    weight: this.form.pagenumberGramhf[hf],
                                    //---- 护封自來料【必填】
                                    isCustMa: this.isCustMahf == false ? 0 : 1,
                                    //---- 正面印色ID【非必填】
                                    color:
                                        this.form.singlesDustJacket[hf] == "colors"
                                            ? this.form.coloursDustJacket[hf]
                                            : this.form.coloursDustJacket[hf],
                                    //---- 反面印色ID【非必填】
                                    colors:
                                        this.form.singlesDustJacket[hf] == "colors"
                                            ? this.form.coloursDustJacket[hf]
                                            : "",
                                    length: this.form.HuaceDustJacket_height,
                                    width: this.form.HuaceDustJacket_width,
                                    //---- P数【必填】
                                    // 'pNum': '',
                                    //---- 后道工艺列表【非必填】
                                    afterIds: afterIdshf,
                                });
                            }
                        }
                    }
                    if (this.HuaceBelt.length != 0) {
                        for (let yd = 0; yd < this.HuaceBelt.length; yd++) {
                            if (
                                this.form.pagenumberGramyd[yd] == "" ||
                                this.form.pagenumberGramyd.length == 0
                            ) {
                                this.$message({
                                    message: "请填写材料克重",
                                    type: "warning",
                                });
                                return;
                            } else {
                                otherData.push({
                                    //---- 部件类型【23，必填】
                                    partsType: 22,
                                    //---- 部件名称【如，自定义，必填】
                                    partsName: "画册加腰带",
                                    //---- 材料ID【必填】
                                    maId: this.form.pagenumberTypeyd[yd],
                                    //---- 克重ID【必填】
                                    weight: this.form.pagenumberGramyd[yd],
                                    //---- 腰带自來料【必填】
                                    isCustMa: this.isCustMayd == false ? 0 : 1,
                                    //---- 正面印色ID【非必填】
                                    color:
                                        this.form.singlesBelt[yd] == "colors"
                                            ? this.form.coloursBelt[yd]
                                            : this.form.coloursBelt[yd],
                                    //---- 反面印色ID【非必填】
                                    colors:
                                        this.form.singlesBelt[yd] == "colors"
                                            ? this.form.coloursBelt[yd]
                                            : "",
                                    length: this.form.HuaceBelt_height,
                                    width: this.form.HuaceBelt_width,
                                    //---- P数【必填】
                                    // 'pNum': '',
                                    //---- 后道工艺列表【非必填】
                                    afterIds: afterIdsyd,
                                });
                            }
                        }
                    }
                    if (this.CustomParts.length != 0) {
                        for (let zdy = 0; zdy < this.CustomParts.length; zdy++) {
                            if (
                                this.form.pagenumberGramzdy[zdy] == "" ||
                                this.form.pagenumberGramzdy.length == 0
                            ) {
                                this.$message({
                                    message: "请填写材料克重",
                                    type: "warning",
                                });
                                return;
                            } else {
                                otherData.push({
                                    //---- 部件类型【23，必填】
                                    partsType: 23,
                                    //---- 部件名称【如，自定义，必填】
                                    partsName: this.form.input3,
                                    //---- 材料ID【必填】
                                    maId: this.form.pagenumberTypezdy[zdy],
                                    //---- 克重ID【必填】
                                    weight: this.form.pagenumberGramzdy[zdy],
                                    //---- 自定义自來料【必填】
                                    isCustMa: this.isCustMazdy == false ? 0 : 1,
                                    //---- 正面印色ID【非必填】
                                    color:
                                        this.form.singleszdy[zdy] == "colors"
                                            ? this.form.colourszdy[zdy]
                                            : this.form.colourszdy[zdy],
                                    //---- 反面印色ID【非必填】
                                    colors:
                                        this.form.singleszdy[zdy] == "colors"
                                            ? this.form.colourszdy[zdy]
                                            : "",
                                    length: this.form.CustomParts_height,
                                    width: this.form.CustomParts_width,
                                    //---- P数【必填】
                                    // 'pNum': '',
                                    //---- 后道工艺列表【非必填】
                                    afterIds: afterIdszdy,
                                });
                            }
                        }
                    }
                    if (this.Drilling.length != 0) {
                        for (let dk = 0; dk < this.Drilling.length; dk++) {
                            otherData.push({
                                //---- 部件类型【23，必填】
                                partsType: 24,
                                //---- 部件名称【如，自定义，必填】
                                partsName: "书本打孔",
                                //---- 打孔数【必填】
                                holesNum: this.form.input4,
                            });
                        }
                    }
                    if (this.Plastic.length != 0) {
                        for (let sf = 0; sf < this.Plastic.length; sf++) {
                            otherData.push({
                                //---- 部件类型【23，必填】
                                partsType: 25,
                                //---- 部件名称【如，自定义，必填】
                                partsName: "画册塑封",
                            });
                        }
                    }
                    if (this.SelfSealingBag.length != 0) {
                        for (let zfd = 0; zfd < this.SelfSealingBag.length; zfd++) {
                            otherData.push({
                                //---- 部件类型【23，必填】
                                partsType: 26,
                                //---- 部件名称【如，自定义，必填】
                                partsName: "画册套自封袋",
                            });
                        }
                    }

                    let userInfo = JSON.parse(window.sessionStorage.getItem("currentInfo"));
                    console.log(userInfo);

                    let datas = this.form;
                    if (datas.binding == "") {
                        this.$message.error("请选择装订方式");
                    } else if (datas.states == "") {
                        this.$message.error("请填写完整印刷本数");
                    } else if (datas.packing == "") {
                        this.$message.error("请填写完整包装方式");
                    } else if (datas.distribution == "") {
                        this.$message.error("请填写完整配送方式");
                    } else if (
                        datas.distribution_mustaddr != 1 &&
                        (datas.provinceId == "" || datas.cityId == "" || datas.areaId == "")
                    ) {
                        this.$message.error("请填写完整省市区目的地");
                    }
                    // else if (
                    //     this.userType == 1 &&
                    //     (this.servicetext == "" || this.user_ID == "")
                    // ) {
                    //     this.$message.error("请选择服务客户");
                    // }
                    else if(datas.needDesign === '1' && !datas.pNums){
                      this.$message.error("设计需要输入p数")
                    } else {
                        let data = {
                            //---- 印厂标识【必填】
                            userfatherid: datas.userfatherid,
                            // ---- 产品类型ID【必填，1028】
                            productId: datas.productId,
                            //---- 用户唯一性标识
                            userId: this.userType == 1 ? this.user_ID : userInfo.user_ID,
                            // ---- 基础数据【必填】
                            baseData: {
                                //---- 是否是自定义【1：是；0：否】
                                isDefind: datas.size == 1 ? "1" : "0",
                                //---- 成品尺寸宽【isDefind = 1，必填；isDefind = 0，非必填】
                                width: datas.size == 1 ? this.cpsize.width : "",
                                //---- 成品尺寸长【isDefind = 1，必填；isDefind = 0，非必填】
                                length: datas.size == 1 ? this.cpsize.height : "",
                                //---- 尺寸ID【isDefind = 0，必填；isDefind = 1，非必填】
                                sizeId: datas.size == 1 ? "" : datas.size,
                                //---- 订单数量【必填】
                                productNum: datas.states,
                                //---- 装订方式【必填】
                                bindingTypeId: datas.binding,
                            },
                            // ---- 封面数据【必填】
                            faceData: faceData,

                            // ---- 内页数据【必填】
                            innerData: innerData,

                            // ---- 其他信息【非必填】
                            otherData: otherData,

                            //---- 包装方式ID【必填】
                            packageTypeId: datas.packing,
                            //---- 配送方式ID【必填】
                            postTypeId: datas.distribution,
                            //---- 省市区
                            dest: datas.dest,
                            //---- 省ID
                            provinceId: datas.provinceId,
                            //---- 市ID
                            cityId: datas.cityId,
                            //---- 区ID
                            areaId: datas.areaId,
                          invoice:  this.form.invoice,
                          needDesign:  this.form.needDesign,
                          pNums: this.form.pNums
                        };
                        console.log(data);
                        console.log(JSON.stringify(data));

                        //立即报价
                        this.fullscreenLoading = true;
                        setTimeout(() => {
                            this.$http.post("/cpshttp/getOrdinaryAlbum", data).then((res) => {
                                console.log(res);
                                console.log(data);
                                console.log(JSON.stringify(data));
                                // this.centerDialogVisible2 = true

                                this.fullscreenLoading = false;
                                if (res.data != "") {
                                    this.$message({
                                        message: "报价成功",
                                        type: "success",
                                    });

                                    this.centerDialogVisible2 = true;
                                    //报价信息
                                    //---- 弹出框展示数据
                                    this.info = res.data.info;
                                    //---- 部件产品信息
                                    this.orderProduct = res.data.orderProduct;
                                    console.log(this.info);
                                } else {
                                    this.$message.error("报价失败");
                                }
                            });
                        }, 2000);
                    }
                }
                // }
            },

            //发货日期
            //时间转换
            //中国标准时间字符串，转换成y-m-d:h-m-s格式
            time(date) {
                var y = date.getFullYear()
                var m = date.getMonth() + 1
                m = m < 10 ? '0' + m : m
                var d = date.getDate()
                d = d < 10 ? '0' + d : d
                var h = date.getHours()
                h = h < 10 ? '0' + h : h
                var minute = date.getMinutes()
                minute = minute < 10 ? '0' + minute : minute
                var second = date.getSeconds()
                second = second < 10 ? '0' + second : second
                // return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second
                return y + '-' + m + '-' + d
            },

            //立即下单
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        // alert('submit!');
                        console.log(this.ruleForm);
                        // this.$emit('transferUser ',this.ruleForm)
                        // this.$router.push({ name: this.name, params: { xinxi: this.ruleForm } });

                        let userInfo = JSON.parse(
                            window.sessionStorage.getItem("currentInfo")
                        );
                        console.log(userInfo);

                        let ruleForm = this.ruleForm;

                        this.centerDialogVisible2 = false;
                        let data = {
                            //---- 产品信息【点击（产品报价）返回信息】
                            orderProduct: this.orderProduct,
                            //---- 印件名称
                            orderName: ruleForm.name,
                            //---- 色彩要求【暂无】
                            printType: ruleForm.color,
                            //---- 发货日期
                            completiontime: this.time(new Date(ruleForm.date)),
                            //---- 收货人
                            contacts: ruleForm.consignee,
                            //---- 印厂标识【相当于前面的userfatherid】
                            enterpriseId: this.form.userfatherid,
                            //---- 客户其他备注
                            customSupply: ruleForm.desc,
                            //---- 联系方式
                            telephone: ruleForm.tel,
                            //---- 订单费用
                            orderFee: this.info.totalFee,
                            //---- 送货地址【报价当中的dest + 详情】
                            sendAddrs: ruleForm.address,
                            //---- 配送方式ID
                            // postTypeId:'',
                            //---- 预付款金额
                            preFee: this.info.totalFee * 0.3,
                            //---- 付款方式
                            // paytype: ruleForm.paymentMethod,
                            //---- 用户唯一性标识
                            userid: userInfo.user_ID,
                            //---- 代客下单中客户user_id
                            customerId:this.user_ID==''?userInfo.user_ID:this.user_ID,
                        };
                        console.log(data);

                        this.fullscreenLoading2 = true;
                        setTimeout(() => {
                            this.$http.post("/cpshttp/createMallOrder", data).then((res) => {
                                console.log(res);
                                this.fullscreenLoading2 = false;
                                if (res.data.code == 200) {
                                    this.$message({
                                        message: "下单成功",
                                        type: "success",
                                    });
                                    this.$router.push({
                                        name: "Personal",
                                        params: {chiecked: 2},
                                    });
                                } else {
                                    this.$message.error(res.data.message);
                                }
                            });
                        }, 2000);

                        // }
                    } else {
                        console.log("error submit!!");
                        return false;
                    }
                });
            },

            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
        },
        mounted() {
        },
    };
</script>

<style lang="scss" scoped>
    .title {
        color: #058af2;
        padding-left: 20px;
        border-left: 5px solid #058af2;
        /*border-bottom: 1px dashed #ccc;*/
    }

    hr {
        border: none;
        border-bottom: 1px solid #ededed;
        margin: 8px 0;
    }

    .body {
        display: flex;
        margin-top: 20px;
        /*左侧图片*/
        .pic {
            width: 350px;
            display: grid;
            height: 690px;

            img:first-of-type {
                width: 325px;
                height: 325px;
                border: 1px solid #d9d9d9;
            }

            img:last-of-type {
                margin: 10px auto 0 auto;
                width: 150px;
                height: 150px;
                border: 1px solid red;
            }
        }

        /*内容主体*/
        .neirong {
            width: 100%;
            padding-left: 30px;
            /*模块*/
            .modular:first-child {
                h4 {
                    margin-top: 0;
                }
            }

            .modular {
                /*margin-bottom: 20px;*/
                margin-bottom: 0px;

                h4 {
                    margin-top: 8px;
                }

                hr {
                    border-bottom: 1px dashed #ccc;
                }

                .titleh4 {
                    display: flex;
                    justify-content: space-between;
                }

                /*成品尺寸*/
                .chengpin {
                    .class {
                        text-align: right;
                        vertical-align: middle;
                        /*float: right;*/
                        font-size: 14px;
                        color: #606266;
                        line-height: 40px;
                        padding: 0 12px 0 12px;
                        box-sizing: border-box;
                    }

                    .el-form-item {
                        margin-bottom: 0px;
                    }
                }

                .houdao {
                    .el-form-item {
                        margin-bottom: 0px;
                        white-space: nowrap;
                    }

                    .setacreage3_desc {
                        margin-left: 20px;
                    }
                }
            }
        }
    }

    /*立即下单详细信息*/
    .ordernowList {
        margin: 20px auto 0px;
        /*border: 1px solid #EBEEF5;*/

        /*请输入印刷品最显著的标题*/
        .grey {
            line-height: 43px;
            font-size: 14px;
            color: #999;
            font-weight: normal;
            margin-left: 15px;
        }

        /*地址*/
        .black {
            line-height: 43px;
            font-size: 14px;
            color: #333;
            font-weight: normal;
            margin-right: 15px;
        }
    }

    .el-checkbox-group {
        ::v-deep .el-checkbox {
            width: 48% !important;
        }
    }

    .el-form-item__content {
        ::v-deep .el-input {
            margin-right: 0 !important;
        }
    }
</style>

<style lang="scss">
    .el-form-item__label {
        text-align: justify;
        text-align-last: justify;
    }

    /*成品尺寸自定义按钮  */
    .el-select-dropdown {
        .el-select-dropdown__item {
            .el-button {
                width: 100%;
                text-align: left;
                color: #606266;
            }
        }
    }

    /*成品尺寸自定义对话框*/
    .zidingyi {
        .el-dialog {
            .el-form {
                .el-form-item {
                    .el-form-item__content {
                        display: flex;

                        .el-input {
                            width: 20%;
                        }

                        span {
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
    }

    /*标题右侧弹出层*/
    .canshu {
        .titleh4 {
            .el-button {
                padding: 8px 0 0px 0;
            }
        }
    }

    /*后道设置多选框*/
    .el-checkbox-group {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-content: stretch;
        flex-wrap: wrap;
        align-items: flex-start;

        .el-checkbox {
            /*width: 48%;*/
            margin: 5px 10px 5px 0;
            display: flex;
            /*position: relative;*/
            /*display: inline-flex;*/
            .el-checkbox__label {
                /*position: absolute;*/
                /*position: relative;*/
                /*bottom: 0;*/
                .el-form {
                    display: block;
                }

                /*.el-form:last-of-type{*/
                /*    display: block;*/
                /*}*/
            }
        }
    }

    /*后道设置*/
    .houdao {
        .el-form {
            width: 100%;
            display: flex;
            margin: 0 40px 0 0 !important;

            .el-form-item__content {
                margin-left: 0 !important;
            }

            /*文本框*/
            .el-textarea__inner {
                height: 40px;
                width: 100px;
            }

            /*输入框*/
            .input {
                /*display: flex;*/
                margin-bottom: 5px;

                .el-form-item__content {
                    display: flex;
                    margin: 0 !important;
                    /*文本框*/
                    .el-textarea__inner {
                        height: 40px;
                        width: 100px;
                    }

                    .el-input {
                        width: 50px;
                    }

                    /*X:符号*/
                    span {
                        margin: 0 5px;
                    }

                    /*新增按钮*/
                    .newlyAdded {
                      margin-left: 0px;
                        text-align: center;
                        line-height: 25px;
                    }
                }
            }
        }
    }

    /*报价按钮*/
    .baojia {
        width: 100%;

        .el-form-item__content {
            display: flex;
            justify-content: center;
        }
    }

    /*包装方式*/
    .table {
        .el-table__header-wrapper {
            width: 180px;
        }

        .el-table__body-wrapper {
            .el-table__body {
                tbody {
                    .el-table__row {
                        .el-table_2_column_6 {
                            border-right: none;
                        }
                    }
                }
            }
        }
    }

    .el-table th > .cell {
        text-align: center;
    }

    .el-table .cell {
        text-align: center;
    }

    /*立即下单对话框*/
    .orderNow {
        table {
            border: 1px solid #ebeef5;
            width: 100%;
            /*tr{*/
            /*    border-bottom: 1px solid #EBEEF5;*/
            /*}*/
            /*tr:last-of-type{*/
            /*    border-bottom: 0;*/
            /*}*/
            tr {
                th {
                    color: #909399;
                    font-size: 14px;
                    font-weight: bold;
                    padding: 9px 6px;
                    text-align: center;
                    border-right: 1px solid #ebeef5;
                    border-bottom: 1px solid #ebeef5;
                }

                th:last-of-type {
                    border-right: 0;
                }

                td {
                    color: #606266;
                    font-size: 14px;
                    padding: 9px 6px;
                    text-align: center;
                    border-right: 1px solid #ebeef5;
                    border-bottom: 1px solid #ebeef5;
                }

                td:last-of-type {
                    border-right: 0;
                }
            }

            tr:last-of-type th {
                border-bottom: 0;
            }

            tr:last-of-type td {
                border-bottom: 0;
            }

            .packType th {
                border-right: 1px solid #ebeef5 !important;
            }

            .packType td {
                border-right: 1px solid #ebeef5 !important;
            }

            .packType td:last-of-type {
                border-right: 0 !important;
            }
        }
    }

    /*选择封面类型*/
    .el-dialog--center {
        .el-radio-group {
            .el-radio {
                display: flex;
                height: 40px;
                line-height: 40px;
                margin-bottom: 15px;

                .el-radio__input {
                    line-height: 40px;
                }
            }

            .el-input {
                width: auto;
                padding: 0;
                text-align: center;

                .el-input__inner {
                    padding: 0;
                    text-align: center;
                    width: 40px;
                    margin: 0 3px;
                    height: 35px;
                    line-height: 35px;
                }
            }
        }
    }

    /*选择封面类型*/
    .fm {
        .el-dialog--center {
            .el-radio-group {
                .el-input {
                    .el-input__inner {
                        padding: 0;
                        text-align: center;
                        width: 40px;
                        margin: 0 3px;
                        height: 35px;
                        line-height: 35px;
                    }
                }
            }
        }
    }

    /*选择内页类型*/
    .ny {
        .el-dialog--center {
            .el-radio-group {
                .el-input {
                    .el-input__inner {
                        padding: 0;
                        text-align: center;
                        width: 80px;
                        margin: 0 3px;
                        height: 35px;
                        line-height: 35px;
                    }
                }
            }
        }
    }

    /*选择封面类型2*/
    .fengmian {
        .el-form-item__content {
            .el-input {
                width: auto;
                padding: 0;
                text-align: center;

                .el-input__inner {
                    padding: 0;
                    text-align: center;
                    width: 40px;
                    margin: 0 3px;
                    height: 35px;
                    line-height: 35px;
                }
            }
        }
    }

    .ordernowList {
        .el-form {
            .el-form-item {
                margin-bottom: 0px;
                /*border-bottom: 1px solid #EBEEF5;*/
                .el-form-item__content {
                    .el-input {
                        width: 41%;
                    }
                }

                .el-form-item__label {
                    text-align: center;
                    /*background-color: #f5f5f5;*/
                    height: 44px;
                    line-height: 44px;
                    width: 12%;
                    text-align: center;
                    font-size: 14px;
                    color: #333;
                    vertical-align: middle;
                }
            }
        }

        .line {
            display: flex;
            width: 100%;
        }

        .line > div {
            width: 50%;

            .el-select {
                width: 100% !important;
            }

            .el-input {
                width: 100% !important;
            }
        }

        .line > div:nth-child(even) {
            margin-left: 24px;
        }

        .paymentMethod {
            .el-input--suffix {
                width: 100%;
            }
        }
    }
</style>
