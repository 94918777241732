<template>
  <div>
    <div class="digitalInfo">
      <div class="card-left">
        <div class="top">
          <i class="iconfont icon-iconcopy"></i>
          <span>数码快印</span>
        </div>
        <ul class="infos">
          <li
            class="info-item"
            :class="checked == item.id ? 'checked' : ''"
            v-for="item in menuInfo"
            :key="item.id"
            @click="replace(item)"
          >
            {{ item.text }}
          </li>
        </ul>
      </div>
      <div class="card-right">
        <div class="info" v-if="checked == 2028">
          <digital-single-page></digital-single-page>
        </div>
        <!-- 数码不干胶 -->
        <div class="info" v-if="checked == 2037">
          <!--            <digital-adhesive></digital-adhesive>-->
          <digital-adhesive></digital-adhesive>
        </div>
        <!-- 数码画册 -->
        <div class="info" v-if="checked == 2020">
          <digital-album></digital-album>
        </div>
        <div class="info" v-if="checked == 2034">
          <DigitalJingzhuangAlbum></DigitalJingzhuangAlbum>
        </div>
        <!-- 小批量定制手提袋 -->
        <div class="info" v-if="checked == 2015">
          <customization-reticule></customization-reticule>
        </div>
        <!-- 小批量定制吊旗 -->
<!--        <div class="info" v-if="checked == 2039">-->
<!--          <customization-showbill></customization-showbill>-->
<!--        </div>-->
        <!-- 数码挂历 -->
        <div class="info" v-if="checked == 2036">
          <digital-calendar></digital-calendar>
        </div>
        <div class="info" v-if="checked == 2035">
          <DigitalDeskCalendar></DigitalDeskCalendar>
        </div>
        <div class="info" v-if="checked == 2032">
          <DigitalEnvelope></DigitalEnvelope>
        </div>

        <div class="info" v-if="checked == 2040">
          <DigitalPhotograph></DigitalPhotograph>
        </div>

        <div class="info" v-if="checked == 2041">
          <DigitalExhibit></DigitalExhibit>
        </div>
        <div class="info" v-if="checked == 2042">
          <DigitalYilapao></DigitalYilapao>
        </div>
        <div class="info" v-if="checked == 2043">
          <DigitalCard></DigitalCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import DigitalSinglePage from "@/components/Goods/DigitalSinglePage";
import DigitalAdhesive from "@/components/Goods/DigitalAdhesive";
import DigitalAlbum from "@/components/Goods/DigitalAlbum";
import DigitalJingzhuangAlbum from "@/components/Goods/DigitalJingzhuangAlbum";
import CustomizationReticule from "@/components/Goods/CustomizationReticule";
import CustomizationShowbill from "@/components/Goods/CustomizationShowbill";
import DigitalCalendar from "@/components/Goods/DigitalCalendar";
import DigitalDeskCalendar from "@/components/Goods/DigitalDeskCalendar";
import DigitalEnvelope from "@/components/Goods/DigitalEnvelope";
import DigitalPhotograph from "@/components/Goods/DigitalPhotograph";
import DigitalExhibit from "@/components/Goods/DigitalExhibit";
import DigitalYilapao from "@/components/Goods/DigitalYilapao";
import DigitalCard from "@/components/Goods/DigitalCard";

export default {
  name: "digitalMenu",
  components: {
    DigitalSinglePage,
    DigitalAdhesive,
    DigitalAlbum,
    DigitalJingzhuangAlbum,
    CustomizationReticule,
    CustomizationShowbill,
    DigitalCalendar,
    DigitalDeskCalendar,
    DigitalEnvelope,
    DigitalPhotograph,
    DigitalExhibit,
    DigitalYilapao,
    DigitalCard
  },
  data() {

    return {

      checked: 2028, // 切换模块
      menuInfo: [
        {
          id: 2028,
          img: "danye/DigitalSinglePage.jpg",
          text: "单页",
        },
        {
          id: 2037,
          img: "selfAdhesive/DigitalAdhesive1.jpg",
          text: "不干胶",
        },
        {
          id: 2020,
          img: "huace/DigitalAlbum1.jpg",
          text: "普通画册",
        },
        {
          id: 2034,
          img: "huace/DigitalAlbum2.jpg",
          text: "精装画册",
        },
        {
          id: 2015,
          img: "smallBatchCustomization/reticule2.jpg",
          text: "手提袋",
        },
        // {
        //   id: 2039,
        //   img: "smallBatchCustomization/showbill2.jpg",
        //   text: "吊旗",
        // },
        {
          id: 2036,
          img: "guali/DigitalCalendar1.jpg",
          text: "挂历",
        },
        {
          id: 2035,
          img: "taili.jpg",
          text: "台历",
        },
        {
          id: 2032,
          img: "fengtao.jpg",
          text: "封套",
        },
        {
          id: 2040,
          img: "xiezhen.jpg",
          text: "写真",
        },
        {
          id: 2041,
          img: "xxing.jpg",
          text: "X型展架",
        },
        {
          id: 2042,
          img: "yilabao.jpg",
          text: "易拉宝",
        },
        {
          id: 2043,
          img: "minpian.jpg",
          text: "名片",
        },
      ],

    };
  },
    created() {
      let id = window.sessionStorage.getItem("leftMenu")
      if (id) {
        this.checked = id;
      }
  },
  methods: {

    // 切换列表
    replace(item) {
      this.checked = item.id
    }

  }
};
</script>

<style lang="scss" scoped>
.digitalInfo {
  padding:20px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  .card-left {
    width: 200px;
    min-height: 1000px;
    .top {
      width: 100%;
      height: 86px;
      line-height: 86px;
      text-align: center;
      background-color: #047bd7;
      color: #fff;
      font-size: 24px;
      i {
        font-size: 28px;
        margin-right: 10px;
      }
    }
    .infos {
      width: 100%;
      box-sizing: border-box;
      // border: 1px solid #e6e6e6;
      // border-top: none;
      li {
        box-sizing: border-box;
        width: 100%;
        height: 55px;
        text-align: center;
        line-height: 55px;
        border-bottom: 1px dashed #e6e6e6;
        cursor: pointer;
      }
      li:last-child {
        border-bottom: none;
      }
      .checked {
        background-color: #058af2;
        color: #fff;
        font-weight: 600;
      }
    }
  }
  .card-right {
    width: 980px;
    background-color: #fff;
  }
  .info {
    width: 100%;
    box-sizing: border-box;
    // padding: 15px;
  }
  .card {
    width: 100%;
    .card-top {
      display: flex;
      align-items: center;
      height: 50px;
      border-bottom: 1px solid #e6e6e6;
      padding: 0 30px;
      line-height: 50px;
      span {
        display: inline-block;
        width: 5px;
        height: 17px;
        background: #058af2;
        margin-right: 20px;
      }
      h3 {
        color: #058af2;
      }
    }
    .list {
      width: 100%;
      box-sizing: border-box;
      padding: 5px 30px;
      .list-top {
        display: flex;
        justify-content: space-between;
        span {
          color: #058af2;
        }
        span:last-child:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
      .list-item {
        width: 100%;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .items {
          margin-top: 10px;
          width: 255px;
          height: 155px;
          background: transparent url("../../static/imgs/beijing1.png")
            no-repeat scroll 0% 0%;
          position: relative;
          .border {
            box-sizing: border-box;
            padding: 15px;
            font-size: 14px;
            font-weight: 400;
            p {
              padding: 3px 0 5px 0;

              border-bottom: 1px solid #e6e6e6;
            }
            i {
              display: inline-block;
              margin-right: 10px;
            }
            p:nth-child(2) {
              height: 65px;
              display: flex;
              align-items: center;
              span {
                display: block;
                overflow: hidden;
                white-space: wrap;
                text-overflow: ellipsis;
              }
            }
            p:last-child {
              border-bottom: none;
            }
          }
          .top {
            position: absolute;
            top: 0;
            right: 15px;
            font-size: 14px;
            height: 20px;
            line-height: 20px;
            color: #fff;
            .top-df {
              display: none;
              background: #fbb244 none repeat scroll 0% 0%;
              padding: 0px 10px;
              border-radius: 0px 0px 5px 5px;
            }
          }
          .active {
            display: block;
            padding: 0 10px;
            background: #058af2 none repeat scroll 0% 0%;
          }
          .top:hover {
            cursor: pointer;
            text-decoration: underline;
          }
          .bottom {
            display: none;
            position: absolute;
            right: 20px;
            bottom: 8px;
            cursor: pointer;
            font-size: 14px;
            p {
              float: left;
              border: 1px solid #d4d4d4;
              margin-left: 5px;
              height: 25px;
              line-height: 25px;
              width: 40px;
              text-align: center;
              border-radius: 3px;
              background-color: #fff;
            }
            p:hover {
              color: #058af2;
            }
          }
        }
        .items-bgd {
          background: transparent url("../../static/imgs/beijing2.png")
            no-repeat scroll 0% 0%;
        }
        .items:hover .top-df {
          display: block;
        }
        .items:hover .bottom {
          display: block;
        }
        .items:nth-last-child(-n + 1) {
          height: 0;
          background: none;
        }
      }
    }
    .card-form {
      width: 50%;
      margin: 30px auto;
    }
  }
}
</style>
